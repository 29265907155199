import React from "react";
import { useSelector } from "react-redux";
import MaskapaiChart from "./MaskapaiChart";
import CargoSHCByCargo from "./Cargo/SHC";
import FavoritDestination from "./Pax/FavoritDestination";
import OTPhartByAirline from "./Airline/OTP";
import AirportOtp from "./Airport/OtpSection/index";
import FlightRouteTop from "./Flight/RouteTop";

const Kargo5thCharts = () => {
    const {
        dashboardState: { summaryCard },
    } = useSelector((item) => item.apps);

    return renderContent(summaryCard)
};

const renderContent = (summary) => {
    switch(summary){
        case "cargo": return <CargoSHCByCargo />
        case "airport": return <AirportOtp />
        case "flight": return <FlightRouteTop />
        case "airline": return <OTPhartByAirline />
        case "pax": return <FavoritDestination />
        default : return <MaskapaiChart />
    }
}

export default Kargo5thCharts;
