import React from "react";
import { useDashboardChart } from "../../../../../../../hooks/useDashboardChart";
import logo from "../../../../../../../assets/icons/bandara_dark.svg";
import { useParams } from "react-router-dom";
import { Spin, Typography } from "antd";
import Config from "../../../../../../../config/env";
import { useFontSizeV2 } from "../../../../../../../hooks/useDimension";

const { primaryColor, secondaryColor } = Config;

const InfoAirline = ({ filterPage }) => {
  const { idAirline } = useParams();
  const fontSize = useFontSizeV2();
  const directory = (code) => {
    return `/assets/carrier/${code}.png`;
  };

  const [detailInfo, loadingDetailInfo] = useDashboardChart({
    ...filterPage,
    airline_id: idAirline,
    type: "info",
    routes: "/airline-detail/otp",
  });

  return (
    <div
      style={{
        padding: "0px 10px",
        display: "flex",
        alignItems: "center",
        gap: 32,
        marginTop: 2,
        height: "100%",
      }}
    >
      <div
        style={{
          width: "50%",
          height: "100%",
          padding: 16,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!!detailInfo?.code ? (
          <img
            src={directory(detailInfo?.code?.toLowerCase())}
            style={{ width: 100 }}
          />
        ) : (
          <Spin />
        )}
        <div style={{ marginTop: 10 }}>
          {!!detailInfo ? (
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: fontSize.xl,
                color: primaryColor,
              }}
            >
              {detailInfo?.name?.toUpperCase()}(
              <span style={{ color: secondaryColor }}>{detailInfo?.code}</span>)
            </Typography>
          ) : (
            <span>Loading . . .</span>
          )}
        </div>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          padding: 8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          //   border: "1px solid red",
        }}
      >
        <Typography style={{ fontSize: fontSize.lg + 5 }}>
          <span>ICAO Code</span> <br />{" "}
          <span style={{ fontWeight: "bold", color: secondaryColor }}>
            {detailInfo?.icao}
          </span>
        </Typography>

        <Typography style={{ fontSize: fontSize.lg + 5 }}>
          <span>Kode Negara</span> <br />{" "}
          <span style={{ fontWeight: "bold", color: secondaryColor }}>
            {detailInfo?.country}
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default InfoAirline;
