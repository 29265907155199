import { tooltipFormater } from "../../Chart/tooltipFormater";
import { buildDataTable } from "../../../../../helper/buildDataTable";

const getDataAirlineByAirport = (dataSource, selectedAirport) => {
  if (!dataSource || !selectedAirport) {
    return [[], [], []];
  }
  let result = {};
  let airportsList = {};

  for (let iii = 0; iii < dataSource.length; iii++) {
    const {
      airport_code,
      airport_id,
      airport_name,
      airline_code,
      domestic,
      international,
      ...rest
    } = dataSource[iii];
    const by = airline_code || "-";
    if (selectedAirport === "all") {
      if (!result[by]) {
        result[by] = {
          totalDom: domestic,
          totalInt: international,
        };
      } else {
        let sumDom = (result[by].totalDom += domestic);
        let sumInt = (result[by].totalInt += international);

        result[by] = {
          totalDom: sumDom,
          totalInt: sumInt,
        };
      }
      if (!result[by]?.originObj) {
        result[by] = {
          ...result[by],
          originObj: { airline_code, ...rest },
        };
      }
    } else if (airport_code === selectedAirport) {
      if (!result[by]) {
        result[by] = {
          totalDom: domestic,
          totalInt: international,
        };
      } else {
        let sumDom = (result[by].totalDom += domestic);
        let sumInt = (result[by].totalInt += international);

        result[by] = {
          totalDom: sumDom,
          totalInt: sumInt,
        };
      }
      if (!result[by]?.originObj) {
        result[by] = {
          ...result[by],
          originObj: { airline_code, ...rest },
        };
      }
    }

    airportsList[airport_code] = {
      code: airport_code,
      airport_id,
      name: airport_name,
    };
  }

  let set = [];
  let dataTable = [];
  for (const key in result) {
    if (Object.hasOwnProperty.call(result, key)) {
      const { totalDom, totalInt, originObj } = result[key];
      const grandTotal = totalDom + totalInt;
      set.push([key, totalDom, totalInt, grandTotal, originObj]);
      dataTable.push({
        totalDom,
        totalInt,
        key: `${key} - ${originObj.airline_name}`,
        grandTotal,
        ...originObj,
      });
    }
  }

  let airports = [];
  for (const key in airportsList) {
    if (Object.hasOwnProperty.call(airportsList, key)) {
      const airport = airportsList[key];
      airports.push(airport);
    }
  }

  const dataset = [
    {
      dimensions: [
        "label",
        "domestic",
        "international",
        "grandTotal",
        "originObj",
      ],
      source: set.sort((a, b) => {
        const [, , , aGrandTot] = a;
        const [, , , bGrandTot] = b;
        return aGrandTot - bGrandTot;
      }),
    },
    {
      transform: {
        type: "sort",
        config: { dimension: "grandTotal", order: "asc" },
      },
    },
  ];
  return [
    dataset,
    buildDataTable(
      dataTable.sort((a, b) => b.grandTotal - a.grandTotal),
      [
        {
          title: "Bandara",
          type: "string",
          name: "key",
          align: "left",
        },
        {
          title: "Total Domestik",
          type: "number",
          name: "totalDom",
          align: "right",
        },
        {
          title: "Total Internasional",
          type: "number",
          name: "totalInt",
          align: "right",
        },
        {
          title: "Grand Total",
          type: "number",
          name: "grandTotal",
          align: "right",
        },
      ]
    ),
    airports,
  ];
};

const renderTooltip = (params) => {
  const { data: domData } = params[0];
  return tooltipFormater({
    title: {
      label: `${domData[4]["airline_code"]} - ${domData[4]["airline_name"]}`,
    },
    params,
  });
};

const getDataAirportByAirline = (dataSource) => {
  if (!dataSource) {
    return [[], []];
  }
  let set = dataSource?.sort((a, b) => b.sumData - a.sumData);
  const dataset = [
    {
      dimensions: [
        "code",
        "name",
        "international",
        "domestic",
        "operator",
        "icao_code",
        "kota",
        "_id",
      ],
      source: set,
    },
    {
      transform: {
        type: "sort",
        config: { dimension: "label", order: "asc" },
      },
    },
  ];
  return [
    dataset,
    buildDataTable(set, [
      {
        title: "Code",
        type: "string",
        name: "code",
        align: "left",
      },
      {
        title: "Name",
        type: "string",
        name: "name",
        align: "left",
      },
      {
        title: "International",
        type: "number",
        name: "international",
        align: "left",
      },
      {
        title: "Domestic",
        type: "number",
        name: "domestic",
        align: "left",
      },
      {
        title: "Operator",
        type: "number",
        name: "operator",
        align: "left",
      },
      {
        title: "ICAO",
        type: "number",
        name: "icao_code",
        align: "left",
      },
      {
        title: "Kota",
        type: "number",
        name: "kota",
        align: "left",
      },
    ]),
  ];
};

const getDataOTPByAirline = (dataSource) => {
  if (!dataSource) {
    return [[], []];
  }

  const dataset = [
    {
      dimensions: ["code", "name", "total", "otp"],
      source: dataSource,
    },
    {
      transform: {
        type: "sort",
        config: { dimension: "label", order: "asc" },
      },
    },
  ];
  return [
    dataset,
    buildDataTable(dataSource, [
      {
        title: "Code",
        type: "string",
        name: "code",
        align: "left",
      },
      {
        title: "Name",
        type: "string",
        name: "name",
        align: "left",
      },
      {
        title: "Total",
        type: "number",
        name: "total",
        align: "right",
      },
      {
        title: "OTP",
        type: "number",
        name: "otp",
        align: "right",
      },
    ]),
  ];
};

export default {
  getDataAirlineByAirport,
  renderTooltip,
  getDataAirportByAirline,
  getDataOTPByAirline,
};
