import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import ForeignCitizen from "../../../../../../features/PanelChart/ForeignCitizen";
export default function PaxForeignCitizen() {
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/pax",
        type: "nationality",
        doSend: true,
        regenerate: true,
    });

    return <ForeignCitizen dataSource={data} filterPage={filterPage} />;
}
