import numeral from "numeral";

const customFormat = (value) => {
  if (value >= 1000000) {
    return numeral(value / 1000000).format("0.0") + "jt";
  } else if (value >= 1000) {
    return numeral(value / 1000).format("0.0") + "rb";
  } else {
    return numeral(value).format("0a");
  }
};

export default customFormat;

export function customFormatWeight(value) {
  return value?.length > 4
    ? numeral(value / 1000).format("0.0")
    : (value / 1000).toFixed(2);
}
