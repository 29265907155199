import React, { Fragment } from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
import { levelToRole } from '../../../../../redux/reducer/levelConvert';
import { green, red } from '@ant-design/colors';
import { Button, Typography } from 'antd';
import { RiCheckboxCircleFill, RiCloseCircleFill } from 'react-icons/ri';
import { GetAPI } from '../../../../../redux';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
const getArea=(row)=>{
    if((row.level & 1)>0){
        return row?.tpi_id?.code;
    }else if((row.level & 2)>0){
        return row?.kanim_id?.name;
    }else if((row.level & 8)>0){
        return row?.kanwil_id?.name;
    }else if((row.level & 4)>0){
        return row?.subdit.toUpperCase();
    }
    return '-';
}
export default () => {
    const columns = [
        {
            selector: (row) =>{ 
                const {block, username} = row;
                const color=block?red[5]:green[5];
                return (
                    <div style={{display:'flex', flexDirection:'row', gap:4, justifyContent:'flex-start', alignItems:'center'}}>
                        <div style={{borderRadius:4, width:8, height:8, background:color}}></div>
                        <Typography.Text>{username}</Typography.Text>
                    </div>
                )
            },
            name: "Username",
        },
        {
            selector: (row) => row?.name,
            name: "Name",
        },
        {
            selector: (row) => row?.email,
            name: "Email",
        },
        {
            selector: (row) => levelToRole(row?.level),
            name: "Level",
        },
        {
            selector: (row) => getArea(row),
            name: "Area",
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(moment().unix());
    return (
        <TablePage
            title={"Master Users"}
            url="api/v1/users"
            actionPosition={'first'}
            createPath="/master/users/create"
            editPath={"/master/users/edit"}
            columns={columns}
            refresh={refresh}
            otherAction={(row)=>{
                const {block, _id} = row;
                const Icon=block?<RiCheckboxCircleFill color={green[5]}/>:<RiCloseCircleFill color={red[5]} />;
                const title=block?'Enable User':"Disabled User";
                return (
                    <Button 
                        shape='circle'
                        icon={Icon}
                        type="link"
                        title={title}
                        onClick={()=>{
                            const disen=block?'enabled':'disabled';
                            // history.push({pathname:editPath, state:{...row, isCreate:false}});
                            dispatch(GetAPI({url:`api/v1/users/${disen}/${_id}`})).then(res=>{
                                if (!!res?.payload) {
                                    setRefresh(moment().unix());
                                }
                            })
                        }}
                    /> 
                )
            }}
        />
    )
}