import React from "react";
import { useSelector } from "react-redux";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import PanelOTPChart from "./OTPChart";

const OTPhartByAirline = () => {
  const { filterPage } = useSelector((item) => item.apps);
  const [data, loading] = useDashboardChart({
    ...filterPage,
    routes: "/airline",
    type: "otp",
    doSend: true,
    regenerate: true,
  });
  return (
    <PanelOTPChart data={data} loading={loading} filterPage={filterPage} />
  );
};

export default OTPhartByAirline;
