import numeral from "numeral";

const defMarker = `<span style=\"display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:white;\"></span>`;
const getItems = (items = [], fontSize, withComma) => {
    let result = [];
    for (let iii = 0; iii < items.length; iii++) {
        const { seriesName, data, marker } = items[iii] || {
            seriesName: "",
            data: "",
            marker: "",
        };
        result.push(`<div class="item-tooltip">
                         <div style="font-size:${
                             fontSize || 14
                         }px;text-transform: capitalize;">
                             ${marker || defMarker} ${seriesName}
                        </div>
                         <div class="text-right text-bold" style="font-size:${
                             fontSize || 15
                         }px;">
                         ${numeral(data[iii + 1]).format(
                             withComma ? "0,000.00" : "0,000"
                         )}
                         </div>
                     </div>`);
    }
    return result.join(" ");
};

export const tooltipFormater = ({
    items,
    title = {},
    fontSize,
    params = [],
    withComma,
}) => {
    const { label, value } = title || { label: "", value: "" };
    return `<div class="main-tooltip">
        <div style="margin-bottom:4px;">
            <strong style="font-size:${
                fontSize || 14
            }px;text-transform: capitalize;">${label} ${
        value ? `(${value})` : ""
    }</strong>
        </div>
        <div class="container-tooltip">
        ${getItems(items || params, undefined, withComma)}
        </div>
    </div>`;
};
