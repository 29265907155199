import React, { Fragment, useMemo, useState } from "react";
import moment from "moment";
import DashboardChart from "../../../pages/dashboard/home/components/DashboardChartV2";
import customFormat from "../../../../../helper/customFormat";
import utilsPaxs from "./utilsPaxs";
import SelectAirport from "../../Form/SelectAirport";
import RadioPeriodic from "../../Form/RadioPeriodic";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import { resetStyleTooltips } from "../../Chart/resetStyleTooltip";

const getOptions = (dataSet, fontSize, filters) => {
    return {
        dataset: dataSet,
        tooltip: {
            trigger: "axis",
            ...resetStyleTooltips,
            formatter: (params) => utilsPaxs.renderTooltip(params, filters),
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },

        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            left: "0%",
            right: "0%",
            bottom: "12%",
            containLabel: true,
            top: "10%",
        },
        xAxis: {
            type: "category",
            axisLabel: {
                textStyle: {
                    fontSize: fontSize.sm,
                },
                formatter:
                    filters.periodic === "daily"
                        ? (params) =>
                              moment(params, "DD-MMM-YYYY").format("DD MMM")
                        : (params) => `${params}`,
            },
        },
        yAxis: {
            type: "value",
            axisLabel: {
                formatter: (params) => customFormat(params),
            },
        },
        series: [
            {
                name: `Domestic`,
                type:
                    filters.periodic === "daily" || filters.periodic === "all"
                        ? "line"
                        : "bar",
                symbol: "none",
                barGap: 0,
                datasetIndex: 0,
                barWidth: 16,
                encode: { x: "label", y: "domestic" },
                emphasis: {
                    focus: "series",
                },
                color: "#023047",
                itemStyle: {
                    color: "#023047",
                },
                areaStyle: {
                    color: "#02304720",
                },
                lineStyle: {
                    width: 3,
                },
            },
            {
                name: `International`,
                type:
                    filters.periodic === "daily" || filters.periodic === "all"
                        ? "line"
                        : "bar",
                symbol: "none",
                barGap: 0,
                barWidth: 16,
                datasetIndex: 0,
                encode: { x: "label", y: "international" },
                emphasis: {
                    focus: "series",
                },
                color: "#FFB703",
                lineStyle: {
                    width: 3,
                },
                itemStyle: {
                    color: "#FFB703",
                },
                areaStyle: {
                    color: "#FFB70320",
                },
            },
        ],
    };
};

export default function PanelChartPax({
    dataSource = [],
    loading,
    filterPage,
}) {
    const [filters, setFilters] = useState({
        airport: "all",
        periodic: "daily",
    });
    const fontSize = useFontSizeV2();
    const [dataset, datatable, airportsList] = useMemo(
        () =>
            utilsPaxs.getDataPaxByAirport(
                dataSource,
                filters.airport,
                filters.periodic
            ),
        [dataSource, filters.airport, filters.periodic]
    );

    return (
        <DashboardChart
            // loading={loading}
            printHeader={`Penumpang ${filters.airport} Report`}
            printPeriode={`${filters.periodic} ${filterPage.year}`}
            repType={`Penumpang ${filters.airport} Report`}
            title={"Penumpang"}
            dataSource={datatable}
            data={getOptions(dataset, fontSize, { ...filters, ...filterPage })}
            more={
                <SelectAirport
                    onChange={(value) =>
                        setFilters((state) => ({ ...state, airport: value }))
                    }
                    value={filters.airport}
                    dataSource={airportsList}
                />
            }
            chartPanelRender={(ChartPanel) => {
                return (
                    <div style={{ padding: 12, height: "100%" }}>
                        <RadioPeriodic
                            value={filters.periodic}
                            onChange={(value) =>
                                setFilters((state) => ({
                                    ...state,
                                    periodic: value,
                                }))
                            }
                        />
                        {ChartPanel}
                    </div>
                );
            }}
        />
    );
}
