import { GiAirplane } from "react-icons/gi";
import { BsMailbox } from "react-icons/bs";
import { RiDashboard3Line, RiSearchEyeLine } from "react-icons/ri";
import { FaCogs } from "react-icons/fa";
import DashboardMenu from "./menus/dashboard";
import FlightMenu from "./menus/flight";
import TelexMenu from "./menus/telex";
import OtherMenu from "./menus/others";
import PassengersMenu from "./menus/passengers";
import { useEffect, useState } from "react";
import { MdOutlinePeopleOutline } from "react-icons/md";
const AllCategory = [
    {
        caption: "dashboard",
        Icon: RiDashboard3Line,
    },
    {
        caption: "search",
        Icon: RiSearchEyeLine,
    },
    {
        caption: "flight",
        Icon: GiAirplane,
    },
    {
        caption: "telex",
        Icon: BsMailbox,
    },
    {
        caption: "passengers",
        Icon: MdOutlinePeopleOutline,
    },
    {
        caption: "others",
        Icon: FaCogs,
    },
];

const useMenu = () => {
    const [menu, setMenu] = useState([]);
    const [category, setCategory] = useState([]);
    useEffect(() => {
        setMenu([
            ...DashboardMenu,
            ...FlightMenu,
            ...PassengersMenu,
            ...TelexMenu,
            ...OtherMenu,
        ]);
        setCategory(AllCategory);
    }, []);
    return [menu, category];
};

export default useMenu;
