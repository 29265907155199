import React, { useMemo, useState } from 'react';

import Table from '../../lists/table';


export default ({data}) => {
    const numberedData=useMemo(()=>{
        return data.map((row, idx)=>({...row, number:idx+1}));
    }, [data]);
    return (
        <Table
            data={numberedData}
            filterField={['house_number', 'mawb', 'commodity', 'desc']}
            url="/house/detail"
            getRowId={row=>row?.awb_id?._id}
            columns={[
                {
                    title:'#',
                    dataIndex:'number',
                },
                {
                    dataIndex:'house_number',
                    title:'house_number',
                },
                {
                    dataIndex:'mawb',
                    title:'airway_bill',
                },
                {
                    title:'route',
                    dataIndex:'origin',
                    render:(val, rec)=>`${rec.origin?.code}-${rec.dest?.code}`
                },
                {
                    title:'commodity',
                    dataIndex:'commodity'
                },
                {
                    title:'desc',
                    dataIndex:'desc'
                },
                {
                    title:'shipper',
                    dataIndex:'shipper',
                    render:(val, {awb_id})=>val?.name || awb_id?.shipper?.name
                },
                {
                    title:'consignee',
                    dataIndex:'consignee',
                    render:(val, {awb_id})=>val?.name || awb_id?.consigne?.name
                },
                {
                    title:'pieces',
                    dataIndex:'shipment_detail',
                    align:'right',
                    render:(val)=>val.pieces
                },
                {
                    title:'weight',
                    dataIndex:'shipment_detail',
                    align:'right',
                    render:(val)=>val.weight
                },
            ]}
        />
    )
}   