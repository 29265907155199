import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { setApps, initMe } from "../../redux";
import SplashPage from "../../splash";
import { setDashboardState, setFilterPage } from "../../redux/reducer/apps";
import Login from "../dashboard-analytic-mobile/pages/login";
import DashboardMobile from "./pages";

const UserPage = ({ userdata }) => {
  return (
    <BrowserRouter basename="/dashboard-analytic">
      <Switch>
        <Route exact path="/">
          {(!!userdata && <Redirect to={"/home"} />) || (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/login">
          {(!!userdata && (userdata.level & 0x1ff0) > 0 && (
            <Redirect to={"/home"} />
          )) || <Login />}
        </Route>
        <Route path="/*">
          {(!!userdata && (userdata.level & 0x1ff0) > 0 && (
            <DashboardMobile />
          )) || <Redirect to="/login" /> || <Redirect to="/login" />}
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default () => {
  const { userdata, initComplete } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setApps("dashboard-hubud")); // set applikasi
    dispatch(initMe()); // cek token validasi
    dispatch(setFilterPage({ chart: "all" }));
    dispatch(setDashboardState({ summaryCard: "", chart: "all" }));
  }, [initMe, setApps]);

  if (!initComplete) return <SplashPage />;
  return <UserPage userdata={userdata} />;
};
