import React, { useState, useMemo } from "react";
import DashboardChart from "./DashboardChart";
import * as echarts from "echarts"; // Import ECharts library
import { getFilterData } from "../../../../../../helper/getFilterData";
import moment from "moment";
import { Fragment } from "react";
import customFormat from "../../../../../../helper/customFormat";
import { useDispatch, useSelector } from "react-redux";
import NotFoundData from "../../../../../../components/NotFound";
import {
  setDashboardState,
  setFilterPage,
} from "../../../../../../redux/reducer/apps";
import { useEffect } from "react";
import compareDates from "../../../../../../helper/compareDates";
import { useFontSizeV2 } from "../../../../../../hooks/useDimension";
import Config from "../../../../../../config/env";
import { useDashboardChart } from "../../../../../../hooks/useDashboardChart";
const { chartColor } = Config;

const PenerbanganChart = () => {
  const [viewProd, setViewProd] = useState("monthly");
  const [direct, setDirect] = useState("");
  const dispatch = useDispatch();
  const fontSize = useFontSizeV2();

  const {
    filterPage,
    dashboardState,
} = useSelector((item) => item.apps);

const [dataFlightV2, loadingFlightV2] = useDashboardChart({
    ...filterPage,
    type: "flightv2",
    // doSend:
    //   dashboardState.chart === "all" || dashboardState.chart === "penerbangan",
});

const dataFlightsV2 = useMemo(() => getFlights(dataFlightV2), [dataFlightV2, filterPage]);


  const [flightCarter, flightReguler, flightFreighter] = useMemo(() => getData(dataFlightsV2), [dataFlightsV2, viewProd]);


  useEffect(() => {
    dispatch(setFilterPage({ direct }));
    dispatch(
      setDashboardState({
        chart: direct === "" ? "all" : "penerbangan",
      })
    );
  }, [direct]);

  const dataTable = useMemo(() => getTables(dataFlightsV2) , [dataFlightsV2]);

  
  if(!dataFlightV2 ){
    return <NotFoundData />
  }

  return (
    <Fragment>
      <DashboardChart
        repType={"Penerbangan"}
        printHeader={"Penerbangan Report"}
        dataSource={dataTable}
        loading={loadingFlightV2}
        direct={direct}
        setDirect={setDirect}
        isDaily={false}
        view={
          dashboardState?.filterState === "all"
            ? dashboardState?.filterTime
            : viewProd
        }
        setView={setViewProd}
        title={"Penerbangan"}
        // isOtherComponent
        isFilterComponent
        data={getOptions(dashboardState, flightReguler, viewProd, flightCarter, flightReguler, flightFreighter, fontSize)}
      />
    </Fragment>
  );
};

export default PenerbanganChart;

const columns = [
  {
    title: "Penerbangan",
    type: "string",
    name: "penerbangan",
    align: "left",
  },
  {
    title: "Total",
    type: "string",
    name: "total",
    align: "right",
  },
];

const getTables = (flights) => {
  const dataSource = [];
  const keys = Object.keys(flights);
  keys?.forEach((item) => {
    dataSource.push({
      penerbangan: item,
      total: flights[item]?.reduce((prev, current) => prev + current.curr, 0),
    });
  });

  return {
    data: dataSource,
    fields: columns,
    header: columns.map((item) => item.title),
  };
}

const getFlights = (data) => {
  {
    const dataRef = !!data ? data : [];
    const dataCarter = dataRef?.map(({ date, carter }) => ({
        name: date,
        curr: carter,
    }));
    const dataReguler = dataRef?.map(({ date, reguler }) => ({
        name: date,
        curr: reguler,
    }));
    const dataFreighter = dataRef?.map(({ date, freighter }) => ({
        name: date,
        curr: freighter,
    }));
    return {
        carter: dataCarter,
        reguler: dataReguler,
        freighter: dataFreighter,
    };
}
}


const getOptions = (dashboardState, flightReguler, viewProd, flightCarter, flightFreighter, fontSize) => {
  return {
    tooltip: {},
    legend: { bottom: -5 },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "15%",
      containLabel: true,
      top: "10%",
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      data:
        flightReguler[
          dashboardState.filterState === "all" &&
          dashboardState.filterTime !== "daily"
            ? dashboardState.filterTime
            : viewProd
        ]?.length !== 0
          ? flightReguler[
              dashboardState.filterState === "all" &&
              dashboardState.filterTime !== "daily"
                ? dashboardState.filterTime
                : viewProd
            ]?.map((item) =>
              viewProd === "monthly"
                ? moment()
                    .month(item.name - 1)
                    .format("MMMM")
                : item.name
            )
          : flightCarter[
              dashboardState.filterState === "all" &&
              dashboardState.filterTime !== "daily"
                ? dashboardState.filterTime
                : viewProd
            ].map((item) =>
              viewProd === "monthly"
                ? moment()
                    .month(item.name - 1)
                    .format("MMMM")
                : item.name
            ),
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => customFormat(params),
      },
    },
    series: [
      {
        name: "Reguler",
        type: "bar",
        stack: "total",
        data: flightReguler[
          dashboardState.filterState === "all" &&
          dashboardState.filterTime !== "daily"
            ? dashboardState.filterTime
            : viewProd
        ]?.map((item) => item.curr),
        barWidth: fontSize.xl,
        // barGap: 5,
        itemStyle: {
          color: "#FB8500",
        },
      },
      {
        name: "Freighter",
        type: "bar",
        stack: "total",
        data: flightFreighter[
          dashboardState.filterState === "all" &&
          dashboardState.filterTime !== "daily"
            ? dashboardState.filterTime
            : viewProd
        ]?.map((item) => item.curr),
        barWidth: fontSize.xl,
        // barGap: 5,
        itemStyle: {
          color: "#023047",
        },
      },
      {
        name: "Charter",
        type: "bar",
        stack: "total",
        data: flightCarter[
          dashboardState.filterState === "all" &&
          dashboardState.filterTime !== "daily"
            ? dashboardState.filterTime
            : viewProd
        ]?.map((item) => item.curr),
        barWidth: fontSize.xl,
        // barGap: 5,
        itemStyle: {
          color: chartColor[2],
        },
      },
    ],
  };
}

const getData = (data) => {
  const { carter, reguler, freighter } = data;
  if (!data)
    return [
      { yearly: [], semesterly: [], quarterly: [], monthly: [] },
      { yearly: [], semesterly: [], quarterly: [], monthly: [] },
    ];
  const {
    semesterly: sNotSch,
    monthly: mNotSch,
    quarterly: qNotSch,
  } = getFilterData(carter?.sort(compareDates));
  const {
    semesterly: sSch,
    monthly: mSch,
    quarterly: qSch,
  } = getFilterData(reguler?.sort(compareDates));
  const {
    semesterly: sFreighter,
    monthly: mFreighter,
    quarterly: qFreighter,
  } = getFilterData(freighter?.sort(compareDates));
  return [
    {
      semesterly: sNotSch,
      quarterly: qNotSch,
      monthly: mNotSch,
    },
    {
      semesterly: sSch,
      quarterly: qSch,
      monthly: mSch,
    },
    {
      semesterly: sFreighter,
      quarterly: qFreighter,
      monthly: mFreighter,
    },
  ];
}