import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import MapAirportActive from "../../../../../../features/PanelChart/MapAirportActive";

export default function AirportActiveMaps() {
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/pax",
        type: "maps",
        doSend: true,
        regenerate: true,
    });
    return <MapAirportActive dataMaps={data} />;
}
