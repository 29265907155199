export const sortingLabel = (data, propsBy) => {
    return data.sort((a, b) => {
        const labelA = a[propsBy || "label"]?.toUpperCase();
        const labelB = b[propsBy || "label"]?.toUpperCase();

        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }

        return 0;
    });
};
