export default function separateDataByProp(data, prop) {
  const separatedData = {};

  !!data &&
    data?.forEach((item) => {
      const cityName = item[`${prop}`];

      if (!separatedData[cityName]) {
        separatedData[cityName] = [item];
      } else {
        separatedData[cityName].push(item);
      }
    });

  return separatedData;
}
