import React, { Fragment, useState } from "react";
import { Form, Input, DatePicker, Select } from "antd";
import ModalFormSearch from "../../../components/ModalFormSearch";
import { isFalsyButNotZero, isSameArray } from "../../../../../helper";
import moment from "moment";

const { RangePicker } = DatePicker;

const statusOpt = [
    {
        label: "Pending",
        value: 0,
    },
    {
        label: "Success",
        value: 1,
    },
    {
        label: "Error",
        value: 2,
    },
    {
        label: "Ignored",
        value: 3,
    },
];

export default function Search({
    onSearchCallback,
    searchValues,
    onSearchChanged,
}) {
    return (
        <ModalFormSearch
            initialValues={searchValues}
            onFinish={onSearchCallback}
            onSearchChanged={onSearchChanged}
            withTagFilter={(values) => {
                const { timestamp, telex_date, status, ...rest } = values;
                let newModel = { ...rest };

                if (telex_date) {
                    const [dt1, dt2] = telex_date;
                    const formatedDate = [
                        moment(dt1).format("DD-MMM-YYYY"),
                        moment(dt2).format("DD-MMM-YYYY"),
                    ];
                    const isSame = isSameArray(
                        [
                            moment().format("DD-MMM-YYYY"),
                            moment().add(1, "day").format("DD-MMM-YYYY"),
                        ],
                        formatedDate
                    );
                    newModel = isSame
                        ? newModel
                        : { ...newModel, telex_date: formatedDate.join("/") };
                }
                if (isFalsyButNotZero(status)) {
                    const findStatus = statusOpt.find(
                        (item) => item?.value === status
                    );
                    newModel = { ...newModel, status: findStatus?.label };
                }
                return newModel;
            }}
            FormComponents={() => (
                <Fragment>
                    <Form.Item label="Tanggal" name="telex_date">
                        <RangePicker
                            format="DD-MMM-YYYY"
                            value={searchValues?.telex_date}
                            allowClear={false}
                        />
                    </Form.Item>
                    <Form.Item label="Asal" name="origin">
                        <Input placeholder="Ketik Asal..." />
                    </Form.Item>
                    <Form.Item label="Tujuan" name="destination">
                        <Input placeholder="Ketik Tujuan..." />
                    </Form.Item>
                    <Form.Item label="SMI" name="subSmi">
                        <Input placeholder="Ketik SMI..." />
                    </Form.Item>
                    <Form.Item label="Status" name="status">
                        <Select
                            placeholder="Pilih Status..."
                            options={statusOpt}
                        />
                    </Form.Item>
                </Fragment>
            )}
        />
    );
}
