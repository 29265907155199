import React, { useState } from "react";
import Tabs from "../../tabs";
import Detail from "./pages/detail";

export default ({ data, id }) => {
  const { flight_schedules_id } = data;
  const domestic = !flight_schedules_id?.is_international;
  const paxUri = `/pnr/passengers${domestic ? "/domestic" : ""}/${id}`;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Tabs
        selTab={0}
        tabs={[
          {
            id: 0,
            caption: "detail",
            href: `/pnr/detail/${id}`,
          },
          {
            id: 1,
            caption: "passengers",
            href: paxUri,
          },
        ]}
      />
      <Detail data={data} />
    </div>
  );
};
