import React, { useMemo, useState } from "react";
import MainPage from "../../../components/MainPage";
import moment from "moment";
import Search from "./Search";

const defValue = {
    origin: "",
    destination: "",
    telex_date: [moment(), moment().add(1, "day")],
    timestamp: moment().unix(),
    subSmi: "",
};

const getStatusTxt = (status) => {
    switch (status) {
        case 0:
            return "Pending";
        case 1:
            return "Success";
        case 2:
            return "Error";
        case 3:
            return "Ignored";
        default:
            break;
    }
};

const getHeaders = (headers) => {
    if (!headers) return false;
    if (!headers?.unh) return false;
    const { msgSubsetIdentification, msgRefNumber } = headers.unh;
    if (!msgSubsetIdentification) return false;
    const { msgSubsetVersionNumber, msgSubsetIdentification: subs } =
        msgSubsetIdentification;
    return `${msgRefNumber} ${subs}${msgSubsetVersionNumber || ""}`;
};

const getDomHeader = (message) => {
    const [, header] = message;
    return header;
};

export default function Inbox() {
    const [search, setSearch] = useState(defValue);
    const COLUMNS = [
        {
            title: "CORID",
            dataIndex: "corid",
            key: "corid",
            render: (val, row) => (
                <strong className="text-primary-new">
                    {row?.msgid || row?.corid}
                </strong>
            ),
        },
        {
            title: "TIPE TELEX",
            dataIndex: "telex_type",
            key: "telex_type",
            title: "telex_type",
            render: (val, row) => row.subSmi,
        },
        {
            title: "REFERENSI",
            dataIndex: "ref",
            key: "ref",
            grow: 2,
            render: (val, row) =>
                getHeaders(row.headers) || getDomHeader(row.message),
        },
        {
            title: "ASAL",
            dataIndex: "origin",
            key: "origin",
        },
        {
            title: "TUJUAN",
            dataIndex: "destination",
            key: "destination",
            render: (val, row) => val.join(", "),
        },
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            render: (val, row) => getStatusTxt(val),
        },
        {
            title: "WAKTU PESAN MASUK",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (val, row) =>
                val && moment(val).format("DD-MMM-YYYY HH:mm"),
        },
        {
            title: "WAKTU PROSES",
            dataIndex: "processedAt",
            key: "processedAt",
            render: (val, row) =>
                val && moment(val).format("DD-MMM-YYYY HH:mm"),
        },
    ];

    const formatedSearch = useMemo(() => {
        const { telex_date } = search;
        let newModel = { ...search };
        if (telex_date) {
            const [dt1, dt2] = telex_date;
            newModel = {
                ...newModel,
                telex_date: [
                    moment(dt1).format("DD-MMM-YYYY"),
                    moment(dt2).format("DD-MMM-YYYY"),
                ],
            };
        }

        return newModel;
    }, [search]);

    return (
        <MainPage
            url={"api/v1/telex/pagination"}
            searchValue={formatedSearch}
            columns={COLUMNS}
            searchComponent={
                <Search
                    searchValues={search}
                    onSearchChanged={(values) => {
                        let newModel = { ...values };
                        if (!newModel?.telex_date) {
                            newModel = {
                                ...newModel,
                                telex_date: defValue.telex_date,
                            };
                        }
                        setSearch({ ...search, ...newModel });
                    }}
                    onSearchCallback={(searchValue) => {
                        setSearch({
                            ...searchValue,
                            timestamp: moment().unix(),
                        });
                    }}
                />
            }
        />
    );
}
