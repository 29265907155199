import { buildDataTable } from "../../../../../../../../helper/buildDataTable";

const getDataAirportByCargo = (dataSource) => {
  if (!dataSource) {
    return [[], []];
  }
  let set = dataSource?.sort((a, b) => b.sumData - a.sumData);
  const dataset = [
    {
      dimensions: [
        "code",
        "dexport",
        "dimport",
        "incoming",
        "outgoing",
        "transit",
        "name",
        "_id",
      ],
      source: set,
    },
    {
      transform: {
        type: "sort",
        config: { dimension: "label", order: "asc" },
      },
    },
  ];
  return [
    dataset,
    buildDataTable(set, [
      {
        title: "Code",
        type: "string",
        name: "code",
        align: "left",
      },
      {
        title: "Name",
        type: "string",
        name: "name",
        align: "left",
      },
      {
        title: "Export",
        type: "number",
        name: "dexport",
        align: "right",
      },
      {
        title: "Import",
        type: "number",
        name: "dimport",
        align: "right",
      },
      {
        title: "Transit",
        type: "number",
        name: "transit",
        align: "right",
      },
    ]),
  ];
};
export default getDataAirportByCargo;
