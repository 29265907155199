import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import PanelChartTrendAirline from "../../../../../../features/PanelChart/TrendAirline";

export default function TrendAirline() {
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/flight",
        type: "daily",
        doSend: true,
        regenerate: true,
    });
    return (
        <PanelChartTrendAirline
            dataSource={data}
            loading={loading}
            filterPage={filterPage}
        />
    );
}
