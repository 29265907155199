import {  GiAirplaneArrival, GiAirplaneDeparture, GiCctvCamera, GiCommercialAirplane, GiExitDoor, GiHarborDock, GiHomeGarage, GiHouse, GiPoliceOfficerHead, GiShipBow, GiShoonerSailboat, GiSleevelessJacket } from 'react-icons/gi';
// import {HiHome} from 'react-icons/hi'
import {  useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Page from './parent';
import { useLanguage } from '../../../../hooks/useLanguage';
import LegendFormatter from '../components/category/charts/legend';
import { Button, DatePicker,  Select,  Typography } from 'antd';
import {TooltipDirectionArr, TooltipDirectionDep} from '../components/category/charts/tooltip';
import CFG from '../../../../config/env';
import { LightenDarkenColor } from '../../utils';
import Panel from '../dashboard/components/panel';
import numeral from 'numeral';
import { IoIosPeople } from 'react-icons/io';
import { ReloadOutlined } from '@ant-design/icons';
import { Bar, BarChart, Tooltip, ResponsiveContainer, XAxis, YAxis, PieChart, Pie, Cell, Legend } from 'recharts';
import LegendFormat from '../components/category/charts/legend';
import { blue, green, orange, purple, red, yellow } from '@ant-design/colors';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useFocus } from '../../../../hooks/useFocus';

const {BgColor, NoVessel} = CFG;

const Title = ({color, caption, Icon})=>{
    const [, getLang] = useLanguage();
    return (
        <Typography.Text style={{fontSize:'1.2rem', color, display:'flex', gap:8, alignItems:'center'}}> 
            <Icon />
            {getLang(caption)}
        </Typography.Text>
    )
}

const getColor=(name)=>{
    switch (name) {
        case 'interpol':
            return red[6];
        case 'cekal':
            return red[4];
        case 'passport-visa-ijin-tinggal':
            return orange[6]
        default:
            const percent=Math.floor(Math.random()*100) % 40;
            return LightenDarkenColor(yellow[5], percent);
    }
}

export default () => {
    const defInterval=localStorage.getItem('interval');
    const [date, setDate] = useState(moment());
    const [response, setResponse] = useState(null);
    const [tab2Active, setTab2Active] = useState('1');
    const [timestamp, setTimestamp]=useState(moment().unix());
    const color=LightenDarkenColor(BgColor, 20)
    const [, getLang] = useLanguage();
    const [interval, setItval]=useState(defInterval || '30');
    const {userdata:{level, tpis}} = useSelector(state=>state.auth);
    const focus = useFocus();
    const poll=useRef(null);
    const [ seaport_active, airport_active ] = useMemo(()=>{
        if((level & 0x1fff4)>0)return [true && !NoVessel, true];
        const [seaport_oke]=tpis.filter(({station_type})=>station_type==='seaport');
        const [airport_oke]=tpis.filter(({station_type})=>station_type==='airport');
        return [seaport_oke && !NoVessel, airport_oke];
    }, [level, tpis]);
    useEffect(()=>{
        if(!!focus)setTimestamp(moment().unix());
        if(!!poll.current)clearInterval(poll.current);
        poll.current=setInterval(()=>{
            if(!!focus){
                setTimestamp(moment().unix());
            }
        }, parseInt(interval) * 1000);
        return ()=>{
            clearInterval(poll.current);
        }        
    }, [interval, focus])
    const detail_cekal=useMemo(()=>{
        if(!response?.detail_cekal)return[];
        if(!Array.isArray(response?.detail_cekal))return [];
        return response?.detail_cekal.map(({_id, ...rest})=>({...rest, command:_id.command}))
    }, [response]);
    // console.log({airport_active, seaport_active})
    return (
        <Page
            title={'home'}
            icon={GiHouse}
            id={tab2Active === '1' ? date.format('DD-MMM-YYYY') : date.format('MMM-YYYY')}
            timestamp={timestamp}
            filters={{}}
            url={"api/v1/summary/landing"}
            onDataSuccess={(data) => {
                setResponse(data);
            }}
            bcumb={[
                {
                    href: '/home',
                    icon: <GiHouse color='white' />,
                    item: 'home'
                }
            ]}
        >
            <div style={{display:'flex', flexDirection:'column', height:'100%', width:'100%'}}>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'end', gap:16, padding:8}}>
                    <DatePicker
                        value={date}
                        allowClear={false}
                        format="DD-MMM-YYYY"
                        autoFocus
                        onChange={dt => setDate(dt)}
                        disabledDate={curr => curr > moment()}
                    /> 
                    <Select value={interval} 
                        onChange={(val)=>{
                            setItval(val)
                            localStorage.setItem('interval', val);
                        }}>
                        <Select.Option value="15">15</Select.Option>
                        <Select.Option value="30">30</Select.Option>
                        <Select.Option value="60">60</Select.Option>
                    </Select>
                    <Button onClick={() => setTimestamp(moment().unix())} icon={<ReloadOutlined />} type={'text'} />
                </div>
                <div style={{display:'flex', flexDirection:'row', width:'100%', gap:8, justifyContent:'center'}}>
                    {
                        airport_active && (
                            <Fragment>
                                <Link to={'/flight/schedule/arrival'}>
                                    <Panel
                                        Icon={GiAirplaneArrival}
                                        bgColor={'linear-gradient(146deg, rgb(10 19 201) 0%, rgb(68 86 185) 35%, rgb(0 69 255) 100%)'}
                                        title="flight"
                                        BgIcon={GiCommercialAirplane}
                                        bgPos={{ top: -4, bottom: 4, right: 0 }}
                                        color={'white'}
                                        value={numeral(response?.flights?.arrival || 0).format('0,000')}
                                    />
                                </Link>
                                <Link to={'/flight/schedule/departure'} >
                                    <Panel
                                        Icon={GiAirplaneDeparture}
                                        bgColor={'linear-gradient(146deg, rgb(10 19 201) 0%, rgb(68 86 185) 35%, rgb(0 69 255) 100%)'}
                                        title="flight"
                                        BgIcon={GiCommercialAirplane}
                                        bgPos={{ top: -4, bottom: 4, right: 0 }}
                                        color={'white'}
                                        value={numeral(response?.flights?.departure || 0).format('0,000')}
                                    />
                                </Link>
                            </Fragment>
                        )
                    }
                    {
                        seaport_active && (
                            <Fragment>
                                <Link to={'/vessel/arrival'}>
                                    <Panel
                                        Icon={GiHarborDock}
                                        bgColor={'linear-gradient(155deg, rgba(5,75,139,1) 0%, rgba(0,97,179,1) 49%, rgba(5,84,139,1) 84%)'}
                                        title="flight"
                                        BgIcon={GiShoonerSailboat}
                                        bgPos={{ top: -20, bottom: -7, right: -26 }}
                                        color={'white'}
                                        value={numeral(response?.vessels?.arrival || 0).format('0,000')}
                                    />
                                </Link>
                                <Link to={'/vessel/departure'}>
                                    <Panel
                                        Icon={GiShipBow}
                                        bgColor={'linear-gradient(155deg, rgba(5,75,139,1) 0%, rgba(0,97,179,1) 49%, rgba(5,84,139,1) 84%)'}
                                        title="flight"
                                        BgIcon={GiShoonerSailboat}
                                        bgPos={{ top: -20, bottom: -7, right: -26 }}
                                        color={'white'}
                                        value={numeral(response?.vessels?.departure || 0).format('0,000')}
                                    />
                                </Link>
                            </Fragment>
                        )
                    }
                    <Link to={'/search'}>
                        <Panel
                            Icon={GiHomeGarage}
                            bgColor={'linear-gradient(155deg, rgba(5,139,8,1) 0%, rgba(19,179,0,1) 49%, rgba(5,139,8,1) 84%)'}
                            title="flight"
                            BgIcon={IoIosPeople}
                            bgPos={{ top: -4, bottom: 4, right: -20 }}
                            color={'white'}
                            value={numeral(response?.passengers?.arrival || 0).format('0,000')}
                        />
                    </Link>
                    <Link to={'/search'}>
                        <Panel
                            Icon={GiExitDoor}
                            bgColor={'linear-gradient(155deg, rgba(5,139,8,1) 0%, rgba(19,179,0,1) 49%, rgba(5,139,8,1) 84%)'}
                            title="flight"
                            BgIcon={IoIosPeople}
                            bgPos={{ top: -4, bottom: 4, right: -20 }}
                            color={'white'}
                            value={numeral(response?.passengers?.departure || 0).format('0,000')}
                        />         
                    </Link>    
                </div>
                <div style={{display:'flex', flexDirection:'row', width:'100%', flexGrow:1, gap:8, 
                     paddingInline:16, paddingBlock:16}}>
                    <div style={{width:'50%'}}>
                        {/* <ResponsiveContainer>
                            <BarChart
                                data={response?.hourly || []}
                            >
                                <YAxis />
                                <XAxis dataKey={'hour'} type="category" />
                                <Tooltip content={TooltipDirection} />
                                <Bar stackId={'a'} dataKey={"arrival"} stroke={blue[4]} fill={blue[8]} />
                            </BarChart>
                        </ResponsiveContainer> */}
                        <div style={{display:'flex', flexDirection:'column', rowGap:8}}>
                            <Typography.Text>Arrival Hourly</Typography.Text>
                            <div style={{flexGrow:1, width:'100%', height:'100%'}}>
                                <ResponsiveContainer width={'100%'} minHeight={320}>
                                    <BarChart
                                        data={response?.hourly || []}
                                    >
                                        <YAxis />
                                        <XAxis dataKey={'hour'} type="category" />
                                        <Tooltip content={TooltipDirectionArr} />
                                        {/* <Legend verticalAlign='left' formatter={LegendFormatter} /> */}
                                        <Bar stackId={'a'} dataKey={"arrival"} stroke={blue[4]} fill={blue[8]} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'50%'}}>
                        <div style={{display:'flex', flexDirection:'column', rowGap:8}}>
                            <Typography.Text>Departure Hourly</Typography.Text>
                            <div style={{flexGrow:1, width:'100%', height:'100%'}}>
                                <ResponsiveContainer width={'100%'} minHeight={320}>
                                    <BarChart
                                        data={response?.hourly || []}
                                    >
                                        <YAxis />
                                        <XAxis dataKey={'hour'} type="category" />
                                        <Tooltip content={TooltipDirectionDep} />
                                        {/* <Legend verticalAlign='left' formatter={LegendFormatter} /> */}
                                        <Bar stackId={'a'} dataKey={"departure"} stroke={green[4]} fill={green[8]} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{width:'30%'}}>
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie 
                                    data={detail_cekal || []}                                
                                    dataKey={'jml'}
                                    nameKey="command"
                                >
                                    {
                                        Array.isArray(detail_cekal) && detail_cekal?.map((row, idx)=>{
                                            return <Cell key={`cell-${idx}`} fill={getColor(row.command)}  />
                                        })
                                    }
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div> */}
                    {/* <div style={{height:'100%', flexGrow:1, flexDirection:'column', display:'flex',
                        justifyContent:'center', alignItems:"center"}}>
                            <div style={{display:'flex', flexDirection:'column', rowGap:4, background:'#ffffff99', width:'100%', padding:16}}>
                                <Typography.Text strong style={{textAlign:'center', fontSize:'1.0rem', borderBottom:'1px solid gray'}}>
                                    Detail
                                </Typography.Text>
                                {
                                    detail_cekal.map((cekal, idx)=>(
                                        <div key={`legend-${idx}`} style={{display:'flex', flexDirection:'row', gap:4}}>
                                            <Typography.Text>{cekal.command}</Typography.Text>
                                            <Typography.Text style={{flexGrow:1, textAlign:'right'}}>{numeral(cekal.jml).format('0,000')}</Typography.Text>
                                        </div>
                                    ))
                                }
                            </div>
                    </div> */}
                </div>
            </div>
        </Page>
    )
}