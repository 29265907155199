import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../../../../assets/images/logo-kemenhub.png";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";

const BgCard = ({ children }) => {
  return (
    <div
      style={{
        background: "white",
        height: "100%",
        borderRadius: 16,
      }}
    >
      {children}
    </div>
  );
};

export default function DetailLayout({ children, breadcrumbItems }) {
  const fontSize = useFontSizeV2();
  const history = useHistory();

  return (
    <div
      style={{
        // border: "1px solid red",
        height: "100vh",
        background: "#ebf0ff",
        padding: "0px 20px",
      }}
    >
      <div
        style={{
          // border: "1px solid red",
          height: "10%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 8,
          }}
        >
          <Button
            style={{
              borderRadius: 8,
              background: "transparent",
              width: "50px",
              height: "50px",
            }}
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined
              style={{ color: "#11375C", fontSize: "20px", fontWeight: 700 }}
            />
          </Button>
          {breadcrumbItems()}
        </div>
        <div style={{ width: fontSize.xl3 * 8 }}>
          <img src={logo} alt="logo kemenhub" style={{ width: "100%" }} />
        </div>
      </div>
      {children}
    </div>
  );
}
