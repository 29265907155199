import React from "react";
import DashboardChart from "../../dashboard/home/components/DashboardChart";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import useWindowDimensions, {
  useFontSizeV2,
} from "../../../../../hooks/useDimension";
import { useMemo } from "react";
import { getFilterData } from "../../../../../helper/getFilterData";
import compareDates from "../../../../../helper/compareDates";
import moment from "moment";
import customFormat, {
  customFormatWeight,
} from "../../../../../helper/customFormat";
import * as echarts from "echarts"; // Import ECharts library
import { useLocation } from "react-router-dom";
import { useDashboardChart } from "../../../../../hooks/useDashboardChart";
import pickOneData from "../../../../../helper/pickOneData";
import Config from "../../../../../config/env";

const { chartColor } = Config;

const options = [
  { label: "Hari", value: "daily" },
  { label: "Bulan", value: "monthly" },
  { label: "Kwartal", value: "quarterly" },
  { label: "Semester", value: "semesterly" },
];

const FifthChart = () => {
  const { dashboardState, filterPage } = useSelector((item) => item.apps);
  const [viewProd, setViewProd] = useState("daily");
  const fontSize = useFontSizeV2();
  const location = useLocation();
  const { data } = location.state;

  const id = useMemo(() => {
    return data?.detail === "Airline"
      ? { airline_id: data.id }
      : { airport_id: data.id };
  }, [data?.detail, location]);

  const filterDetail = useMemo(() => {
    const filterEliminate = pickOneData(filterPage, "year");
    return { ...filterEliminate, ...id };
  }, [filterPage, location]);

  const [detailDaily, loadingDetailDaily] = useDashboardChart({
    ...filterDetail,
    type: "daily",
    routes: `/cargo-detail/${data?.detail.toLowerCase()}`,
  });

  const dataCargoDom = useMemo(() => {
    const dataDom =
      Array.isArray(detailDaily) &&
      detailDaily?.map(({ date, domestic }) => ({
        name: date,
        curr: domestic,
      }));
    const dataExp =
      Array.isArray(detailDaily) &&
      detailDaily?.map(({ date, dexport }) => ({
        name: date,
        curr: dexport,
      }));
    const dataImp =
      Array.isArray(detailDaily) &&
      detailDaily?.map(({ date, dimport }) => ({
        name: date,
        curr: dimport,
      }));
    const dataInc =
      Array.isArray(detailDaily) &&
      detailDaily?.map(({ date, incoming }) => ({
        name: date,
        curr: incoming,
      }));
    const dataOut =
      Array.isArray(detailDaily) &&
      detailDaily?.map(({ date, outgoing }) => ({
        name: date,
        curr: outgoing,
      }));
    const dataTransit =
      Array.isArray(detailDaily) &&
      detailDaily?.map(({ date, transit }) => ({
        name: date,
        curr: transit,
      }));
    const datas =
      data?.detail === "Airline"
        ? {
            dom: dataDom,
            exp: dataExp,
            imp: dataImp,
          }
        : {
            exp: dataExp,
            imp: dataImp,
            inc: dataInc,
            out: dataOut,
            tra: dataTransit,
          };
    return datas;
  }, [detailDaily, filterPage, viewProd]);

  const [Domestic, Export, Import, Incoming, Outgoing, Transit] =
    useMemo(() => {
      const {
        dom = [],
        exp = [],
        imp = [],
        inc = [],
        out = [],
        tra = [],
      } = dataCargoDom;
      if (!dataCargoDom)
        return [
          { yearly: [], semesterly: [], quarterly: [], monthly: [], daily: [] },
          { yearly: [], semesterly: [], quarterly: [], monthly: [], daily: [] },
          { yearly: [], semesterly: [], quarterly: [], monthly: [], daily: [] },
        ];
      const {
        daily: dDom,
        semesterly: sDom,
        monthly: mDom,
        quarterly: qDom,
      } = getFilterData(Array.isArray(dom) && dom?.sort(compareDates));
      const {
        daily: dExp,
        semesterly: sExp,
        monthly: mExp,
        quarterly: qExp,
      } = getFilterData(Array.isArray(exp) && exp?.sort(compareDates));
      const {
        daily: dImp,
        semesterly: sImp,
        monthly: mImp,
        quarterly: qImp,
      } = getFilterData(Array.isArray(imp) && imp?.sort(compareDates));
      const {
        daily: dInc,
        semesterly: sInc,
        monthly: mInc,
        quarterly: qInc,
      } = getFilterData(Array.isArray(inc) && inc?.sort(compareDates));
      const {
        daily: dOut,
        semesterly: sOut,
        monthly: mOut,
        quarterly: qOut,
      } = getFilterData(Array.isArray(out) && out?.sort(compareDates));
      const {
        daily: dTra,
        semesterly: sTra,
        monthly: mTra,
        quarterly: qTra,
      } = getFilterData(Array.isArray(tra) && tra?.sort(compareDates));
      const datas = [
        {
          semesterly: sDom,
          quarterly: qDom,
          monthly: mDom,
          daily: dDom,
        },
        {
          semesterly: sExp,
          quarterly: qExp,
          monthly: mExp,
          daily: dExp,
        },
        {
          semesterly: sImp,
          quarterly: qImp,
          monthly: mImp,
          daily: dImp,
        },
        {
          semesterly: sInc,
          quarterly: qInc,
          monthly: mInc,
          daily: dInc,
        },
        {
          semesterly: sOut,
          quarterly: qOut,
          monthly: mOut,
          daily: dOut,
        },
        {
          semesterly: sTra,
          quarterly: qTra,
          monthly: mTra,
          daily: dTra,
        },
      ];
      return datas;
    }, [detailDaily, filterPage, viewProd]);

  const stackState = useMemo(() => {
    return viewProd === "daily" ? { stack: "Total" } : {};
  }, [viewProd]);

  const seriesData = useMemo(() => {
    const arrSeries = [
      {
        name: "Export",
        type: viewProd !== "daily" ? "bar" : "line",
        barWidth: 20,
        barGap: 2,
        ...stackState,
        areaStyle: {
          // Specify the linear gradient for the areaStyle
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: chartColor[0] },
            { offset: 1, color: "transparent" },
          ]),
        },
        symbol:
          dashboardState?.filterTime !== "daily" || viewProd !== "daily"
            ? "circle"
            : "none",
        lineStyle: {
          color: chartColor[0],
        },
        itemStyle: {
          color: chartColor[0],
        },
        emphasis: {
          focus: "series",
        },
        data:
          Array.isArray(Export[viewProd]) &&
          Export[viewProd]?.map((item) => item.curr),
      },
      {
        name: "Import",
        type: viewProd !== "daily" ? "bar" : "line",
        barWidth: 20,
        barGap: 2,
        ...stackState,
        areaStyle: {
          // Specify the linear gradient for the areaStyle
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: chartColor[1] },
            { offset: 1, color: "transparent" },
          ]),
        },
        symbol:
          dashboardState?.filterTime !== "daily" || viewProd !== "daily"
            ? "circle"
            : "none",
        lineStyle: {
          color: chartColor[1],
        },
        itemStyle: {
          color: chartColor[1],
        },
        emphasis: {
          focus: "series",
        },
        data:
          Array.isArray(Import[viewProd]) &&
          Import[viewProd]?.map((item) => item.curr),
      },
    ];
    return data?.detail === "Airline"
      ? [
          {
            name: "Domestic",
            type: viewProd !== "daily" ? "bar" : "line",
            barWidth: 20,
            barGap: 2,
            ...stackState,
            areaStyle: {
              // Specify the linear gradient for the areaStyle
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: chartColor[2] },
                { offset: 1, color: "transparent" },
              ]),
            },
            symbol:
              dashboardState?.filterTime !== "daily" || viewProd !== "daily"
                ? "circle"
                : "none",
            lineStyle: {
              color: chartColor[2],
            },
            itemStyle: {
              color: chartColor[2],
            },
            emphasis: {
              focus: "series",
            },
            data:
              Array.isArray(Domestic[viewProd]) &&
              Domestic[viewProd]?.map((item) => item.curr),
          },
          ...arrSeries,
        ]
      : [
          {
            name: "Incoming",
            type: viewProd !== "daily" ? "bar" : "line",
            barWidth: 20,
            barGap: 2,
            ...stackState,
            areaStyle: {
              // Specify the linear gradient for the areaStyle
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: chartColor[5] },
                { offset: 1, color: "transparent" },
              ]),
            },
            symbol:
              dashboardState?.filterTime !== "daily" || viewProd !== "daily"
                ? "circle"
                : "none",
            lineStyle: {
              color: chartColor[5],
            },
            itemStyle: {
              color: chartColor[5],
            },
            emphasis: {
              focus: "series",
            },
            data:
              Array.isArray(Incoming[viewProd]) &&
              Incoming[viewProd]?.map((item) => item.curr),
          },
          {
            name: "Outgoing",
            type: viewProd !== "daily" ? "bar" : "line",
            barWidth: 20,
            barGap: 2,
            ...stackState,
            areaStyle: {
              // Specify the linear gradient for the areaStyle
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: chartColor[6] },
                { offset: 1, color: "transparent" },
              ]),
            },
            symbol:
              dashboardState?.filterTime !== "daily" || viewProd !== "daily"
                ? "circle"
                : "none",
            lineStyle: {
              color: chartColor[6],
            },
            itemStyle: {
              color: chartColor[6],
            },
            emphasis: {
              focus: "series",
            },
            data:
              Array.isArray(Outgoing[viewProd]) &&
              Outgoing[viewProd]?.map((item) => item.curr),
          },
          {
            name: "Transit",
            type: viewProd !== "daily" ? "bar" : "line",
            barWidth: 20,
            barGap: 2,
            ...stackState,
            areaStyle: {
              // Specify the linear gradient for the areaStyle
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "purple" },
                { offset: 1, color: "transparent" },
              ]),
            },
            symbol:
              dashboardState?.filterTime !== "daily" || viewProd !== "daily"
                ? "circle"
                : "none",
            lineStyle: {
              color: "purple",
            },
            itemStyle: {
              color: "purple",
            },
            emphasis: {
              focus: "series",
            },
            data:
              Array.isArray(Transit[viewProd]) &&
              Transit[viewProd]?.map((item) => item.curr),
          },
          ...arrSeries,
        ];
  }, [dataCargoDom]);

  const optionsDaily = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      data:
        data?.detail === "Airline"
          ? ["Domestic", "Export", "Import"]
          : ["Export", "Import", "Incoming", "Outgoing", "Transit"],
      bottom: "0%",
      icon: "square",
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "12%",
      containLabel: true,
      top: "10%",
    },
    xAxis: {
      type: "category",
      // boundaryGap: false,
      data:
        Array.isArray(Export[viewProd]) &&
        Export[viewProd]?.map((item) =>
          viewProd === "monthly"
            ? moment()
                .month(item.name - 1)
                .format("MMMM")
            : item.name
        ),
      axisLabel: {
        textStyle: {
          fontSize: fontSize.sm,
        },
        formatter:
          viewProd === "daily"
            ? (params) => moment(params).format("DD MMM")
            : (params) => `${params}`,
        // interval: 3,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => customFormatWeight(params) + "Ton",
      },
    },
    series: seriesData,
  };

  return (
    <DashboardChart
      titleSelected={`of ${data?.name} (${data?.code})`}
      isDetail
      loading={loadingDetailDaily}
      options={options}
      title={"Daily Chart (Ton)"}
      data={optionsDaily}
      view={viewProd}
      setView={setViewProd}
      isFilterComponent
    />
  );
};

export default FifthChart;
