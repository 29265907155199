import React from "react";

const HomeMobile = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Sorry, This page is under maintenance.
    </div>
  );
};

export default HomeMobile;
