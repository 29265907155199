import React from "react";
import DashboardChart from "./DashboardChart";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import NotFoundData from "../../../../../../components/NotFound";
import { useFontSizeV2 } from "../../../../../../hooks/useDimension";
import { useDashboardChart } from "../../../../../../hooks/useDashboardChart";
import separateDataByProp from "../../../../../../helper/separateDatabyProp";

const BandaraChart = () => {
  const fontSize = useFontSizeV2();
  const { filterPage, dashboardState } = useSelector((item) => item.apps);

  const [dataMaps, loadingMaps] = useDashboardChart({
    ...filterPage,
    type: "maps",
    doSend: dashboardState.chart === "all" || dashboardState.chart === "peta",
  });

  const dataAirports = useMemo(
    () => !!dataMaps && separateDataByProp(dataMaps, "operator"),
    [dataMaps]
  );

  const dataChart = useMemo(
    () => getData(dataAirports),
    [dataAirports, dataMaps]
  );

  const dataTable = useMemo(
    () => getTableDetails(dataAirports),
    [dataAirports]
  );

  if (!dataAirports || dataAirports?.length === 0) {
    return <NotFoundData />;
  }
  return (
    <DashboardChart
      printHeader={"Bandara Report"}
      dataSource={dataTable}
      repType={"Bandara"}
      loading={loadingMaps}
      isDaily={false}
      title={"Bandara"}
      data={getOptions(dataChart, fontSize)}
      isTotal={dataMaps?.length}
      OnClick={() => {}}
    />
  );
};

export default BandaraChart;

const getTableDetails = (airports) => {
  const dataSource = [];
  const keys = Object.keys(airports);
  keys?.forEach((item) => {
    dataSource.push({
      operator: item,
      total: airports[item].length,
    });
  });

  return {
    data: dataSource,
    fields: columnTable,
    header: columnTable.map((item) => item.title),
  };
};

const getData = (airports) => {
  const arr = Object.keys(airports).map((key) => {
    return {
      label: key,
      value: airports[key].length,
    };
  });
  return arr.sort((a, b) => a.value - b.value);
};

const getOptions = (dataChart, fontSize) => {
  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // type: "cross",
        label: {
          backgroundColor: "#Fb8500",
        },
      },
    },
    grid: {
      left: "15%",
      right: "4%",
      bottom: "15%",
      top: "5%",
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      //   type: "category",
      //   data: dataChart?.map((item) => item.label),
      type: "value",
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      //   type: "value",
      type: "category",
      data: dataChart?.map((item) => item.label),
      axisLabel: {
        fontSize: fontSize.sm,
      },
    },
    series: [
      {
        data: dataChart?.map((item) => item.value),
        type: "bar",
        // barWidth: "24px",
        itemStyle: {
          color: "#023047",
        },
      },
    ],
  };
};

const columnTable = [
  {
    title: "Operator",
    type: "operator",
    name: "operator",
    align: "left",
  },
  {
    title: "Total",
    type: "total",
    name: "total",
    align: "right",
  },
];
