import { buildDataTable } from "../../../../../helper/buildDataTable";
import { tooltipFormater } from "../../Chart/tooltipFormater";

const getDataPax = (dataSource) => {
    if (!dataSource) {
        return [[], []];
    }
    let result = {};

    for (let iii = 0; iii < dataSource.length; iii++) {
        const { nationality, total_pax } = dataSource[iii];
        const by = nationality || "-";

        if (!result[by]) {
            result[by] = {
                total_pax,
            };
        } else {
            let sum = (result[by].total_pax += total_pax);

            result[by] = {
                total_pax: sum,
            };
        }
        if (!result[by]?.originObj) {
            result[by] = {
                ...result[by],
                originObj: { nationality },
            };
        }
    }

    let set = [];
    let dataTable = [];
    for (const key in result) {
        if (Object.hasOwnProperty.call(result, key)) {
            const { total_pax, originObj } = result[key];
            set.push([key, total_pax, originObj]);
            dataTable.push({
                total_pax,
                key,
                ...originObj,
            });
        }
    }

    const dataset = [
        {
            dimensions: ["label", "total", "originObj"],
            source: set,
        },
        {
            transform: {
                type: "sort",
                config: { dimension: "total", order: "asc" },
            },
        },
    ];
    return [
        dataset,
        buildDataTable(dataTable, [
            {
                title: "Kewarganegaraan",
                type: "string",
                name: "nationality",
                align: "left",
            },
            {
                title: "Total Penumpang",
                type: "number",
                name: "total_pax",
                align: "right",
            },
        ]),
    ];
};
const renderTooltip = (params) => {
    const { data } = params[0];
    return tooltipFormater({
        title: {
            label: `${data[2]["nationality"]} `,
        },
        params,
    });
};

export default {
    getDataPax,
    renderTooltip,
};
