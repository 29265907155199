import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PanelChartInternational from "./InternasionalChart";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";

const CargoInternationalChart = () => {
  const {
    filterPage,
  } = useSelector((item) => item.apps);

  const [data, loading] = useDashboardChart({
    ...filterPage,
    type: "cargov2",
    doSend: true,
    routes: "/cargov2",
  });

  const dataCargoInt = useMemo(() => getCargos(data), [data, filterPage]);
  return (
    <PanelChartInternational
      datatable={data}
      data={dataCargoInt}
      loading={loading}
    />
  );
};

const getCargos = (data) => {
  const dataRef = !!data ? data : [];
  const dataAll = dataRef?.map(({ _id, domestic, dexport, dimport }) => ({
    name: _id,
    curr: domestic + dexport + dimport,
  }));
  const dataExp = dataRef?.map(({ _id, dexport }) => ({
    name: _id,
    curr: dexport,
  }));
  const dataImp = dataRef?.map(({ _id, dimport }) => ({
    name: _id,
    curr: dimport,
  }));
  return {
    all: dataAll,
    exp: dataExp,
    imp: dataImp,
  };
}

export default CargoInternationalChart;
