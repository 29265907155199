import { GiChart, GiInfo, GiLog, GiMailbox, GiRobotHelmet, GiStabbedNote, GiWatch } from "react-icons/gi";
import { MdGTranslate } from "react-icons/md";
import { RiWhatsappLine } from "react-icons/ri";

export default [    
    {
        caption:'bot',
        category:'others',
        group:2, 
        Icon:GiRobotHelmet,
        level:0x1f00,
        linkTo:'/others/bot/fr'
    },
    {
        caption:'bot_telex',
        category:'others',
        group:2, 
        hide:true,
        Icon:GiMailbox,
        level:0x1ff0,
        linkTo:'/others/bot/telex'
    },
    {
        caption:'process_status',
        category:'others',
        group:2, 
        hide:true,
        Icon:GiWatch,
        level:0x1ff0,
        linkTo:'/others/bot/process/status'
    },
    {
        caption:'process_logs',
        category:'others',
        group:2, 
        hide:true,
        level:0x1ff0,
        Icon:GiLog,
        linkTo:'/others/bot/process/logs'
    },
    {
        caption:'about',
        category:'others',
        group:2, 
        Icon:GiInfo,
        linkTo:'/others/about'
    },
]