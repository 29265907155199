import React from "react";
import DashboardChart from "../../dashboard/home/components/DashboardChart";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import { useDashboardChart } from "../../../../../hooks/useDashboardChart";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import pickOneData from "../../../../../helper/pickOneData";
import Config from "../../../../../config/env";

const { chartColor } = Config;

const Summary = () => {
  const fontSize = useFontSizeV2();
  const location = useLocation();
  const { data } = location.state;
  const { filterPage } = useSelector((item) => item.apps);

  const id = useMemo(() => {
    return data?.detail === "Airline"
      ? { airline_id: data.id }
      : { airport_id: data.id };
  }, [data?.detail, location]);

  const filterDetail = useMemo(() => {
    const filterEliminate = pickOneData(filterPage, "year");
    return { ...filterEliminate, ...id };
  }, [filterPage, location]);

  const [detailSummary, loadingDetailSummary] = useDashboardChart({
    ...filterDetail,
    type: "summary",
    routes: `/cargo-detail/${data?.detail.toLowerCase()}`,
  });

  const chartData = useMemo(() => {
    const arr = [
      {
        value: detailSummary?.dexport,
        name: "Export",
        itemStyle: {
          color: chartColor[6],
        },
      },
      {
        value: detailSummary?.dimport,
        name: "Import",
        itemStyle: {
          color: chartColor[5],
        },
      },
      {
        value: detailSummary?.domestic,
        name: "Domestic",
        itemStyle: {
          color: chartColor[4],
        },
      },
    ];
    return data?.detail === "Airline"
      ? arr
      : [
          ...arr,
          {
            value: detailSummary?.transit,
            name: "Transit",
            itemStyle: {
              color: chartColor[1],
            },
          },
          {
            value: detailSummary?.incoming,
            name: "Incoming",
            itemStyle: {
              color: chartColor[0],
            },
          },
          {
            value: detailSummary?.outgoing,
            name: "Outgoing",
            itemStyle: {
              color: chartColor[2],
            },
          },
        ];
  }, [detailSummary]);

  const option = {
    tooltip: {
      // trigger: "none",
      // alwaysShowContent: true,
    },
    legend: { show: false },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        startAngle: 160,
        avoidLabelOverlap: false,
        padAngle: 0,
        itemStyle: {
          borderRadius: 8,
          borderColor: "#fff",
          // borderWidth: 20,
        },
        label: {
          show: true,
          formatter(param) {
            // return param.name + " (" + param.percent * 2 + "%)";
            return `${param.name}\n(${param.percent}%)`;
          },
          textStyle: {
            fontSize: fontSize.sm,
            color: "white",
            textAlign: "center",
          },
          backgroundColor: "#1E1B39",
          borderColor: "none",
          borderWidth: 1,
          borderRadius: 8,
          padding: 10,
          // width: 60,
          textAlign: "center",
        },
        labelLine: {
          length: fontSize.xl,
          smooth: true,
          lineStyle: {
            miterLimit: 5,
            width: fontSize.xs - 5,
            color: "#1E1B39",
            cap: "round",
          },
        },
        data: chartData,
      },
    ],
  };

  return (
    <DashboardChart
      titleSelected={`of ${data?.name} (${data?.code})`}
      isDetail
      loading={loadingDetailSummary}
      title={"Summary Chart"}
      data={option}
    />
  );
};

export default Summary;
