import React from "react";
import MainCard from "./MainCard";
import { MdOutlineSearchOff } from "react-icons/md";
import { Typography } from "antd";
import Empty from "../assets/images/empty.png";

const NotFoundData = () => {
  return (
    <MainCard isNonBackground isBorder>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img width={200} src={Empty} />
        <Typography style={{ fontWeight: "bold" }}>
          Data Not Found <MdOutlineSearchOff size={20} />
        </Typography>
      </div>
    </MainCard>
  );
};

export default NotFoundData;
