import React from 'react';
import {RiHome2Line,  RiDatabase2Line, RiFlightTakeoffLine, RiBuildingLine, RiUserAddLine } from 'react-icons/ri';
import { GiWorld} from 'react-icons/gi'
import {Link} from 'react-router-dom';
import {getLanguage} from '../../../languages/utils';

const SIZE=20;
export default (languages)=> [
    {
        key:'/home',
        icon:<RiHome2Line size={SIZE}/>,
        label:<Link to={'/home'}>{getLanguage('home', languages)}</Link>,
        level:0x1fff
    },
    {
        key:'/master',
        icon:<RiDatabase2Line size={SIZE}/>,
        label:getLanguage('master', languages),
        level:0x1ff0,
        children:[
            {
                key:'/master/airline',
                icon:<RiFlightTakeoffLine size={SIZE}/>,
                label:<Link to={'/master/airline'}>{getLanguage('airline', languages)}</Link>,
                level:0x1ff0,
            },
            {
                key:'/master/country',
                icon:<GiWorld size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/master/country'}>{getLanguage('country', languages)}</Link>,
            },
            {
                key:'/master/airport',
                icon:<RiBuildingLine size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/master/airport'}>{getLanguage('airport', languages)}</Link>,
            },
            {
                key:'/master/users',
                icon:<RiUserAddLine size={SIZE}/>,
                level:0x1ff0,
                label:<Link to={'/master/users'}>{getLanguage('users', languages)}</Link>,
            }
        ]
    },
    
]