import CargoPage from '../pages/cargo/detail';
import ManifestPage from '../pages/cargo/manifest';
import HousePage from '../pages/cargo/house';
export default [    
    {
        path:'/cargo/detail/:id',
        exact:true,
        Component:CargoPage
    },
    {
        path:'/cargo/manifest/detail/:id',
        exact:true,
        Component:ManifestPage
    },
    {
        path:'/cargo/house/detail/:id',
        exact:true,
        Component:HousePage
    },
];