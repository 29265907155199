import React from 'react';
import { GiHouse } from 'react-icons/gi';
import { useParams } from 'react-router-dom';
import Detail from '../components/pages/detail';
import DetailComponent from '../components/details/house/cargo';
import { GiCargoShip } from 'react-icons/gi';
export default () => {
    const {id} = useParams();
    
    return (
        <Detail 
            title={`cargo`} 
            icon={GiCargoShip} 
            id={id}
            url={`api/v1/house/detail/awb_id`}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'cargo'
                    },
                    {
                        item:'house'
                    },
                ]
            }
            DetailComponent={DetailComponent}
            additionalData={{ id}}
        />
    )
}