import React, { useState } from "react";
import useWindowDimensions, {
    useFontSizeV2,
} from "../../../../../../hooks/useDimension";
import DashboardChart from "./DashboardChart";
import * as echarts from "echarts"; // Import ECharts library
import NotFoundData from "../../../../../../components/NotFound";
import { useMemo } from "react";
import {
    forecastData,
    getFilterData,
} from "../../../../../../helper/getFilterData";
import { useDashboardChart } from "../../../../../../hooks/useDashboardChart";
import moment from "moment";
import customFormat from "../../../../../../helper/customFormat";
import { useEffect } from "react";
import {
    setDashboardState,
} from "../../../../../../redux/reducer/apps";
import { useDispatch, useSelector } from "react-redux";
import compareDates from "../../../../../../helper/compareDates";
import { parseFormatedNumber } from "../../../../../../helper/parseFormatedNumber";

const options = [
    { label: "Hari", value: "daily" },
    { label: "Bulan", value: "monthly" },
    { label: "Kwartal", value: "quarterly" },
    { label: "Semester", value: "semesterly" },
];

const optionsDirectCargo = [
    { label: "All", value: 0 },
    { label: "Domestik", value: 1 },
    { label: "Export", value: 2 },
    { label: "Import", value: 3 },
];

const KargoChart = () => {

    const { dashboardState, filterPage } = useSelector((item) => item.apps);
    const [viewProd, setViewProd] = useState("daily");
    const [direct, setDirect] = useState(0);
    const fontSize = useFontSizeV2();
    const dispatch = useDispatch();
    const { height } = useWindowDimensions();

    const [dataCargov3, loadingCargov3] = useDashboardChart({
        ...filterPage,
        type: "cargov2",
        doSend:
            dashboardState.chart === "all" || dashboardState.chart === "kargo",
        routes: "/cargov2",
    });

        const dataCargos = useMemo(() => getCargos(dataCargov3), [dataCargov3, filterPage]);



    
    const [cargoData, fcData] = useMemo(
        () => getFilters(dataCargos, direct),
        [dataCargos, direct, filterPage]
    );

    const optionsKargo = getOptions(
        fcData,
        cargoData,
        viewProd,
        dashboardState,
        fontSize,
        direct
    );

    useEffect(() => {
        // dispatch(setFilterPage({ direct }));
        dispatch(setDashboardState({ chart: direct === "" ? "all" : "kargo" }));
    }, [direct]);

    const dataTable = useMemo(() => getTables(dataCargov3), [dataCargos]);

    if(!dataCargov3 || dataCargov3?.length === 0 ){
        <NotFoundData />
        return 
    }

    return (
        <DashboardChart
            optDirect={optionsDirectCargo}
            printHeader={"Kargo Report"}
            dataSource={dataTable}
            repType={"Kargo"}
            loading={loadingCargov3}
            direct={direct}
            setDirect={setDirect}
            view={
                dashboardState?.filterState === "all"
                    ? dashboardState?.filterTime
                    : viewProd
            }
            setView={setViewProd}
            isOtherComponent
            isFilterComponent
            title={"Kargo"}
            data={optionsKargo}
            options={options}
        />
    );
};

const getCargos = (cargos) => {
    const dataRef = !!cargos ? cargos : [];
    const dataAll = dataRef?.map(({ _id, domestic, dexport, dimport }) => ({
        name: _id,
        curr: domestic + dexport + dimport,
    }));
    const dataDom = dataRef?.map(({ _id, domestic }) => ({
        name: _id,
        curr: domestic,
    }));
    const dataExp = dataRef?.map(({ _id, dexport }) => ({
        name: _id,
        curr: dexport,
    }));
    const dataImp = dataRef?.map(({ _id, dimport }) => ({
        name: _id,
        curr: dimport,
    }));
    return {
        all: dataAll,
        dom: dataDom,
        exp: dataExp,
        imp: dataImp,
    };
}

const getTables = (data) => {
    const result =
        !!data &&
        data?.map((item) => {
            return {
                _id: item._id,
                domestic: item.domestic,
                dexport: item.dexport,
                dimport: item.dimport,
                total: item.dimport + item.dexport + item.domestic,
            };
        });
    return {
        data: result,
        fields: columnTable,
        header: columnTable.map((item) => item.title),
    };
};

const columnTable = [
    {
        title: "Date",
        type: "string",
        name: "_id",
        align: "left",
    },
    {
        title: "Domestic",
        type: "string",
        name: "domestic",
        align: "right",
    },
    {
        title: "Export",
        type: "string",
        name: "dexport",
        align: "right",
    },
    {
        title: "Import",
        type: "string",
        name: "dimport",
        align: "right",
    },
    {
        title: "Total",
        type: "string",
        name: "total",
        align: "right",
    },
];

const getOptions = (
    fcData,
    cargoData,
    viewProd,
    dashboardState,
    fontSize,
    direct
) => {
    const min = Math.min(
        ...cargoData[viewProd].map((item) => parseInt(item.curr))
    );
    const max = Math.max(
        ...cargoData[viewProd].map((item) => parseInt(item.curr))
    );

    const optionsKargo = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        // legend: {
        //   data: ["Domestik", "Internasional"],
        //   bottom: height > 720 ? "0%" : "-5%",
        //   icon: "square",
        // },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            left: "0%",
            right: "5%",
            bottom: "12%",
            containLabel: true,
            top: "10%",
        },
        xAxis: {
            type: "category",
            // boundaryGap: false,
            data:
                fcData[viewProd]?.length >= 100 && viewProd === "daily"
                    ? fcData[viewProd]?.map((item) =>
                          viewProd === "monthly"
                              ? moment()
                                    .month(item.name - 1)
                                    .format("MMMM")
                              : item.name
                      )
                    : cargoData[viewProd]?.map((item) =>
                          viewProd === "monthly"
                              ? moment()
                                    .month(item.name - 1)
                                    .format("MMMM")
                              : item.name
                      ),
            axisLabel: {
                textStyle: {
                    fontSize: fontSize.sm,
                },
                formatter:
                    viewProd === "daily"
                        ? (params) => moment(params).format("DD MMM")
                        : (params) => `${params}`,
                // interval: ,
            },
        },

        yAxis: {
            type: "value",
            axisLabel: {
                formatter: (params) => customFormat(params),
            },
        },

        visualMap: [
            {
                show: false,
                type: "continuous",
                seriesIndex: 0,
                min: min,
                max: max,
                inRange: {
                    color: ["#fb8500", "#023047"],
                },
            },
            {
                show: false,
                type: "continuous",
                seriesIndex: 0,
                min: min,
                max: max,
                inRange: {
                    color: ["#fb8500", "#023047"],
                },
            },
        ],

        series: [
            {
                name:
                    direct === 0
                        ? "All"
                        : direct === 1
                        ? "Domestic"
                        : direct === 2
                        ? "Import"
                        : "Export",
                type: viewProd === "daily" ? "line" : "bar",
                // areaStyle: {
                //   // Specify the linear gradient for the areaStyle
                //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //     { offset: 0, color: "#219EBC" },
                //     { offset: 1, color: "transparent" },
                //   ]),
                // },
                barWidth: 10,
                barGap: 0,
                areaStyle: {
                    opacity: 0.3,
                },
                symbol:
                    dashboardState?.filterTime !== "daily" ||
                    viewProd !== "daily"
                        ? "circle"
                        : "none",
                // lineStyle: {
                //   color: "#219EBC",
                // },
                // itemStyle: {
                //   color: "#219EBC",
                // },
                emphasis: {
                    focus: "series",
                },
                data: cargoData[viewProd]?.map((item) => item.curr),
                markLine: {
                    data: [
                        {
                            type: "average",
                            itemStyle: {
                                color: "#219ebc",
                            },
                            label: {
                                formatter: (params) =>
                                    `Avg. ${parseFormatedNumber(params.value)}`,
                                textStyle: {
                                    fontSize: 15,
                                },
                                position: "insideEndTop",
                            },
                        },
                    ],
                    silent: true,
                },
            },
            {
                seriesIndex: 10,
                areaStyle: {
                    opacity: 0.3,
                },
                z: 0,
                name: "Forecase",
                type: "line",
                smooth: 0.1,
                // stack: "Total",
                symbol: "none",
                showSymbol: false,
                data:
                    fcData[viewProd]?.length >= 100
                        ? fcData[viewProd]?.map((item) => item.value)
                        : [],
                color: "#FFB703",
                //   lineStyle: {
                //     type: 'dashed'
                // },
            },
        ],
    };
    return optionsKargo;
};

const getFilters = (data, direct) => {
    const { dom, exp, imp, all } = data;
    const directData =
        direct === 0 ? all : direct === 1 ? dom : direct === 2 ? imp : exp;
    if (!data)
        return [
            {
                yearly: [],
                semesterly: [],
                quarterly: [],
                monthly: [],
                daily: [],
            },
            {
                yearly: [],
                semesterly: [],
                quarterly: [],
                monthly: [],
                daily: [],
            },
        ];
    const { daily, semesterly, monthly, quarterly } = getFilterData(
        directData?.sort(compareDates)
    );
    const fcDaily = forecastData(daily, "daily");
    return [
        {
            semesterly,
            quarterly,
            monthly,
            daily,
        },
        {
            daily: fcDaily,
        },
    ];
};
export default KargoChart;
