import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import BandaraChart from "./BandaraChart";
import NotFoundData from "../../../../../../components/NotFound";
import { useDashboardChart } from "../../../../../../hooks/useDashboardChart";
import CargoPaxChart from "./Cargo/Airline";
import AirportChart from "./Airline/Airport";
import PaxAirlineWithAirport from "./Pax/AirlineWithAirport";
import TrafficAirport from "./Flight/TrafficAirport";
import AirportAirline from "./Airport/Airline";

const Kargo3rdCharts = () => {
  const {
    filterPage,
    dashboardState: { summaryCard },
    dashboardState,
  } = useSelector((item) => item.apps);

  const [datav2, loadingDatav2] = useDashboardChart({
    ...filterPage,
    type: "airline",
    doSend:
      dashboardState.chart === "all" ||
      dashboardState.chart === "Maskapai (Tons)".toLowerCase(),
    routes: "/cargov2",
  });

  if (!datav2 || datav2?.length === 0) return <NotFoundData />;

  return getSummaryCard(summaryCard);
};

const getSummaryCard = (summary) => {
  switch (summary) {
    case "cargo":
      return <CargoPaxChart />;
    case "airline":
      return <AirportChart />;
    case "flight":
      return <TrafficAirport />;
    case "airport":
      return <AirportAirline />;
    case "pax":
      return <PaxAirlineWithAirport />;
    default:
      return <BandaraChart />;
  }
};

export default Kargo3rdCharts;
