import React, { useMemo, useState } from 'react';
import { Input, Table } from 'antd';
import { useLanguage } from '../../../../../hooks/useLanguage';
import { useHistory } from 'react-router-dom';
import './list-table.css';
export default ({data, columns, scroll, filterField, url, getRowId, OtherSearch, ...others }) => {
    const [,getLang]=useLanguage();
    const [qry, setQry]=useState('');
    const cols=useMemo(()=>{
        return !!columns && Array.isArray(columns) && columns.map(({title, ...rest},idx)=>({...rest, title:getLang(title)}))
    }, [columns])
    const filteredData=useMemo(()=>{
        if(!data || !Array.isArray(data))return [];
        if(!filterField)return data;
        if(!qry)return data;
        return data.filter(row=>{
            for (let iii = 0; iii < filterField.length; iii++) {
                const f = filterField[iii];
                if(!row[f])continue;
                if(typeof row[f]!=='string')continue;
                if(`${row[f]}`.toLocaleLowerCase().indexOf(qry.toLocaleLowerCase())>=0){
                    return true;
                }
            }
            return false;
        });
    }, [data, qry, filterField]);
    const history=useHistory();
    return (
        <div style={{display:'flex', flexDirection:'column', rowGap:8, padding:24}}>
            <div style={{display:'flex', flexDirection:'row', gap:8, justifyContent:'end'}}>
                { OtherSearch }
                <Input.Search style={{width:220}} value={qry} onChange={e=>setQry(e.target.value)} />
            </div>
            <Table
                dataSource={filteredData}
                rowClassName={!!url && "row-pointer"}
                rowKey={({_id})=>_id}
                onRow={(row, idx)=>{
                    if(!!url){
                        const id=typeof getRowId ==='function' && getRowId(row) || row?._id || '';
                        if(!!id){
                            const uri=`${url}/${id}`;
                            return {
                                onClick:()=>{
                                    history.push(uri);
                                }
                            }
                        }
                    }
                }}
                columns={cols}
                {...others}
            />
        </div>
    )
}   