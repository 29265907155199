import React, { Fragment, useMemo, useState } from 'react';

import Table from '../../lists/table';
import { ImMan, ImWoman, } from 'react-icons/im';
import {FaBaby} from 'react-icons/fa';
import Flags from '../../../../../../components/flags';
import moment from 'moment';
import numeral from 'numeral';
import { Button, Select, Typography } from 'antd';
import {FileExcelOutlined} from '@ant-design/icons';
import { green } from '@ant-design/colors';
import {exportManifest} from './manifest';
import WatchFlag from '../../category/watchflag';

const OtherPanel=({ onExportBtnClick, data, setData })=>{
    return (
        <React.Fragment>
            <Button onClick={onExportBtnClick}  style={{background:green[8], borderRadius:99}} icon={<FileExcelOutlined style={{color:'white'}} />} type="text" />
            <Select style={{width:120}} value={data.message} onChange={(val)=>setData({...data, message:val})}>
                <Select.Option value="apis">APIS</Select.Option>
                <Select.Option value="pnr">PNR</Select.Option>
                <Select.Option value="pnr_apis">APIS+PNR</Select.Option>
            </Select>
        </React.Fragment>
    )
}

export default ({data}) => {
    const [model, setModel] = useState({message:'apis', watchlist:'all'});
    const sortedData=useMemo(()=>{
        if(!data)return [];
        if(!Array.isArray(data))return [];
        const filteredData=data.filter((row)=>{
            const {message} = model;
            
            if(message==='apis'){
                return row?.status==='boarded';
            }
            if(message==='pnr'){
                return !row?.status;
            }
            return true;
        })
        return filteredData.sort((a, b)=>`${a.passenger_name}`.localeCompare(`${b.passenger_name}`)).map((row,idx)=>({...row, number:idx+1}));
    },[data, model])
    const renderGender=(gender, pax_type)=>{
        if(pax_type!=='IN'){
            switch (gender.toLowerCase()) {
                case 'female':
                    return <ImWoman size={24} color="#ff6e87" />
                case 'male':
                    return <ImMan size={24} color="blue"/>
                default:
                    return <ImMan size={24} color="blue"/>
            }
        }
        return <FaBaby size={24} color="green" />
    }
    const getPaxType=(pax_type)=>{
        switch (pax_type.toLowerCase()) {
            case 'in-transit passenger':
                return "T"
            case 'passenger':
                return "P";
            case "In-Transit Crew Member":
                return 'T';
        
            default:
                return "P";
        }
    }

    const handleExport=()=>{
        if(sortedData.length>0){
            const [{flight_schedule_id}] = sortedData
            return exportManifest(sortedData, flight_schedule_id);
        }
    }

    return (
        <Table
            data={sortedData}
            OtherSearch={<OtherPanel data={model} setData={setModel} onExportBtnClick={handleExport} />}
            filterField={['passenger_name', 'doc_number', 'pnr']}
            url="/passengers/detail"
            columns={[
                {
                    title:'#',
                    dataIndex:'number',
                },
                {
                    title:'passenger_name',
                    dataIndex:'passenger_name',     
                    render:(val, rec)=>{
                        const {passenger_type} = rec;
                        return (
                            <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'start'}}>
                                <span style={{color:'white', textTransform:'uppercase', paddingInline:2, background:'#525050', borderRadius:2}}>
                                    {!!passenger_type && getPaxType(passenger_type)}
                                </span>
                                <Typography.Text title={val} ellipsis style={{width:160}}>{val}</Typography.Text>
                            </div>
                        )
                    }             
                },
                {
                    title:'pnr',
                    dataIndex:'pnr',
                    render:(val, rec)=>{
                        const {pnr_id} = rec;
                        const total_pax=pnr_id?.total_pax || Array.isArray(pnr_id?.passengers) && pnr_id.passengers.length || '';
                        return (
                            <div style={{display:'flex', flexDirection:'row', gap:4}}>
                                <span>{val}</span>
                                {
                                    !!total_pax && total_pax>1 && (
                                        <Fragment>
                                            <span style={{color:'white', textTransform:'uppercase', paddingInline:2, background:'#525050', borderRadius:2}}>
                                                {total_pax}
                                            </span>
                                        </Fragment>
                                    )
                                }
                            </div>
                        )
                    }
                },
                {
                    title:'gender',
                    dataIndex:'gender',
                    width:"60px",   
                    render:(val, rec)=>renderGender(val, rec?.pax_type)
                },
                {
                    title:'dob',
                    dataIndex:'dob',
                    width:"180px",   
                    render:(val, rec)=>{
                        return (
                            <div style={{display:'flex', flexDirection:'row', gap:4}}>
                                {
                                    typeof rec?.age === 'number' && (
                                        <span style={{color:'white', textTransform:'uppercase', paddingInline:2, background:'#525050', borderRadius:2}}>
                                            {numeral(rec.age).format('00') || '-'}
                                        </span>
                                    )
                                }
                                <span> {(!!val && moment(val).format('DD-MMM-YYYY')) || '-'} </span>                                
                            </div>
                        )
                    }
                },
                {
                    title:'seat_number',
                    width:"100px",   
                    dataIndex:'reference',
                    render:(val, rec)=>val?.seat_number
                },
                {
                    title:'nationality',
                    width:"80px",   
                    dataIndex:'doc_nationality',
                    render:(val, rec)=>!!val && <Flags countryWidth={48} country_code={val} withCountry countryFormat={'alpha3'} /> || '-'                     
                },
                {
                    title:'doc_number',
                    width:"160px",   
                    dataIndex:'doc_number',
                    render:(val, rec)=>{
                        const {doc_type} = rec;
                        return (
                            <div style={{display:'flex', flexDirection:'row', gap:4}}>
                                <span style={{color:'white', textTransform:'uppercase', paddingInline:2, background:'#525050', borderRadius:2}}>
                                    {!!doc_type && doc_type.substring(0,1)}
                                </span>
                                <span>{val}</span>
                            </div>
                        )
                    }
                },
                {
                    title:'doc_expiry',
                    dataIndex:'doc_expiry',
                    width:"150px",   
                    render:(val, rec)=>(!!val && moment(val).format('DD-MMM-YYYY')) || '-'
                },  
                {
                    title:'melintas',
                    dataIndex:'perlintasan',
                    render:(val)=>!!val?.timestamp && moment(val.timestamp).format('DD-MMM HH:mm')
                }, 
                {
                    title:'status',
                    dataIndex:'status',
                }, 
            ]}
        />
    )
}   