import { useEffect, useMemo, useState } from "react";
import { useFetch } from "./useFetch";
import moment from "moment";

export const useDashboardChart = ({ routes, type, doSend = true, ...rest }) => {
  const [timestamp, setTimestamp] = useState(moment().unix());
  const url = useMemo(() => {
    return (
      rest &&
      JSON.stringify(rest) !== "{}" &&
      `/api/v1/analytic${routes || ""}?type=${type}&search=${JSON.stringify(
        rest
      )}&r=${timestamp}`
    );
  });

  useEffect(() => {
    const time = setInterval(() => {
      setTimestamp((state) => (state = moment().unix()));
    }, 30000);
    return () => clearInterval(time);
  }, [timestamp]);

  const [data, loading] = useFetch(url, true, doSend);
  return [data, loading];
};
