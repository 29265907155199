import { Typography, Button, Dropdown, Select } from "antd";
import React, { Fragment, useState } from "react";
import { useFontSizeV2 } from "../../hooks/useDimension";
import { DatePicker } from "antd";
import Logo from "../../assets/images/sidaapi_logo_icon_dark_bg.png";
import { SearchOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";
import SummaryCard from "../../content/dashboard-analytic/components/SummaryCard";
import { useDispatch, useSelector } from "react-redux";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { doLogout } from "../../redux";
import { useMemo } from "react";
import { sortingLabel } from "../../helper/sortingLabel";
import moment from "moment";
import { setDashboardState, setFilterPage } from "../../redux/reducer/apps";
import { useDashboardChart } from "../../hooks/useDashboardChart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useQueryUrl } from "../../hooks/useQueryUrl";

const useStyles = createUseStyles({
    selectStyle: {
        width: "100%",
        "& .ant-select-selector": {
            borderRadius: "8px !important",
            height: ({ fontSize }) => `${fontSize.lg * 1.8}px !important`,
        },
        "& .ant-select-selection-placeholder": {
            fontSize: ({ fontSize }) => `${fontSize.sm}px`,
            lineHeight: ({ fontSize }) => `${fontSize.xl2 + 3}px !important`,
            // paddingTop: ({ fontSize }) => `-5px !important`,
        },
        "& .ant-select-selection-search-input": {
            height: "100% !important",
        },
    },
    customDatePicker: {
        "& .ant-picker-suffix": {
            right: 8, // Adjust the right position as needed
            left: "auto",
        },
    },
    buttonTransparent: {
        borderRadius: 8,
        border: "1px solid white",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        // background: ({ isChecked }) => (isChecked ? "white" : "red"),
        fontSize: ({ fontSize }) => fontSize.xs,
        "&:hover": {
            background: "white",
            border: "1px solid white",
            fontWeight: "bold", // Change the background color when the button is clicked
            color: "#57b4ce", // Change the text color when the button is clicked
            // Add other styles as needed
        },
        "&:focus": {
            background: "white",
            border: "1px solid white",
            fontWeight: "bold", // Change the background color when the button is clicked
            color: "#57b4ce", // Change the text color when the button is clicked
            // Add other styles as needed
        },
    },
    dropDownStyle: {
        "& ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light":
            {
                borderRadius: "8px !important",
            },
    },
    radioStyle: {
        "& .ant-radio-button-wrapper": {
            marginRight: "4px",
            borderRadius: "6px",
            borderLeftWidth: 1.02,
            fontSize: ({ fontSize }) => fontSize.xxs,
            fontWeight: "bold",
        },
        "& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before":
            {
                display: "none",
            },
        "& .ant-radio-button-wrapper:not(:first-child)::before": {
            display: "none",
        },
    },
});

const isSelected = (isClicked, idx, label, query, summaryCard) => {
    if (!summaryCard) {
        return false;
    }
    return label === query || (isClicked.state && isClicked.id === idx);
};

const DashboardLayout = ({ children, state, onChange }) => {
    const { airportCache, airlineCache, filterPage, dashboardState } =
        useSelector((item) => item.apps);
    const fontSize = useFontSizeV2();
    const classes = useStyles({ fontSize });
    const { userdata } = useSelector((state) => state.auth);
    const history = useHistory();
    const dispatch = useDispatch();
    const [isClicked, setIsClicked] = useState({
        state: false,
        id: null,
    });

    const items = getItems(userdata, dispatch);

    const [dataTotal, loadingTotal] = useDashboardChart({
        ...filterPage,
        type: "total",
        // doSend: dashboardState.chart === "all",
    });
    const summaryData = useMemo(() => getSummaryData(dataTotal), [dataTotal]);

    const [airports, airlines] = useMemo(
        () => refacCache(airportCache, airlineCache),
        [airlineCache, airportCache]
    );

    const currentYear = moment().year();

    // Disable dates before 2023 and after the current year
    const disabledDate = (current) => {
        return (
            current && (current.year() < 2023 || current.year() > currentYear)
        );
    };

    const optionAirports = useMemo(
        () => getOptionAirPort(airports),
        [airports]
    );

    const optionAirlines = useMemo(
        () => getOptionAirlines(airlines),
        [airports]
    );

    const query = useQueryUrl();

    return (
        <div
            style={{ height: "100vh", width: "100vw", boxSizing: "border-box" }}
        >
            <div
                style={{
                    height: "26%",
                    background: "#023047",
                    padding: "0px 20px 0px 20px",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "",
                        // border: "1px solid red",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 16,
                            // marginBottom: 2,
                        }}
                    >
                        {/* <img
              src={Logo}
              alt="logo kemenhub"
              style={{ width: fontSize.xl }}
            /> */}
                        <Button
                            href="/apps/search"
                            style={{
                                borderRadius: "8px",
                                background: "white",
                                border: "none",
                                color: "white",
                                width: "100%",
                                height: fontSize.lg * 1.8,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: 8,
                            }}
                            size="medium"
                        >
                            <Typography
                                style={{
                                    color: "#023047",
                                    fontSize: fontSize.md,
                                    fontWeight: "bold",
                                }}
                            >
                                Apps Menu
                            </Typography>
                            <SearchOutlined style={{ color: "black" }} />
                        </Button>
                    </div>
                    <div>
                        <Typography
                            style={{
                                fontSize: fontSize.lg * 1.2,
                                color: "white",
                                textAlign: "center",
                                letterSpacing: "4px",
                                fontWeight: "bolder",
                                padding: "6px",
                            }}
                        >
                            <span style={{ height: 10, paddingRight: "8px" }}>
                                <img
                                    src={Logo}
                                    alt="logo kemenhub"
                                    style={{
                                        width: fontSize.xl,
                                        marginRight: 6,
                                        marginBottom: 6,
                                    }}
                                />
                            </span>
                            DASHBOARD ANALYTIC SYSTEM
                        </Typography>
                        <div style={{ display: "flex", gap: 8 }}>
                            <DatePicker
                                className={classes.customDatePicker}
                                style={{
                                    width: fontSize.lg * 10,
                                    borderRadius: "8px",
                                    height: fontSize.lg * 1.8,
                                }}
                                onChange={(val) => {
                                    const year = moment(val).year();
                                    onChange("year", year);
                                }}
                                picker="year"
                                size="large"
                                placeholder="Pilih tahun"
                                disabledDate={disabledDate}
                                defaultValue={moment(filterPage)}
                            />
                            <Select
                                allowClear
                                showSearch
                                className={classes.selectStyle}
                                size="large"
                                placeholder="Maskapai"
                                optionFilterProp="children"
                                onChange={(val) => {
                                    onChange("airline", val);
                                }}
                                suffixIcon={
                                    <SearchOutlined
                                        style={{ color: "#c3bfc7" }}
                                    />
                                }
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                style={{
                                    width: fontSize.lg * 10,
                                }}
                                options={optionAirlines}
                                value={filterPage?.airline_id || null}
                            />
                            <Select
                                allowClear
                                className={classes.selectStyle}
                                showSearch
                                size="large"
                                placeholder="Bandara"
                                optionFilterProp="children"
                                onChange={(val) => {
                                    onChange("airport", val);
                                }}
                                suffixIcon={
                                    <SearchOutlined
                                        style={{ color: "#c3bfc7" }}
                                    />
                                }
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                style={{
                                    width: fontSize.lg * 10,
                                    // height: fontSize.lg * 1.8,
                                }}
                                options={optionAirports}
                                value={filterPage?.airport_id || null}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 8,
                        }}
                    >
                        <Dropdown
                            className={classes.dropDownStyle}
                            menu={{ items }}
                            placement="bottomRight"
                            trigger={["click"]}
                            arrow
                        >
                            <Button
                                style={{
                                    borderRadius: "8px",
                                    background: "white",
                                    border: "none",
                                    color: "white",
                                    width: "100%",
                                    height: fontSize.lg * 1.8,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: 8,
                                }}
                                size="medium"
                                onClick={() => {}}
                            >
                                <div
                                    style={{
                                        borderRadius: "100%",
                                        width: fontSize.md,
                                        height: fontSize.md,
                                        background: "#301d6e",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: "yellow",
                                            fontSize: fontSize.md,
                                        }}
                                    >
                                        {userdata.name
                                            .slice(0, 1)
                                            .toUpperCase()}
                                    </Typography>
                                </div>
                                <Typography
                                    style={{
                                        color: "#023047",
                                        fontSize: fontSize.md,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {userdata.name}
                                </Typography>
                            </Button>
                        </Dropdown>
                    </div>
                </div>

                <SummaryCard
                    dataTotal={dataTotal}
                    isClicked={isClicked}
                    setIsClicked={setIsClicked}
                    summaryData={summaryData}
                    loadingTotal={loadingTotal}
                    dashboardState={dashboardState}
                />
            </div>
            <div style={{ height: fontSize.xl * 1.5, position: "relative" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "0px 20px",
                        gap: 10,
                        position: "absolute",
                        top: 0,
                        left: 0,
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "15%",
                        }}
                    />
                    {!loadingTotal && !!summaryData
                        ? summaryData.map(({ label }, idx) => {
                              const title = getLabel(label);
                              return (
                                  <div
                                      onClick={() => {
                                          setIsClicked({
                                              ...isClicked,
                                              state:
                                                  isClicked.id !== idx
                                                      ? true
                                                      : !isClicked.state,
                                              id: idx,
                                          });
                                          if (
                                              dashboardState.summaryCard ===
                                              label
                                          ) {
                                              dispatch(
                                                  setDashboardState({
                                                      summaryCard: "",
                                                  })
                                              );
                                              history.push({ search: "" });
                                          } else {
                                              dispatch(
                                                  setDashboardState({
                                                      summaryCard: label,
                                                  })
                                              );
                                              history.push({
                                                  search: `s=${label}`,
                                              });
                                          }
                                      }}
                                      key={idx}
                                      style={{
                                          height: "100%",
                                          width: "13%",
                                          cursor: "pointer",
                                          //   transform:
                                          //     isClicked.state && isClicked.id === idx && "scale(1.2)",
                                          //   margin:
                                          //     isClicked.state && isClicked.id === idx && "0px 16px",
                                      }}
                                  >
                                      <div
                                          style={{
                                              opacity: isSelected(
                                                  isClicked,
                                                  idx,
                                                  label,
                                                  query.get("s"),
                                                  dashboardState.summaryCard
                                              )
                                                  ? 1
                                                  : !dashboardState.summaryCard
                                                  ? 1
                                                  : 0.5,
                                              height: fontSize.xl * 1.2,
                                              width: "100%",
                                              borderBottomLeftRadius:
                                                  fontSize.xs,
                                              borderBottomRightRadius:
                                                  fontSize.xs,
                                              background: "#023047",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              borderBottom: isSelected(
                                                  isClicked,
                                                  idx,
                                                  label,
                                                  query.get("s"),
                                                  dashboardState.summaryCard
                                              )
                                                  ? "2px solid white"
                                                  : "",
                                              borderLeft: isSelected(
                                                  isClicked,
                                                  idx,
                                                  label,
                                                  query.get("s"),
                                                  dashboardState.summaryCard
                                              )
                                                  ? "2px solid white"
                                                  : "",
                                              borderRight: isSelected(
                                                  isClicked,
                                                  idx,
                                                  label,
                                                  query.get("s"),
                                                  dashboardState.summaryCard
                                              )
                                                  ? "2px solid white"
                                                  : "",
                                          }}
                                      >
                                          <Typography
                                              style={{
                                                  fontSize: fontSize.md,
                                                  color: "white",
                                              }}
                                          >
                                              {title}
                                          </Typography>
                                      </div>
                                  </div>
                              );
                          })
                        : [...Array(5)].map((item, idx) => {
                              return (
                                  <div
                                      style={{
                                          height: "100%",
                                          width: "13%",
                                          cursor: "pointer",
                                      }}
                                      key={idx}
                                  >
                                      <div
                                          key={idx}
                                          style={{
                                              opacity:
                                                  isClicked.state &&
                                                  isClicked.id === idx
                                                      ? 1
                                                      : !isClicked.state
                                                      ? 1
                                                      : 0.5,
                                              height: fontSize.xl * 1.2,
                                              width: "100%",
                                              borderBottomLeftRadius:
                                                  fontSize.xs,
                                              borderBottomRightRadius:
                                                  fontSize.xs,
                                              background: "#023047",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                          }}
                                      />
                                  </div>
                              );
                          })}
                    <div style={{ height: "100%", width: "15%" }} />
                </div>
            </div>
            <Fragment>{children}</Fragment>
        </div>
    );
};

export default DashboardLayout;

const getItems = (userdata, dispatch) => [
    {
        key: "1",
        label: (
            <Fragment>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #F0E5FC",
                        paddingBottom: 10,
                        paddingTop: 10,
                    }}
                >
                    <UserOutlined />
                    <Typography style={{ fontWeight: 600 }}>
                        {userdata.name}
                    </Typography>
                </div>
            </Fragment>
        ),
    },
    {
        key: "2",

        label: (
            <Fragment>
                <div
                    onClick={() => {
                        dispatch(doLogout());
                        dispatch(setFilterPage({ chart: "all" }));
                        dispatch(setDashboardState({ summaryCard: "all" }));
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: 10,
                        paddingTop: 10,
                    }}
                >
                    <LogoutOutlined />
                    <Typography style={{ fontWeight: 600 }}>Logout</Typography>
                </div>
            </Fragment>
        ),
    },
];

const getSummaryData = (data) => {
    const dataArray =
        !!data &&
        Object.keys(data?.current_year).map((key) => ({
            label: key,
            value: data?.current_year[key],
            prevValue: data?.prev_year[key],
        }));
    return dataArray;
};

const refacCache = (airportCache, airlineCache) => {
    const aports = airportCache
        .filter((item) => !!item.name)
        .map((item) => {
            return {
                value: item._id,
                label: item.name,
                code: item.code,
            };
        });

    const alines = airlineCache
        .filter((item) => !!item.name)
        .map((item) => {
            return {
                value: item._id,
                label: item.name,
                code: item.code,
            };
        });

    return [sortingLabel(aports), sortingLabel(alines)];
};

const getOptionAirPort = (airports) => {
    const data = airports?.map((item) => {
        return {
            value: item.value,
            label: `${item?.code} -- ${item?.label}`,
        };
    });
    return data;
};

const getOptionAirlines = (airlines) => {
    const data = airlines?.map((item) => {
        return {
            value: item.value,
            label: `${item?.code} -- ${item?.label}`,
        };
    });
    return data;
};

const getLabel = (label) => {
    switch (label) {
        case "airport":
            return "Bandara";
        case "airline":
            return "Maskapai";
        case "flight":
            return "Penerbangan";
        case "pax":
            return "Penumpang";
        default:
            return "Kargo(Ton)";
    }
};
