import { Button, Select, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { IoMdPeople } from "react-icons/io";
import { useLanguage } from "../../../../../hooks/useLanguage";
import Panel from "../panel/panel";
import Table from "../lists/table";
import { ImMan, ImWoman } from "react-icons/im";
import { FaBaby } from "react-icons/fa";
import Flags from "../../../../../components/flags";
import { useFetch } from "../../../../../hooks/useFetch";
import { lookup } from "country-data";
import numeral from "numeral";
import WatchFlag from "./watchflag";
import moment from "moment";
import { FileExcelOutlined } from "@ant-design/icons";
import { green } from "@ant-design/colors";
import { exportManifestVessel } from "../details/passengers/manifest";
import { useEffect } from "react";

const OtherPanel = ({ onExportBtnClick, data, setData }) => {
  return (
    <React.Fragment>
      <Button
        onClick={onExportBtnClick}
        style={{ background: green[8], borderRadius: 99 }}
        icon={<FileExcelOutlined style={{ color: "white" }} />}
        type="text"
      />
      <Select
        style={{ width: 120 }}
        value={data.watchlist}
        onChange={(val) => setData({ ...data, watchlist: val })}
      >
        <Select.Option value="all">ALL</Select.Option>
        <Select.Option value="orange">PPT/VIT</Select.Option>
        <Select.Option value="red">ITP/CKL</Select.Option>
        <Select.Option value="melintas">MELINTAS</Select.Option>
      </Select>
    </React.Fragment>
  );
};

export default ({ vessel_id, format, onPaxReceives }) => {
  const [data, loading] = useFetch(
    "api/v1/vessel_pax/detail/vessel_id/" + vessel_id
  );
  const [model, setModel] = useState({ watchlist: "all" });
  const [, getLan] = useLanguage();
  const renderGender = (gender, pax_type) => {
    if (pax_type !== "IN") {
      switch (gender.toLowerCase()) {
        case "f":
          return <ImWoman size={24} color="#ff6e87" />;
        case "m":
          return <ImMan size={24} color="blue" />;
        default:
          return <ImMan size={24} color="blue" />;
      }
    }
    return <FaBaby size={24} color="green" />;
  };

  const nData = useMemo(() => {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    const fData = data.filter(({ watch_flag, perlintasan }) => {
      const { watchlist } = model;
      if (watchlist !== "all" && watchlist !== "melintas") {
        if (watch_flag && watch_flag > 0) {
          const filtered =
            watchlist === "orange"
              ? (watch_flag & 24) > 0
              : (watch_flag & 7) > 0;
          return filtered;
        }
        return false;
      } else if (watchlist === "melintas") {
        return perlintasan?.timestamp;
      }
      return true;
    });
    const sData = fData
      .sort((a, b) => `${a?._id}`.localeCompare(`${b?._id}`))
      .map((row, idx) => ({ ...row, number: idx + 1 }));
    return sData;
  }, [data, model]);

  useEffect(() => {
    typeof onPaxReceives === "function" && onPaxReceives(data);
  }, [data]);

  const handleExport = () => {
    if (nData.length > 0) {
      const [{ vessel_id }] = nData;
      return exportManifestVessel(nData, vessel_id);
    }
  };

  return (
    <Panel
      headerLeft={<IoMdPeople size={24} />}
      headerRight={
        <Typography.Text>{getLan("vessel_passengers")}</Typography.Text>
      }
    >
      <Table
        data={nData || []}
        filterField={["passenger_name", "doc_number"]}
        url="/vessel/passengers/detail"
        OtherSearch={
          <OtherPanel
            data={model}
            setData={setModel}
            onExportBtnClick={handleExport}
          />
        }
        columns={[
          {
            title: "#",
            dataIndex: "number",
            width: 60,
          },
          {
            title: "passenger_name",
            dataIndex: "passenger_name",
            width: 240,
            render: (val, rec) => {
              const { crew } = rec;
              const isCrew = crew?.position;
              return (
                <div
                  title={val}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    alignItems: "start",
                    width: 230,
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      textTransform: "uppercase",
                      paddingInline: 2,
                      background: isCrew ? "#0e0ea3" : "#525050",
                      borderRadius: 2,
                    }}
                  >
                    {isCrew ? "C" : "P"}
                  </span>
                  <Typography.Text ellipsis>{val}</Typography.Text>
                </div>
              );
            },
          },
          {
            title: "gender",
            dataIndex: "gender",
            width: 60,
            render: (val, rec) => renderGender(val, rec?.pax_type),
          },
          {
            title: "age",
            dataIndex: "age",
            render: (val, rec) => {
              const { dob } = rec;
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    alignItems: "start",
                    width: 160,
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      textTransform: "uppercase",
                      paddingInline: 2,
                      background: "#525050",
                      borderRadius: 2,
                    }}
                  >
                    {numeral(val).format("00")}
                  </span>
                  <span>{dob}</span>
                </div>
              );
            },
          },
          {
            title: "doc_number",
            dataIndex: "doc_number",
            render: (val, rec) => {
              const { doc_type } = rec;
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      textTransform: "uppercase",
                      paddingInline: 2,
                      background: "#525050",
                      borderRadius: 2,
                    }}
                  >
                    {doc_type.substring(0, 1)}
                  </span>
                  <span>{val}</span>
                </div>
              );
            },
          },
          {
            title: "doc_expiry",
            dataIndex: "doc_expiration",
          },
          {
            title: "nationality",
            dataIndex: "nationality",
            render: (val, rec) => {
              if (val.length > 3) {
                const c = val.substring(0, 1) + val.substring(1).toLowerCase();
                const [cty] = lookup.countries({ name: c });
                return (
                  (!!cty && (
                    <Flags
                      width={80}
                      countryFormat={"alpha3"}
                      withCountry
                      country_code={cty?.alpha2 || ""}
                    />
                  )) ||
                  "-"
                );
              }
              return (
                (!!val && (
                  <Flags
                    width={80}
                    countryFormat={"alpha3"}
                    withCountry
                    country_code={val}
                  />
                )) ||
                "-"
              );
            },
          },
          {
            title: "melintas",
            dataIndex: "perlintasan",
            ellipsis: true,
            render: (val) =>
              (val?.timestamp &&
                moment(val.timestamp).format("DD MMM, HH:mm")) ||
              "-",
          },
          {
            title: "watchlist",
            dataIndex: "watch_flag",
            render: (val, rec) => <WatchFlag flag={val} row={rec} />,
          },
        ]}
      />
    </Panel>
  );
};
