import moment from "moment";
import hw from "holtwinters-md";
// Custom function to get the semester number
function getSemesterNumber(date) {
  const month = date.month() + 1; // months are zero-based in Moment.js

  if (month >= 1 && month <= 6) {
    return 1; // Spring Semester
  } else if (month >= 7 && month <= 12) {
    return 2; // Fall Semester
  } else {
    return 0; // Unknown Semester
  }
}

// Extend Moment.js prototype to add a semester method
moment.fn.semester = function () {
  const semesterNumber = getSemesterNumber(this);
  return semesterNumber === 0 ? "Unknown Semester" : semesterNumber;
};

const formatData = (data) => {
  const result = { monthly: {}, quarterly: {}, semesterly: {} };
  for (let iii = 0; iii < data?.length; iii++) {
    const { name, curr } = data[iii];
    const d = moment(name, "DD-MMM-YYYY");
    if (!result.monthly[d.month()]) {
      result.monthly[d.month()] = 0;
    }
    if (!result.quarterly[d.quarter()]) {
      result.quarterly[d.quarter()] = 0;
    }
    if (!result.semesterly[d.semester()]) {
      result.semesterly[d.semester()] = 0;
    }
    // result.weekly[d.week()] += curr;
    result.monthly[d.month()] += curr;
    result.quarterly[d.quarter()] += curr;
    result.semesterly[d.semester()] += curr;
  }
  const monthly = [];
  const quarterly = [];
  const semesterly = [];
  for (const key in result.monthly) {
    if (Object.hasOwnProperty.call(result.monthly, key)) {
      const tot = result.monthly[key];
      monthly.push({ name: parseInt(key) + 1, curr: tot });
    }
  }
  for (const key in result.quarterly) {
    if (Object.hasOwnProperty.call(result.quarterly, key)) {
      const tot = result.quarterly[key];
      quarterly.push({ name: `Kwartal ${parseInt(key)}`, curr: tot });
    }
  }
  for (const key in result.semesterly) {
    if (Object.hasOwnProperty.call(result.semesterly, key)) {
      const tot = result.semesterly[key];
      semesterly.push({ name: `Semester ${parseInt(key)}`, curr: tot });
    }
  }
  return [monthly, quarterly, semesterly];
};

export const getFilterData = (data, type) => {
  const daily = data;
  const [monthly, quarterly, semesterly] = formatData(data);
  return { daily, semesterly, monthly, quarterly };
};

const formatDataDetail = (data) => {
  const result = { monthly: {}, quarterly: {}, semesterly: {} };
  for (let iii = 0; iii < data?.length; iii++) {
    const { date, domestic, dexport, dimport } = data[iii];
    const d = moment(date, "DD-MMM-YYYY");
    if (!result.monthly[d.month()]) {
      result.monthly[d.month()] = 0;
    }
    if (!result.quarterly[d.quarter()]) {
      result.quarterly[d.quarter()] = 0;
    }
    if (!result.semesterly[d.semester()]) {
      result.semesterly[d.semester()] = 0;
    }
    // result.weekly[d.week()] += curr;
    result.monthly[d.month()] += domestic;
    result.quarterly[d.quarter()] += domestic;
    result.semesterly[d.semester()] += domestic;
    result.monthly[d.month()] += dexport;
    result.quarterly[d.quarter()] += dexport;
    result.semesterly[d.semester()] += dexport;
    result.monthly[d.month()] += dimport;
    result.quarterly[d.quarter()] += dimport;
    result.semesterly[d.semester()] += dimport;
  }
  const monthly = [];
  const quarterly = [];
  const semesterly = [];
  for (const key in result.monthly) {
    if (Object.hasOwnProperty.call(result.monthly, key)) {
      const tot = result.monthly[key];
      monthly.push({ name: parseInt(key) + 1, curr: tot });
    }
  }
  for (const key in result.quarterly) {
    if (Object.hasOwnProperty.call(result.quarterly, key)) {
      const tot = result.quarterly[key];
      quarterly.push({ name: `Kwartal ${parseInt(key)}`, curr: tot });
    }
  }
  for (const key in result.semesterly) {
    if (Object.hasOwnProperty.call(result.semesterly, key)) {
      const tot = result.semesterly[key];
      semesterly.push({ name: `Semester ${parseInt(key)}`, curr: tot });
    }
  }
  return [monthly, quarterly, semesterly];
};

export const getFilterDataDetail = (data, type) => {
  const daily = data;
  const [monthly, quarterly, semesterly] = formatDataDetail(data);
  return { daily, semesterly, monthly, quarterly };
};

export const forecastData = (data, dataType) => {
  // console.log({data});
  if (!data) return [];
  if (data.length === 0) return [];
  if (data.length < 10) return [];
  const raw = data.map(({ curr }) => curr);
  const sizePredict = raw.length >= 100 ? Math.ceil(raw.length * 0.1) : 4;

  const fc = raw.length < 4 ? [] : hw(raw, sizePredict);
  const last = data[data.length - 1];
  let date =
    dataType === "daily" ? moment(last.name, "DD-MMM-YYYY") : last.name;
  if (fc.length === 0) return data;
  // moment(last._id, 'DD-MMM-YYYY').add(1,'day')
  if (!fc) return data;
  // console.log({data, fc, sizePredict, date });
  const fData = [...data];
  for (let iii = data.length; iii < fc.augumentedDataset.length; iii++) {
    const pNum = fc.augumentedDataset[iii];
    let id = "";
    switch (dataType) {
      case "daily":
        date = date.add(1, "day");
        id = date.format("DD-MMM-YYYY");
        break;
      case "monthly":
        date++;
        id = parseInt(date) % 12;
        break;
      case "weekly":
        date++;
        id = parseInt(date) % 52;
        break;
      case "quarterly":
        date++;
        id = parseInt(date) % 4;
        break;
      case "semesterly":
        date++;
        id = parseInt(date) % 2;
        break;
      default:
        break;
    }
    fData.push({ name: id, curr: pNum });
  }
  return fData.map(({ curr, name }) => ({ name, value: curr }));
};
