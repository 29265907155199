import { useEffect } from "react";

const isReCaptcha = (errMsg = "") => {
    if (!errMsg) return false;
    switch (errMsg?.toLowerCase()) {
        case "captcha invalid":
            return false;

        default:
            return true;
    }
};

export const useHandlerCaptcha = (errorMessage, callback) => {
    useEffect(() => {
        callback(isReCaptcha(errorMessage));
    }, [errorMessage]);
};
