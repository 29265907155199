import React, { useEffect } from "react";
import { SiderLayout3 } from "../../../components/layouts/siderV3";
import Menus from "./menu";
import { green } from "@ant-design/colors";
import { useSelector, useDispatch } from "react-redux";
import { Routing } from "./routing";
import { Route } from "react-router-dom";
// import {loadAirlines, loadAirports} from '../../../redux'
import { Switch } from "react-router-dom";
import { useLocHref } from "../../../hooks/useLocHref";
import { useMenu } from "../../../hooks/useMenu";
import { useNavs } from "../../../hooks/useNavs";
import CONFIG from "../../../config/env";
import { setBreadcrumbsKey } from "../../../redux/reducer/navigations";
import { useLocation } from "react-router-dom";
import PageWithBreadcrumbs from "../components/PageWithBreadcrumbs";
const { Title } = CONFIG;
const TheRouter = () => {
    const {
        userdata: { level },
    } = useSelector((state) => state.auth);
    const routes = Routing.filter((r) => (r.level & level) > 0);

    return (
        <PageWithBreadcrumbs>
            <Switch>
                {routes.map((route, idx) => (
                    <Route
                        key={idx}
                        path={route.to}
                        exact={route.isExact !== false}
                        render={(props) => {
                            return !!route.component && route.component;
                        }}
                    />
                ))}
                <Route path={"/*"}>
                    <div>Page Not Found</div>
                </Route>
            </Switch>
        </PageWithBreadcrumbs>
    );
};

export default ({ idx }) => {
    // const dispatch = useDispatch();
    // const location = useLocation();
    // useEffect(() => {
    //     dispatch(setBreadcrumbsKey(location?.pathname));
    // }, [location?.pathname]);
    const {
        userdata: { level },
    } = useSelector((state) => state.auth);
    const key = useLocHref("beranda");
    const { keys, menus } = useMenu(Menus, level);
    useNavs(keys, key);
    // console.log({ menus });
    return (
        <SiderLayout3 theme={"light"} menus={menus} title={`${Title}`}>
            <TheRouter />
        </SiderLayout3>
    );
};
