import React from "react";
import DashboardChart from "../../DashboardChartV2";
import { customFormatWeight } from "../../../../../../../../helper/customFormat";
import { useState } from "react";
import { useFontSizeV2 } from "../../../../../../../../hooks/useDimension";
import Config from "../../../../../../../../config/env";
import utilsAirline from "../../../../../../features/PanelChart/Airline/utilsAirline";

const { chartColor } = Config;

const getOptions = (dataSource, fontSize) => {
  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    dataset: dataSource,
    legend: {
      bottom: -5,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "24%",
      containLabel: true,
      top: "5%",
    },
    dataZoom: [
      {
        type: "inside",
        start: 1,
        end:
          dataSource?.source?.length < 20
            ? 100
            : dataSource?.source?.length < 50
            ? 30
            : dataSource?.source?.length < 100
            ? 20
            : 15,
      },
      {
        show: true,
        type: "slider",
        start: 1,
        end: 100,
        height: 15,
        bottom: "15%",
      },
    ],

    xAxis: {
      type: "category",
      axisLabel: {
        textStyle: {
          fontSize: fontSize.sm,
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => customFormatWeight(params),
      },
    },
    series: [
      {
        name: `Domestic`,
        type: "bar",
        symbol: "none",
        barWidth: 10,
        barGap: 0,
        datasetIndex: 0,
        encode: { x: "label", y: "domestic" },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#023047",
        },
      },
      {
        name: `International`,
        type: "bar",
        symbol: "none",
        barWidth: 10,
        barGap: 0,
        datasetIndex: 0,
        encode: { x: "label", y: "international" },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#FFB703",
        },
      },
    ],
  };
};

const AirportChart = ({ data, loading, filterPage }) => {
  const [view, setView] = useState("detail");
  const fontSize = useFontSizeV2();
  const [dataset, datatable] = utilsAirline.getDataAirportByAirline(data);

  return (
    <DashboardChart
      printHeader={`Bandara Report`}
      // printPeriode={`${filters.airport} ${filterPage.year}`}
      repType={`Bandara Report`}
      title={"Bandara"}
      data={getOptions(dataset, view, fontSize)}
      dataSource={datatable}
    />
  );
};

export default AirportChart;
