import React, { Fragment, useMemo, useState } from "react";
import Vessel from "../../../components/pages/Vessel";
import moment from "moment";
import { useFetch } from "../../../../../hooks/useFetch";
const defValue = {
    vessel_type: "ferry",
    date: moment(),
};

export default function Arrival() {
    const [search, setSearch] = useState(defValue);

    const formatedSearch = useMemo(() => {
        const { date, ...rest } = search;
        const start = moment(search.date).format("YYYYMMDD");
        const end = moment(search.date).add(1, "day").format("YYYYMMDD");
        return { ...rest, schedule_date: [start, end] };
    }, [search]);

    const uri = useMemo(() => {
        return `api/v1/vessel/arrival/inquiry?search=${JSON.stringify(
            formatedSearch
        )}`;
    }, [formatedSearch]);
    const [result, loading] = useFetch(uri);
    return <Vessel dataSource={result} type="Kedatangan" />;
}
