import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import MainCard from "../MainCard";
import { Col, Radio, Row, Spin, Table, Typography } from "antd";
import ReactECharts from "echarts-for-react";
import ModalChart from "./ModalChart";
import { useFontSizeV2 } from "../../hooks/useDimension";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardState } from "../../redux/reducer/apps";
import Config from "../../config/env";
import TableForm from "../pages/reports/tableForm";
const { secondaryColor } = Config;

const useStyles = createUseStyles({
    buttonTransparent: {
        borderRadius: 4,
        border: "1px solid #7a6ea5",
        color: "gray",
        fontSize: ({ fontSize }) => fontSize.xxs,
        "&:hover": {
            background: "#dee7fe",
            border: "1px solid #7a6ea5",
            fontWeight: "bold", // Change the background color when the button is clicked
            color: "#7a6ea5", // Change the text color when the button is clicked
            // Add other styles as needed
        },
        "&:focus": {
            background: "#dee7fe",
            border: "1px solid #7a6ea5",
            fontWeight: "bold", // Change the background color when the button is clicked
            color: "#7a6ea5", // Change the text color when the button is clicked
            // Add other styles as needed
        },
    },
    radioStyle: {
        "& .ant-radio-button-wrapper": {
            marginRight: "4px",
            borderRadius: "6px",
            borderLeftWidth: 1.02,
            fontSize: ({ fontSize }) => fontSize.xxs,
            fontWeight: "bold",
        },
        "& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before":
            {
                display: "none",
            },
        "& .ant-radio-button-wrapper:not(:first-child)::before": {
            display: "none",
        },
    },
});

const Div = ({ ...props }) => {
    return <div {...props}></div>;
};

const optionsWith = [
    {
        label: "Chart",
        value: "chart",
    },
    {
        label: "Table",
        value: "table",
    },
];

const ComponentChart = ({ key, height, callbackClick, opt }) => {
    return (
        <ReactECharts
            key={key}
            style={{
                height,
                width: "97%",
                padding: 8,
                paddingBottom: 0,
            }}
            onEvents={{
                click: callbackClick,
            }}
            option={opt}
            opts={{ renderer: "svg" }}
        />
    );
};

const RenderComponentChart = ({
    key,
    height,
    callbackClick,
    opt,
    chartPanelRender,
}) => {
    return (
        <Fragment>
            {chartPanelRender && typeof chartPanelRender === "function" ? (
                chartPanelRender(
                    <ComponentChart
                        key={key}
                        height={height}
                        callbackClick={callbackClick}
                        opt={opt}
                    />
                )
            ) : (
                <ComponentChart
                    key={key}
                    height={height}
                    callbackClick={callbackClick}
                    opt={opt}
                />
            )}
        </Fragment>
    );
};

export default function MainChart({
    title,
    className,
    subTitle,
    option,
    height,
    filterComp = [],
    fullscreen = false,
    onFullScreen,
    otherComponent,
    onClickItemChart,
    withOutCard,
    isTotal,
    valueFilter = "",
    setValueFilter,
    loading,
    key,
    titleSelected = "",
    isDetail,
    dataSource,
    printHeader,
    repType,
    more,
    chartPanelRender,
    printPeriode,
}) {
    const { dashboardState } = useSelector((item) => item.apps);
    const fontSize = useFontSizeV2();
    const [open, setOpen] = useState(false);
    const [doRenderModal, setDoRenderModal] = useState(false);
    const [dots, setDots] = useState(0);
    const [value, setValue] = useState("chart");
    const classes = useStyles({ fontSize });
    const dispatch = useDispatch();

    useEffect(() => {
        !!onFullScreen && onFullScreen(open);
    }, [open]);

    const Component = !!withOutCard ? Div : MainCard;

    const opt = useMemo(() => {
        let result = {
            ...option,
            textStyle: {
                ...option?.textStyle,
                fontFamily: "",
                fontSize: fontSize.sm,
            },
        };
        if (!!option?.yAxis) {
            result = {
                ...result,
                yAxis: {
                    ...result.yAxis,
                    axisLabel: {
                        ...result.yAxis?.axisLabel,
                        fontSize: fontSize.sm,
                    },
                },
            };
        }

        if (!!option?.xAxis) {
            result = {
                ...result,
                xAxis: {
                    ...result.xAxis,
                    axisLabel: {
                        ...result.xAxis?.axisLabel,
                        fontSize: fontSize.sm,
                    },
                },
            };
        }
        return result;
    }, [option]);

    useEffect(() => {
        if (!open) {
            setDoRenderModal(false);
        } else {
            setTimeout(() => {
                setDoRenderModal(true);
            }, 500);
        }
    }, [open]);
    const callbackClick = useCallback((val) => {
        typeof onClickItemChart === "function" && onClickItemChart(val);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDots((prevDots) => (prevDots === 3 ? 0 : prevDots + 1));
        }, 500);

        return () => clearInterval(intervalId);
    }, []);

    const loadingText = `Loading${".".repeat(dots)}`;

    const renderBody = (isModal, t, titleSelected, isDetail) => {
        const h = isModal ? "90%" : t;
        return (
            <Fragment>
                <Row
                    style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingTop: !!filterComp ? 8 : 4,
                        paddingLeft: 16,
                        paddingRight: 16,
                        // border: "1px solid red",
                    }}
                    gutter={[16]}
                >
                    <Col>
                        <Typography
                            className="title-onDoubleClick"
                            style={{
                                fontWeight: "bold",
                                cursor: !!fullscreen && "pointer",
                                fontSize: fontSize.md,
                                color: "#024157",
                                textTransform: "capitalize",
                            }}
                        >
                            {title} {isDetail && <span>{titleSelected}</span>}
                        </Typography>
                        {!!subTitle && typeof subTitle === "string" && (
                            <Typography
                                style={{
                                    fontSize: fontSize.sm,
                                    color: "gray",
                                }}
                            >
                                <em>{subTitle}</em>
                            </Typography>
                        )}
                    </Col>
                    {!!more && (
                        <Col
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                                justifyContent: "end",
                            }}
                        >
                            {more}
                        </Col>
                    )}
                </Row>
                {!!isModal && (
                    <div
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 10,
                        }}
                    >
                        <Radio.Group
                            options={optionsWith}
                            onChange={({ target: { value } }) => {
                                setValue(value);
                            }}
                            value={value}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </div>
                )}
                {!isModal &&
                    !!otherComponent &&
                    typeof otherComponent === "function" && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            {otherComponent()}
                        </div>
                    )}
                <div
                    style={{
                        flexGrow: 1,
                        position: "relative",
                    }}
                >
                    {!!loading ? (
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            <Spin tip={loadingText} size="large">
                                <div
                                    style={{
                                        minHeight: "100px",
                                        minWidth: "100px",
                                        // border: "1px solid red",
                                    }}
                                />
                            </Spin>
                        </div>
                    ) : (
                        <Fragment>
                            {isModal ? (
                                value === "chart" ? (
                                    <RenderComponentChart
                                        callbackClick={callbackClick}
                                        chartPanelRender={chartPanelRender}
                                        height={h}
                                        key={key}
                                        opt={opt}
                                        view={value}
                                    />
                                ) : (
                                    <TableForm
                                        repType={repType}
                                        dataSource={dataSource}
                                        printHeaderReport={printHeader}
                                        printPeriode={printPeriode}
                                    />
                                )
                            ) : (
                                <RenderComponentChart
                                    callbackClick={callbackClick}
                                    chartPanelRender={chartPanelRender}
                                    height={h}
                                    key={key}
                                    opt={opt}
                                    view={value}
                                />
                            )}
                        </Fragment>
                    )}
                    {/* {isModal &&
                        !!otherComponent &&
                        typeof otherComponent === "function" && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 10,
                                    marginTop: 10,
                                }}
                            >
                                {otherComponent()}
                            </div>
                        )} */}
                </div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Component isNonBackground isBorder className={className}>
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        // border: "1px solid red",
                    }}
                    onDoubleClick={(e) => fullscreen && setOpen(true)}
                >
                    {renderBody(false, height)}
                </div>
                <ModalChart open={open} setOpen={setOpen}>
                    {doRenderModal &&
                        renderBody(true, height, titleSelected, isDetail)}
                </ModalChart>
            </Component>
        </Fragment>
    );
}
