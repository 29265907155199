import moment from "moment";
export const getIdPeriodic = (periodic, dateString) => {
    const getSemesterFromDate = (dateStr) => {
        const monthNumber = moment(dateStr, "DD-MMM-YYYY").month();
        return monthNumber < 6 ? 1 : 2;
    };

    switch (periodic) {
        case "daily":
            return dateString?.substring(0, 6);
        case "monthly":
            return moment(dateString, "DD-MMM-YYYY").format("MMM");
        case "quarterly":
            const qNumber = moment(dateString, "DD-MMM-YYYY").quarter();
            return `Kwartal ${qNumber}`;
        case "semesterly":
            const sNumber = getSemesterFromDate(dateString);
            return `Semester ${sNumber}`;
        default:
            return;
    }
};
