import React from "react";
import { useSelector } from "react-redux";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import PanelCargoChart from "./CargoChart";

const CargoChartByaAirline = () => {
  const {
    filterPage,
  } = useSelector((item) => item.apps);
  const [data, loading] = useDashboardChart({
    ...filterPage,
    routes: "/airline",
    type: "cargo",
    doSend: true,
    regenerate: true,
  });
  console.log({ data });
  return (
    <PanelCargoChart
      dataSource={data}
      loading={loading}
      filterPage={filterPage}
    />
  );
};

export default CargoChartByaAirline;
