import FRBotPage from '../pages/others/bot';
import StatusPage from '../pages/others/bot/process_status';
import LogsPage from '../pages/others/bot/process_logs';
import TypebBotPage from '../pages/others/bot/typeb';
import AboutPage from '../pages/others/about';
import password from '../pages/profile/password';
import CheckerPage from '../pages/checker'
// import second from '../pages/'
import ReportPage from '../pages/others/report';

export default [
    {
        path:'/others/report',
        exact:true,
        Component:ReportPage
    },
    {
        path:'/others/bot/message_checker',
        exact:true,
        Component:CheckerPage
    },
    {
        path:'/others/bot/telex',
        exact:true,
        Component:TypebBotPage
    },
    {
        path:'/others/bot/fr',
        exact:true,
        Component:FRBotPage
    },
    {
        path:'/others/bot/process/status',
        exact:true,
        Component:StatusPage
    },
    {
        path:'/others/bot/process/logs',
        exact:true,
        Component:LogsPage
    },
    {
        path:'/others/about',
        exact:true,
        Component:AboutPage
    },
    {
        path:'/password',
        exact:true,
        Component:password
    },
]