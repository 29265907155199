import React, { useState } from "react";
import LoginImage from "../../../../assets/images/login-image.png";
import LoginIllustrastion from "../../../../assets/images/Sidapi_login_illustrations.png"
import LogoKemenhub from "../../../../assets/images/logo-splash.png";
import LogoSidapiLong from "../../../../assets/images/sidaapi_logo_dark_bg.png"
import logoSidapi from "../../../../assets/images/sidaapi_logo_icon_dark_bg.png"
import { Image, Typography } from "antd";
import { useFontSize } from "../../../../hooks/useDimension";
import { Button, Checkbox, Form, Input } from "antd";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { CreateRandomString } from "../../../login/utils";
import { GetAPI } from "../../../../redux";
import { useEffect } from "react";
import { login } from "../../../../redux/reducer/auth";
import { useCallback } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { useHandlerCaptcha } from "../../../../hooks/useHandlerCaptcha";

const useStyles = createUseStyles({
    inputStyle: {
        "& .ant-input": {
            borderRadius: "8px",
            border: "1px solid #AADAB7",
            height: ({ fontSize }) => fontSize.xl * 2,
        },
    },
    inputPassStyle: {
        "& .ant-input-affix-wrapper.ant-input-password": {
            borderRadius: "8px !important",
            border: "1px solid #AADAB7",
        },
        "& .ant-input": {
            height: ({ fontSize }) => fontSize.xl * 1.5,
        },
    },
    customLabel: {
        // width: ({ fontSize }) => fontSize.xl * 5,
        // fontWeight: "bold", // Customize label text color
        fontSize: ({ fontSize }) => fontSize.sm + 2, // Customize label text size
        // Add other styles as needed
    },
});

const createRandomStr = () => {
    const timestamps = new Date().getTime();
    return timestamps + "_" + CreateRandomString(16);
};

const loadImage = (dispatch, setImage, refresh) => {
    dispatch(GetAPI({ url: "auth/captcha/" + refresh })).then((resp) => {
        if (!!resp?.payload) {
            setImage(resp.payload.data);
        }
    });
};

const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
};

const Login = ({ apps }) => {
    const fontSize = useFontSize();
    const classes = useStyles({ fontSize });
    const dispatch = useDispatch();
    const [image, setImage] = useState("");
    const { errorMessage } = useSelector((state) => state.apps);

    const [refresh, setRefresh] = useState(createRandomStr());

    useEffect(() => {
        loadImage(dispatch, setImage, refresh);
    }, [refresh]);
    const onFinish = useCallback(
        (values) => {
            dispatch(login({ ...values, app: apps, token: refresh }));
        },
        [refresh]
    );

    useHandlerCaptcha(errorMessage, (isReCaptcha) => {
        if (isReCaptcha) {
            loadImage(dispatch, setImage, refresh);
            // setData((state) => ({ ...state, captcha: "" }));
        }
    });
    return (
        <div style={{ display: "flex", height: "100vh" }}>
            <div
                style={{
                    background: "#023047",
                    width: "50%",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        marginTop: "28px",
                        marginLeft: "28px",
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                    }}
                >
                    <img style={{ width: 46 }} src={LogoKemenhub} alt="logo" />
                    <Typography
                        style={{
                            fontSize: "13px",
                            fontWeight: "bold",
                            color: "white",
                            marginLeft: "4px"
                        }}
                    >
                        KEMENTERIAN PERHUBUNGAN REPUBLIK INDONESIA
                        <br /> DIREKTORAT JENDERAL PERHUBUNGAN UDARA
                    </Typography>
                </div>
                <div style={{ textAlign: "center", marginTop: "14%", display: "block" }}>
                    <img style={{ height: 56, marginBottom: "2%" }} src={LogoSidapiLong} alt="logoSidapi" />
                    <Typography
                        style={{
                            fontSize: fontSize.md,
                            marginBottom: "16px",
                            fontWeight: "bold",
                            color: "white",
                            marginBottom:"1%"
                        }}
                    >
                        DASHBOARD ANALYTIC SYSTEM
                    </Typography>
                        <img src={LoginIllustrastion} alt="logo" style={{ width: "52%", marginTop: "2%" }} />
                </div>
            </div>
            <div
                style={{
                    background: "white",
                    width: "50%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <Typography
                    style={{
                        fontWeight: "bold",
                        color: "#023047",
                        fontSize: "32px",
                    }}
                >
                    LOGIN
                </Typography>
                <div style={{ width: "55%" }}>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        initialValues={{
                            remember: true,
                            layout: "vertical",
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <Form.Item
                            label={
                                <span className={classes.customLabel}>
                                    Username
                                </span>
                            }
                            className={classes.inputStyle}
                            name={"user"}
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your username!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className={classes.inputPassStyle}
                            label={
                                <span className={classes.customLabel}>
                                    Password
                                </span>
                            }
                            name={"password"}
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!",
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "",
                                flexDirection: "row-reverse",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    padding: 8,
                                    gap: 8,
                                    alignItems: "center",
                                    width: "50%",
                                }}
                            >
                                <Button
                                    icon={<ReloadOutlined />}
                                    type="ghost"
                                    onClick={() =>
                                        setRefresh(createRandomStr())
                                    }
                                />
                                <Image src={image} height={64} width={"100%"} />
                            </div>
                            <Form.Item
                                style={{ width: "50%" }}
                                label={
                                    <span className={classes.customLabel}>
                                        Captcha Code
                                    </span>
                                }
                                className={classes.inputStyle}
                                name="captcha"
                                // label="Captcha Result"
                                required
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <Form.Item
                            name="remember"
                            valuePropName="checked"
                            wrapperCol={{
                                offset: 0,
                                span: 24,
                            }}
                        >
                            <Checkbox>Ingat saya</Checkbox>
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 0,
                                span: 24,
                            }}
                        >
                            <Button
                                size="large"
                                style={{
                                    width: "100%",
                                    background: "#FFB703",
                                    border: "none",
                                    borderRadius: "8px",
                                    height: fontSize.xl * 3,
                                }}
                                type="primary"
                                htmlType="submit"
                            >
                                LOGIN
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Login;