import { Skeleton, Spin, Typography } from "antd";
import moment from "moment";
import React, { Fragment, useState } from "react";
import useWindowDimensions, {
    useFontSizeV2,
} from "../../../hooks/useDimension";
import { useDispatch } from "react-redux";
import customFormat from "../../../helper/customFormat";
import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import { BsInfinity } from "react-icons/bs";
import { createUseStyles } from "react-jss";
import { setDashboardState } from "../../../redux/reducer/apps";
import IcAirport from "../../../assets/icons/IcAirport";
import IcAirline from "../../../assets/icons/IcAirline";
import IcFlights from "../../../assets/icons/IcFlights";
import IcPax from "../../../assets/icons/IcPax";
import IcCargo from "../../../assets/icons/IcCargo";
import AnimatedNumbers from "react-animated-numbers";
import { useMemo } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQueryUrl } from "../../../hooks/useQueryUrl";

const useStyles = createUseStyles({
    noUserSelect: {
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",
    },
    animatedNumber: {
        // marginBottom: 10,
        // border: "1px solid red",
        // height: 100,
    },
});

const GetIcon = ({ idx, label, isClicked, isSelected }) => {
    const fontSize = useFontSizeV2();

    switch (label) {
        case "airport":
            return (
                <IcAirport
                    isDark={isSelected}
                    width={fontSize.xl2}
                    height={fontSize.xl2}
                />
            );
        case "airline":
            return (
                <IcAirline
                    isDark={isSelected}
                    width={fontSize.xl2}
                    height={fontSize.xl2}
                />
            );
        case "flight":
            return (
                <IcFlights
                    isDark={isSelected}
                    width={fontSize.xl2}
                    height={fontSize.xl2}
                />
            );
        case "pax":
            return (
                <IcPax
                    isDark={isSelected}
                    width={fontSize.xl2}
                    height={fontSize.xl2}
                />
            );
        case "cargo":
            return (
                <IcCargo
                    isDark={isSelected}
                    width={fontSize.xl2}
                    height={fontSize.xl2}
                />
            );
        default:
            return <div />;
    }
};

const isSelected = (isClicked, idx, label, query, summaryCard) => {
    if (!summaryCard) {
        return false;
    }
    return label === query || (isClicked.state && isClicked.id === idx);
};

const SummaryCard = ({
    isClicked,
    setIsClicked,
    summaryData,
    loadingTotal,
    dashboardState,
}) => {
    const fontSize = useFontSizeV2();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { width, height } = useWindowDimensions();
    const [paxPropObj, setPaxPropObj] = useState({
        label: "Total",
        value: "total",
    });
    const [flightPropObj, setFlightPropObj] = useState({
        label: "Total",
        value: "total",
    });
    const [cargoPropObj, setCargoPropObj] = useState({
        label: "Total",
        value: "total",
    });
    const paxObj = [
        { label: "Total", value: "total" },
        { label: "Average", value: "average" },
        { label: "Domestik", value: "domestic" },
        { label: "Internasional In", value: "international_in" },
        { label: "Internasional Out", value: "international_out" },
    ];
    const flightObj = [
        { label: "Total", value: "total" },
        { label: "Average", value: "average" },
        { label: "Total Domestik", value: "total_dom" },
        { label: "Total Internasional", value: "total_int" },
    ];
    const cargoObj = [
        { label: "Total", value: "total" },
        { label: "Average", value: "average" },
        { label: "Domestik", value: "domestic" },
        { label: "Export", value: "dexport" },
        { label: "Import", value: "dimport" },
    ];

    useEffect(() => {
        const paxInterval = setInterval(() => {
            const nextPaxIndex =
                (paxObj.findIndex((item) => item.value === paxPropObj.value) +
                    1) %
                paxObj.length;
            setPaxPropObj(paxObj[nextPaxIndex]);
        }, 3000);

        const flightInterval = setInterval(() => {
            const nextFlightIndex =
                (flightObj.findIndex(
                    (item) => item.value === flightPropObj.value
                ) +
                    1) %
                flightObj.length;
            setFlightPropObj(flightObj[nextFlightIndex]);
        }, 3000);

        const cargoInterval = setInterval(() => {
            const nextCargoIndex =
                (cargoObj.findIndex(
                    (item) => item.value === cargoPropObj.value
                ) +
                    1) %
                cargoObj.length;
            setCargoPropObj(cargoObj[nextCargoIndex]);
        }, 3000);

        return () => {
            clearInterval(paxInterval);
            clearInterval(flightInterval);
            clearInterval(cargoInterval);
        };
    }, [paxPropObj, flightPropObj, cargoPropObj]);

    const itemValue = (item) => {
        return customFormat(
            typeof item.value === "number"
                ? item.value
                : item.label === "cargo"
                ? item.value[cargoPropObj.value] / 1000
                : item.label === "flight"
                ? item.value[flightPropObj.value]
                : item.value[paxPropObj.value]
        );
    };

    const history = useHistory();
    const query = useQueryUrl();

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: "0px 20px",
                gap: 10,
                position: "absolute",
                bottom: 0,
                left: 0,
                // height: fontSize.xl * 6,
            }}
        >
            <div
                style={{
                    height: "100%",
                    width: "15%",
                    //   padding: "20px 0px",
                }}
            />

            {!loadingTotal && !!summaryData
                ? summaryData?.map((item, idx) => {
                      const newData = item.value - item.prevValue;
                      const percentageData = (
                          ((item.value.total - item.prevValue.total) /
                              item.prevValue.total) *
                          100
                      ).toFixed(2);

                      return (
                          <div
                              className={classes.noUserSelect}
                              onClick={() => {
                                  setIsClicked({
                                      ...isClicked,
                                      state:
                                          isClicked.id !== idx
                                              ? true
                                              : !isClicked.state,
                                      id: idx,
                                  });

                                  if (
                                      dashboardState.summaryCard === item.label
                                  ) {
                                      dispatch(
                                          setDashboardState({ summaryCard: "" })
                                      );
                                      history.push({ search: "" });
                                  } else {
                                      dispatch(
                                          setDashboardState({
                                              summaryCard: item.label,
                                          })
                                      );
                                      history.push({
                                          search: `s=${item.label}`,
                                      });
                                  }
                              }}
                              key={idx}
                              style={{
                                  padding: 4,
                                  //   transform:
                                  //     isClicked.state && isClicked.id === idx && "scale(1.2)",
                                  //   margin: isClicked.state && isClicked.id === idx && "0px 16px",
                                  opacity: isSelected(
                                      isClicked,
                                      idx,
                                      item.label,
                                      query.get("s"),
                                      dashboardState.summaryCard
                                  )
                                      ? 1
                                      : !dashboardState.summaryCard
                                      ? 1
                                      : 0.5,
                                  width: "13%",
                                  background: isSelected(
                                      isClicked,
                                      idx,
                                      item.label,
                                      query.get("s"),
                                      dashboardState.summaryCard
                                  )
                                      ? "#023047"
                                      : "white",
                                  borderTopLeftRadius: fontSize.xs,
                                  borderTopRightRadius: fontSize.xs,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  borderTop: isSelected(
                                      isClicked,
                                      idx,
                                      item.label,
                                      query.get("s"),
                                      dashboardState.summaryCard
                                  )
                                      ? "2px solid white"
                                      : "",
                                  borderLeft: isSelected(
                                      isClicked,
                                      idx,
                                      item.label,
                                      query.get("s"),
                                      dashboardState.summaryCard
                                  )
                                      ? "2px solid white"
                                      : "",
                                  borderRight: isSelected(
                                      isClicked,
                                      idx,
                                      item.label,
                                      query.get("s"),
                                      dashboardState.summaryCard
                                  )
                                      ? "2px solid white"
                                      : "",
                              }}
                          >
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      padding: "5px",
                                      width: "100%",
                                  }}
                              >
                                  <div
                                      style={{
                                          // background:
                                          //     isClicked.state &&
                                          //     isClicked.id === idx
                                          //         ? "white"
                                          //         : "#023047",

                                          borderRadius: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          position: "relative",
                                      }}
                                  >
                                      <GetIcon
                                          idx={idx}
                                          label={item.label}
                                          isClicked={isClicked}
                                          style={{
                                              width: fontSize.sm,
                                              height: fontSize.sm,
                                              aspectRatio: "1/2",
                                          }}
                                          isSelected={isSelected(
                                              isClicked,
                                              idx,
                                              item.label,
                                              query.get("s"),
                                              dashboardState.summaryCard
                                          )}
                                      />
                                  </div>
                                  {item.label !== "airport" &&
                                  item.label !== "airline" ? (
                                      <div>
                                          {percentageData === "Infinity" ||
                                          Number(percentageData) > 100 ? (
                                              <div
                                                  style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                  }}
                                              >
                                                  <BsInfinity
                                                      style={{
                                                          fontSize: fontSize.md,
                                                          color: "#18A61E",
                                                      }}
                                                  />
                                                  <span
                                                      style={{
                                                          fontSize: fontSize.md,
                                                          color: "#18A61E",
                                                      }}
                                                  >
                                                      %
                                                  </span>
                                              </div>
                                          ) : (
                                              <Typography
                                                  style={{
                                                      fontSize: fontSize.md,
                                                      color: "#18A61E",
                                                  }}
                                              >
                                                  {percentageData}%
                                                  {item.value >
                                                  item.prevValue ? (
                                                      <RiseOutlined />
                                                  ) : item.value <
                                                    item.prevValue ? (
                                                      <FallOutlined />
                                                  ) : (
                                                      ""
                                                  )}
                                              </Typography>
                                          )}
                                      </div>
                                  ) : (
                                      // <Fragment />
                                      <Fragment>
                                          {newData === 0 ? (
                                              <div />
                                          ) : (
                                              <div
                                                  style={{
                                                      // border: "1px solid red",
                                                      padding: "2px 6px",
                                                      background:
                                                          dashboardState.summaryCard ===
                                                          item.label
                                                              ? ""
                                                              : "#023047",
                                                      borderRadius: 4,
                                                  }}
                                              >
                                                  <Typography
                                                      style={{
                                                          fontSize: fontSize.sm,
                                                          color: "white",
                                                      }}
                                                  >
                                                      +{newData}{" "}
                                                      {item.label === "airport"
                                                          ? "Bandara"
                                                          : "Maskapai"}
                                                  </Typography>
                                              </div>
                                          )}
                                      </Fragment>
                                  )}
                              </div>
                              <div
                                  style={{
                                      marginBottom:
                                          item.label !== "airline" &&
                                          item.label !== "airport"
                                              ? height > 720
                                                  ? "15px"
                                                  : "8px"
                                              : height > 720
                                              ? "20px"
                                              : "16px",
                                  }}
                              >
                                  <Typography
                                      style={{
                                          textAlign: "center",
                                          fontSize: fontSize.md * 2,
                                          fontWeight: "bold",
                                          color: isSelected(
                                              isClicked,
                                              idx,
                                              item.label,
                                              query.get("s"),
                                              dashboardState.summaryCard
                                          )
                                              ? "white"
                                              : "#023047",
                                          lineHeight:
                                              height > 720 ? "25px" : "15px",
                                      }}
                                  >
                                      {loadingTotal ? (
                                          <span>
                                              <Spin size="large" />
                                          </span>
                                      ) : (
                                          itemValue(item)
                                      )}
                                      <br />
                                  </Typography>
                              </div>
                              <div style={{ width: "100%" }}>
                                  {item.label !== "airline" &&
                                      item.label !== "airport" && (
                                          <Typography
                                              style={{
                                                  fontWeight: 600,
                                                  fontSize: fontSize.xs,
                                                  lineHeight: "15px",
                                                  color: "#FB8500",
                                                  // border: "1px solid red",
                                                  textAlign: "end",
                                              }}
                                          >
                                              {item.label === "cargo"
                                                  ? cargoPropObj.label
                                                  : item.label === "flight"
                                                  ? flightPropObj.label
                                                  : paxPropObj.label}
                                          </Typography>
                                      )}
                              </div>
                          </div>
                      );
                  })
                : [...Array(5)].map((item, idx) => (
                      <div
                          key={idx}
                          style={{
                              width: "13%",
                              background: "white",
                              borderTopLeftRadius: fontSize.xs,
                              borderTopRightRadius: fontSize.xs,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              gap: 8,
                              height: fontSize.xl * 3.8,
                          }}
                      >
                          <Spin size="large" />
                      </div>
                  ))}

            <div
                style={{
                    height: "100%",
                    width: "15%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    padding: "10px 0px",
                }}
            >
                {/* <Typography
          style={{
            fontSize: fontSize.md * 2,
            color: "white",
            lineHeight: 1,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {moment().format("HH:mm")}
          <br />
          <span style={{ fontSize: fontSize.lg }}>
            {moment().format("DD MMM YYYY")}
          </span>
        </Typography> */}
            </div>
        </div>
    );
};

export default SummaryCard;
