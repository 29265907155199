import React, { useMemo, useState } from "react";
import utilsFlight from "./utilsFlight";
import DashboardChart from "../../../pages/dashboard/home/components/DashboardChartV2";
import RadioPeriodic from "../../Form/RadioPeriodic";
import SelectAirport from "../../Form/SelectAirport";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import customFormat from "../../../../../helper/customFormat";
import { resetStyleTooltips } from "../../Chart/resetStyleTooltip";

const MAX_ITEM_BAR = 11;
const MAX_PERCENT_ITEM = 100;

const options = (dataSet, fontSize, filters, getPersen, getLenghtData) => {
    return {
        dataset: dataSet,
        tooltip: {
            trigger: "axis",
            ...resetStyleTooltips,
            formatter: (params) => utilsFlight.renderTooltip(params, filters),
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            left: "0%",
            right: "5%",
            bottom: "12%",
            containLabel: true,
            top: "10%",
        },
        xAxis: {
            type: "category",
            axisLabel: {
                textStyle: {
                    fontSize: fontSize.sm,
                },
            },
        },

        yAxis: {
            type: "value",
            axisLabel: {
                formatter: (params) => customFormat(params),
            },
        },
        // dataZoom: getPersen.toFixed() > 0 &&
        //     getPersen.toFixed() < MAX_PERCENT_ITEM && [
        //         {
        //             type: "slider",
        //             start: 0,
        //             orient: "horizontal",
        //             // right: 24,
        //             end: !!getLenghtData ? getPersen.toFixed() : 100,
        //             handleSize: 8,
        //             bottom: 20,
        //             height: 12,
        //         },
        //         {
        //             type: "inside",
        //             start: 0,
        //             end: !!getLenghtData && 1 ? getPersen.toFixed() : 100,
        //             orient: "horizontal",
        //             zoomOnMouseWheel: false,
        //             moveOnMouseMove: true,
        //             moveOnMouseWheel: true,
        //         },
        //     ],
        series: [
            {
                name: `Domestic`,
                type: filters.periodic === "daily" ? "line" : "bar",
                symbol: "none",
                barGap: 0,
                datasetIndex: 0,
                barWidth: 16,
                encode: { x: "label", y: "domestic" },
                emphasis: {
                    focus: "series",
                },
                color: "#023047",
                itemStyle: {
                    color: "#023047",
                },
                areaStyle: {
                    color: "#02304720",
                },
                lineStyle: {
                    width: 3,
                },
            },
            {
                name: `International`,
                type: filters.periodic === "daily" ? "line" : "bar",
                symbol: "none",
                barGap: 0,
                barWidth: 16,
                datasetIndex: 0,
                encode: { x: "label", y: "international" },
                emphasis: {
                    focus: "series",
                },
                color: "#FFB703",
                lineStyle: {
                    width: 3,
                },
                itemStyle: {
                    color: "#FFB703",
                },
                areaStyle: {
                    color: "#FFB70320",
                },
            },
        ],
    };
};

export default function PanelChartFlight({
    dataSource = [],
    loading,
    filterPage,
}) {
    const fontSize = useFontSizeV2();
    const [filters, setFilters] = useState({
        airport: "all",
        periodic: "daily",
    });
    const [dataset, table, airportsList] = useMemo(
        () =>
            utilsFlight.getDataFlightByAirport(
                dataSource,
                filters.airport,
                filters.periodic
            ),
        [dataSource, filters.airport, filters.periodic]
    );

    const [getLenghtData, getPersen] = useMemo(() => getData  (dataset)  , [filters, dataset]);

    return (
        <DashboardChart
            // loading={loading}
            printHeader={`Penerbangan ${filters.airport} Report`}
            printPeriode={`${filters.periodic} ${filterPage.year}`}
            repType={`Penerbangan ${filters.airport} Report`}
            title={"Penerbangan"}
            dataSource={table}
            data={options(
                dataset,
                fontSize,
                { ...filters, ...filterPage },
                getPersen,
                getLenghtData
            )}
            more={
                <SelectAirport
                    onChange={(value) =>
                        setFilters((state) => ({ ...state, airport: value }))
                    }
                    value={filters.airport}
                    dataSource={airportsList}
                />
            }
            chartPanelRender={(ChartPanel) => {
                return (
                    <div style={{ padding: 12, height: "100%" }}>
                        <RadioPeriodic
                            value={filters.periodic}
                            onChange={(value) =>
                                setFilters((state) => ({
                                    ...state,
                                    periodic: value,
                                }))
                            }
                        />
                        {ChartPanel}
                    </div>
                );
            }}
        />
    );
}

const getData = (dataset) => {
    if (!Array.isArray(dataset)) return [false, 0];
    let isLenghtData =
        !!dataset[0]?.source && dataset[0]?.source?.length > 10;
    let percent = isLenghtData
        ? (MAX_ITEM_BAR / dataset[0]?.source?.length) * 100
        : 0;
    return [isLenghtData, percent];
}
