import { Typography } from "antd";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { MultiSelect } from "react-multi-select-component";
import { useFontSize } from "../../hooks/useDimension";
import { IoClose } from "react-icons/io5";
import Config from "../../config/env";
const { colorGray } = Config;
const useStyles = createUseStyles({
  selectStyles: {
    width: "100%",
    height: "100%",
    fontSize: ({ fontSize }) => fontSize.sm,
    // borderRadius: 50,
    // border: "1px solid red",\
    "& .dropdown-content": {
      borderRadius: 8,
      position: "absolute",
      zIndex: 99999,
      overflowY: "hidden",
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
      "& .panel-content": {
        "& .select-panel": {
          "& .options": {
            maxHeight: ({ fontSize }) => `${fontSize.xs * 18}px !important`,
          },
        },
      },
    },
    "& .dropdown-heading": {
      height: ({ fontSize, isMobile }) =>
        isMobile ? fontSize.md * 3 : fontSize.md * 2,
      background: "tranparent",
    },
    "& .dropdown-container": {
      borderRadius: "8px !important",
    },
    "& :focus-within": {
      borderColor: "transparent !important",
    },
  },
});

function MultiSelectComponent({
  hasSelectAll = true,
  disableSearch,
  options = [],
  selected = [],
  onChange,
  className,
  loading,
  disabled = false,
  valueRender,
  isMobile,
}) {
  const fontSize = useFontSize({ isMobile });
  const classes = useStyles({ fontSize });
  const [txt, setTxt] = useState("");
  const fData = useMemo(() => {
    if (!options) return [];
    if (txt === "") {
      const sliced = options.slice(0, 50);
      // console.log({sliced})
      return sliced;
    }

    const re = new RegExp(txt, "i");
    const filtered =
      Array.isArray(options) &&
      options.filter(({ label }) => label && label.match(re));
    return filtered.slice(0, 50);
  }, [txt, options]);
  const filteredData = useCallback((aData, filter) => {
    // console.log({filter})
    if (!filter) {
      setTxt("");
    } else {
      setTxt(filter);
    }
    return aData;
  }, []);
  return (
    <div>
      <MultiSelect
        disabled={disabled}
        isLoading={loading}
        ClearIcon={<IoClose />}
        hasSelectAll={hasSelectAll}
        disableSearch={disableSearch}
        className={`${classes.selectStyles} ${className}`}
        ClearSelectedIcon={<IoClose color={colorGray} />}
        options={fData}
        filterOptions={filteredData}
        value={selected}
        onChange={onChange}
        labelledBy={"Select"}
        isCreatable={true}
        valueRenderer={valueRender}
      />
    </div>
  );
}

export default MultiSelectComponent;
