import XLS from 'exceljs';
import {saveAs} from 'file-saver';
import moment from 'moment';

/**
 * 
 * @param {XLS.Workbook} wb 
 * @param {Array} records 
 * @param {String} direction 
 */
const ProcessData=(wb, records, columns)=>{
    let sheet=wb.getWorksheet('Report');
    if(!sheet){
        sheet=wb.addWorksheet('Report');
    }
    for (let iui = 0; iui < columns.length; iui++) {
        const col = columns[iui];
        sheet.getCell(`1`, `${iui+1}`).value=col;
    }
    let last=1;
    for (let iii = 0; iii < records.length; iii++) {
        const rec=records[iii];
        last++;
        for (let iuu = 0; iuu < rec.length; iuu++) {
            const data = rec[iuu];
            sheet.getCell(last, `${iuu+1}`).value=data;
        }
    }
}

export const DownloadExcel=async(records, columns, title)=>{
    console.log(title); 
    const wb=new XLS.Workbook();
    wb.title="Report "+title;
    wb.subject="Report "+title;
    wb.creator="ESI - EDIFly Solusi Indonesia ~~~HK~~~"
    wb.created=new Date();

    ProcessData(wb, records, columns)
    const buff=await wb.xlsx.writeBuffer();
    saveAs(new Blob([buff], {type:"application/octet-stream"}), 'Report-'+title+'.xlsx');
    return true;
}