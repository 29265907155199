import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { BiSolidPlaneAlt } from "react-icons/bi";
import IcPlane from "../icons/IcPlane";

const createCustomMarkerIcon = (color, size) => {
  const html = renderToStaticMarkup(
    // <BiSolidPlaneAlt color={color} size={size} />
    <IcPlane color={color} size={size} />
  );
  return divIcon({
    html: html,
    // iconSize: [100, 100],
    iconAnchor: [12, 24], // half of icon size for X and full size for Y
    popupAnchor: [0, -12], // half of icon size for X and 0 for Y
    className: "custom-marker-icon",
  });
};

export default createCustomMarkerIcon;
