import React from "react";
import { useFontSizeV2 } from "../../../../hooks/useDimension";
import MainRadioGroup from "./MainRadioGroup";

const options = [
    { label: "Hari", value: "daily" },
    { label: "Bulan", value: "monthly" },
    { label: "Kwartal", value: "quarterly" },
    { label: "Semester", value: "semesterly" },
];

export default function RadioPeriodic({ value, onChange, ...props }) {
    return (
        <MainRadioGroup
            options={options}
            onChange={({ target: { value } }) => {
                onChange(value);
            }}
            value={value}
            {...props}
        />
    );
}
