import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import PanelChartPaxWithRoute from "../../../../../../features/PanelChart/PaxsWithRoute";

export default function PaxDomestic() {
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/pax",
        type: "pax",
        doSend: true,
        regenerate: true,
    });
    return (
        <PanelChartPaxWithRoute
            filterPage={filterPage}
            dataSource={data}
            route={"Domestik"}
        />
    );
}
