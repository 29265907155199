import React from 'react';
import FormReport from '../../components/pages/report';
import {useFetch} from '../../../../../hooks/useFetch';
import { GiChart } from 'react-icons/gi';

export default () => {
    return (
        <FormReport 
            pageProps={{
                title:'daily_report',
                icon:GiChart
            }}
            
        >
            Test
        </FormReport>
    )
}