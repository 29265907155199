import React, { useState, useEffect } from "react";
import { useFontSizeV2 } from "../../../../../../../../hooks/useDimension";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import Config from "../../../../../../../../config/env";
import { customFormatWeight } from "../../../../../../../../helper/customFormat";
import DashboardChart from "../../DashboardChartV2";
import getDataAirlineByCargo from "./utilsAirline";
import { Radio } from "antd";
import { createUseStyles } from "react-jss";

const { chartColor } = Config;

const optionSelect = [
  { value: "detail", label: "Detail" },
  { value: "negara", label: "Negara" },
];

const optionsMaskapai = (dataSource, view, fontSize, series) => {
  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
      formatter: (params) => {
        let string = `<strong>${params[0].name}${
          view === "detail" ? `-- ${params[0].data.name}` : ""
        }</strong>`;
        const param = params?.map((item) => {
          const { dexport, dimport, domestic } = item.data;
          return ` <br/> ${item.marker}<strong>${item.seriesName}</strong>: ${
            item.seriesName === "Export"
              ? (dexport / 1000).toFixed(2)
              : item.seriesName === "Import"
              ? (dimport / 1000).toFixed(2)
              : (domestic / 1000).toFixed(2)
          } Ton`;
        });
        return string + param.join("");
      },
    },
    dataset: dataSource,
    legend: {
      bottom: -5,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "24%",
      containLabel: true,
      top: "5%",
    },
    dataZoom: [
      {
        type: "inside",
        start: 1,
        end:
          dataSource?.source?.length < 20
            ? 100
            : dataSource?.source?.length < 50
            ? 30
            : dataSource?.source?.length < 100
            ? 20
            : 15,
      },
      {
        show: true,
        type: "slider",
        start: 1,
        end: 100,
        height: 15,
        bottom: "15%",
      },
    ],

    xAxis: {
      type: "category",
      axisLabel: {
        textStyle: {
          fontSize: fontSize.sm,
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => customFormatWeight(params),
      },
    },
    series,
  };
};

function sumByCountry(data) {
  const groupedData = data.reduce((acc, airline) => {
    const { code, dexport, dimport, domestic, name } = airline;
    const countryCategory = code === "ID" ? "Lokal" : "Asing";

    if (!acc[countryCategory]) {
      acc[countryCategory] = {
        code: countryCategory,
        dexport: 0,
        dimport: 0,
        domestic: 0,
        name: "",
        sumData: 0,
      };
    }

    acc[countryCategory].dexport += dexport;
    acc[countryCategory].dimport += dimport;
    acc[countryCategory].domestic += domestic;
    acc[countryCategory].name = name; // Assuming the name is the same for all entries in the same category
    acc[countryCategory].sumData += dexport + dimport + domestic;

    return acc;
  }, {});

  const resultArray = Object.values(groupedData);

  return resultArray;
}
const useStyles = createUseStyles({
  radioStyle: {
    "& .ant-radio-button-wrapper": {
      marginRight: "4px",
      borderRadius: "6px",
      borderLeftWidth: 1.02,
      fontSize: ({ fontSize }) => fontSize.xxs,
      fontWeight: "bold",
    },
    "& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before":
      {
        display: "none",
      },
    "& .ant-radio-button-wrapper:not(:first-child)::before": {
      display: "none",
    },
  },
});

const getData = (view, dataSource) => {
  const data =
    Array.isArray(dataSource) && view !== "detail"
      ? sumByCountry(dataSource)
      : !!dataSource &&
      dataSource?.map((item) => {
          return {
            code: item.code,
            dexport: item.dexport,
            dimport: item.dimport,
            domestic: item.domestic,
            name: item.name,
            id: item._id,
            sumData: item.dexport + item.dimport + item.domestic,
          };
        });
  return data;
}


const series = [
  {
    name: "Export",
    type: "bar",
    stack: "total",
    itemStyle: { color: "#fb8500" },
  },
  {
    name: "Import",
    type: "bar",
    stack: "total",
    itemStyle: { color: "#ffb703" },
  },

  {
    name: "Domestik",
    type: "bar",
    itemStyle: { color: "#023047" },
  },
];

const MaskapaiLokalChart = ({ datav2, loadingDatav2 }) => {
  const [view, setView] = useState("detail");
  const fontSize = useFontSizeV2();
  const history = useHistory();
  const classes = useStyles({ fontSize });
  const dataSource = useMemo(() => getData(view, datav2), [datav2, view]);

  const handleClickItem = (params) => {
    if (view === "detail") {
      const {
        name: code,
        data: { name, id },
      } = params;
      history.push(`/detail-cargo/${id}`, {
        data: { code, name, id, detail: "Airline" },
      });
    }
  };

  const [dataset, datatable] = getDataAirlineByCargo(dataSource);

  return (
    <DashboardChart
      repType={"Maskapai"}
      printHeader={"Maskapai Report"}
      dataSource={datatable}
      key={"Maskapai (Tons)"}
      title={"Maskapai (Tons)"}
      data={optionsMaskapai(dataset, view, fontSize, series)}
      OnClick={handleClickItem}
      //   loading={loadingDatav2}
      more={
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Radio.Group
            size="small"
            options={optionSelect}
            onChange={({ target: { value } }) => {
              setView(value);
            }}
            value={view}
            optionType="button"
            className={classes.radioStyle}
          />
        </div>
      }
      setView={setView}
      view={view}
    />
  );
};

export default MaskapaiLokalChart;
