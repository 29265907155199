import React, { useEffect } from "react";
import PetaKeseluruhan from "./components/PetaKeseluruhan";
import ThirdrdCharts from "./components/Kargo3rdCharts";
import FifthCharts from "./components/Kargo5thCharts";
import FirstCharts from "./components/FirstCharts";
import SecondCharts from "./components/SecondCharts";
import FourthCharts from "./components/FourthCharts";
import { useDispatch, useSelector } from "react-redux";
import {
    setDashboardState,
    setFilterPage,
} from "../../../../../redux/reducer/apps";
import DashboardLayout from "../../../../../components/layouts/DashboardLayout";
import { useQueryUrl } from "../../../../../hooks/useQueryUrl";

const WrapperHomePage = ({ children }) => {
    const { filterPage } = useSelector((state) => state.apps);
    const dispatch = useDispatch();

    const query = useQueryUrl();

    const handleOnChange = (type, value, chart) => {
        switch (type) {
            case "airport":
                dispatch(setFilterPage({ airport_id: value, chart: "all" }));
                break;
            case "airline":
                dispatch(setFilterPage({ airline_id: value, chart: "all" }));
                break;
            case "year":
                dispatch(
                    setFilterPage({
                        year: value,
                        chart: "all",
                    })
                );
                break;
            case "route":
                if (filterPage.route === value) {
                    dispatch(setFilterPage({ route: "", chart }));
                } else {
                    // setState((state) => ({ ...state, route: value }));
                    dispatch(setFilterPage({ route: value, chart }));
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (query.get("s")) {
            dispatch(setDashboardState({ summaryCard: query.get("s") }));
        }
    }, [query.get("s")]);

    return (
        <DashboardLayout state={filterPage} onChange={handleOnChange}>
            {children}
        </DashboardLayout>
    );
};

const Home = () => {
    return (
        <WrapperHomePage>
            <div
                style={{ height: "68vh", width: "100vw", padding: "0rem 1rem" }}
            >
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "repeat(24, 1fr)",
                        gridTemplateRows: "repeat(2, 1fr)",
                        gridColumnGap: "1rem",
                        gridRowGap: "1rem",
                    }}
                >
                    <div
                        style={{ gridArea: "1 / 1 / 2 / 8", minHeight: "50%" }}
                    >
                        <FirstCharts />
                    </div>
                    <div
                        style={{ gridArea: "1 / 8 / 2 / 18", minHeight: "50%" }}
                    >
                        <PetaKeseluruhan />
                    </div>
                    <div
                        style={{
                            gridArea: "1 / 18 / 2 / 25",
                            minHeight: "50%",
                        }}
                    >
                        <SecondCharts />
                    </div>
                    <div
                        style={{ gridArea: "2 / 1 / 3 / 9", minHeight: "50%" }}
                    >
                        <ThirdrdCharts />
                    </div>
                    <div
                        style={{ gridArea: "2 / 9 / 3 / 17", minHeight: "50%" }}
                    >
                        <FourthCharts />
                    </div>
                    <div
                        style={{
                            gridArea: "2 / 17 / 3 / 25",
                            minHeight: "50%",
                        }}
                    >
                        <FifthCharts />
                    </div>
                </div>
            </div>
        </WrapperHomePage>
    );
};

export default Home;
