import DashboardHome from "./dashboard/home";
import DetailAirportOtp from "./details/airport/otp";
import DetailAirlineOtp from "./details/airline/otp";

export const Routing = [
  {
    to: "/home",
    component: <DashboardHome />,
  },
  {
    to: "/detail/airport/otp/:idAirport",
    component: <DetailAirportOtp />,
  },
  {
    to: "/detail/airline/otp/:idAirline",
    component: <DetailAirlineOtp />,
  },
];
