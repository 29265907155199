import { Form, Input, Select } from 'antd';
import React, { useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'
import AutoComplete from '../../../../../components/autocomplete/Online';
import { useMemo } from 'react';
const DefModel = {
    type_tpi: 'udara',
    kanim_id: null,
    station_id: null,
    station_type: null
}

const RenderStation = ({ type_tpi, onChange, model }) => {

    const url = useMemo(() => {
        switch (type_tpi) {
            case 'udara':
                return 'api/v1/airport/domestic';
            case 'laut':
                return 'api/v1/seaport/domestic';
            case 'darat':
                return 'api/v1/land';
            default:
                break;
        }
    }, [type_tpi]);

    return (
        <Form.Item
            label={'Station'}
            name="station_id"
        >
            <AutoComplete
                getKey={(opt) => opt._id}
                getSelectedOptions={(opt, val) => opt._id === val._id}
                getValues={(val) => `${val.name}`}
                uri={url}
                onChange={onChange}
                value={model.kanim_id}
            />
        </Form.Item>
    )
}

export default () => {
    const [model, setModel] = useState(DefModel);

    const onCheckStationType = (option) => {
        switch (option) {
            case 'udara':
                return 'airport';
            case 'laut':
                return 'seaport';
            case 'darat':
                return 'land';
            default:
                break;
        }
    }
    return (
        <FormPage
            url={'api/v1/tpi'}
            callbackPath={'/master/tpi'}
            // formValid={false}
            model={{
                ...model,
                coordinate: JSON.stringify(model.coordinate)
            }}
            setModel={setModel}
            title={"TPI"}
            emptyModel={DefModel}
            onSaveEvent={(row) => {
                const { coordinate } = row
                return { ...row, coordinate: JSON.parse(coordinate) }
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', gap: 32 }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '40%', rowGap: 8 }}>
                    <Form.Item name={'name'} label="Name" rules={[{ required: true }]} >
                        <Input autoFocus />
                    </Form.Item>
                    <Form.Item name={'code'} label="Code" rules={[{ required: true }]} >
                        <Input maxLength={4} onInput={e => e.target.value = e.target.value.toUpperCase()} />
                    </Form.Item>

                    <Form.Item name={'type_tpi'} label="Type" rules={[{ required: true }]} >
                        <Select onChange={(v) => setModel({ ...model, type_tpi: v, })}>
                            <Select.Option value="udara">Udara</Select.Option>
                            <Select.Option value="laut">Laut</Select.Option>
                            <Select.Option value="darat" >Darat</Select.Option>
                            <Select.Option value="traditional" disabled={true}>Traditional</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '40%', rowGap: 8 }}>
                    <Form.Item
                        label={'Kanim'}
                        name="kanim_id"
                    >
                        <AutoComplete
                            getKey={(opt) => opt._id}
                            getSelectedOptions={(opt, val) => opt._id === val._id}
                            getValues={(val) => `${val.name}`}
                            uri="api/v1/kanim"
                            onChange={(val) => setModel({ ...model, kanim_id: val })}
                            value={model.kanim_id}
                        />
                    </Form.Item>
                    <RenderStation type_tpi={model.type_tpi} model={model} onChange={(v) => setModel({ ...model, station_id: v, station_type: onCheckStationType(model.type_tpi) })} />
                    <Form.Item name={'coordinate'} label="Coordinate" >
                        <Input value={`${model.coordinate}`} placeholder="latxxx, longxxx" />
                    </Form.Item>
                </div>
            </div>
        </FormPage>
    )
}