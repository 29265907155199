import { buildDataTable } from "../../../../../helper/buildDataTable";
import { tooltipFormater } from "../../Chart/tooltipFormater";

const getData = (dataSource) => {
    if (!dataSource) {
        return [[], []];
    }
    let result = {};

    for (let iii = 0; iii < dataSource.length; iii++) {
        const { international_in, international_out, domestic, code, ...rest } =
            dataSource[iii];
        const by = code || "-";

        if (!result[by]) {
            result[by] = {
                domestic,
                international_out,
                international_in,
            };
        } else {
            let sumDom = (result[by].domestic += domestic);
            let sumIntIn = (result[by].international_in += international_in);
            let sumIntOut = (result[by].international_out += international_out);

            result[by] = {
                domestic: sumDom,
                international_in: sumIntIn,
                international_out: sumIntOut,
            };
        }
        if (!result[by]?.originObj) {
            result[by] = {
                ...result[by],
                originObj: { code, ...rest },
            };
        }
    }

    let set = [];
    let dataTable = [];
    for (const key in result) {
        if (Object.hasOwnProperty.call(result, key)) {
            const { domestic, international_out, international_in, originObj } =
                result[key];
            const grandTotal = domestic + international_out + international_in;
            set.push([
                key,
                domestic,
                international_in,
                international_out,
                grandTotal,
                originObj,
            ]);
            dataTable.push({
                domestic,
                international_out,
                international_in,
                key: `${key} - ${originObj.name}`,
                grandTotal,
                ...originObj,
            });
        }
    }

    const dataset = [
        {
            dimensions: [
                "label",
                "domestic",
                "international_in",
                "international_out",
                "grandTotal",
                "originObj",
            ],
            source: set,
        },
        {
            transform: {
                type: "sort",
                config: { dimension: "grandTotal", order: "asc" },
            },
        },
    ];
    return [
        dataset,
        buildDataTable(dataTable, [
            {
                title: "Bandara",
                type: "string",
                name: "key",
                align: "left",
            },
            {
                title: "Total Domestik",
                type: "number",
                name: "domestic",
                align: "right",
            },
            {
                title: "Total Internasional In",
                type: "number",
                name: "international_in",
                align: "right",
            },
            {
                title: "Total Internasional Out",
                type: "number",
                name: "international_out",
                align: "right",
            },
            {
                title: "Grand Total",
                type: "number",
                name: "grandTotal",
                align: "right",
            },
        ]),
    ];
};

const renderTooltip = (params) => {
    const { data: domData } = params[0];
    return tooltipFormater({
        title: {
            label: `${domData[5]["code"]} - ${domData[5]["name"]}`,
        },
        params,
    });
};
export default { getData, renderTooltip };
