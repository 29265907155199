import {Typography } from 'antd';
import moment from 'moment';
import React, { useMemo }  from 'react';
import { GiSailboat, GiHarborDock } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import { useFetch } from '../../../../../../hooks/useFetch';
import numeral from 'numeral';

export default ({row, to, url, direction}) => {
    const {_id, schedule_date, origin, destination, ferry_key, schedule_time, std, sta, destination_id, origin_id, vessel_name,
        operator_id, callsign, schedule_id, source} = row;
    const [total, loading]=useFetch(`api/v1/vessel/pax_total/${_id}`);
    const [,staTime]=sta?.split(' ') || [,'-'];
    const [,stdTime]=std?.split(' ') || [,'-'];
    const [,getLanguage] = useLanguage()
    const {manifest, interpol, imigrasi, passport, visa, poi} = useMemo(()=>{
        if(!total)return {manifest:0, interpol:0, imigrasi:0, passport:0, visa:0, poi:0};
        return total;
    },[total]);
    return (
        <Link to={`${url}/${_id}`}>
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', flexDirection:'row', background:'#ffffffaa', padding:4, justifyContent:'space-between'}}>
                    <Typography.Text style={{textAlign:"left", width:'33%'}} title={getLanguage('ferry_key')} strong>{ferry_key} </Typography.Text>                    
                    {/* <Typography.Text style={{width:'20%'}} strong>{moment(schedule_date, 'YYYYMMDD').format('DD-MMM-YYYY')}</Typography.Text> */}
                    {source==='LNSW' && (
                                <Typography.Text style={{textAlign:'center', width:'33%'}} title={getLanguage('vessel_name')} strong>
                                    {vessel_name}
                                </Typography.Text>
                    )}
                    <Typography.Text style={{textAlign:'right', width:'33%'}} title={getLanguage('operator_id')} strong> 
                        {operator_id} 
                    </Typography.Text>
                    
                </div>
                <div style={{display:'flex', flexDirection:'row', background:'#ffffff77', alignItems:'center', padding:8}}>
                    <div style={{display:'flex', width:'20%', flexDirection:'column', rowGap:4}}>
                        <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center'}}>
                            <Typography.Text strong>{origin_id?.name?.toUpperCase() || origin?.toUpperCase()}</Typography.Text>
                            <GiSailboat size={18} color='black'/>
                        </div>
                        <Typography.Text strong>{stdTime || '-'}</Typography.Text>
                    </div>
                    <div style={{display:'flex', width:'20%', flexDirection:'column', rowGap:4}}>
                        <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center'}}>
                            <Typography.Text strong>{destination_id?.name?.toUpperCase() || destination?.toUpperCase()}</Typography.Text>
                            <GiHarborDock size={18} color='black' />
                        </div>
                        <Typography.Text strong>{staTime || '-'}</Typography.Text>
                    </div>
                    <div style={{display:'flex', width:'20%', flexDirection:'column', rowGap:4}}>
                        <Typography.Text strong>{callsign}</Typography.Text>
                        <Typography.Text strong>{source==='LNSW'?(direction==='arrival'?sta:std):schedule_id}</Typography.Text>
                    </div>
                    <div style={{display:'flex', flexGrow:1, flexDirection:'column', rowGap:4, alignItems:'end'}}>
                        <div style={{display:'flex', flexDirection:'row', gap:8, alignItems:'start', justifyContent:'end', flexWrap:'wrap'}}>
                            <span title='MANIFEST' className={`msg-wrapper ${manifest>0?'msg-exists':''}`}>
                                MAN:{numeral(manifest).format('000')}
                            </span>
                            <span title='Passport' className={`msg-wrapper ${passport===0?'msg-exists':'blink_me'}`}>
                                PPT:{numeral(passport||0).format('000')}
                            </span>
                            <span title='INTERPOL' className={`msg-wrapper ${interpol===0?'msg-exists':'blink_me'}`}>
                                ITP:{numeral(interpol).format('000')}
                            </span>
                        </div>
                        <div style={{display:'flex', flexDirection:'row', gap:8, alignItems:'start', justifyContent:'end', flexWrap:'wrap'}}>
                            <span title='Imigrasi Watchlist' className={`msg-wrapper ${imigrasi===0?'msg-exists':'blink_me'}`}>
                                CKL:{numeral(imigrasi).format('000')}
                            </span>
                            <span title='Person Of Interest' className={`msg-wrapper ${poi===0?'msg-exists':'blink_me'}`}>
                                POI:{numeral(poi).format('000')}
                            </span>
                            <span title='Visa Izin Tinggal' className={`msg-wrapper ${visa===0?'msg-exists':'blink_me'}`}>
                                VIT:{numeral(visa||0).format('000')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}