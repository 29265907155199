import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import MapAirportActive from "../../../../../../features/PanelChart/MapAirportActive";
import RowList from "../../../../../../features/RowList";
import { useFontSizeV2 } from "../../../../../../../../hooks/useDimension";
import { Typography, Divider } from "antd";
import numeral from "numeral";
import { logaritRadiusMarkMap } from "../../../../../../../../helper/logaritRadiusMarkMap";

const RenderTooltips = ({ data }) => {
    const fontSize = useFontSizeV2();
    const { name, international, domestic, code, airport_id } = data;

    const [topThree, loading] = useDashboardChart({
        airport_id,
        routes: "/flight",
        type: "map-detail",
        doSend: true,
        regenerate: true,
    });

    return (
        <div>
            <Typography
                style={{
                    fontSize: fontSize.xs,
                    fontWeight: "bold",
                    textAlign: "center",
                }}
            >
                {code} - {name}
            </Typography>
            <Divider style={{ margin: "8px 0px" }} />
            <RowList title={"Rute"} description={"Rata-rata/total"} />
            <Divider style={{ margin: "8px 0px" }} />
            <div style={{ padding: "0px 16px" }}>
                <RowList
                    title={"Domestik"}
                    description={`${numeral(topThree.avgDomestic).format(
                        "0,000"
                    )} / ${numeral(domestic).format("0,000")}`}
                    isBoldDescription
                />
                <RowList
                    title={"Internasional"}
                    description={`${numeral(topThree.avgInternational).format(
                        "0,000"
                    )} / ${numeral(international).format("0,000")}`}
                    isBoldDescription
                />
            </div>

            <Divider style={{ margin: "8px 0px" }} />
            <RowList title={"Maskapai"} description={"Rata-rata penerbangan"} />
            <Divider style={{ margin: "8px 0px" }} />
            <div style={{ padding: "0px 16px" }}>
                {topThree.airlines &&
                    Array.isArray(topThree?.airlines) &&
                    topThree.airlines.map((airline, idx) => (
                        <RowList
                            key={idx}
                            title={`${airline.code}`}
                            description={numeral(airline?.avgDomestic).format(
                                "0,000"
                            )}
                            isBoldDescription
                        />
                    ))}
            </div>
        </div>
    );
};

export default function MapFlightActive() {
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/flight",
        type: "maps",
        doSend: true,
        regenerate: true,
    });

    return (
        <MapAirportActive
            dataMaps={data}
            title={"Peta Penerbangan Aktif"}
            customTooltips={(data) => <RenderTooltips data={data} />}
            customMarkRadius={(data) => {
                const { domestic, international } = data || {
                    domestic: 0,
                    international: 0,
                };
                return logaritRadiusMarkMap(domestic + international);
            }}
        />
    );
}
