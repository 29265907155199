import { Typography } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React from "react";
import { useSelector } from "react-redux";
import { AppVersion } from "../../../config/appVersion";
import CFG from "../../../config/env";
const { BgColor } = CFG;

export default () => {
  const {
    userdata: { be_version },
  } = useSelector((state) => state.auth);
  return (
    <Footer
      style={{
        height: 48,
        padding: "0 0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingInline: 8,
        backgroundColor: BgColor,
      }}
    >
      <div style={{ width: "33%" }}>
        {/* <a href={'https://edifly-si.com/'}>
                    <Typography.Text style={{ color: 'white',  textAlign: 'left' }}>
                        Powered by Edifly-SI @2022
                    </Typography.Text>
                </a> */}
      </div>
      <div
        style={{
          width: "33%",
          display: "flex",
          flexDirection: "column",
          rowGap: 4,
        }}
      >
        {/*                 
                <Typography.Text ellipsis style={{ color: 'white', fontSize: '0.9rem',  textAlign: 'center' }}>
                    Adam Passenger Risk Management
                </Typography.Text> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          rowGap: 2,
          width: "33%",
        }}
      >
        <Typography.Text style={{ color: "white" }}>
          Frontend Version : {AppVersion}
        </Typography.Text>
        <Typography.Text style={{ color: "white" }}>
          Backend Version : {be_version}
        </Typography.Text>
      </div>
    </Footer>
  );
};
