import React, { useMemo } from "react";
import { Button, Dropdown, Menu, Typography } from "antd";
import { languages } from "../../../../languages/utils";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../../redux";
import { Link } from "react-router-dom";
import { createUseStyles } from "react-jss";
import Flags from "../flags";
// const Items=

const useStyles = createUseStyles((theme) => ({
  flag: {
    //   border: "1px solid black"
  },
}));

export default ({ withCountry }) => {
  const classes = useStyles();
  const { language, name } = useSelector((state) => state.apps);
  const dispatch = useDispatch();
  const OverlayMenu = () => {
    return (
      <Menu
        items={languages.map(({ id, name }, idx) => ({
          label: (
            <Button
            //   to={"#"}
            type="text"
              onClick={() =>
                dispatch(setLanguage({ language: id.toUpperCase(), name }))
              }
            >
                <Typography.Text>{name}</Typography.Text>
              
            </Button>
          ),
          key: name,
          icon: <Flags country_code={id} />,
        }))}
      />
    );
  };
  return (
    <Dropdown
      placement="bottomRight"
      menu={OverlayMenu}
      trigger={["click"]}
      className=""
    >
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          gap: 8,
        }}
      >
        <div className={classes.flag}>
          <Flags country_code={language} />
        </div>
        {!!withCountry && (
          <Typography.Text ellipsis style={{ color: "white" }}>
            {name}
          </Typography.Text>
        )}
      </div>
    </Dropdown>
  );
};
