import { Avatar, Image, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import Flags from '../../../../components/flags';
import FLItem from '../components/category/items/flight_schedule';
import CFG from '../../../../config/env';
import { LightenDarkenColor } from '../../utils';
import { Link } from 'react-router-dom';
const { BgColor } = CFG;

export const FlightItem=({flight_record})=>{
    const {is_arrival} = flight_record;
    const direction=is_arrival?'arrival':'departure';
    // console.log({flight_record});
    return (
        <FLItem direction={direction} row={flight_record} url={`/flight/schedule/${direction}`}  />
    )
}

export const PaxItem=({passenger})=>{
    const menuColor=LightenDarkenColor(BgColor, -10);
    const {image, fullname, dob, nationality, id_expiry, no_id, gender, _id } = passenger;
    const getGender=(gender)=>{
        switch (gender) {
            case 'F':
                return 'Female';
            case 'M':
                return 'Male';
            default:
                return gender;
        }
    }
    return (
        <Link to={`/passengers/citizen/detail/${_id}`}>
            <div style={{display:'flex', flexDirection:'column', rowGap:4, width:220, height:110, background:menuColor+'aa', borderRadius:16, paddingInline:8}}>
                <Typography.Text ellipsis 
                    style={{fontSize:'0.8rem', color:'white', fontWeight:'bold', textAlign:"center", borderBottom:'1px white solid'}}>
                        {fullname}
                </Typography.Text>
                <div style={{display:'flex', flexDirection:'row', flexGrow:1, gap:8}}>
                    <div style={{paddingInline:4,display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                        <Avatar 
                            src={image || "/assets/images/pax-not-found.jpg"}
                            size="large"
                            width={80}
                            height={80}
                        />
                        <Typography.Text style={{fontSize:'0.7rem', color:'white', paddingBlock:4}}>{getGender(gender)}</Typography.Text>
                    </div>            
                    <div style={{flexGrow:1, display:'flex', flexDirection:'column', paddingLeft:8}}>
                        <Typography.Text style={{fontSize:'0.75rem', color:'white',}}>{no_id}</Typography.Text>
                        {/* <Typography.Text style={{fontSize:'0.75rem', color:'white',}}>{id_expiry}</Typography.Text> */}
                        <Flags countryFontColor="white" country_code={nationality} withCountry />
                        <Typography.Text style={{fontSize:'0.75rem', color:'white'}}>{moment(dob).format('DD-MMM-YYYY')}</Typography.Text>                    
                    </div>    
                </div>
            </div>
        </Link>
    )
}

export const AwbNumber=({awb})=>{
    const menuColor=LightenDarkenColor(BgColor, -10);
    const {awb_number, origin, dest, product_desc, createdAt, _id } = awb;
    console.log({awb});
    return (
        <Link to={`/cargo/detail/${_id}`}>
            <div style={{display:'flex', flexDirection:'column', rowGap:4, width:120, height:110, background:menuColor+'aa', borderRadius:16, paddingInline:8}}>
                <Typography.Text ellipsis 
                    style={{fontSize:'0.8rem', color:'white', fontWeight:'bold', textAlign:"center", borderBottom:'1px white solid'}}>
                        {awb_number}
                </Typography.Text>
                <div style={{display:'flex', flexDirection:'row', flexGrow:1, gap:8}}>                    
                    <div style={{flexGrow:1, display:'flex', flexDirection:'column', paddingLeft:8}}>
                        <Typography.Text style={{fontSize:'0.75rem', color:'white',}}>{origin?.code} {dest?.code}</Typography.Text>
                        <Typography.Text style={{fontSize:'0.75rem', color:'white'}}>{product_desc}</Typography.Text>                    
                        <Typography.Text style={{fontSize:'0.75rem', color:'white'}}>{moment(createdAt).format('DD-MMM-YYYY')}</Typography.Text>                    
                    </div>    
                </div>
            </div>
        </Link>
    )
}

export const PaxItemDomestic=({passenger})=>{
    const menuColor=LightenDarkenColor(BgColor, -10);
    const {passenger_name:fullname, flight_number, flight_date_str:flight_date, gender, origin_id, dest_id, _id } = passenger;
    const getGender=(gender)=>{
        switch (gender) {
            case 'F':
                return 'Female';
            case 'M':
                return 'Male';
            default:
                return gender;
        }
    }

    return (
        <Link to={`/passengers/domestic/detail/${_id}`}>
            <div style={{display:'flex', flexDirection:'column', rowGap:4, width:220, height:110, background:menuColor+'aa', borderRadius:16, paddingInline:8}}>
                <Typography.Text ellipsis 
                    style={{fontSize:'0.8rem', color:'white', fontWeight:'bold', textAlign:"center", borderBottom:'1px white solid'}}>
                        {fullname}
                </Typography.Text>
                <div style={{display:'flex', flexDirection:'row', flexGrow:1, gap:8}}>
                    <div style={{paddingInline:4,display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                        <Avatar 
                            src={"/assets/images/pax-not-found.jpg"}
                            size="large"
                            width={80}
                            height={80}
                        />
                        <Typography.Text style={{fontSize:'0.7rem', color:'white', paddingBlock:4}}>{getGender(gender)}</Typography.Text>
                    </div>            
                    <div style={{flexGrow:1, display:'flex', flexDirection:'column', paddingLeft:8, justifyContent:'center'}}>
                        <Typography.Text style={{fontSize:'0.75rem', color:'white',}}>{flight_number}</Typography.Text>
                        <Typography.Text style={{fontSize:'0.75rem', color:'white',}}>{flight_date}</Typography.Text>
                        {/* <Flags countryFontColor="white" country_code={nationality} withCountry /> */}
                        <Typography.Text style={{fontSize:'0.75rem', color:'white'}} title={`${origin_id?.kota} - ${dest_id?.kota}`}>{origin_id?.code}-{dest_id?.code}</Typography.Text>                    
                    </div>    
                </div>
            </div>
        </Link>
    )
}