import React, { useState } from "react";
import PanelChartAirlineAndAirport from "../../../../../../features/PanelChart/AirlineAndAirport";
import { useSelector } from "react-redux";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";

export default function PaxAirlineWithAirport() {
    const [filters, setFilters] = useState({
        category: "Airport",
    });
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/pax",
        type: filters.category?.toLowerCase(),
        doSend: true,
        regenerate: true,
    });

    return (
        <PanelChartAirlineAndAirport
            filterPage={filterPage}
            filters={filters}
            dataSource={data}
            onChangeFilter={(e) =>
                setFilters((state) => ({
                    ...state,
                    category: e.target.value,
                }))
            }
        />
    );
}
