import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import RouteTop from "../../../../../../features/PanelChart/RouteTop";

export default function FlightRouteTop() {
    const {
        filterPage,
        dashboardState: { summaryCard },
    } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/flight",
        type: "route",
        doSend: true,
        regenerate: true,
    });

    return <RouteTop dataSource={data} filterPage={filterPage} />;
}
