import React from "react";
import PanelChartAirport from "./BandaraKargoChart";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";

const CargoAirportChart = () => {
  const {
    filterPage,
  } = useSelector((item) => item.apps);
  const [data, loading] = useDashboardChart({
    ...filterPage,
    type: "airport",
    doSend: true,
    routes: "/cargov2",
  });
  return <PanelChartAirport datav2={data} loadingv2={loading} />;
};

export default CargoAirportChart;
