import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAPI } from "./apiHandling";
import { levels } from "./levelConvert";
import Axios from "axios";
import moment from "moment";
const lan = localStorage.getItem("language") || "id";
const name = localStorage.getItem("languageName") || "Indonesia";

const Apps = createSlice({
    name: "apps",
    initialState: {
        errorMessage: "",
        loading: false,
        airportCache: [],
        airlineCache: [],
        language: lan,
        name,
        levels,
        rules: {},
        config: {
            base_url: "/service",
            root_url: "/",
            attachment_url: "/",
            template_color: "#558b2f",
            loaded: false,
        },
        filterPage: {
            year: moment().year(),
            airport_id: "",
            airline_id: "",
            route: "",
        },
        dashboardState: {
            chart: "all",
            summaryCard: "",
            filterTime: "daily",
            filterState: "",
        },
    },
    reducers: {
        setLoading(state) {
            state.loading = true;
        },
        unsetLoading(state) {
            state.loading = false;
        },
        setAirport(state, payload) {
            state.airportCache = payload.payload.data;
        },
        setAirline(state, payload) {
            state.airlineCache = payload.payload.data;
        },
        setLanguage(state, payload) {
            state.language = payload.payload.language;
            state.name = payload.payload.name;
            localStorage.setItem("language", payload.payload.language);
            localStorage.setItem("languageName", payload.payload.name);
        },
        setRules(state, payload) {
            state.rules = { ...payload.payload };
        },
        setConfig(state, payload) {
            state.config = { ...payload.payload, loaded: true };
        },
        setFilterPage(state, payload) {
            state.filterPage = { ...state.filterPage, ...payload.payload };
        },
        setDashboardState(state, payload) {
            state.dashboardState = {
                ...state.dashboardState,
                ...payload.payload,
            };
        },
        setErrorMessage(state, payload) {
            state.errorMessage = payload.payload;
        },
    },
});

export const {
    setLoading,
    unsetLoading,
    setAirline,
    setAirport,
    setLanguage,
    setRules,
    setConfig,
    setFilterPage,
    setDashboardState,
    setErrorMessage,
} = Apps.actions;
export const loadAirports = createAsyncThunk(
    "apps/load-airport",
    async (payload, thunkApi) => {
        const { dispatch } = thunkApi;
        const resp = await dispatch(GetAPI({ url: "api/v1/airport/domestic" }));
        if (resp?.payload?.data) dispatch(setAirport(resp.payload.data));
    }
);

export const loadAirlines = createAsyncThunk(
    "apps/load-airlines",
    async (payload, thunkApi) => {
        const { dispatch } = thunkApi;
        const resp = await dispatch(GetAPI({ url: "api/v1/airlines" }));
        if (resp?.payload?.data) dispatch(setAirline(resp.payload.data));
    }
);

export const loadRules = createAsyncThunk(
    "apps/load-rules",
    async (payload, thunkApi) => {
        const { dispatch } = thunkApi;
        const resp = await dispatch(GetAPI({ url: "api/v1/rules" }));
        if (resp?.payload?.data) dispatch(setRules(resp.payload.data));
    }
);

export const loadConfig = createAsyncThunk(
    "apps/load-config",
    async (payload, thunkApi) => {
        const { dispatch } = thunkApi;
        const resp = await Axios.get("/apps.json", {});
        // console.log({ d: resp.data });
        if (!!resp.data) {
            dispatch(setConfig(resp.data));
        }
    }
);

export default Apps.reducer;
