import { useState, useEffect } from "react";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

const defFontSize = { lg: 22, md: 18, sm: 12, xs: 10, xl: 18 };

export const useFontSize = () => {
    const { width, height } = useWindowDimensions();
    const [fontSize, setFontSize] = useState(defFontSize);
    useEffect(() => {
        if (width > 1000) {
            setFontSize(defFontSize);
        } else if (width <= 1000 && width > 800) {
            setFontSize({ lg: 20, md: 16, sm: 11, xs: 9, xl: 17 });
        } else if (width < 800) {
            setFontSize({ lg: 18, md: 14, sm: 10, xs: 9, xl: 16 });
        }
    }, [width]);

    return fontSize;
};

const defFontSizeV2 = {
    xl3: 36,
    xl2: 30,
    xl: 24,
    lg: 18,
    md: 14,
    sm: 12,
    xs: 10,
    xxs: 8,
};
export const useFontSizeV2 = () => {
    const { width, height } = useWindowDimensions();
    const [fontSize, setFontSize] = useState(defFontSizeV2);

    useEffect(() => {
        const adder = Math.round((1400 - width) / 100);
        if (width > 1200) {
            setFontSize({
                xl3: defFontSizeV2.xl3 - adder,
                xl2: defFontSizeV2.xl2 - adder,
                xl: defFontSizeV2.xl - adder,
                lg: defFontSizeV2.lg - adder,
                md: defFontSizeV2.md - adder,
                sm: defFontSizeV2.sm - adder,
                xs: defFontSizeV2.xs - adder,
                xxs: defFontSizeV2.xxs - adder,
            });
        }
    }, [width]);

    return fontSize;
};
