import React, { Fragment } from "react";
import CardMaps from "../../../../../../components/Maps/CardMaps";
import { useSelector } from "react-redux";
import { useDashboardChart } from "../../../../../../hooks/useDashboardChart";
import AirportActiveMaps from "./Airport/ActiveMaps";
import AirlineActiveMaps from "./Airline/ActiveMap";
import MapFlightActive from "./Flight/MapFlightActive";
import PaxMapAirport from "./Pax/MapAirport";
import { useQueryUrl } from "../../../../../../hooks/useQueryUrl";

const DefaultView = ({ isMobile }) => {
    const { filterPage, dashboardState } = useSelector((item) => item.apps);

    const [dataMaps, loadingMaps] = useDashboardChart({
        ...filterPage,
        type: "maps",
        doSend: true,
        regenerate: true,
    });

    return (
        <CardMaps
            data={dataMaps}
            fullscreen
            height={isMobile ? "65%" : "100%"}
            title={<Fragment>Peta Keseluruhan</Fragment>}
        />
    );
};

const renderContent = (view) => {
    switch (view) {
        case "airport":
            return <AirportActiveMaps />;

        case "flight":
            return <MapFlightActive />;

        case "airline":
            return <AirlineActiveMaps />;

        case "pax":
            return <PaxMapAirport />;

        default:
            return <DefaultView />;
    }
};

const PetaKeseluruhan = () => {
    const {
        dashboardState: { summaryCard },
    } = useSelector((item) => item.apps);
    const query = useQueryUrl();

    return renderContent(summaryCard === query.get("s") && summaryCard);
};

export default PetaKeseluruhan;
