export const formatedOptions = (
  opts = [],
  label = [],
  value = "",
  orderBy = "asc",
  disabledValue = [],
  cb
) => {
  const [label1, label2, label3] = label;
  const reMapOpts =
    Array.isArray(opts) &&
    opts?.map((item) => {
      const [newLabel1, newLabel2, newLabel3] =
        typeof cb === "function"
          ? cb(item[label1], item[label2])
          : [label1, label2];
      return (item = {
        label: !label
          ? item
          : !!label2
          ? `${item[newLabel1]} -- ${item[newLabel2]}`
          : !!label3
          ? `${item[label3]} (${item[newLabel1]})`
          : item[label],
        value: item[value],
      });
    });

  if (!!orderBy) {
    if (orderBy === "asc") {
      return (
        Array.isArray(reMapOpts) &&
        reMapOpts.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
      );
    } else {
      return (
        Array.isArray(reMapOpts) &&
        reMapOpts.sort((a, b) => {
          if (a.label < b.label) {
            return 1;
          }
          if (a.label > b.label) {
            return -1;
          }
          return 0;
        })
      );
    }
  }

  return reMapOpts;
};
