import { Breadcrumb, Row, Col } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFontSizeV2 } from "../../../../../../hooks/useDimension";
import DetailLayout from "../../components/DetailLayout";
import { useDashboardChart } from "../../../../../../hooks/useDashboardChart";
import InfoAirline from "./components/InfoAirline";
import DetailDaily from "./components/DetailDaily";
import ListAirlines from "./components/ListAirlines";
// import DetailDaily from "./components/DetailDaily";
// import ListAirlines from "./components/ListAirlines";

const BgCard = ({ children }) => {
  return (
    <div
      style={{
        background: "white",
        height: "100%",
        borderRadius: 16,
      }}
    >
      {children}
    </div>
  );
};

export default function DetailAirlineOtp() {
  const { filterPage } = useSelector((item) => item.apps);

  const breadcrumbItems = () => {
    return (
      <Breadcrumb style={{ fontSize: 18 }}>
        <Breadcrumb.Item>
          <a href={"/dashboard-analytic/home?s=airline"}>Home</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Detail Airline OTP</Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  return (
    <DetailLayout breadcrumbItems={breadcrumbItems}>
      <div
        style={{
          height: "88%",
          display: "flex",
          justifyContent: "space-between",
          gap: 8,
        }}
      >
        <div style={{ flex: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: 8,
            }}
          >
            <div style={{ height: "50%" }}>
              <BgCard>
                <InfoAirline filterPage={filterPage} />{" "}
              </BgCard>
            </div>
            <div style={{ height: "50%" }}>
              <BgCard>
                <DetailDaily filterPage={filterPage} />
              </BgCard>
            </div>
          </div>
        </div>
        <div style={{ flex: 2 }}>
          <Row gutter={8} style={{ height: "100%" }}>
            <Col lg={{ span: 24 }} style={{ height: "100%" }}>
              <ListAirlines filterPage={filterPage} />
            </Col>
          </Row>
        </div>
      </div>
    </DetailLayout>
  );
}
