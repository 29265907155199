import { Form, Input, Switch } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'
import AutoComplete from '../../../../../components/autocomplete/Offline'
import {useSelector} from 'react-redux'

const DefModel={
    alpha2:'',
    alpha3:'',
    name:'',
    bvk:false,
    voa:false,    
    pattern:"",
}

export default ()=>{
    const [model, setModel]=useState(DefModel);
    return (
        <FormPage 
            url={'api/v1/country'}
            callbackPath={'/master/country'}
            formValid={false}
            model={model}
            formLayout={'horizontal'}
            onEditFormEvent={(st)=>{
                const {name} = st;
                return {...st, name:name.join(';')}
            }}
            setModel={setModel}
            wrapperCol={{span:8}}
            labelCol={{span:3}}
            title={"Country"}            
            emptyModel={DefModel}
            onSaveEvent={(dt)=>{
                const {name} = dt;
                return {...dt, name:name.split(';')}
            }}
        >
            <Form.Item name={'alpha2'} label="Alpha2" rules={[{required:true}]} >
                <Input autoFocus />
            </Form.Item>
            <Form.Item name={'alpha3'} label="Alpha3" >
                <Input  />
            </Form.Item>
            <Form.Item name={'name'} label="Name" rules={[{required:true}]}>
                <Input  />
            </Form.Item>
            <Form.Item name={'bvk'} label="BVK">
                <Switch  />
            </Form.Item>
            <Form.Item name={'voa'}  label="VOA">
                <Switch />
            </Form.Item>
            <Form.Item name={'pattern'} label="Pattern">
                <Input  />
            </Form.Item>
        </FormPage>
    )
}