import { Radio, Spin, Typography } from "antd";
import React, { Fragment, useMemo } from "react";
// import { Popup } from 'react-leaflet';
import { markerTooltipComp } from "../Chart/markerTooltip";
import numeral from "numeral";
import useWindowDimensions, { useFontSize } from "../../hooks/useDimension";
import { useLocation } from "react-router-dom";
import { customFormatWeight } from "../../helper/customFormat";
import { useDashboardChart } from "../../hooks/useDashboardChart";
import { GiPodiumWinner, GiPodiumSecond, GiPodiumThird } from "react-icons/gi";
import { useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  Container: {
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    MsUserSelect: "none",
    userSelect: "none",
  },
  radioStyle: {
    // border: "1px solid red",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    "& .ant-radio-button-wrapper": {
      marginRight: "4px",
      borderRadius: "6px",
      borderLeftWidth: 1.02,
      fontSize: ({ fontSize }) => fontSize.xxs,
      fontWeight: "",
    },
    "& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before":
      {
        display: "none",
      },
    "& .ant-radio-button-wrapper:not(:first-child)::before": {
      display: "none",
    },
  },
});

const options = [
  { label: "Incoming", value: "incoming" },
  { label: "Outgoing", value: "outgoing" },
];

export default ({ item, filterDetail, viewData, isCargo }) => {
  const fontSize = useFontSize();
  const { height } = useWindowDimensions();
  const [tab, setTab] = useState("incoming");
  const classes = useStyles({ fontSize, height });
  const filterMap = useMemo(() => {
    const filter =
      viewData === "transit"
        ? {
            ...filterDetail,
            direct_id: item._id,
            type: "top-transit-commodity",
          }
        : {
            ...filterDetail,
            direct_id: item._id,
            type: "top-commodity",
            direct: viewData?.slice(0, 3),
          };
    return filter;
  }, [filterDetail, viewData]);

  const [detailTopCommodity, loadingDetailTopCommodity] = useDashboardChart({
    ...filterMap,
    direct_id: item._id,
    viewData,
    type: viewData === "transit" ? "top-transit-commodity" : "top-commodity",
    routes: `/cargo-detail/airport`,
  });

  const data = useMemo(() => {
    return viewData === "transit"
      ? detailTopCommodity[tab]
      : detailTopCommodity;
  }, [viewData, tab, detailTopCommodity]);

  console.log({ item });

  return (
    <Fragment>
      <div className="main-tooltip" style={{ padding: 0, background: "white" }}>
        {isCargo ? (
          <Fragment>
            <Typography
              style={{
                color: "black",
                marginBottom: 4,
                fontSize: fontSize.sm,
                fontWeight: "bold",
                // textAlign: "center",
              }}
            >
              {item.name} ({item.code})
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              Total :{" "}
              <span style={{ fontWeight: "lighter" }}>
                {customFormatWeight(item.kilo)} Ton
              </span>
            </Typography>
            <br />
            {data?.length > 0 && (
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: 4,
                }}
              >
                {viewData === "transit"
                  ? "Top 3 Transit Commodity"
                  : "Top 3 Commodity"}
              </Typography>
            )}
            {viewData === "transit" && (
              <div
                style={{
                  marginBottom: 4,
                }}
              >
                <div
                  className={classes.Container}
                  style={{
                    padding: "0px 10px",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    marginTop: 2,
                  }}
                >
                  <Radio.Group
                    size="small"
                    options={options}
                    onChange={({ target: { value } }) => {
                      setTab(value);
                    }}
                    value={tab}
                    optionType="button"
                    className={classes.radioStyle}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                rowGap: 4,
                flexDirection: "column",
              }}
            >
              {loadingDetailTopCommodity ? (
                <Spin />
              ) : (
                Array.isArray(data) &&
                !!data &&
                data?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // marginBottom: 2,
                        // border: "1px solid red",
                        gap: 4,
                      }}
                    >
                      <div>
                        {idx === 0 ? (
                          <GiPodiumWinner size={fontSize.lg} color="gold" />
                        ) : idx === 1 ? (
                          <GiPodiumSecond size={fontSize.lg} color="silver" />
                        ) : (
                          <GiPodiumThird size={fontSize.lg} color="#CD7F32" />
                        )}
                      </div>
                      <Typography.Text
                        ellipsis
                        title={item._id}
                        style={{
                          fontSize: fontSize.sm,
                          fontWeight: "bold",
                          width: 120,
                          // color: "gold",
                        }}
                      >
                        {item._id}
                      </Typography.Text>
                      <Typography style={{ textAlign: "right", flexGrow: 1 }}>
                        {numeral(item.kilo).format("0,000")} Kg
                      </Typography>
                    </div>
                  );
                })
              )}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Typography
                style={{
                  color: "#FB8500",
                  marginBottom: 4,
                  fontSize: fontSize.sm,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {" "}
                {item.name} ({item.code})
              </Typography>
              <Typography style={{ fontWeight: "bold", color: "#219EBC" }}>
                Country Code :{" "}
                <span style={{ fontWeight: "lighter", color: "black" }}>
                  {item.country || "-"}
                </span>
              </Typography>
              <Typography style={{ fontWeight: "bold", color: "#219EBC" }}>
                ICAO Code :{" "}
                <span style={{ fontWeight: "lighter", color: "black" }}>
                  {item.icao_code || "-"}
                </span>
              </Typography>
              <Typography style={{ fontWeight: "bold", color: "#219EBC" }}>
                Operator :{" "}
                <span style={{ fontWeight: "lighter", color: "black" }}>
                  {item.operator || "-"}
                </span>
              </Typography>
              <Typography style={{ fontWeight: "bold", color: "#219EBC" }}>
                Province :{" "}
                <span style={{ fontWeight: "lighter", color: "black" }}>
                  {item.provinsi || "-"}
                </span>
              </Typography>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
