import React, { Fragment } from "react";
import CardMaps from "../../../../../components/Maps/CardMaps";
import { Typography, Divider } from "antd";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import RowList from "../../RowList";

const RenderTooltips = ({ data }) => {
  const fontSize = useFontSizeV2();
  const { name, operator, provinsi, timezone, icao_code, country, code, kota } =
    data;
  return (
    <div>
      <Typography
        style={{
          fontSize: fontSize.xs,
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {name}
      </Typography>
      <Divider style={{ margin: "8px 0px" }} />

      <RowList title={"Code"} description={code} isBoldDescription />
      <RowList title={"ICAO Code"} description={icao_code} isBoldDescription />
      <RowList title={"Kota"} description={kota} isBoldDescription />
      <RowList title={"Provinsi"} description={provinsi} isBoldDescription />
      <RowList title={"Negara"} description={country} isBoldDescription />
      <RowList title={"Timezone"} description={timezone} isBoldDescription />
      <RowList title={"Operator"} description={operator} isBoldDescription />
    </div>
  );
};

export default function MapAirportActive({
  dataMaps,
  isMobile,
  title,
  customTooltips,
  customMarkRadius,
}) {
  return (
    <CardMaps
      data={dataMaps}
      fullscreen
      height={isMobile ? "65%" : "100%"}
      title={<Fragment>{title || "Peta Bandara Aktif"}</Fragment>}
      customMarkRadius={(data) =>
        customMarkRadius ? customMarkRadius(data) : 10000
      }
      customTooltip={(data) =>
        customTooltips ? customTooltips(data) : <RenderTooltips data={data} />
      }
    />
  );
}
