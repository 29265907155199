import React, { useState } from "react";
import DashboardChart from "../../DashboardChart";
import { useMemo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions, {
  useFontSizeV2,
} from "../../../../../../../../hooks/useDimension";
import compareDates from "../../../../../../../../helper/compareDates";
import {
  forecastData,
  getFilterData,
} from "../../../../../../../../helper/getFilterData";
import customFormat from "../../../../../../../../helper/customFormat";
import { parseFormatedNumber } from "../../../../../../../../helper/parseFormatedNumber";

const options = [
  { label: "Hari", value: "daily" },
  { label: "Bulan", value: "monthly" },
  { label: "Kwartal", value: "quarterly" },
  { label: "Semester", value: "semesterly" },
];

const optionsDirectInter = [
  { label: "All", value: 0 },
  { label: "Export", value: 1 },
  { label: "Import", value: 2 },
];

const InternasionalChart = ({ data, loading, datatable }) => {
  const { dashboardState, filterPage } = useSelector((item) => item.apps);
  const [viewProd, setViewProd] = useState("daily");
  const [direct, setDirect] = useState("");
  const fontSize = useFontSizeV2();

  const [InterData, fcData] = useMemo(() => {
    const { all, exp, imp } = data;
    const directData = direct === 0 ? all : direct === 1 ? exp : imp;
    if (!data)
      return [
        { yearly: [], semesterly: [], quarterly: [], monthly: [], daily: [] },
        { yearly: [], semesterly: [], quarterly: [], monthly: [], daily: [] },
      ];
    const { daily, semesterly, monthly, quarterly } = getFilterData(
      directData?.sort(compareDates)
    );
    const fcDaily = forecastData(daily, "daily");
    return [
      {
        semesterly,
        quarterly,
        monthly,
        daily,
      },
      {
        daily: fcDaily,
      },
    ];
  }, [data, filterPage, direct]);

  const min = Math.min(
    ...InterData[viewProd].map((item) => parseInt(item.curr))
  );
  const max = Math.max(
    ...InterData[viewProd].map((item) => parseInt(item.curr))
  );

  const optionsInternasional = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    // legend: {
    //   data: ["Import", "Export"],
    //   bottom: height > 720 ? "0%" : "-5%",
    //   icon: "square",
    // },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "0%",
      right: "5%",
      bottom: "12%",
      containLabel: true,
      top: "10%",
    },
    xAxis: {
      type: "category",
      // boundaryGap: false,
      data:
        fcData[viewProd]?.length >= 100 && viewProd === "daily"
          ? fcData[viewProd]?.map((item) =>
              viewProd === "monthly"
                ? moment()
                    .month(item.name - 1)
                    .format("MMMM")
                : item.name
            )
          : InterData[viewProd]?.map((item) =>
              viewProd === "monthly"
                ? moment()
                    .month(item.name - 1)
                    .format("MMMM")
                : item.name
            ),
      axisLabel: {
        textStyle: {
          fontSize: fontSize.sm,
        },
        formatter:
          viewProd === "daily"
            ? (params) => moment(params).format("DD MMM")
            : (params) => `${params}`,
        // interval: ,
      },
    },

    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => customFormat(params),
      },
    },

    visualMap: [
      {
        show: false,
        type: "continuous",
        seriesIndex: 0,
        min: min,
        max: max,
        inRange: {
          color: ["#fb8500", "#023047"],
        },
      },
      {
        show: false,
        type: "continuous",
        seriesIndex: 0,
        min: min,
        max: max,
        inRange: {
          color: ["#fb8500", "#023047"],
        },
      },
    ],

    series: [
      {
        name: direct === 0 ? "All" : direct === 1 ? "Import" : "Export",
        type: viewProd === "daily" ? "line" : "bar",
        barWidth: 10,
        barGap: 0,
        areaStyle: {
          opacity: 0.3,
        },
        symbol:
          dashboardState?.filterTime !== "daily" || viewProd !== "daily"
            ? "circle"
            : "none",

        emphasis: {
          focus: "series",
        },
        data: InterData[viewProd]?.map((item) => item.curr),
        markLine: {
          data: [
            {
              type: "average",
              itemStyle: {
                color: "#219ebc",
              },
              label: {
                formatter: (params) =>
                  `Avg. ${parseFormatedNumber(params.value)}`,
                textStyle: {
                  fontSize: 15,
                },
                position: "insideEndTop",
              },
            },
          ],
          silent: true,
        },
      },
      {
        seriesIndex: 10,
        z: 0,
        name: "Forecase",
        type: "line",
        smooth: 0.1,
        areaStyle: {
          opacity: 0.3,
        },
        // stack: "Total",
        symbol: "none",
        showSymbol: false,
        data:
          fcData[viewProd]?.length >= 100
            ? fcData[viewProd]?.map((item) => item.value)
            : [],
        color: "#FFB703",
      },
    ],
  };

  const dataTable = useMemo(() => {
    const datas =
      !!datatable &&
      datatable?.map((item) => {
        return {
          _id: item._id,
          dexport: item.dexport,
          dimport: item.dimport,
          total: item.dimport + item.dexport + item.domestic,
        };
      });

    const columnTable = [
      {
        title: "Date",
        type: "string",
        name: "_id",
        align: "left",
      },
      {
        title: "Export",
        type: "string",
        name: "dexport",
        align: "right",
      },
      {
        title: "Import",
        type: "string",
        name: "dimport",
        align: "right",
      },
      {
        title: "Total",
        type: "string",
        name: "total",
        align: "right",
      },
    ];
    return {
      data: datas,
      fields: columnTable,
      header: columnTable.map((item) => item.title),
    };
  }, [data]);

  return (
    <DashboardChart
      optDirect={optionsDirectInter}
      printHeader={"Internasional Report"}
      dataSource={dataTable}
      repType={"Internasional"}
      //   loading={loading}
      direct={direct}
      setDirect={setDirect}
      view={
        dashboardState?.filterState === "all"
          ? dashboardState?.filterTime
          : viewProd
      }
      setView={setViewProd}
      isFilterComponent
      isOtherComponent
      title={"Internasional"}
      data={optionsInternasional}
      options={options}
    />
  );
};

export default InternasionalChart;
