import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import PanelChartFavoriteDestination from "../../../../../../features/PanelChart/FavoriteDestination";
export default function FavoritDestination() {
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/pax",
        type: "dest",
        doSend: true,
        regenerate: true,
    });

    return (
        <PanelChartFavoriteDestination
            dataSource={data}
            filterPage={filterPage}
        />
    );
}
