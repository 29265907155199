import { Button } from 'antd';
import React, { Fragment, useMemo, useState } from 'react';
import {FileExcelFilled, FileExcelOutlined, ManOutlined} from '@ant-design/icons';
import { green } from '@ant-design/colors';
import moment from 'moment';
import { DownloadFlightRecords, DownloadPaxRecords } from './excel_writer';

const timePnr = [259200, 86400, 7200, 3600, 0];
const timeApis = 1800;
// const timeCrw=1800;

const ExportFlightData = (flight_records, direction) => {
    if (!Array.isArray(flight_records)) return false;
    const sortedData = flight_records.sort((a, b) => {
        return direction === "departure"
            ? a.unixStd - b.unixStd
            : a.unixSta - b.unixSta;
    });
    // console.log({sortedData});
    const mapedData = sortedData.map((frec) => {
        const { customs_messages, unixStd } = frec;
        if (!customs_messages) return frec;
        const timed = {};
        for (let iii = 0; iii < customs_messages.length; iii++) {
            const { msgType, msgId } = customs_messages[iii];
            const { createdAt } = msgId;
            const unixTimeMessage = moment(createdAt).unix();
            switch (msgType) {
                case "PNRGOV":
                    const diffTime = unixStd - unixTimeMessage;
                    const deltas = timePnr.map((tm) => ({
                        v: Math.abs(tm - diffTime),
                        r: tm - diffTime,
                        n: tm,
                        time: createdAt,
                    }));
                    const [selTime] = deltas.sort((a, b) => a.v - b.v);
                    // const minLeft=Math.ceil(selTime.r / 60);
                    const hourTime = Math.ceil(selTime.n / 3600);
                    timed[`PNR_${hourTime}`] = unixTimeMessage;
                    break;
                case "PAXLST":
                case "CRWLST":
                    // const apisDiffTime=unixTimeMessage - (unixStd + timeApis);
                    timed[msgType] = unixTimeMessage; ///Math.ceil(apisDiffTime / 60);
                    break;
                default:
                    break;
            }
        }
        return { ...frec, timed };
    });
    // console.log({mapedData});
    DownloadFlightRecords(mapedData, direction);
};

const ExportFlightDataPassenger=(flight_records, direction)=>{
    if(!Array.isArray(flight_records))return false;
    const sortedData=flight_records.sort((a, b)=>{
        return direction==='departure'?a.unixStd - b.unixStd:a.unixSta - b.unixSta;
    })
    DownloadPaxRecords(sortedData, direction);
}

export const ExcelExporter=({flight_records, direction})=>{
    const [hover, setHover]=useState(false);
    const [hover2, setHover2]=useState(false);
    const [bgColor, ftColor]=useMemo(()=>{
        return hover?['white',green[6]]:[green[6], 'white'];
    }, [hover])
    const [bgColor2, ftColor2]=useMemo(()=>{
        return hover?['white',green[6]]:[green[6], 'white'];
    }, [hover2])
    return (
        <Fragment>
            <Button 
                icon={<FileExcelOutlined />} 
                onMouseEnter={()=>setHover(true)}
                onMouseLeave={()=>setHover(false)}
                style={{color:bgColor, border:`1px solid ${green[6]}`, background:ftColor, borderRadius:99}} 
                type='ghost' 
                onClick={()=>{
                    ExportFlightData(flight_records, direction);
                }}    
            />
            <Button 
                icon={<FileExcelFilled />} 
                onMouseEnter={()=>setHover2(true)}
                onMouseLeave={()=>setHover2(false)}
                style={{color:bgColor2, border:`1px solid ${green[6]}`, background:ftColor2, borderRadius:99}} 
                type='ghost' 
                onClick={()=>{
                    ExportFlightDataPassenger(flight_records, direction);
                }}    
            />
        </Fragment>
    )
}
