import { buildDataTable } from "../../../../../helper/buildDataTable";
import { tooltipFormater } from "../../Chart/tooltipFormater";

const getData = (dataSource) => {
    if (!dataSource) {
        return [[], []];
    }
    let result = {};

    for (let iii = 0; iii < dataSource.length; iii++) {
        const { code, domestic, international, ...rest } = dataSource[iii];
        const by = code || "-";

        if (!result[by]) {
            result[by] = {
                totalDom: domestic,
                totalInt: international,
            };
        } else {
            let sumDom = (result[by].totalDom += domestic);
            let sumInt = (result[by].totalInt += international);

            result[by] = {
                totalDom: sumDom,
                totalInt: sumInt,
            };
        }
        if (!result[by]?.originObj) {
            result[by] = {
                ...result[by],
                originObj: { code, ...rest },
            };
        }
    }

    let set = [];
    let dataTable = [];
    for (const key in result) {
        if (Object.hasOwnProperty.call(result, key)) {
            const { totalDom, totalInt, originObj } = result[key];
            const grandTotal = totalDom + totalInt;
            set.push([key, totalDom, totalInt, grandTotal, originObj]);
            dataTable.push({
                totalDom,
                totalInt,
                key: `${key} - ${originObj.name}`,
                grandTotal,
                ...originObj,
            });
        }
    }

    const dataset = [
        {
            dimensions: [
                "label",
                "domestic",
                "international",
                "grandTotal",
                "originObj",
            ],
            source: set.sort((a, b) => {
                const [, , , aGrandTot] = a;
                const [, , , bGrandTot] = b;
                return aGrandTot - bGrandTot;
            }),
        },
        {
            transform: {
                type: "sort",
                config: { dimension: "grandTotal", order: "asc" },
            },
        },
    ];
    return [
        dataset,
        buildDataTable(
            dataTable.sort((a, b) => b.grandTotal - a.grandTotal),
            [
                {
                    title: "Bandara",
                    type: "string",
                    name: "key",
                    align: "left",
                },
                {
                    title: "Total Domestik",
                    type: "number",
                    name: "totalDom",
                    align: "right",
                },
                {
                    title: "Total Internasional",
                    type: "number",
                    name: "totalInt",
                    align: "right",
                },
                {
                    title: "Grand Total",
                    type: "number",
                    name: "grandTotal",
                    align: "right",
                },
            ]
        ),
    ];
};

const renderTooltip = (params) => {
    const { data: domData } = params[0];
    return tooltipFormater({
        title: {
            label: `${domData[4]["code"]} - ${domData[4]["name"]}`,
        },
        params,
    });
};
export default { getData, renderTooltip };
