import React from "react";
import { useDashboardChart } from "../../../../../../../hooks/useDashboardChart";
import logo from "../../../../../../../assets/icons/bandara_dark.svg";
import { useParams } from "react-router-dom";
import { Typography } from "antd";
import Config from "../../../../../../../config/env";

const { primaryColor, secondaryColor } = Config;

const InfoAirport = ({ filterPage }) => {
  const { idAirport } = useParams();

  const [detailInfo, loadingDetailInfo] = useDashboardChart({
    ...filterPage,
    airport_id: idAirport,
    type: "info",
    routes: "/airport-detail/otp",
  });

  return (
    <div
      style={{
        padding: "0px 10px",
        display: "flex",
        alignItems: "center",
        gap: 32,
        marginTop: 2,
        height: "100%",
      }}
    >
      <div
        style={{
          width: "50%",
          height: "100%",
          padding: 16,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} style={{ width: 200 }} />
        <div style={{ marginTop: 10 }}>
          {!!detailInfo ? (
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "28px",
                color: primaryColor,
              }}
            >
              {detailInfo?.name?.toUpperCase()}(
              <span style={{ color: secondaryColor }}>{detailInfo?.code}</span>)
            </Typography>
          ) : (
            <span>Loading . . .</span>
          )}
        </div>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          padding: 8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          //   border: "1px solid red",
        }}
      >
        <Typography style={{ fontSize: "24px" }}>
          <span>Operator</span> <br />{" "}
          <span style={{ fontWeight: "bold", color: secondaryColor }}>
            {detailInfo?.operator}
          </span>
        </Typography>
        <Typography style={{ fontSize: "24px" }}>
          <span>Kota</span> <br />{" "}
          <span style={{ fontWeight: "bold", color: secondaryColor }}>
            {detailInfo?.kota}
          </span>
        </Typography>
        <Typography style={{ fontSize: "24px" }}>
          <span>Kode Negara</span> <br />{" "}
          <span style={{ fontWeight: "bold", color: secondaryColor }}>
            {detailInfo?.country}
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default InfoAirport;
