import { green, grey, red } from '@ant-design/colors';
import { Avatar, Typography } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import { GiAirplaneArrival, GiAirplaneDeparture } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import Flags from '../../../../../../components/flags';
import { useFetch } from '../../../../../../hooks/useFetch';
import { useLanguage } from '../../../../../../hooks/useLanguage';
import './fs.css';

export default ({row, to, url, direction}) => {
    const {_id, flight_number, origin_id, dest_id, airline_id, flight_date, 
        aircraft_registration_id:aircraft_id, customs_messages, arrival_date, actype,
        localAta, localAtd, localSta, localStd, sta, std, status, flight_rule_affected } = row;
    const _ta = ( localAta || localSta || sta);
    const _td = ( localAtd || localStd || std);
    const ta=_ta && (_ta.length===5?_ta:_ta.substring(0,2)+':'+_ta.substring(2)) || '';
    const td=_td && (_td.length===5?_td:_td.substring(0,2)+':'+_td.substring(2)) || '';
    const airlineCode=airline_id?.code || airline_id?.icao || '';
    const [total, loading]=useFetch(`api/v1/flight_schedule/inter_pax_total/${_id}`);
    const date=direction==='departure'?flight_date:arrival_date;
    const [bkd, man, pcs, kgs, smu]=useMemo(()=>{
        if(!total)return [0,0,0,0,0];
        if(!total?.total)return [0,0,0,0,0];

        const {paxes, cargos:[cargo]} = total.total;
        let bkd=0;
        let man=0;
        let kgs=0;
        let pcs=0;
        let smu=0;
        for (let iii = 0; iii < paxes.length; iii++) {
            const {_id, total:tot} = paxes[iii];
            switch (_id.status) {
                case 'boarded':
                    bkd+=tot;
                    man+=tot;
                    break;                
                default:
                    bkd+=tot;
                    break;
            }
        }
        if(!!cargo){
            const {kilo, koli, smu:awb} = cargo;
            kgs=kilo;
            pcs=koli;
            smu=awb;
        }
        return [bkd, man, pcs, kgs, smu];
    }, [total]);
    // const {crew, pnr, manifest, pantau, interpol, imigrasi, passport, visa, poi, melintas} = total;
    const flight_affected=Array.isArray(flight_rule_affected) && flight_rule_affected.length>0;
    const borders=flight_affected?{border:'2px red solid'}:{};
    const [,getLanguage] = useLanguage()
    return (
        <Link to={`${url}/${_id}`}>
            <div style={{display:'flex', flexDirection:'column', borderRadius:16, overflow:'hidden', ...borders}}>
                <div style={{display:'flex', flexDirection:'row', background:'#ffffffaa', padding:4}}>
                    <div style={{display:'flex', flexDirection:'row', gap:4, width:`calc(25% + 64px)`}}>
                        <Typography.Text title={getLanguage('flight_number')} strong>{flight_number} </Typography.Text>                    
                        <Typography.Text>: {status}</Typography.Text>
                    </div>
                    <Typography.Text style={{width:'25%'}} title={getLanguage('flight_date')} strong>{date}</Typography.Text>
                    <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center', flexGrow:1, justifyContent:'end'}}>
                        {
                            !!airline_id.country && <Flags country_code={airline_id.country?.toLowerCase()} />
                        }
                        <Typography.Text style={{textAlign:'right'}}>{airline_id?.name}</Typography.Text>
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'row', background:'#ffffff77', alignItems:'center', justifyContent:'space-between', padding:8}}>
                    <div style={{width:64}}>
                        <Avatar
                            alt={airline_id?.code}
                            size="large"
                            style={{alignSelf:'center'}}
                            className={'avatar-contain'}
                            src={`/assets/carrier/${airlineCode?.toLowerCase() || 'XX'}.png`}
                        />
                    </div>
                    <div style={{display:'flex', width:'23%', flexDirection:'column', rowGap:4}}>
                        <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center', flexWrap:'wrap'}}>
                            {
                                !!origin_id.country && <Flags country_code={origin_id.country?.toLowerCase()} />
                            }
                            <Typography.Text strong>{origin_id?.code}</Typography.Text>
                            <Typography.Text>({origin_id?.kota})</Typography.Text>
                            {/* <Typography.Text>{flight_date.substring(0,2)} {td}</Typography.Text> */}
                            <Typography.Text>{td}</Typography.Text>
                            <GiAirplaneDeparture size={18} color='black'/>
                        </div>
                        <Typography.Text ellipsis>{origin_id?.name}</Typography.Text>
                    </div>
                    <div style={{display:'flex', width:'23%', flexDirection:'column', rowGap:4}}>
                        <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center', flexWrap:'wrap'}}>
                            {
                                !!dest_id.country && <Flags country_code={dest_id.country?.toLowerCase()} />
                            }
                            <Typography.Text strong>{dest_id?.code}</Typography.Text>
                            <Typography.Text>({dest_id?.kota})</Typography.Text>
                            <Typography.Text>{ta}</Typography.Text>
                            {/* <Typography.Text>{arrival_date.substring(0,2)} {ta}</Typography.Text> */}
                            <GiAirplaneArrival size={18} color='black' />
                        </div>
                        <Typography.Text ellipsis>{dest_id?.name}</Typography.Text>
                    </div>
                    <div style={{display:'flex', width:'18%', flexDirection:'column', rowGap:4, alignItems:'end'}}>
                        {
                            !aircraft_id?.aircraft_registration && (
                                <Typography.Text>{ getLanguage('no_aircraft') }</Typography.Text>
                            )
                            || (
                                <Typography.Text ellipsis>{ aircraft_id.aircraft_registration }</Typography.Text>
                            )
                        }
                        <Typography.Text ellipsis>{aircraft_id?.aircraft_type_txt || actype}</Typography.Text>
                    </div>
                    <div style={{display:'flex', flexGrow:1, flexDirection:'column', rowGap:4, alignItems:'end'}}>
                    <div style={{display:'flex', flexDirection:'row', gap:8, alignItems:'start', justifyContent:'end', flexWrap:'wrap'}}>
                            <span title='Kg' className={`msg-wrapper ${kgs>0?'msg-exists':''}`}>
                                Kgs: <span>{numeral(kgs).format('0,000')}</span> 
                            </span>
                            <span title='Pcs' className={`msg-wrapper ${pcs>0?'msg-exists':''}`}>
                                Pcs: <span>{numeral(pcs).format('0')}</span>
                            </span>
                            <span title='AWB' className={`msg-wrapper ${smu>0?'msg-exists':''}`}>
                                AWB:<span>{numeral(smu).format('0')}</span>
                            </span>
                        </div>
                        <div style={{display:'flex', flexDirection:'row', gap:8, alignItems:'start', justifyContent:'end', flexWrap:'wrap'}}>
                            <span title='Booked' className={`msg-wrapper ${bkd>0?'msg-exists':''}`}>
                                BKD:<span>{numeral(bkd).format('0')}</span>
                            </span>
                            <span title='Manifest' className={`msg-wrapper ${man>0?'msg-exists':''}`}>
                                MAN:<span>{numeral(man).format('0')}</span>
                            </span>                            
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}