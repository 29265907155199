import React, { useEffect, useState } from "react";
import PanelChartFlight from "../../../../../../features/PanelChart/Flight";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";

export default function AirportFlightTrend() {
    const {
        filterPage,
    } = useSelector((item) => item.apps);

    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/airport",
        type: "flight",
        doSend: true,
        regenerate: true,
    });

    return (
        <PanelChartFlight
            dataSource={data}
            loading={loading}
            filterPage={filterPage}
        />
    );
}
