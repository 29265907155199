import React from "react";
import PanelChartAirline from "../../../../../../features/PanelChart/Airline";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";

export default function AirportAirline() {
    const {
        filterPage,
    } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/airport",
        type: "airline",
        doSend: true,
        regenerate: true,
    });
    return (
        <PanelChartAirline
            dataSource={data}
            loading={loading}
            filterPage={filterPage}
        />
    );
}
