import React, { useMemo } from 'react';
import Panel from '../panel/panel';
import { Typography } from 'antd';
import Row from './row';
import { GiPerson } from 'react-icons/gi';

export const AgentCargo = ({data})=>{
    const [location, name, iata_cargo_agent_num_code] = useMemo(()=>{
        if(!data)return ['-', '-', '-'];
        const {location, name, iata_cargo_agent_num_code}=data;
        return [location || '-', name || '-', iata_cargo_agent_num_code || '-'];
    }, [data]);
    return (
        <Panel
            headerLeft={<Typography.Text>Agent</Typography.Text>}
            headerRight={
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <GiPerson />
                </div>
            }
        >
            <Row name={'name'} value={name} />
            <Row name={'location'} value={location} />
            <Row name={'iata_number'} value={iata_cargo_agent_num_code} last />
        </Panel>
    )
}