import React, { useMemo, useState } from "react";
import { Layout, Menu, Typography } from "antd";
import "./layout.css";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMenu, setOpenKeys } from "../../redux";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import Logo from "../../assets/images/logo-dashboard.png";
import LOGO from "../../content/appsv3/assets/images/Logo.png";
import { UserProfile } from "./userProfile";
import { useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import HeaderSearchInput from "../HeaderSearchInput";
import { createUseStyles } from "react-jss";
import { useLanguage } from "../../hooks/useLanguage";
import { getLanguage } from "../../languages/utils";
import useWindowDimensions, { useFontSizeV2 } from "../../hooks/useDimension";
import IconMenu from "../../content/appsv3/components/IconMenu";
import { MdKeyboardArrowRight } from "react-icons/md";

const { Header, Content, Footer, Sider } = Layout;

const useStyles = createUseStyles({
    menuItem: {
        "& .ant-menu": {
            padding: 5,
        },
        "& .ant-menu-item-selected": {
            backgroundColor: "#f4f4f6 !important",
            border: "none !important",
            color: "#0C356A !important",
            borderRadius: 15,
            fontWeight: "bold",
        },
        "& .ant-menu-item-selected a, .ant-menu-item-selected a:hover": {
            color: "#0C356A",
            fontWeight: "bold",
        },
        "& .ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after":
            {
                transform: "none !important",
                opacity: 0,
            },
        "& .ant-menu-title-content a": {
            color: "#0C356A !important",
        },
        "& .ant-menu-title-content a:hover": {
            color: "black !important",
        },
        // "& .ant-menu-item": {
        //     paddingLeft: "30px !important",
        // },
        // "& .ant-menu-submenu-title": {
        //     paddingLeft: "30px !important",
        // },
    },
});

export const SiderLayout3 = ({
    children,
    menus,
    title,
    theme,
    headerBgColor,
    headerColor,
}) => {
    const { selectedMenu, openKeys } = useSelector((state) => state.nav);
    const { userdata } = useSelector((state) => state.auth);
    const { language } = useSelector((state) => state.apps);
    const windowSize = useWindowDimensions();
    const [Lang] = useLanguage(language);
    const location = useLocation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [colapsed, setColapsed] = useState(false);
    const rootSubmenuKeys = useMemo(() => {
        return menus.map(({ key }) => key);
    }, [menus]);
    const renderThumb = ({ style }) => {
        return <div style={{ ...style, backgroundColor: "#eecccc6e" }} />;
    };

    const breadCrumb = location?.pathname.split("/").slice(1);
    const fontSize = useFontSizeV2();

    return (
        <Layout hasSider>
            <Sider
                width={300}
                style={{
                    height: "100vh",
                    position: "fixed",
                    top: 0,
                    bottom: 0,
                    left: 0,
                }}
                breakpoint="lg"
                theme={theme || "dark"}
                // collapsed={colapsed}
            >
                <div
                    style={{ padding: 16, width: "100%", textAlign: "center" }}
                >
                    <img src={LOGO} alt="Logo" style={{ width: "inherit" }} />
                </div>
                <Scrollbars renderThumbVertical={renderThumb}>
                    <Menu
                        style={{}}
                        className={`${classes.menuItem} sider-v3-new`}
                        mode="inline"
                        selectedKeys={[selectedMenu]}
                        theme={theme || "dark"}
                        items={menus || []}
                        onClick={(e) => {
                            dispatch(setSelectedMenu(e.key));
                            console.log({ e });
                        }}
                        openKeys={openKeys}
                        inlineIndent={8}
                        onOpenChange={(keys) => {
                            const latestOpenKey = keys.find(
                                (key) => openKeys.indexOf(key) === -1
                            );
                            if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                                dispatch(setOpenKeys(keys));
                            } else {
                                dispatch(
                                    setOpenKeys(
                                        latestOpenKey ? [latestOpenKey] : []
                                    )
                                );
                            }
                        }}
                    />
                </Scrollbars>
            </Sider>
            <Layout
                style={{ marginLeft: colapsed ? 80 : 300, minHeight: "100vh" }}
            >
                <HeaderSearchInput />
                {/* <div
                    style={{
                        marginTop: 16,
                        marginBottom: 24,
                        marginLeft: 16,
                        marginRight: 16,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 16,
                        }}
                    >
                        <IconMenu
                            color="#0C356A"
                            type={`${location?.pathname}`}
                            size={25}
                        />
                        <Typography
                            style={{
                                fontWeight: 600,
                                color: "#0C356A",
                                fontSize: "24px",
                                textTransform: "capitalize",
                            }}
                        >
                            {getLanguage(
                                breadCrumb[breadCrumb.length - 1]
                                    ?.split("_")
                                    ?.join(" "),
                                Lang
                            )}
                        </Typography>
                    </div>

                    <Typography
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {breadCrumb?.map((item, idx) => (
                            <span
                                key={idx}
                                style={{
                                    fontWeight:
                                        idx === breadCrumb.length - 1
                                            ? "bold"
                                            : "",
                                    textTransform: "capitalize",
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: fontSize.md,
                                }}
                                className="text-grey-1"
                            >
                                {`${getLanguage(
                                    item?.split("_")?.join(" "),
                                    Lang
                                )}`}
                                {idx === breadCrumb.length - 1 ? (
                                    ""
                                ) : (
                                    <MdKeyboardArrowRight />
                                )}
                            </span>
                        ))}
                    </Typography>
                </div> */}
                <Scrollbars renderThumbVertical={renderThumb}>
                    <Content
                        style={{
                            margin: "0px 16px 0",
                            display: "flex",
                            flexDirection: "column",
                            minHeight: `calc(100%)`,
                        }}
                    >
                        <div
                            className="site-layout-background"
                            style={{ flexGrow: 1 }}
                        >
                            {children}
                        </div>
                    </Content>
                </Scrollbars>
                {/* <Footer style={{ textAlign: "center", height: 64 }}>
                    {windowSize.width} x {windowSize.height}
                </Footer> */}
            </Layout>
        </Layout>
    );
};
