import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:({alpha2})=>alpha2,
            name:"Alpha2",
        },
        {
            selector:({alpha3})=>alpha3,
            name:"Alpha3",
        },
        {
            selector:({name})=>(name || []).join('; '),
            name:"Name",
        },
        {
            selector:({passport_pattern})=>passport_pattern,
            name:"Pattern",
        },
        {
            selector:({bvk})=>bvk && "Yes" || 'No',
            name:"BVK",
        },
        {
            selector:({voa})=>voa && "Yes" || 'No',
            name:"VOA",
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
        },
    ]
    return (
        <TablePage
            title={"Master Country"}
            url="api/v1/country"
            actionPosition={'first'}
            createPath="/master/country/create"
            editPath={"/master/country/edit"}
            columns={columns}            
        />
    )
}