import numeral from "numeral";

export const parseFormatedNumber = (num, type) => {
  if (!num) return 0;
  return numeral(num).format("0,000.0a");
  // let number = num?.toString().replace(/[^0-9.]/g, '') ;
  // if (number < 1000) {
  //     return number;
  // }

  // const weightUnit = [
  //     {v: 1E3, s: "Ton"},
  //     // {v: 1E6, s: ""},
  //     // {v: 1E6, s: ""},
  // ]

  // const moneyUnit = [
  //     {v: 1E3, s: "Rb"},
  //     {v: 1E6, s: "Jt"},
  //     {v: 1E9, s: "M"},
  //     ]
  // let si = type === 'weight-unit' ? weightUnit : moneyUnit;

  // let index;
  // for (index = si.length - 1; index > 0; index--) {
  //     if (number >= si[index].v) {
  //         break;
  //     }
  // }
  // return (number / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + " " + si[index].s;
};
