import DomesticPage from '../pages/domestic';
import DomesticDetailPage from '../pages/domestic/detail';
import DomesticPaxPage from '../pages/domestic/pax';
import DomesticCargoPage from '../pages/domestic/cargo';
import DomesticPnrPage from '../pages/domestic/pnr';
import CFG from '../../../config/env';
const {withDomestic} = CFG;
export default withDomestic?[
    {
        path:'/flight/domestic',
        exact:true,
        Component:DomesticPage
    },
    {
        path:'/flight/domestic/:id',
        exact:true,
        Component:DomesticDetailPage
    },
    {
        path:'/flight/domestic/pnr/:id',
        exact:true,
        Component:DomesticPnrPage
    },
    {
        path:'/flight/domestic/passengers/:id',
        exact:true,
        Component:DomesticPaxPage
    },
    {
        path:'/flight/domestic/cargo/:id',
        exact:true,
        Component:DomesticCargoPage
    },
]:[]