import React from "react";
import { Select } from "antd";
import { createUseStyles } from "react-jss";
import { useFontSizeV2 } from "../../../../hooks/useDimension";

const useStyles = createUseStyles({
    selectStyle: {
        width: "100%",
        "& .ant-select-selector": {
            borderRadius: "8px !important",
            height: 16,
        },
        "& .ant-select-selection-placeholder": {
            fontSize: ({ fontSize }) => `${fontSize.sm - 2}px`,
            lineHeight: ({ fontSize }) => `${fontSize.xl2 - 8}px !important`,
            // paddingTop: ({ fontSize }) => `-5px !important`,
        },
        "& .ant-select-selection-search-input": {
            height: "100% !important",
        },
    },
});

const getOptionsAirport = (airports) => {
    let result = [{ label: "Semua Bandara", value: "all" }];
    for (let iii = 0; iii < airports.length; iii++) {
        const { code, name } = airports[iii];
        result.push({ label: `${code}-${name}`, value: code });
    }
    return result;
};

export default function SelectAirport({
    dataSource,
    onChange,
    value,
    ...props
}) {
    const fontSize = useFontSizeV2();
    const classes = useStyles({ fontSize });
    const optionsAirport = getOptionsAirport(dataSource);

    return (
        <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "")
                    .toLowerCase()
                    .includes(input?.toLowerCase())
            }
            options={optionsAirport}
            value={value}
            className={classes.selectStyle}
            onChange={(value) => {
                onChange(value);
            }}
            {...props}
        />
    );
}
