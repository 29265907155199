import { IoList } from "react-icons/io5";

export default [
    {
        caption: "list",
        category: "passengers",
        group: 0,
        Icon: IoList,
        linkTo: "/passengers/list",
    },
];
