import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import PanelChartOtp from "../../../../../../features/PanelChart/Otp";

export default function AirportOtp() {
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/airport",
        type: "otp",
        doSend: true,
        regenerate: true,
    });
    return (
        <PanelChartOtp
            dataSource={data}
            filterPage={filterPage}
            loading={loading}
        />
    );
}
