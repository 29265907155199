/* eslint-disable react-hooks/exhaustive-deps */
// import { Paper, Table, TableCell as TD, TableContainer, TableHead, TableRow, TableBody, withStyles } from '@material-ui/core';
import { Pagination } from "antd";
import React, { useEffect, useMemo, useState } from "react";
// import {useMyFetch} from '../../component/useMyFetch';
import moment from "moment";
import numeral from "numeral";
import "./table.css";

const PAGESIZE = 10;
const CURRPAGE = 1;

export default ({
    dataSource,
    setFilterLists,
    onDataChange,
    filtersValue,
    getFilteredData,
    getHeaderFields,
    searchStr,
}) => {
    const [refresh, setRefresh] = useState(0);
    const [currentPage, setCurrentPage] = useState(CURRPAGE);
    const [pageSize, setPageSize] = useState(PAGESIZE);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (curr, size) => {
        setPageSize(size);
        setCurrentPage(curr); // Reset to the first page when changing the number of rows per page
    };

    const [header, setheader] = useState([]);

    const { fields, data } = useMemo(() => {
        if (!dataSource) return { fields: [], data: [] };
        let resultHeader = [];
        for (let iii = 0; iii < dataSource?.fields?.length; iii++) {
            const { title, isHidenColumn } = dataSource?.fields[iii];
            if (!isHidenColumn) resultHeader.push(title);
        }
        getHeaderFields &&
            typeof getHeaderFields === "function" &&
            getHeaderFields({ ...dataSource, header: resultHeader });

        setheader(resultHeader);

        return { ...dataSource, header: resultHeader };
    }, [dataSource]);

    const [filteredData, total] = useMemo(() => {
        if (!data || !Array.isArray(data)) return [];

        let searchResult = searchStr?.value
            ? data?.filter((row) => {
                  for (const key in row) {
                      if (Object.hasOwnProperty.call(row, key)) {
                          const element = row[key];

                          if (
                              searchStr.fields[`${key}`] &&
                              element
                                  ?.toLowerCase()
                                  ?.includes(
                                      `${searchStr?.value?.toLowerCase()}`
                                  )
                          ) {
                              return element
                                  ?.toLowerCase()
                                  ?.includes(
                                      `${searchStr?.value?.toLowerCase()}`
                                  );
                          }
                      }
                  }
              })
            : data;

        const fltr = searchResult.filter((row) => {
            let result = true;
            if (Object.keys(filtersValue).length === 0 && !searchStr) {
                result = true;
            }
            if (Object.keys(filtersValue).length > 0) {
                for (const key in filtersValue) {
                    if (Object.hasOwnProperty.call(filtersValue, key)) {
                        const element = filtersValue[key];
                        if (Array.isArray(element)) {
                            const [dt1, dt2] = element;
                            result =
                                result &&
                                dt1 < row[`${key}`] &&
                                row[`${key}`] < dt2;
                        } else {
                            result = result && element === row[`${key}`];
                        }
                    }
                }
            }
            return result;
        });

        getFilteredData &&
            typeof getFilteredData === "function" &&
            getFilteredData(fltr);

        const tot = [""];
        let filterFieldWithoutHidenColum = fields?.filter(
            ({ isHidenColumn }) => !isHidenColumn
        );
        for (let iii = 0; iii < fltr.length; iii++) {
            const row = fltr[iii];
            for (
                let xxx = 0;
                xxx < filterFieldWithoutHidenColum.length;
                xxx++
            ) {
                const { type, no_sum, name, field } =
                    filterFieldWithoutHidenColum[xxx];
                if (xxx === 0) continue;
                const iv = xxx;
                switch (type) {
                    case "string":
                        if (!tot[iv]) tot[iv] = "";
                        break;
                    case "time":
                        if (!tot[iv]) tot[iv] = "";
                        break;
                    case "number":
                        if (!no_sum) {
                            const val = !!field
                                ? (row[name] && row[name][field]) || 0
                                : row[name];
                            if (!tot[iv]) tot[iv] = 0;
                            tot[iv] += val;
                        } else {
                            if (!tot[iv]) tot[iv] = "";
                        }
                        break;
                    default:
                        if (!tot[iv]) tot[iv] = "";
                        break;
                }
            }
        }
        const [, ...rest] = tot;
        return [fltr, ["Grand Total", ...rest]];
    }, [data, fields, filtersValue, searchStr]);

    useEffect(() => {
        const strHeader = [];
        for (let iii = 0; iii < fields.length; iii++) {
            const cell = fields[iii];
            const {
                type,
                filter_string,
                field,
                name,
                layout_order,
                filter_range_date,
                filter_time_date,
                format,
                title,
            } = cell;
            if (type !== "string" && type !== "unix_time") {
                continue;
            } else if (filter_string) {
                strHeader.push({
                    header: title,
                    filter_string,
                    index: iii,
                    filter_data: "",
                    field,
                    name,
                    layout_order,
                    type,
                });
                continue;
            } else if (filter_range_date) {
                strHeader.push({
                    header: title,
                    filter_string,
                    index: iii,
                    filter_data: "",
                    field,
                    name,
                    layout_order,
                    filter_range_date,
                    format,
                    type,
                });
                continue;
            } else if (filter_time_date) {
                strHeader.push({
                    header: title,
                    filter_string,
                    index: iii,
                    filter_data: "",
                    field,
                    name,
                    layout_order,
                    filter_time_date,
                    format,
                    type,
                });
                continue;
            }

            const filter_data = [];
            for (let iii = 0; iii < data.length; iii++) {
                const row = data[iii];
                const val = getValue(row, cell);
                if (filter_data.indexOf(val) < 0) filter_data.push(val);
            }
            strHeader.push({
                header: title,
                name,
                filter_string,
                index: iii,
                filter_data,
                field,
                layout_order,
                filter_range_date,
                type,
            });
        }
        setFilterLists(strHeader);
    }, [header, fields, data]);

    useEffect(() => {
        onDataChange &&
            typeof onDataChange === "function" &&
            onDataChange(filteredData);
    }, [filteredData]);

    // Calculate the start and end index for the current page
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Get the data to display on the current page
    const pagedData = filteredData?.slice(startIndex, endIndex);

    const serialNumber = (idx, currPage, start) => {
        return currPage === 1 ? idx + 1 : idx + 1 + start;
    };

    const getValue = (row, cell) => {
        const { type, name, field, format } = cell;
        const val = !!field
            ? (row[name] && row[name][field]) || "-"
            : row[name];
        switch (type) {
            case "string":
                return val;
            case "time":
                return !!val ? moment(val).format(format) : "-";
            case "unix_time":
                return !!val ? moment.unix(val).format(format) : "-";
            case "number":
                return numeral(val).format("0,000");
            default:
                return val;
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "inherit",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                }}
            ></div>

            <div style={{ overflow: "auto", maxHeight: 500, marginTop: 12 }}>
                <table
                    className="table-0"
                    style={
                        {
                            // width: "100%",
                            // display: "block",
                        }
                    }
                >
                    <thead style={{ top: 0, position: "sticky" }}>
                        <tr>
                            {/* {withSerialNumber && <th style={{ textAlign: "center" }}>No</th>} */}
                            {header &&
                                Array.isArray(header) &&
                                header.map((head, idx) => {
                                    return (
                                        <th
                                            style={{
                                                // minWidth: 100,
                                                whiteSpace: "nowrap",
                                                padding: "8px 12px",
                                            }}
                                            key={idx}
                                        >
                                            {head}
                                        </th>
                                    );
                                })}
                        </tr>
                    </thead>
                    {pagedData?.length > 0 && (
                        <tbody>
                            {pagedData &&
                                Array.isArray(pagedData) &&
                                pagedData.map((row, idx) => (
                                    <tr key={idx}>
                                        {/* {withSerialNumber && (
                      <td className="data-number">
                        {serialNumber(idx, currentPage, startIndex)}
                      </td>
                    )} */}
                                        {fields &&
                                            Array.isArray(fields) &&
                                            fields.map((cell, idx2) => {
                                                const {
                                                    isHidenColumn,
                                                    format,
                                                } = cell;

                                                const currValue = getValue(
                                                    row,
                                                    cell
                                                );

                                                return (
                                                    !isHidenColumn && (
                                                        <td
                                                            key={`${idx}__${idx2}`}
                                                            style={{
                                                                textAlign:
                                                                    cell.align ||
                                                                    "center",
                                                                whiteSpace:
                                                                    "nowrap",
                                                            }}
                                                        >
                                                            {format &&
                                                            typeof format ===
                                                                "function"
                                                                ? format(
                                                                      row,
                                                                      currValue
                                                                  )
                                                                : currValue}
                                                        </td>
                                                    )
                                                );
                                            })}
                                    </tr>
                                ))}
                        </tbody>
                    )}
                    {/* <tfoot>
            <tr>
              {total &&
                Array.isArray(total) &&
                total.map((cell, idx2) => (
                  <td
                    key={`SUM_${idx2}`}
                    style={{
                      textAlign:
                        typeof cell === "string" &&
                        cell?.toLowerCase() === "grand total"
                          ? "left"
                          : "right",
                      fontWeight: "bold",
                    }}
                  >
                    {typeof cell === "number"
                      ? numeral(cell).format("0,000")
                      : cell}
                  </td>
                ))}
            </tr>
          </tfoot> */}
                </table>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 16,
                }}
            >
                <div style={{ fontWeight: "bold" }}>
                    Total Row :{" "}
                    {numeral(filteredData?.length).format("0,000") || 0}
                </div>
                <Pagination
                    current={currentPage}
                    total={filteredData.length}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onShowSizeChange={(curr, size) =>
                        handlePageSizeChange(curr, size)
                    }
                    totalRows={filteredData?.length || 0}
                    pageSizeOptions={[10, 25, 50]}
                />
            </div>
        </div>
    );
};
