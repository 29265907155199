import React from "react";
import { useDashboardChart } from "../../../../../../../hooks/useDashboardChart";
import { Progress } from "antd";
import { useParams } from "react-router-dom";
import Typography from "antd/lib/typography/Typography";
import Config from "../../../../../../../config/env";
import { createUseStyles } from "react-jss";

function getInitials(name) {
  const words = name.split(" ");
  const initials =
    words.length >= 2 &&
    words.map((word) => word.charAt(0).toUpperCase()).join("");

  return initials;
}

const useStyles = createUseStyles({
  container: {
    "&::-webkit-scrollbar": {
      width: "0", // Hide the scrollbar width
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide the scrollbar thumb
    },
    "&:hover::-webkit-scrollbar": {
      width: "8px", // Show scrollbar width on hover
    },
    "&:hover::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc", // Show scrollbar thumb on hover
      borderRadius: "4px",
    },
  },
});
const { primaryColor, secondaryColor } = Config;

const ListAirlines = ({ filterPage }) => {
  const { idAirport } = useParams();
  const classes = useStyles();

  const directory = (code) => {
    return `/assets/carrier/${code}.png`;
  };

  const [detailAirline, loadingDetailAirline] = useDashboardChart({
    ...filterPage,
    airport_id: idAirport,
    type: "airline",
    routes: "/airport-detail/otp",
  });

  return (
    <div
      style={{
        // border: "1px solid red",
        height: "100%",
        padding: 8,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        style={{
          textAlign: "center",
          fontSize: 24,
          fontWeight: "bold",
          color: primaryColor,
          marginBottom: 20,
        }}
      >
        AIRLINES
      </Typography>
      <div
        className={classes.container}
        style={{ width: "100%", overflowX: "hidden", overflowY: "auto" }}
      >
        {!!detailAirline &&
          detailAirline?.map((item, idx) => {
            const initial = getInitials(item.name);
            return (
              <div
                key={idx}
                style={{
                  width: "95%",
                  display: "flex",
                  //   border: "1px solid red",
                  gap: 24,
                  marginBottom: 10,
                  padding: "8px",
                  background: "#ffffff",
                  borderRadius: 8,
                }}
              >
                <div
                  style={{
                    width: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // border: "1px solid red",
                  }}
                >
                  <img
                    src={
                      !!item?.code
                        ? directory(item?.code?.toLowerCase())
                        : item?.code === "UN"
                        ? "/assets/carrier/no-image.png"
                        : "/assets/carrier/no-image.png"
                    }
                    style={{ width: "100%" }}
                    title={item.name}
                    alt={initial}
                  />
                </div>
                <div style={{ width: "85%" }}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      //   color: secondaryColor,
                    }}
                  >
                    {item?.name}
                  </Typography>
                  <Progress
                    // strokeColor={{
                    //   "0%": primaryColor,
                    //   "100%": secondaryColor,
                    // }}
                    percent={item?.otp?.toFixed(2)}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ListAirlines;
