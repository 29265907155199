// Menggelapkan warna dengan faktor gelap yang diberikan
function redenColor(color, factor) {
  // Mendapatkan komponen RGB
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);

  // Mengurangi kecerahan dengan faktor yang diberikan
  r = Math.round(r * (1 - factor * 0.0));
  g = Math.round(g * (1 - factor * 0.5)); // Adjust the green component to prevent the color from turning completely red
  b = Math.round(b * (1 - factor * 0.5)); // Adjust the blue component similarly

  // Mengonversi kembali ke representasi HEX
  const hex = `#${(r < 16 ? "0" : "") + r.toString(16)}${
    (g < 16 ? "0" : "") + g.toString(16)
  }${(b < 16 ? "0" : "") + b.toString(16)}`;
  return hex;
}

function getColorByRange(value) {
  return redenColor("#F9D923", value / 100);
  //   if (value >= 0 && value <= 30) {
  //     const factor = ((30 - value) / 30) * (40 / 100);
  //     // Return color for range 0-100
  //   } else if (value >= 31 && value <= 60) {
  //     const factor = ((60 - value) / 30) * (40 / 100);
  //     // Return color for range 101-200
  //     return lightenColor("#36AE7C", factor);
  //   } else if (value >= 61 && value <= 120) {
  //     const factor = ((120 - value) / 30) * (40 / 100);

  //     // Return color for range 201-300
  //     return lightenColor("#F9D923", factor);
  //   } else {
  //     const factor = ((30 - value) / (max - 121)) * (40 / 100);
  //     // Return color for range 501 and above
  //     return lightenColor("#EB5353", factor);
  //   }
}

export default getColorByRange;
