import React, { useState, useEffect } from "react";
import moment from "moment";
import { createUseStyles } from "react-jss";
import { DatePicker, Input, Form, Row, Col } from "antd";
import AutoComplete from "../../../../../../components/autocomplete/Online";
import ButtonSearch from "../../components/ButtonSearch";
import AsyncSelect from "../../../../../../components/autocomplete/AsyncSelect";

const useStyles = createUseStyles({
    autoCompleteStyle: {
        "& .css-13cymwt-control": {
            borderRadius: "8px",
        },
    },
});

const Search = ({ onSearchCallback }) => {
    const classes = useStyles();
    const [model, setModel] = useState({
        name: "",
        createdAt: moment(),
        screen_name: null,
    });

    useEffect(() => {
        const val = {
            name: "",
            createdAt: {
                $gte: moment().startOf("day").toDate(),
                $lt: moment().endOf("day").toDate(),
            },
        };
        onSearchCallback &&
            typeof onSearchCallback === "function" &&
            onSearchCallback(val);
    }, []);

    return (
        <Form
            autoComplete="off"
            initialValues={model}
            labelCol={{ span: 5 }}
            onFinish={(val) => {
                let { screen_name, createdAt, ...value } = val;
                let newModel = { ...value };
                const m = moment(createdAt).startOf("days");

                if (!!screen_name) {
                    newModel = { ...newModel, screen_name };
                }
                if (!!createdAt) {
                    newModel = {
                        ...newModel,
                        createdAt: {
                            $gte: m.toDate(),
                            $lt: m.endOf("days").toDate(),
                        },
                    };
                }
                onSearchCallback &&
                    typeof onSearchCallback === "function" &&
                    onSearchCallback(newModel);
            }}
        >
            <Row gutter={[16, 16]} style={{ justifyContent: "right" }}>
                <Col lg={5}>
                    <Form.Item name="createdAt">
                        <DatePicker
                            allowClear={false}
                            format={"DD-MMM-YYYY"}
                            size="large"
                            className="date-picker-new"
                            popupClassName="date-picker-popup-new"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col lg={5}>
                    <Form.Item name={"name"}>
                        <Input
                            size="large"
                            placeholder="Name"
                            allowClear
                            autoFocus
                            className="input-primary"
                        />
                    </Form.Item>
                </Col>
                <Col lg={5}>
                    <Form.Item name="screen_name">
                        <AsyncSelect
                            size="large"
                            url={"api/v1/matched/commands"}
                            filterOption={(input, opt) =>
                                (opt?._id ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            getLabel={(val) => (
                                <span style={{ textTransform: "capitalize" }}>
                                    {val?._id?.split("-")?.join(" ")}
                                </span>
                            )}
                            getValue={(val) => val?._id}
                            className="input-primary"
                        />
                    </Form.Item>
                </Col>
                <Col lg={5}>
                    <Form.Item>
                        <ButtonSearch
                            title="Cari Kecocokan"
                            htmlType="submit"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default Search;
