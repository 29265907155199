import React from "react";
import { useSelector } from "react-redux";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import PanelChartAirline from "./MaskapaiKargoChart";

const CargoAirlineChart = () => {
  const { filterPage } = useSelector((item) => item.apps);
  const [data, loading] = useDashboardChart({
    ...filterPage,
    type: "airline",
    doSend: true,
    routes: "/cargov2",
  });
  return <PanelChartAirline datav2={data} loadingDatav2={loading} />;
};

export default CargoAirlineChart;
