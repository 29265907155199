import { magenta } from "@ant-design/colors";
import {
  Avatar,
  Divider,
  List,
  Typography,
  Input,
  Row,
  Col,
  DatePicker,
} from "antd";
import moment from "moment";
import React, { Fragment, useMemo, useState } from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { RiFileSettingsLine, RiSettings3Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import BaseListPage from "../../components/BaseListPage";
import { getUrlMatchedType, onLimitChar, renderRuleType } from "../../library";
import AutoComplete from "../../../../components/autocomplete/Online";
import { useFontSize } from "../../../../hooks/useDimension";

const { Search } = Input;

export default function PassengersMatched() {
  const fontSize = useFontSize();
  const [searchApi, setSearchApi] = useState({
    name: "",
    selectedDate: moment(),
    screen_name: "",
    createdAt: {
      $gte: moment().startOf("day").utc(),
      $lt: moment().endOf("day").utc(),
    },
  });

  const formatedSearch = useMemo(() => {
    let { selectedDate, screen_name, ...rest } = searchApi;
    const m = moment(selectedDate).startOf("days");
    if (!!screen_name) rest = { ...rest, screen_name: screen_name._id };

    if (!!selectedDate) {
      // const created = {
      //   $gte: m.toDate(),
      //   $lt: m.endOf("days").toDate(),
      // };
      rest = {
        ...rest,
        createdAt: {
          $gte: m.toDate(),
          $lt: m.endOf("days").toDate(),
        },
      };
    }
    return rest;
  }, [searchApi]);

  return (
    <BaseListPage
      paginationUrl={"api/v1/matched/pagination"}
      fixedPagination
      searchApiProps={formatedSearch}
      FilterComponent={({ resetCurrentPage }) => {
        return (
          <Row className="p-4" gutter={[8, 8]}>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Search
                size="large"
                placeholder="name"
                allowClear
                onSearch={(val) => {
                  setSearchApi({ ...searchApi, name: val });
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={12} xl={12}>
              <Row gutter={[8, 8]}>
                <Col xs={12} sm={12} md={12} xl={12}>
                  <DatePicker
                    style={{ width: "100%" }}
                    format={"DD-MMM-YYYY"}
                    value={moment(searchApi?.selectedDate)}
                    onChange={(val, valStr) => {
                      setSearchApi({ ...searchApi, selectedDate: val });
                    }}
                    size="large"
                    allowClear={false}
                    disabledDate={(current) => {
                      return current && current > moment();
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} xl={12}>
                  <AutoComplete
                    getKey={(opt) => opt._id}
                    getSelectedOptions={(opt, val) => opt._id === val._id}
                    getValues={(val) => val._id}
                    uri="api/v1/matched/commands"
                    onChange={(val) => {
                      setSearchApi({ ...searchApi, screen_name: val });
                    }}
                    value={searchApi.screen_name}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        );
      }}
      renderItem={(item) => {
        const {
          passenger_name,
          db_name,
          related_station,
          screen_name,
          rule_id,
          obj_id,
          vessel_station,
        } = item || {
          passenger_name: "",
          db_name: "",
          related_station: "",
          screen_name: "",
          rule_id: "",
          obj_id: "",
          vessel_station: "",
        };
        const { rule_type } = rule_id || { rule_type: "" };

        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: magenta[6],
                  }}
                  icon={renderRuleType(rule_type)}
                />
              }
              title={
                <Link to={getUrlMatchedType(db_name, obj_id)}>
                  <div>
                    <Typography.Title style={{ fontSize: fontSize.md }}>
                      {passenger_name?.toUpperCase()}
                    </Typography.Title>
                  </div>
                </Link>
              }
              description={
                <Fragment>
                  <div
                    className="rowslist-inline-flex"
                    style={{ margin: "0px 8px" }}
                  >
                    <MdOutlineLocationOn />

                    <span>
                      {onLimitChar(
                        !!related_station?.name
                          ? related_station?.name
                          : vessel_station,
                        16
                      )}
                    </span>

                    {related_station?.code ? (
                      <span>({related_station?.code})</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <Divider className="m-4" type="vertical" />
                  <div
                    className="rowslist-inline-flex"
                    style={{ margin: "0px 8px" }}
                  >
                    <RiSettings3Line />
                    <div>
                      {screen_name} {`${!!db_name ? `(${db_name})` : ""}`}
                    </div>
                  </div>
                </Fragment>
              }
            />
          </List.Item>
        );
      }}
    />
  );
}
