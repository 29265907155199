import { Button, Dropdown, Input, Modal, Typography } from "antd";
import React from "react";
import TableSection from "./component/TableV2";
import FilterData from "./component/FilterV2";
import { useState } from "react";
import { useMemo } from "react";
import { FaRegFileExcel } from "react-icons/fa";
import { ExportExcel } from "./component/excel_exporterv2";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    expButton: {
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",
    },
});

const ModalFilters = ({ isOpen, onClose, filtersProps, paramsFilter }) => {
    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            width={"60%"}
            title="Filters"
            footer={null}
        >
            <FilterData
                {...filtersProps}
                onClose={onClose}
                paramsFilter={paramsFilter}
            />
        </Modal>
    );
};

const TableForm = ({
    printHeaderReport,
    onDataChange,
    dataSource,
    repType,
    onBeforeFilter,
    printPeriode,
}) => {
    const [filterLists, setFilterLists] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filtersValue, setFiltersValue] = useState({});
    const [isOpenFilters, setOpenFilters] = useState(false);
    const [headerField, setHearderField] = useState({
        header: null,
        fields: null,
    });
    const classes = useStyles();

    const namesSearchFiltersString = useMemo(() => {
        let result = {};
        for (let iii = 0; iii < headerField?.fields?.length; iii++) {
            const { name, filter_string } = headerField?.fields[iii];
            if (filter_string) {
                result[`${name}`] = filter_string;
            }
        }

        return result;
    }, [headerField?.fields]);

    const [searchStr, setSearchStr] = useState({
        fields: namesSearchFiltersString,
        value: "",
    });

    const titleFilters = useMemo(() => {
        let result = [];
        for (let iii = 0; iii < headerField?.fields?.length; iii++) {
            const { name, title } = headerField?.fields[iii];
            for (const key in filtersValue) {
                if (Object.hasOwnProperty.call(filtersValue, key)) {
                    if (name !== key) {
                        continue;
                    }
                    result.push({ name: title, value: filtersValue[key] });
                }
            }
        }
        return result;
    }, [filtersValue, headerField?.fields]);

    const ExportBtn = () => {
        return (
            <div
                className={classes.expButton}
                onClick={() => {
                    ExportExcel(
                        filteredData,
                        headerField.header,
                        headerField.fields?.filter(
                            ({ isHidenColumn }) => !isHidenColumn
                        ),
                        repType,
                        titleFilters,
                        printHeaderReport,
                        printPeriode
                    );
                }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid",
                    padding: 8,
                    gap: 8,
                    cursor: "pointer",
                }}
            >
                <Typography
                //   type="text"
                >
                    Export to Excel
                </Typography>
                <FaRegFileExcel color="green" />
            </div>
        );
    };

    return (
        <div>
            <div>
                <div
                    style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "center",
                        // border: "1px solid red",
                        flex: "end",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        {/* <Input.Search
              placeholder="search ..."
              onSearch={(e) =>
                setSearchStr({
                  ...searchStr,
                  fields: namesSearchFiltersString,
                  value: e,
                })
              }
            /> */}
                    </div>
                    <div>
                        <ExportBtn />
                    </div>
                </div>
                <TableSection
                    dataSource={dataSource}
                    setFilterLists={setFilterLists}
                    onDataChange={onDataChange}
                    filtersValue={filtersValue}
                    getFilteredData={(rows) => setFilteredData(rows)}
                    getHeaderFields={(rows) => setHearderField(rows)}
                    searchStr={searchStr}
                />
            </div>
            <ModalFilters
                isOpen={isOpenFilters}
                onClose={() => setOpenFilters(!isOpenFilters)}
                filtersProps={{
                    lists: filterLists,
                    idReport: `${repType}`,
                    filtersValue,
                    onFilterValueChange: (fval) => {
                        const newVal =
                            onBeforeFilter &&
                            typeof onBeforeFilter === "function"
                                ? onBeforeFilter(fval)
                                : fval;
                        setFiltersValue(newVal);
                    },
                }}
            />
        </div>
    );
};

export default TableForm;
