import React, { useState } from "react";
import DashboardChart from "../../DashboardChartV2";
import { useFontSizeV2 } from "../../../../../../../../hooks/useDimension";
import utilsPaxs from "../../../../../../features/PanelChart/Paxs/utilsPaxs";
import customFormat from "../../../../../../../../helper/customFormat";
import moment from "moment";
import SelectAirport from "../../../../../../features/Form/SelectAirport";
import RadioPeriodic from "../../../../../../features/Form/RadioPeriodic";

const getOptions = (dataSet, fontSize, filters) => {
  return {
    dataset: dataSet,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "12%",
      containLabel: true,
      top: "10%",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        textStyle: {
          fontSize: fontSize.sm,
        },
        formatter:
          filters.periodic === "daily"
            ? (params) => moment(params).format("DD MMM")
            : (params) => `${params}`,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => customFormat(params),
      },
    },
    series: [
      {
        name: `Domestic`,
        type:
          filters.periodic === "daily" || filters.periodic === "all"
            ? "line"
            : "bar",
        symbol: "none",
        barGap: 0,
        datasetIndex: 0,
        barWidth: 16,
        encode: { x: "label", y: "domestic" },
        emphasis: {
          focus: "series",
        },
        color: "#023047",
        itemStyle: {
          color: "#023047",
        },
        areaStyle: {
          color: "#02304720",
        },
        lineStyle: {
          width: 3,
        },
      },
      {
        name: `International In`,
        type:
          filters.periodic === "daily" || filters.periodic === "all"
            ? "line"
            : "bar",
        symbol: "none",
        barGap: 0,
        barWidth: 16,
        datasetIndex: 0,
        encode: { x: "label", y: "international_in" },
        emphasis: {
          focus: "series",
        },
        color: "#FFB703",
        lineStyle: {
          width: 3,
        },
        itemStyle: {
          color: "#FFB703",
        },
        areaStyle: {
          color: "#FFB70320",
        },
      },
      {
        name: `International Out`,
        type:
          filters.periodic === "daily" || filters.periodic === "all"
            ? "line"
            : "bar",
        symbol: "none",
        barGap: 0,
        barWidth: 16,
        datasetIndex: 0,
        encode: { x: "label", y: "international_out" },
        emphasis: {
          focus: "series",
        },
        color: "#fb8500",
        lineStyle: {
          width: 3,
        },
        itemStyle: {
          color: "#fb8500",
        },
        areaStyle: {
          color: "#fb850020",
        },
      },
    ],
  };
};

const PaxChart = ({ data, loading, filterPage }) => {
  const [filters, setFilters] = useState({
    airline: "all",
    periodic: "daily",
  });
  const fontSize = useFontSizeV2();
  const [dataset, datatable, airlineList] = utilsPaxs.getDataPaxByAirline(
    data,
    filters.airline,
    filters.periodic
  );

  return (
    <DashboardChart
      title={"Penumpang"}
      //   loading={loading}
      printHeader={`Penumpang ${filters.airline} Report`}
      printPeriode={`${filters.periodic} ${filterPage.year}`}
      repType={`Penumpang ${filters.airline} Report`}
      //   title={"Penumpang"}
      dataSource={datatable}
      data={getOptions(dataset, fontSize, filters)}
      more={
        <SelectAirport
          onChange={(value) =>
            setFilters((state) => ({ ...state, airline: value }))
          }
          value={filters.airline}
          dataSource={airlineList}
        />
      }
      chartPanelRender={(ChartPanel) => {
        return (
          <div style={{ padding: 12, height: "100%" }}>
            <RadioPeriodic
              value={filters.periodic}
              onChange={(value) =>
                setFilters((state) => ({
                  ...state,
                  periodic: value,
                }))
              }
            />
            {ChartPanel}
          </div>
        );
      }}
    />
  );
};

export default PaxChart;
