import React from "react";

export default function IcPlane({ className, size = 22, color }) {
  return (
    <div style={{ transform: "rotate(-30deg)" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size + 1}
        className={className}
        viewBox="0 0 22 23"
        fill={color}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6668 10.0074H18.3335C19.346 10.0074 20.1668 10.8282 20.1668 11.8407C20.1668 12.8532 19.346 13.674 18.3335 13.674H14.6668L11.0002 20.0907H8.25016L10.0835 13.674H6.41683L4.5835 15.5074H1.8335L3.66683 11.8407L1.8335 8.17403H4.5835L6.41683 10.0074H10.0835L8.25016 3.5907H11.0002L14.6668 10.0074Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6668 10.0074H18.3335C19.346 10.0074 20.1668 10.8282 20.1668 11.8407C20.1668 12.8532 19.346 13.674 18.3335 13.674H14.6668L11.0002 20.0907H8.25016L10.0835 13.674H6.41683L4.5835 15.5074H1.8335L3.66683 11.8407L1.8335 8.17403H4.5835L6.41683 10.0074H10.0835L8.25016 3.5907H11.0002L14.6668 10.0074Z"
          stroke="currentColor"
          strokeOpacity="0.2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
