import React from "react";
import { useSelector } from "react-redux";
import PenerbanganChart from "./PenerbanganChart";
import CargoAirportChart from "./Cargo/Airport";
import PaxForeignCitizen from "./Pax/ForeignCitizen";
import AirportCargo from "./Airport/Cargo";
import FlightAirline from "./Flight/Airline";

const FourthCharts = () => {
    const {
        dashboardState: { summaryCard },
    } = useSelector((item) => item.apps);

    return renderContent(summaryCard)
};

const renderContent = (summary) => {
    switch(summary){
        case "cargo": return <CargoAirportChart />
        case "airport": return <AirportCargo />
        case "pax": return <PaxForeignCitizen />
        case "flight": return <FlightAirline />
        default : return <PenerbanganChart />
    }
}

export default FourthCharts;
