import FlightSchedule from '../pages/flight_schedule';
import FlightDetail from '../pages/flight_schedule/detail';
import PnrFsPage from '../pages/flight_schedule/pnr';
import CargoPage from '../pages/flight_schedule/cargo';
import PaxFsPage from '../pages/flight_schedule/pax';
import ReportPage from '../pages/flight_schedule/report';
import FlightMatch from '../pages/flight_schedule/detail_matched';
import second from '../pages/flight_schedule'
const routes = [
    {
        path:'/flight/report',
        exact:true,
        Component:ReportPage
    },
    {
        path:'/flight/schedule/type/:flight_type/:direction',
        exact:true,
        Component:FlightSchedule
    },
    {
        path:'/flight/schedule/match/:id',
        exact:true,
        Component:FlightMatch
    },
    {
        path:'/flight/schedule/:direction/:id',
        exact:true,
        Component:FlightDetail
    },
    {
        path:'/flight/schedule/:direction/pnr/:id',
        exact:true,
        Component:PnrFsPage
    },
    {
        path:'/flight/schedule/:direction/passengers/:id',
        exact:true,
        Component:PaxFsPage
    },
    {
        path:'/flight/schedule/:direction/cargo/:id',
        exact:true,
        Component:CargoPage
    },
]

export default routes;