import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import PanelChartDomestic from "./DomestikChart";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const CargoDomesticChart = () => {
  const {
    filterPage,
    dashboardState,
  } = useSelector((item) => item.apps);
  const [dataDomv2, loadingDomv2] = useDashboardChart({
    ...filterPage,
    type: "cargov2",
    doSend:
      dashboardState.chart === "all" || dashboardState.chart === "domestik",
    routes: "/cargov2",
  });

  const dataCargoDom = useMemo(() => getDataDom(dataDomv2), [dataDomv2, filterPage]);

  return (
    <PanelChartDomestic
      datatable={dataDomv2}
      data={dataCargoDom}
      loading={loadingDomv2}
    />
  );
};

const getDataDom = (dataDom) =>  {
  const dataRef = !!dataDom ? dataDom : [];
  const dom = dataRef?.map(({ _id, domestic }) => ({
    name: _id,
    curr: domestic,
  }));
  return {
    dom,
  };
}

export default CargoDomesticChart;
