import React, { useState, useMemo } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import MainPage from "../../../../components/MainPage";
import SearchComp from "./SearchV2";

const Setting = () => {
    const [search, setSearch] = useState({ name: "" });
    const [editData, setEditData] = useState(false);
    const COLUMNS = [
        {
            title: "name",
            dataIndex: "name",
            render: (val, row) => (
                <strong
                    className="text-primary-new"
                    style={{ cursor: "pointer" }}
                    onClick={() => setEditData(!editData ? row : false)}
                >
                    {val}
                </strong>
            ),
        },
        {
            title: "rule_type",
            dataIndex: "rule_type",
        },
        {
            title: "origin",
            dataIndex: "origin",
            render: (val) => val?.code,
        },
        {
            title: "dest",
            dataIndex: "dest",
            render: (val) => val?.code,
        },
        {
            title: "nationality",
            dataIndex: "nationality",
        },
        {
            title: "gender",
            dataIndex: "gender",
        },
        {
            title: "ages",
            dataIndex: "first_age",
            render: (v, rec) => rec.last_age > 0 && `${v} - ${rec?.last_age}`,
        },
        {
            title: "values",
            dataIndex: "min_value",
            render: (v, rec) => `${v} - ${rec.max_value}`,
        },
        {
            title: "created_by",
            dataIndex: "createdBy",
            render: (val) => val?.username,
        },
        {
            title: "time",
            dataIndex: "createdAt",
            render: (val) => moment(val).format("DD-MMM-YYYY HH:mm"),
        },
    ];
    return (
        <MainPage
            url={"api/v1/rules/pagination"}
            searchValue={search}
            columns={COLUMNS}
            searchComponent={
                <SearchComp
                    onSearchCallback={(searchValue) => {
                        setSearch({
                            ...searchValue,
                            timestamp: moment().unix(),
                        });
                    }}
                    setEditData={setEditData}
                    editData={editData}
                />
                // <Search
                //   onFormRefresh={() => {
                //     setSearch({ ...search, timestamp: moment().unix() });
                //   }}
                //   onSearchCallback={(searchValue) => {
                //     setSearch({ ...searchValue, timestamp: moment().unix() });
                //   }}
                // />
            }
        />
    );
};

export default Setting;
