import "flag-icons/css/flag-icons.min.css";
import React, { Fragment } from "react";
import ctyData, { lookup } from "country-data";
import { Typography } from "antd";
import { useMemo } from "react";

export default ({
    country_code,
    square,
    withCountry,
    flexGrow,
    width,
    countryFormat,
    countryWidth,
    countryFontColor,
    getCaption,
    alternate,
}) => {
    // const country=ctyData.countries[country_code.toUpperCase()]?.name || '';
    const [alpha2, country] = useMemo(() => {
        if (!country_code) return [false, "Unknown"];
        if (country_code.length > 3) {
            const c =
                country_code.substring(0, 1) +
                country_code.substring(1).toLowerCase();
            const [cty] = lookup.countries({ name: c });
            // console.log({cty})
            const country =
                countryFormat === "alpha2"
                    ? cty?.alpha2
                    : countryFormat === "alpha3"
                    ? cty?.alpha3
                    : cty?.name;
            return [cty?.alpha2, country];
        }
        const cty = ctyData.countries[country_code.toUpperCase()];
        const country =
            countryFormat === "alpha2"
                ? cty?.alpha2
                : countryFormat === "alpha3"
                ? cty?.alpha3
                : typeof getCaption === "function"
                ? getCaption(cty)
                : cty?.name;
        return [cty?.alpha2, country];
    }, [country_code, countryFormat]);
    return (
        !!country_code && (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    flexGrow,
                    width,
                }}
            >
                {(alpha2 && (
                    <Fragment>
                        <span
                            style={{ width: 24 }}
                            className={`fi fi-${alpha2.toLowerCase()} ${
                                square ? "fis" : ""
                            }`}
                            title={country_code}
                        ></span>
                        {withCountry && (
                            <Typography.Text
                                ellipsis
                                style={{
                                    width: countryWidth || 120,
                                    color: countryFontColor,
                                }}
                            >
                                {country}
                            </Typography.Text>
                        )}
                    </Fragment>
                )) || (
                    <Typography.Text
                        ellipsis
                        title={country_code}
                        style={{
                            width: countryWidth || 120,
                            color: countryFontColor,
                        }}
                    >
                        {country_code}
                    </Typography.Text>
                )}
            </div>
        )
    );
};
