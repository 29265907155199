import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
    MapContainer,
    TileLayer,
    Popup,
    CircleMarker,
    Circle,
    useMap,
    Marker,
    useMapEvents,
} from "react-leaflet";
import PopupMap from "./popup";
import { createUseStyles } from "react-jss";
import useWindowDimensions, { useFontSizeV2 } from "../../hooks/useDimension";
import createCustomMarkerIcon from "../../assets/icons/customIconMarker";
import getColorByRange from "../../helper/getColorByRange";

const useStyles = createUseStyles({
    mapContainer: {
        position: "relative",
        width: "80%",
        height: 0,
        paddingBottom: "calc(80% * 9/16)",
    },
});

const ComponentResize = () => {
    const map = useMap();
    useEffect(() => {
        const time = setTimeout(() => {
            map?.invalidateSize();
            return () => map.remove();
        }, 10);
        return () => clearTimeout(time);
    }, []);
    return null;
};

function ChangeView({ center, zoom }) {
    const [zoomLevel, setZoomLevel] = useState(zoom);
    const map = useMap();
    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });
    // console.log(zoomLevel);
    // map.setView(center, zoom);
    return null;
}

const FlightMap = ({
    data,
    mapCenter,
    state,
    dir,
    isMobile = false,
    apps,
    total,
    viewData,
    filterDetail,
    isCargo,
    customMarkRadius,
    customTooltip,
    isPlane = false,
    zoomInitial,
}) => {
    const classes = useStyles();
    const { width } = useWindowDimensions();
    const fontSize = useFontSizeV2();

    const bounds = [
        [-10, 95],
        [5, 140],
    ];
    const center = useMemo(() => {
        return !!mapCenter && mapCenter?.length !== 0
            ? mapCenter
            : // : [-2.5489, 118.0149];
              [-2.4833826, 117.8902853];
    }, [mapCenter]);

    // const customIcon = createCustomMarkerIcon();
    const maxRadius = 120000;

    const logaritRadius = (value) => {
        const radius =
            (Math.log(value + 1) / Math.log(1000000 + 1)) * maxRadius;
        return radius;
    };

    const zoomAdjust = useMemo(() => {
        if (zoomInitial) return zoomInitial;
        return width >= 1200 ? 4 : 3;
    }, [width]);

    function findDataWithHighestTotal(data) {
        let maxTotal = -Infinity;
        !!isPlane &&
            !!data &&
            data?.forEach((item) => {
                const total = item?.airlines?.length;
                if (total > maxTotal) {
                    maxTotal = total;
                }
            });
        return maxTotal;
    }

    //   console.log({ total: findDataWithHighestTotal(data) });

    return (
        <div
            style={{
                width: "100%",
                padding: "8px 0px",
                height: "100%",
                flexGrow: 1,
            }}
            className={classes.mapContainer}
        >
            <MapContainer
                bounds={bounds}
                attributionControl={false}
                scrollWheelZoom={false}
                center={center}
                zoom={zoomAdjust}
                style={{
                    width: "100%",
                    zIndex: 0,
                    height: isMobile ? "90%" : "100%",
                    borderRadius: 8,
                }}
            >
                <ChangeView zoom={zoomAdjust} />
                <ComponentResize />
                <TileLayer
                    attribution="Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                />
                {!!data &&
                    data.map((item, index) => {
                        const sum = !!isPlane ? item.airlines?.length : 0;
                        const getColor = getColorByRange(sum);
                        const customIcon = createCustomMarkerIcon(
                            getColor,
                            fontSize.lg
                        );
                        return (
                            <Fragment key={index}>
                                {!!isPlane ? (
                                    <Marker
                                        position={[
                                            item?.lat === "-"
                                                ? 0
                                                : item?.lat || 0,
                                            item?.lon === "-"
                                                ? 0
                                                : item?.lon || 0,
                                        ]}
                                        icon={customIcon}
                                    >
                                        <Popup
                                            closeOnEscapeKey
                                            minWidth={240}
                                            maxWidth={280}
                                            className="popup-style"
                                        >
                                            {customTooltip ? (
                                                customTooltip(item)
                                            ) : (
                                                <PopupMap
                                                    isCargo={isCargo}
                                                    filterDetail={filterDetail}
                                                    viewData={viewData}
                                                    apps={apps}
                                                    dir={dir}
                                                    item={item}
                                                    state={state}
                                                    total={total}
                                                />
                                            )}
                                        </Popup>
                                        {/* You can add popup content here */}
                                    </Marker>
                                ) : (
                                    <Circle
                                        center={[
                                            item?.lat === "-"
                                                ? 0
                                                : item?.lat || 0,
                                            item?.lon === "-"
                                                ? 0
                                                : item?.lon || 0,
                                        ]}
                                        pathOptions={{ fillColor: "blue" }}
                                        radius={
                                            customMarkRadius
                                                ? customMarkRadius(item)
                                                : !!item?.kilo &&
                                                  logaritRadius(item?.kilo)
                                        }
                                    >
                                        <Popup
                                            closeOnEscapeKey
                                            minWidth={240}
                                            maxWidth={280}
                                            className="popup-style"
                                        >
                                            {customTooltip ? (
                                                customTooltip(item)
                                            ) : (
                                                <PopupMap
                                                    isCargo={isCargo}
                                                    filterDetail={filterDetail}
                                                    viewData={viewData}
                                                    apps={apps}
                                                    dir={dir}
                                                    item={item}
                                                    state={state}
                                                    total={total}
                                                />
                                            )}
                                        </Popup>
                                    </Circle>
                                )}
                            </Fragment>
                        );
                    })}
            </MapContainer>
        </div>
    );
};

export default FlightMap;
