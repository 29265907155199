import React, { useState } from "react";
import DashboardChart from "../../DashboardChart";
import { useMemo } from "react";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions, {
  useFontSizeV2,
} from "../../../../../../../../hooks/useDimension";
import {
  forecastData,
  getFilterData,
} from "../../../../../../../../helper/getFilterData";
import customFormat from "../../../../../../../../helper/customFormat";
import { parseFormatedNumber } from "../../../../../../../../helper/parseFormatedNumber";
import {
  setDashboardState,
  setFilterPage,
} from "../../../../../../../../redux/reducer/apps";
import compareDates from "../../../../../../../../helper/compareDates";

const options = [
  { label: "Hari", value: "daily" },
  { label: "Bulan", value: "monthly" },
  { label: "Kwartal", value: "quarterly" },
  { label: "Semester", value: "semesterly" },
];

const DomestikChart = ({ data, loading, datatable }) => {
  const { dashboardState, filterPage } = useSelector((item) => item.apps);
  const [viewProd, setViewProd] = useState("daily");
  const dispatch = useDispatch();
  const [direct, setDirect] = useState("");
  const fontSize = useFontSizeV2();
  const { height } = useWindowDimensions();

  const [domData, fcData] = useMemo(() => {
    const { dom } = data;
    if (!data)
      return [
        { yearly: [], semesterly: [], quarterly: [], monthly: [], daily: [] },
        { yearly: [], semesterly: [], quarterly: [], monthly: [], daily: [] },
      ];
    const { daily, semesterly, monthly, quarterly } = getFilterData(
      dom?.sort(compareDates)
    );
    const fcDaily = forecastData(daily, "daily");
    return [
      {
        semesterly,
        quarterly,
        monthly,
        daily,
      },
      {
        daily: fcDaily,
      },
    ];
  }, [data, filterPage]);

  const optionsDomestik = getOptionsDomestik({
    fcData,
    domData,
    fontSize,
    viewProd,
    dashboardState,
  });

  useEffect(() => {
    dispatch(setFilterPage({ direct }));
    dispatch(
      setDashboardState({ direct, chart: direct === "" ? "all" : "domestik" })
    );
  }, [direct]);

  const dataTable = useMemo(() => getMemoDataTable(datatable), [data]);

  console.log({ datatable });

  return (
    <DashboardChart
      printHeader={"Domestik Report"}
      title={"Domestik"}
      repType={"Domestik"}
      //   loading={loading}
      dataSource={dataTable}
      direct={direct}
      setDirect={setDirect}
      view={
        dashboardState?.filterState === "all"
          ? dashboardState?.filterTime
          : viewProd
      }
      setView={setViewProd}
      isFilterComponent
      data={optionsDomestik}
      options={options}
    />
  );
};

const getOptionsDomestik = ({
  fcData,
  domData,
  fontSize,
  viewProd,
  dashboardState,
}) => {
  const min = Math.min(...domData[viewProd].map((item) => parseInt(item.curr)));
  const max = Math.max(...domData[viewProd].map((item) => parseInt(item.curr)));

  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },

    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "0%",
      right: "5%",
      bottom: "12%",
      containLabel: true,
      top: "10%",
    },
    xAxis: {
      type: "category",
      // boundaryGap: false,
      data:
        fcData[viewProd]?.length >= 100 && viewProd === "daily"
          ? fcData[viewProd]?.map((item) =>
              viewProd === "monthly"
                ? moment()
                    .month(item.name - 1)
                    .format("MMMM")
                : item.name
            )
          : domData[viewProd]?.map((item) =>
              viewProd === "monthly"
                ? moment()
                    .month(item.name - 1)
                    .format("MMMM")
                : item.name
            ),

      axisLabel: {
        textStyle: {
          fontSize: fontSize.sm,
        },
        formatter:
          viewProd === "daily"
            ? (params) => moment(params).format("DD MMM")
            : (params) => `${params}`,
        // interval: ,
      },
    },

    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => customFormat(params),
        textStyle: {
          fontSize: fontSize.sm - 2,
        },
      },
    },

    visualMap: [
      {
        show: false,
        type: "continuous",
        seriesIndex: 0,
        min: min,
        max: max,
        inRange: {
          color: ["#fb8500", "#023047"],
        },
      },
      {
        show: false,
        type: "continuous",
        seriesIndex: 0,
        min: min,
        max: max,
        inRange: {
          color: ["#fb8500", "#023047"],
        },
      },
    ],

    series: [
      {
        name: "Domestik",
        type: viewProd === "daily" ? "line" : "bar",
        barWidth: 10,
        barGap: 0,

        symbol:
          dashboardState?.filterTime !== "daily" || viewProd !== "daily"
            ? "circle"
            : "none",

        emphasis: {
          focus: "series",
        },
        data: domData[viewProd]?.map((item) => item.curr),
        markLine: {
          data: [
            {
              type: "average",
              itemStyle: {
                color: "#219ebc",
              },
              label: {
                formatter: (params) =>
                  `Avg. ${parseFormatedNumber(params.value)}`,
                textStyle: {
                  fontSize: 15,
                },
                position: "insideEndTop",
              },
            },
          ],
          silent: true,
        },
        areaStyle: {
          opacity: 0.3,
        },
      },
      {
        seriesIndex: 10,
        z: 0,
        name: "Forecase",
        type: "line",
        smooth: 0.1,
        areaStyle: {
          opacity: 0.3,
        },
        symbol: "none",
        showSymbol: false,
        data:
          fcData[viewProd]?.length >= 100
            ? fcData[viewProd]?.map((item) => item.value)
            : [],
        color: "#FFB703",
      },
    ],
  };
};

const getMemoDataTable = (dataTable) => {
  const datas =
    !!dataTable &&
    dataTable?.map((item) => {
      return {
        _id: item._id,
        domestic: item.domestic,
        dexport: item.dexport,
        dimport: item.dimport,
        total: item.dimport + item.dexport + item.domestic,
      };
    });

  return {
    data: datas,
    fields: columnTable,
    header: columnTable.map((item) => item.title),
  };
};

const columnTable = [
  {
    title: "Date",
    type: "string",
    name: "_id",
    align: "left",
  },
  {
    title: "Domestic",
    type: "string",
    name: "domestic",
    align: "right",
  },
  {
    title: "Export",
    type: "string",
    name: "dexport",
    align: "right",
  },
  {
    title: "Import",
    type: "string",
    name: "dimport",
    align: "right",
  },
  {
    title: "Total",
    type: "string",
    name: "total",
    align: "right",
  },
];

export default DomestikChart;
