import React, { useEffect, useMemo, useState, Fragment } from "react";
import { useAos } from "../../../hooks/useAos";
import { useFetch } from "../../../hooks/useFetch";
import { Table } from "antd";
import { useLanguage } from "../../../hooks/useLanguage";
import { useRef } from "react";
import { createUseStyles } from "react-jss";
import PaginationWIthCustomText from "./PaginationWIthCustomText";

const generateRandomString = () => {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 15);
    return `${timestamp}_${randomString}`;
};

// const useStyles = createUseStyles({
//     tableStyle: {
//         "&.ant-table.ant-table-small.ant-table-bordered": {
//             "& .ant-table-thead > tr > th, & .ant-table-tbody > tr > td, & .ant-table-tfoot > tr > td":
//                 {
//                     borderRadius: "10px", // Adjust the border radius as needed
//                 },
//         },
//         "& .ant-table-thead .ant-table-cell": {
//             textTransform: "uppercase",
//             color:
//         },
//     },
// });

const MainPage = ({
    url,
    searchComponent,
    searchValue,
    columns,
    onRowClick,
    formaterRow,
}) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [srcVal, setSrcVal] = useState(false);
    const timeout = useRef(null);
    const [defUrl, setDefUrl] = useState(false);
    // const classes = useStyles();

    // useEffect(() => {
    //     // if(!srcVal)srcVal;
    //     if (!!srcVal) {
    //         if (!!timeout.current) clearTimeout(timeout.current);
    //         timeout.current = setTimeout(() => {
    //             const u = `${url}?search=${JSON.stringify(
    //                 srcVal
    //             )}&page=${page}&perPage=${perPage}`;
    //             setDefUrl(u);
    //         }, 100);
    //     }
    // }, [url, srcVal, page, perPage]);

    const uri = useMemo(() => {
        return `${url}?search=${JSON.stringify(
            searchValue
        )}&page=${page}&perPage=${perPage}`;
    }, [url, searchValue, page, perPage]);

    useEffect(() => {
        // setSrcVal(searchValue);
        setPage(1);
    }, [searchValue]);

    const aos = useAos();
    const [, getLang] = useLanguage();
    const [rows, loading] = useFetch(uri);
    const cols = useMemo(() => {
        return (
            (!!columns &&
                Array.isArray(columns) &&
                columns.map(({ title, ...rest }) => ({
                    ...rest,
                    title: getLang(title),
                }))) ||
            []
        );
    }, [columns]);

    const [data, , subTotal] = useMemo(() => {
        if (!rows) return [[], 0, 0];
        const { data: d, total: t, subTotal: st } = rows;
        let currdata = d || [];
        if (typeof formaterRow === "function") {
            currdata = formaterRow(rows);
        }
        return [currdata, t, st];
    }, [rows]);

    return (
        <Fragment>
            <div
                data-aos={aos}
                style={{
                    marginBottom: "15px",
                    // display: "flex",
                    // flexDirection: "row",
                    // justifyContent: "space-between",
                    // alignItems: "center",
                    // gap: 10,
                    position: "relative",
                    zIndex: 1000,
                }}
            >
                {searchComponent}
            </div>
            {loading ? (
                "Loading .."
            ) : (
                <Fragment>
                    <div className={`main-table-page`}>
                        <Table
                            style={{
                                borderRadius: "16px",
                                overflow: "hidden",
                            }}
                            size="small"
                            columns={cols}
                            dataSource={data}
                            loading={loading}
                            bordered
                            rowKey={(r) => generateRandomString()}
                            pagination={false}
                            rowClassName={
                                typeof onRowClick === "function" &&
                                "row-pointer"
                            }
                            onRow={(row, idx) => {
                                return {
                                    onClick: () => {
                                        typeof onRowClick === "function" &&
                                            onRowClick(row);
                                    },
                                };
                            }}
                        />
                    </div>
                    {subTotal > perPage && (
                        <div style={{ marginTop: 30, textAlign: "right" }}>
                            <PaginationWIthCustomText
                                current={page}
                                total={subTotal}
                                onChange={(page, perPage) => {
                                    setPage(page);
                                    setPerPage(perPage);
                                }}
                            />
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default MainPage;
