import React, { useState } from "react";
import DashboardChart from "./DashboardChart";
import * as echarts from "echarts"; // Import ECharts library
import { useMemo } from "react";
import moment from "moment";
import {
  getFilterData,
  forecastData,
} from "../../../../../../helper/getFilterData";
import customFormat from "../../../../../../helper/customFormat";
import useWindowDimensions, {
  useFontSizeV2,
} from "../../../../../../hooks/useDimension";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardState } from "../../../../../../redux/reducer/apps";
import compareDates from "../../../../../../helper/compareDates";
import { parseFormatedNumber } from "../../../../../../helper/parseFormatedNumber";

const options = [
  { label: "Hari", value: "daily" },
  { label: "Bulan", value: "monthly" },
  { label: "Kwartal", value: "quarterly" },
  { label: "Semester", value: "semesterly" },
];

const optionsDirectPax = [
  { label: "All", value: 0 },
  { label: "Domestik", value: 1 },
  { label: "International In", value: 2 },
  { label: "International Out", value: 3 },
];

const PenumpangChart = ({ data, loading, datatable }) => {
  const { dashboardState, filterPage } = useSelector((item) => item.apps);
  const [viewProd, setViewProd] = useState("daily");
  const dispatch = useDispatch();
  const [direct, setDirect] = useState(0);
  const fontSize = useFontSizeV2();
  const { height } = useWindowDimensions();

  const [paxData, fcData] = useMemo(() => {
    const { int_in, int_out, dom, all } = data;
    const directData =
      direct === 0 ? all : direct === 1 ? dom : direct === 2 ? int_in : int_out;
    if (!data)
      return [
        { semesterly: [], quarterly: [], monthly: [], daily: [] },
        { semesterly: [], quarterly: [], monthly: [], daily: [] },
      ];
    const { daily, semesterly, monthly, quarterly } = getFilterData(
      directData?.sort(compareDates)
    );
    const fcDaily = forecastData(daily, "daily");

    return [
      {
        semesterly,
        quarterly,
        monthly,
        daily,
      },
      {
        daily: fcDaily,
      },
    ];
  }, [data, filterPage, direct]);

  const min = Math.min(...paxData[viewProd].map((item) => parseInt(item.curr)));
  const max = Math.max(...paxData[viewProd].map((item) => parseInt(item.curr)));

  const optionsPenumpang = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    // legend: {
    //   data: ["Domestik"],
    //   bottom: height > 720 ? "-2%" : "-5%",
    //   icon: "square",
    // },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "0%",
      right: "5%",
      bottom: "12%",
      containLabel: true,
      top: "10%",
    },
    xAxis: {
      type: "category",
      // boundaryGap: false,
      data:
        fcData[viewProd]?.length >= 100 && viewProd === "daily"
          ? fcData[viewProd]?.map((item) =>
              viewProd === "monthly"
                ? moment()
                    .month(item.name - 1)
                    .format("MMMM")
                : item.name
            )
          : paxData[viewProd]?.map((item) =>
              viewProd === "monthly"
                ? moment()
                    .month(item.name - 1)
                    .format("MMMM")
                : item.name
            ),
      axisLabel: {
        textStyle: {
          fontSize: fontSize.sm,
        },
        formatter:
          viewProd === "daily"
            ? (params) => moment(params).format("DD MMM")
            : (params) => `${params}`,
        // interval: ,
      },
    },

    yAxis: {
      //   show: false,
      type: "value",
      axisLabel: {
        formatter: (params) => customFormat(params),
        textStyle: {
          fontSize: fontSize.sm - 2,
        },
      },
    },

    visualMap: [
      {
        show: false,
        type: "continuous",
        seriesIndex: 0,
        min: min,
        max: max,
        inRange: {
          color: ["#fb8500", "#023047"],
        },
      },
      {
        show: false,
        type: "continuous",
        seriesIndex: 0,
        min: min,
        max: max,
        inRange: {
          color: ["#fb8500", "#023047"],
        },
      },
    ],
    series: [
      {
        name:
          direct === 0
            ? "All"
            : direct === 1
            ? "Domestic"
            : direct === 2
            ? "International In"
            : "International Out",
        type: viewProd === "daily" ? "line" : "bar",
        barWidth: 10,
        barGap: 0,
        // areaStyle: {
        //   // Specify the linear gradient for the areaStyle
        //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        //     { offset: 0, color: "#FFB703" },
        //     { offset: 1, color: "transparent" },
        //   ]),
        // },
        smooth: 0.1,
        symbol: "none",
        // lineStyle: {
        //   color: "#fb8500",
        // },
        // itemStyle: {
        //   color: "#fb8500",
        // },
        emphasis: {
          focus: "series",
        },
        areaStyle: {
          opacity: 0.3,
        },
        data: paxData[viewProd]?.map((item) => item.curr),
        markLine: {
          data: [
            {
              type: "average",
              itemStyle: {
                color: "#219ebc",
              },
              label: {
                formatter: (params) =>
                  `Avg. ${parseFormatedNumber(params.value)}`,
                textStyle: {
                  fontSize: 15,
                },
                position: "insideEndTop",
              },
            },
          ],
          silent: true,
        },
      },
      {
        areaStyle: {
          opacity: 0.3,
        },
        seriesIndex: 10,
        z: 0,
        name: "Forecase",
        type: "line",
        smooth: 0.1,
        // stack: "Total",
        symbol: "none",
        showSymbol: false,
        data:
          fcData[viewProd]?.length >= 100
            ? fcData[viewProd]?.map((item) => item.value)
            : [],
        color: "#FFB703",
        //   lineStyle: {
        //     type: 'dashed'
        // },
      },
    ],
  };

  useEffect(() => {
    // dispatch(setFilterPage({ direct }));
    dispatch(
      setDashboardState({ direct, chart: direct === "" ? "all" : "penumpang" })
    );
  }, [direct]);

  const dataTable = useMemo(() => {
    const datas =
      !!datatable &&
      datatable?.map((item) => {
        return {
          _id: item._id,
          domestic: item.domestic,
          international_in: item.international_in,
          international_out: item.international_out,
          total: item.international_in + item.international_out + item.domestic,
        };
      });

    const columnTable = [
      {
        title: "Date",
        type: "string",
        name: "_id",
        align: "left",
      },
      {
        title: "Domestic",
        type: "string",
        name: "domestic",
        align: "right",
      },
      {
        title: "International In",
        type: "string",
        name: "international_in",
        align: "right",
      },
      {
        title: "International Out",
        type: "string",
        name: "international_out",
        align: "right",
      },
      {
        title: "Total",
        type: "string",
        name: "total",
        align: "right",
      },
    ];
    return {
      data: datas,
      fields: columnTable,
      header: columnTable.map((item) => item.title),
    };
  }, [data]);

  return (
    <DashboardChart
      optDirect={optionsDirectPax}
      printHeader={"Penumpang Report"}
      repType={"Penumpang"}
      dataSource={dataTable}
      loading={loading}
      direct={direct}
      setDirect={setDirect}
      view={
        dashboardState?.filterState === "all"
          ? dashboardState?.filterTime
          : viewProd
      }
      setView={setViewProd}
      isOtherComponent
      isFilterComponent
      title={"Penumpang"}
      data={optionsPenumpang}
      options={options}
    />
  );
};

export default PenumpangChart;
