import React from 'react';
import {Airwaybill} from '../../category/airwaybill';
import { ShpCne } from '../../category/shpcne';
import { AgentCargo } from '../../category/agent_cargo';
import Airport from '../../category/airport';
import { DescCargo } from '../../category/description_cargo';
export default ({data}) => {
    const {awb_number, origin, dest, shipment_detail, shipper, consigne, agent, product_desc, hscode, rate, special_handling_request} = data;
    return(
        <div style={{display:'flex', flexDirection:'column', rowGap:16}}>
            <div style={{display:'flex', flexDirection:'row', gap:16, flexWrap:'wrap' }}>
                <div style={{flexGrow:1}}>
                    <Airwaybill 
                        awb_number={awb_number} 
                        origin={origin} 
                        dest={dest} 
                        shipment_detail={shipment_detail} 
                        product_desc={product_desc} 
                        hscode={hscode}
                        shc={special_handling_request}
                    />
                </div>
                <div style={{width:'35%'}}>
                    <Airport direction={"origin"} data={origin} />
                </div>
                <div style={{width:'35%'}}>
                    <Airport direction={"dest"} data={dest} />
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:16, flexWrap:'wrap' }}>
                <div style={{flexGrow:1}}>
                    <AgentCargo data={agent} />
                </div>
                
                <div style={{width:'35%'}}>
                    <ShpCne title={"Shipper"} data={shipper} />
                </div>
                <div style={{width:'35%'}}>
                    <ShpCne title={"Consignee"} data={consigne} />
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap:16, flexWrap:'wrap' }}>
                <div style={{flexGrow:1}}>
                    <DescCargo description={rate?.description || []} />
                </div>
                <div style={{width:'35%'}}>

                </div>
                
            </div>
        </div>
    )
}