import { buildDataTable } from "../../../../../helper/buildDataTable";
import { kgToTon } from "../../../../../helper/kgToTon";
import { tooltipFormater } from "../../Chart/tooltipFormater";

const getByDirections = (dataSource, category) => {
    const { dexport, dimport, domestic, code, ...rest } = dataSource;
    const originId = { code, ...rest };
    switch (category) {
        case "domestic":
            return {
                dataset: [code, domestic, originId],
                dataTable: { code, total: domestic },
            };
        case "export":
            return {
                dataset: [code, dexport, originId],
                dataTable: { code, total: dexport },
            };

        case "import":
            return {
                dataset: [code, dimport, originId],
                dataTable: { code, total: dimport },
            };

        default:
            return {
                dataset: [],
                dataTable: {},
            };
    }
};

const getDataCargoByAirportWithDirection = (dataSource, category) => {
    if (!dataSource || !category) {
        return [];
    }
    let result = [];
    let table = [];
    // let airportList = {};
    for (let iii = 0; iii < dataSource.length; iii++) {
        const { dexport, dimport, domestic, code, ...rest } = dataSource[iii];
        const { dataTable, dataset } = getByDirections(
            dataSource[iii],
            category
        );
        result.push(dataset);
        table.push(dataTable);
        // airportList[`${code}`] = { code, ...rest };
    }
    const dataset = [
        {
            dimensions: ["label", "value", "originObj"],
            source: result,
        },
        {
            transform: {
                type: "sort",
                config: { dimension: "value", order: "asc" },
            },
        },
    ];

    return [
        dataset,
        buildDataTable(table, [
            {
                title: "Airport Code",
                type: "string",
                name: "code",
                align: "left",
            },
            {
                title: "Total",
                type: "number",
                name: "total",
                align: "right",
            },
        ]),
    ];
};

const getDataCargoByAirport = (dataSource) => {
    if (!dataSource) {
        return [];
    }
    let result = [];
    let table = [];
    // let airportList = {};
    for (let iii = 0; iii < dataSource.length; iii++) {
        const { dexport, dimport, domestic, code, name, ...rest } =
            dataSource[iii];
        const grandTotal = kgToTon(dexport + dimport + domestic);
        result.push([
            code,
            kgToTon(domestic),
            kgToTon(dimport),
            kgToTon(dexport),
            grandTotal,
            { code, name, ...rest },
        ]);
        table.push({
            name: `${code} - ${name}`,
            export: kgToTon(dexport),
            import: kgToTon(dimport),
            domestic: kgToTon(domestic),
            grandTotal,
        });
        // airportList[`${code}`] = { code, ...rest };
    }

    const dataset = [
        {
            dimensions: [
                "label",
                "domestic",
                "import",
                "export",
                "grandTotal",
                "originObj",
            ],
            source: result,
        },
        {
            transform: {
                type: "sort",
                config: { dimension: "grandTotal", order: "asc" },
            },
        },
    ];

    return [
        dataset,
        buildDataTable(table, [
            {
                title: "Bandara",
                type: "string",
                name: "name",
                align: "left",
            },
            {
                title: "Total Domestik (Ton)",
                type: "number",
                name: "domestic",
                align: "right",
            },
            {
                title: "Total Import (Ton)",
                type: "number",
                name: "import",
                align: "right",
            },
            {
                title: "Total Export (Ton)",
                type: "number",
                name: "export",
                align: "right",
            },
            {
                title: "Grand Total (Ton)",
                type: "number",
                name: "grandTotal",
                align: "right",
            },
        ]),
    ];
};

const renderTooltip = (params) => {
    const { data } = params[0];
    return tooltipFormater({
        title: {
            label: `${data[5]["code"]} - ${data[5]["name"]}`,
        },
        params,
        withComma: true,
    });
};

export default {
    getDataCargoByAirport,
    getDataCargoByAirportWithDirection,
    renderTooltip,
};
