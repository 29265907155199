import { Col, Row, Typography } from "antd";
const RowList = ({ title, description, isBoldDescription, fontSize = 14 }) => {
  return (
    <Row>
      <Col span={10}>
        <Typography style={{ fontSize }}>{title}</Typography>
      </Col>
      <Col
        span={14}
        style={{
          fontWeight: isBoldDescription ? "bold" : "",
          textAlign: "right",
        }}
      >
        <Typography style={{ fontSize }}>{description}</Typography>
      </Col>
    </Row>
  );
};

export default RowList;
