import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import MapAirlineActive from "../../../../../../features/PanelChart/MapAirlineActive";
// import matchImageFilesWithCodes from "../../../../../../../../helper/matchImageFilesWithCodes";

export default function AirlineActiveMaps() {
  const { filterPage } = useSelector((item) => item.apps);
  const [data, loading] = useDashboardChart({
    ...filterPage,
    routes: "/airline",
    type: "maps",
    doSend: true,
    regenerate: true,
  });

  return <MapAirlineActive dataMaps={data} />;
}
