import {
    green,
    volcano,
    lime,
    blue,
    orange,
    red,
    grey,
} from "@ant-design/colors";
import { FaBaby, FaPassport } from "react-icons/fa";
import { IoIosFemale, IoIosMale } from "react-icons/io";
import { RiPassportLine, RiShipFill } from "react-icons/ri";
import { MdFlight, MdPeopleAlt } from "react-icons/md";
import { HiTicket } from "react-icons/hi";
import Badge from "../components/Badge";

export const getLogoAirline = (airlineCode) => {
    return `/assets/carrier/${airlineCode?.toLowerCase()}.png`;
};

export const onSearchByPropsObj = ({
    arrayData = [],
    arrayPropsStr = [],
    searchStr = "",
}) => {
    return arrayData.filter((el, idx) => {
        for (let i = 0; i < arrayPropsStr.length; i++) {
            if (
                el[`${arrayPropsStr[i]}`]
                    ?.toString()
                    ?.toLowerCase()
                    ?.indexOf(searchStr?.trim()?.toLowerCase()) !== -1
            )
                return (
                    el[`${arrayPropsStr[i]}`]
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(searchStr?.trim()?.toLowerCase()) !== -1
                );
        }
    });
};

export const getFormatedTime = ({ localAt, localSt, st }) => {
    const time = localAt || localSt || st;
    if (time === "Invalid date") {
        return "-";
    }
    return (
        (!!time &&
            (time.length === 5
                ? time
                : time.substring(0, 2) + ":" + time.substring(2))) ||
        ""
    );
};

export const renderGender = (gender, pax_type) => {
    if (pax_type !== "IN") {
        switch (gender?.toLowerCase()) {
            case "female":
                return <IoIosFemale size={24} color="#ff6e87" />;
            case "f":
                return <IoIosFemale size={24} color="#ff6e87" />;
            case "male":
                return <IoIosMale size={24} color={blue[6]} />;
            case "m":
                return <IoIosMale size={24} color={blue[6]} />;
            default:
                return <IoIosMale size={24} color={blue[6]} />;
        }
    }
    return <FaBaby size={24} color="green" />;
};

export const getPaxType = (pax_type) => {
    switch (pax_type?.toLowerCase()) {
        case "in-transit passenger":
            return "T";
        case "passenger":
            return "P";
        case "In-Transit Crew Member":
            return "T";
        case "Crew Member":
            return "C";
        default:
            return "P";
    }
};

export const getPaxTypePassenger = (pax_type) => {
    switch (pax_type) {
        case "A":
            return "Adult";
        case "C":
            return "Children";
        case "IN":
        case "I":
            return "Infant";
        default:
            return pax_type;
    }
};

export const renderRuleType = (ruleType) => {
    switch (ruleType?.toLowerCase()) {
        case "passport":
            return <FaPassport />;
        case "flight":
            return <MdFlight />;
        case "passenger":
            return <MdPeopleAlt />;
        case "pnr":
            return <HiTicket />;
        default:
            break;
    }
};

export const getUrlMatchedType = (type, id) => {
    switch (type?.toLowerCase()) {
        case "flight-pax-inter":
            return `/passengers/detail/${id}`;
        case "vessel-pax-inter":
            return `/vessel/passengers/detail/${id}`;
        case "flight":
            return `/flight/schedule/match/${id}`;
        default:
            return `/404`;
    }
};

export const onLimitChar = (text, limit) => {
    return text?.length > limit ? `${text?.substring(0, limit)} ...` : text;
};

export const getTypeDocument = (doc_type) => {
    switch (doc_type?.toLowerCase()) {
        case "p":
            return "Passport";
        default:
            return doc_type;
    }
};

export const getColorStatusTelex = (statusNumber) => {
    switch (statusNumber) {
        case 0:
            return orange[6];
        case 1:
            return green[6];
        case 2:
            return red[6];
        case 3:
            return grey[1];
        default:
            return grey[1];
    }
};

export const getHeaders = (headers) => {
    if (!headers) return false;
    if (!headers?.unh) return false;
    const { msgSubsetIdentification, msgRefNumber } = headers.unh;
    if (!msgSubsetIdentification) return false;
    const { msgSubsetVersionNumber, msgSubsetIdentification: subs } =
        msgSubsetIdentification;
    return `${msgRefNumber} ${subs}${msgSubsetVersionNumber || ""}`;
};

export const getDomHeader = (message) => {
    const [, header] = message;
    return header;
};

export const getBadgeStatus = (statusNumber, retry) => {
    switch (statusNumber) {
        case 0:
            return <Badge text={`Pending`} block color={`warning`} />;
        case 1:
            return <Badge text={`Success`} block color={`success`} />;
        case 2:
            return <Badge text={`Error`} block color={`error`} />;
        case 3:
            return <Badge text={`Ignored`} block color={`def`} />;
        case 4:
            return <Badge text={`Processing`} block color={`primary`} />;
        case 4:
            return <Badge text={`Retrying ${retry}`} block color={`warning`} />;
        default:
            return <Badge text={`Ignored`} block color={`def`} />;
    }
};

export const getServiceStatus = (status) => {
    switch (status?.toLowerCase()) {
        case "idle":
            return <Badge text={`Idle`} block color={`success`} />;
        case "success":
            return <Badge text={`Success`} block color={`success`} />;
        case "error":
            return <Badge text={`Error`} block color={`error`} />;
        case "processing":
            return <Badge text={`processing`} block color={`primary`} />;
        default:
            return <Badge text={`Ignored`} block color={`def`} />;
    }
};
