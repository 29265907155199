import React, { useMemo, useState } from "react";
import moment from "moment";
import DashboardChart from "../../../pages/dashboard/home/components/DashboardChartV2";
import customFormat from "../../../../../helper/customFormat";
import utilsPaxsWithRoute from "./utilsPaxsWithRoute";
import RadioPeriodic from "../../Form/RadioPeriodic";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import { resetStyleTooltips } from "../../Chart/resetStyleTooltip";

const getOptions = (dataSet, fontSize, filters, route) => {
    const mainColor =
        route.toLowerCase() === "domestik" ? "#023047" : "#FFB703";
    return {
        dataset: dataSet,
        tooltip: {
            trigger: "axis",
            ...resetStyleTooltips,
            formatter: (params) =>
                utilsPaxsWithRoute.renderTooltip(params, filters),
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },

        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            left: "0%",
            right: "0%",
            bottom: "12%",
            containLabel: true,
            top: "10%",
        },
        xAxis: {
            type: "category",
            axisLabel: {
                textStyle: {
                    fontSize: fontSize.sm,
                },
                formatter:
                    filters.periodic === "daily"
                        ? (params) => moment(params).format("DD MMM")
                        : (params) => `${params}`,
            },
        },
        yAxis: {
            type: "value",
            axisLabel: {
                formatter: (params) => customFormat(params),
            },
        },
        series: [
            {
                name: route,
                type: filters.periodic === "daily" ? "line" : "bar",
                symbol: "none",
                barGap: 0,
                datasetIndex: 0,
                barWidth: 16,
                encode: { x: "label", y: "total" },
                emphasis: {
                    focus: "series",
                },
                color: mainColor,
                itemStyle: {
                    color: mainColor,
                },
                areaStyle: {
                    color: `${mainColor}20`,
                },
                lineStyle: {
                    width: 3,
                },
            },
        ],
    };
};

export default function PanelChartPaxWithRoute({
    dataSource = [],
    loading,
    filterPage,
    route,
}) {
    const [filters, setFilters] = useState({
        periodic: "daily",
    });
    const fontSize = useFontSizeV2();
    const [dataset, datatable] = useMemo(
        () =>
            utilsPaxsWithRoute.getDataPaxByRoute(
                dataSource,
                filters.periodic,
                route
            ),
        [dataSource, filters.periodic, route]
    );

    return (
        <DashboardChart
            // loading={loading}
            printHeader={`Penumpang ${route} ${filters.airport} Report`}
            printPeriode={`${filters.periodic} ${filterPage.year}`}
            repType={`Penumpang ${route} ${filters.airport} Report`}
            title={`Penumpang ${route}`}
            dataSource={datatable}
            data={getOptions(
                dataset,
                fontSize,
                { ...filters, ...filterPage },
                route
            )}
            more={
                <RadioPeriodic
                    value={filters.periodic}
                    onChange={(value) =>
                        setFilters((state) => ({
                            ...state,
                            periodic: value,
                        }))
                    }
                />
            }
        />
    );
}
