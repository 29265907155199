/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Button, Select, Form, Col, Row, DatePicker } from "antd";
import React, { useEffect, useState, useMemo, Fragment } from "react";
import moment from "moment";

const { RangePicker } = DatePicker;

export default ({
  lists,
  onFilterValueChange,
  maxFilter,
  idReport,
  filtersValue,
  onClose,
  paramsFilter,
}) => {
  // const [filterIdx, setFilterIdx]=useState(0);
  const [model, setModel] = useState({});
  const [filterTypeStr, setFilterTypeStr] = useState([]);
  const [qry, setQry] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [values, setValues] = useState([]);
  const [filters, setFilters] = useState([]);
  useEffect(() => {
    setFilters([]);
    setValues([]);
    setSelectedValue([]);
    setQry([]);
    setFilterTypeStr([]);
    setSelectedFilters([]);
  }, [idReport]);

  const renderFilter = (idx, row) => {
    const {
      name,
      filter_data,
      header,
      filter_range_date,
      format,
      filter_time_date,
      type,
    } = row;

    return (
      <Form.Item name={name} label={header}>
        {filter_range_date ? (
          <RangePicker
            format={"DD-MMM-YYYY HH:mm:ss"}
            showTime={{
              defaultValue: [
                moment("00:00:00", "HH:mm:ss"),
                moment("23:59:59", "HH:mm:ss"),
              ],
            }}
            panelRender={(panel) => {
              return (
                <Fragment>
                  <Row gutter={[4, 4]} style={{ padding: 16 }}>
                    <Col
                      xs={15}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Select Date
                    </Col>
                    <Col
                      xs={9}
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Select Time
                    </Col>
                  </Row>
                  {panel}
                </Fragment>
              );
            }}
          />
        ) : (
          <Select
            size="small"
            style={{ width: "100%", marginInline: 5 }}
            showSearch
          >
            {Array.isArray(filter_data) &&
              filter_data.map((v, ix) => (
                <Select.Option key={`FILTER_VALUE_${ix}`} value={v || ""}>
                  {v === "" ? "Empty String" : v}
                </Select.Option>
              ))}
          </Select>
        )}
      </Form.Item>
    );
  };
  useEffect(() => {
    let tempFiltersValue = {};
    const list = lists?.filter(
      ({ filter_string, name, filter_data, ...rest }) => {
        tempFiltersValue[`${name}`] = null;
        return !filter_string;
      }
    );

    setFilters(list);
  }, [lists]);

  const [form] = Form.useForm();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Form
        layout="vertical"
        form={form}
        onFinish={(row) => {
          let result = {};
          for (const key in row) {
            if (Object.hasOwnProperty.call(row, key)) {
              const element = row[key];
              if (element) {
                result[`${key}`] = element;
              }
            }
          }
          onFilterValueChange(result);
          onClose();
        }}
        initialValues={model}
      >
        <Row gutter={[16, 16]}>
          {filters.map((row, idx) => {
            return (
              <Col
                style={{
                  order: row?.layout_order || 100 + idx,
                }}
                key={idx}
                span={6}
              >
                {renderFilter(idx, row)}
              </Col>
            );
          })}
        </Row>
        <div style={{ display: "flex", gap: 16 }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" danger onClick={() => form.resetFields()}>
              Reset
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
