import React from "react";
import DashboardChart from "../../DashboardChartV2";
import { useFontSizeV2 } from "../../../../../../../../hooks/useDimension";
import utilsAirline from "../../../../../../features/PanelChart/Airline/utilsAirline";
import { Button, Radio } from "antd";
import { createUseStyles } from "react-jss";
import { useState } from "react";
import { useMemo } from "react";
import utilsOtp from "../../../../../../features/PanelChart/Otp/utilsOtp";
import { resetStyleTooltips } from "../../../../../../features/Chart/resetStyleTooltip";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { useHistory } from "react-router-dom";

const MAX_ITEM_BAR = 9;

const optionSelect = [
  { value: "up", label: "Top 10 Up" },
  { value: "down", label: "Top 10 Down" },
];

const useStyles = createUseStyles({
  radioStyle: {
    "& .ant-radio-button-wrapper": {
      marginRight: "4px",
      borderRadius: "6px",
      borderLeftWidth: 1.02,
      fontSize: ({ fontSize }) => fontSize.xxs,
      fontWeight: "bold",
    },
    "& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before":
      {
        display: "none",
      },
    "& .ant-radio-button-wrapper:not(:first-child)::before": {
      display: "none",
    },
  },
});

const getOption = (dataSet, getLenghtData, getPersen, fontSize) => {
  return {
    dataset: dataSet,
    tooltip: {
      trigger: "axis",
      ...resetStyleTooltips,
      formatter: (params) => utilsOtp.renderTooltip(params),
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },

    grid: {
      x: 40,
      x2: 2,
      y: 2,
      y2: 2,
    },
    xAxis: {
      show: true,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },

    yAxis: {
      type: "category",
      axisLabel: {
        show: true,
        textStyle: {
          fontSize: fontSize.xs,
        },
      },
    },
    dataZoom: [
      {
        type: "slider",
        start: !!getLenghtData ? 100 : 0,
        orient: "vertical",
        right: 24,
        end: !!getLenghtData ? getPersen : 100,
        handleSize: 8,
      },
      {
        type: "inside",
        start: !!getLenghtData ? 100 : 0,
        end: !!getLenghtData ? getPersen : 100,
        orient: "vertical",
        zoomOnMouseWheel: false,
        moveOnMouseMove: true,
        moveOnMouseWheel: true,
      },
    ],
    series: [
      {
        name: `OTP (%)`,
        type: "bar",
        symbol: "none",
        barWidth: 12,
        barGap: 0,
        datasetIndex: 0,
        encode: { y: "label", x: "otp" },
        emphasis: {
          focus: "series",
        },
        stack: "total",
        itemStyle: {
          color: "#023047",
        },
      },
    ],
  };
};

const OTPChart = ({ data, loading }) => {
  const fontSize = useFontSizeV2();
  const history = useHistory();
  const [filters, setFilters] = useState({
    sort: "down",
  });

  const [dataset, dataTable] = useMemo(
    () => utilsOtp.getDataByAirport(data, filters.sort),
    [data, filters.sort]
  );

  const getLenghtData = !!dataset[0]?.source && dataset[0]?.source?.length > 10;

  const getPersen = 100 - (MAX_ITEM_BAR / dataset[0]?.source?.length) * 100;

  return (
    <DashboardChart
      //   loading={loading}
      OnClick={(row) => {
        console.log(row);
        history.push(`/detail/airline/otp/${row?.data[2]?._id}`);
      }}
      printHeader={`OTP Report`}
      repType={`OTP Report`}
      title={"OTP Chart"}
      dataSource={dataTable}
      data={getOption(dataset, getLenghtData, getPersen, fontSize)}
      more={
        <Button
          type="text"
          icon={filters.sort === "top" ? <BiSortUp /> : <BiSortDown />}
          onClick={() => {
            setFilters((state) => ({
              ...state,
              sort: filters.sort === "top" ? "down" : "top",
            }));
          }}
        />
      }
    />
  );
};

export default OTPChart;
