import { buildDataTable } from "../../../../../helper/buildDataTable";
import { getIdPeriodic } from "../../../../../helper/getIdPeriodic";
import { tooltipFormater } from "../../Chart/tooltipFormater";

const getDataPaxByRoute = (dataSource = [], periodic, route) => {
    if (!dataSource || !route || !periodic) {
        return [[], [], []];
    }
    let result = {};

    for (let iiii = 0; iiii < dataSource.length; iiii++) {
        const { international, domestic, code, flight_date, ...rest } =
            dataSource[iiii];
        const by = getIdPeriodic(periodic, flight_date);

        if (!result[by]) {
            result[by] = {
                totalDom: domestic,
                totalInt: international,
            };
        } else {
            let sumDom = (result[by].totalDom += domestic);
            let sumInt = (result[by].totalInt += international);

            result[by] = {
                totalDom: sumDom,
                totalInt: sumInt,
            };
        }
    }

    let set = [];
    let dataTable = [];
    for (const key in result) {
        if (Object.hasOwnProperty.call(result, key)) {
            const { totalDom, totalInt, originObj } = result[key];
            const grandTotal = totalDom + totalInt;
            if (route?.toLowerCase() === "domestik") {
                set.push([key, totalDom, grandTotal, originObj]);
                dataTable.push({
                    total: totalDom,
                    periode: key,
                    route,
                });
            } else if (route?.toLowerCase() === "internasional") {
                set.push([key, totalInt, grandTotal, originObj]);
                dataTable.push({
                    total: totalInt,
                    periode: key,
                    route,
                });
            }
        }
    }

    const dataset = [
        {
            dimensions: ["label", "total", "originObj"],
            source: set,
        },
        {
            transform: {
                type: "sort",
                config: { dimension: "label", order: "asc" },
            },
        },
    ];

    return [
        dataset,
        buildDataTable(dataTable, [
            {
                title: "Periode",
                type: "string",
                name: "periode",
                align: "left",
            },
            {
                title: "Route",
                type: "string",
                name: "route",
                align: "left",
            },
            {
                title: "Total ",
                type: "number",
                name: "total",
                align: "right",
            },
        ]),
    ];
};

const renderTooltip = (params, filters) => {
    const { data } = params[0];
    return tooltipFormater({
        title: {
            label: `${data[0]} ${filters?.year || ""}`,
        },
        params,
    });
};

export default { renderTooltip, getDataPaxByRoute };
