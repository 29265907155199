import React, { useMemo } from "react";
import { useDashboardChart } from "../../../../../../../hooks/useDashboardChart";
import { useParams } from "react-router-dom";
import customFormat, {
  customFormatWeight,
} from "../../../../../../../helper/customFormat";
import utilsPaxs from "../../../../../features/PanelChart/Paxs/utilsPaxs";
import moment from "moment";
import { resetStyleTooltips } from "../../../../../features/Chart/resetStyleTooltip";
import { useState } from "react";
import { useFontSizeV2 } from "../../../../../../../hooks/useDimension";
import compareDates from "../../../../../../../helper/compareDates";
import { getFilterData } from "../../../../../../../helper/getFilterData";
import * as echarts from "echarts"; // Import ECharts library
import { useSelector } from "react-redux";
import DashboardChart from "../../../../dashboard/home/components/DashboardChart";
import Config from "../../../../../../../config/env";

const { chartColor } = Config;

const options = [
  { label: "Hari", value: "daily" },
  { label: "Bulan", value: "monthly" },
  { label: "Kwartal", value: "quarterly" },
  { label: "Semester", value: "semesterly" },
];

const DetailDaily = ({ filterPage }) => {
  const { idAirport } = useParams();
  const { dashboardState } = useSelector((item) => item.apps);
  const [viewProd, setViewProd] = useState("daily");

  const fontSize = useFontSizeV2();

  const [detailDaily, loadingDetailDaily] = useDashboardChart({
    ...filterPage,
    airport_id: idAirport,
    type: "daily",
    routes: "/airport-detail/otp",
  });

  const dataDetail = useMemo(() => {
    const dataOtp =
      Array.isArray(detailDaily) &&
      detailDaily?.map(({ date, otp }) => ({
        name: date,
        curr: otp,
      }));
    return {
      otp: dataOtp,
    };
  }, [detailDaily, viewProd]);

  const [OTP] = useMemo(() => {
    const { otp = [] } = dataDetail;
    if (!dataDetail)
      return [
        { yearly: [], semesterly: [], quarterly: [], monthly: [], daily: [] },
      ];
    const {
      daily: dOtp,
      semesterly: sOtp,
      monthly: mOtp,
      quarterly: qOtp,
    } = getFilterData(Array.isArray(otp) && otp?.sort(compareDates));
    return [
      {
        semesterly: sOtp,
        quarterly: qOtp,
        monthly: mOtp,
        daily: dOtp,
      },
    ];
  }, [detailDaily, filterPage, viewProd]);

  const stackState = useMemo(() => {
    return viewProd === "daily" ? { stack: "Total" } : {};
  }, [viewProd]);

  const seriesData = useMemo(() => {
    const arrSeries = [
      {
        name: "OTP",
        type: viewProd !== "daily" ? "bar" : "line",
        barWidth: 20,
        barGap: 2,
        ...stackState,
        areaStyle: {
          // Specify the linear gradient for the areaStyle
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: chartColor[0] },
            { offset: 1, color: "transparent" },
          ]),
        },
        symbol:
          dashboardState?.filterTime !== "daily" || viewProd !== "daily"
            ? "circle"
            : "none",
        lineStyle: {
          color: chartColor[0],
        },
        itemStyle: {
          color: chartColor[0],
        },
        emphasis: {
          focus: "series",
        },
        data:
          Array.isArray(OTP[viewProd]) &&
          OTP[viewProd]?.map((item) => item.curr),
      },
    ];
    return arrSeries;
  }, [dataDetail]);

  const optionsDaily = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      data: ["OTP"],
      bottom: "0%",
      icon: "square",
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "12%",
      containLabel: true,
      top: "10%",
    },
    xAxis: {
      type: "category",
      // boundaryGap: false,
      data:
        Array.isArray(OTP[viewProd]) &&
        OTP[viewProd]?.map((item) =>
          viewProd === "monthly"
            ? moment()
                .month(item.name - 1)
                .format("MMMM")
            : item.name
        ),
      axisLabel: {
        textStyle: {
          fontSize: fontSize.sm,
        },
        formatter:
          viewProd === "daily"
            ? (params) => moment(params).format("DD MMM")
            : (params) => `${params}`,
        // interval: 3,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => params + "%",
      },
    },
    series: seriesData,
  };

  return (
    <DashboardChart
      isDetail
      loading={loadingDetailDaily}
      //   options={options}
      title={"Daily Chart (%)"}
      data={optionsDaily}
      view={viewProd}
      setView={setViewProd}
      isFilterComponent
    />
  );
};

export default DetailDaily;
