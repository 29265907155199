import React, { useState } from 'react';
import Tabs from '../../tabs';
import Detail from './cargo_detail';
export default ({data, id}) => {
    return (
        <div style={{display:'flex', flexDirection:'column'}}>
            <Tabs 
                selTab={1}
                tabs={[
                    {
                        id:0,
                        caption:'detail',
                        href:`/cargo/detail/${id}`,
                    },
                    {
                        id:1,
                        caption:'pengangkut',
                        href:`/cargo/manifest/detail/${id}`,
                    },
                    {
                        id:2, 
                        caption:'house',
                        href:`/cargo/house/detail/${id}`,
                    }
                ]}
            />
            <Detail data={data} />
        </div>
    )
}