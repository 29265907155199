import React from "react";
import Table from "../../lists/table";
import moment from "moment";

export default ({ data }) => {
  return (
    <Table
      data={data || []}
      pagination={false}
      filterField={[
        "dokim_no",
        "nama",
        "nama_satker",
        "nomor_paspor",
        "penjamin",
      ]}
      columns={[
        {
          title: "indeks",
          dataIndex: "indeks",
        },
        {
          title: "source",
          dataIndex: "source",
        },
        {
          title: "no_visa",
          dataIndex: "no_visa",
        },
        {
          title: "no_permohonan",
          dataIndex: "no_permohonan",
        },
        {
          title: "tanggal_permohonan",
          dataIndex: "tanggal_permohonan",
          render: (val) => (!!val ? moment(val).format("DD-MMM-YYYY") : "-"),
        },
        {
          title: "visa_berlaku_mulai",
          dataIndex: "visa_berlaku_mulai",
          render: (val) => (!!val ? moment(val).format("DD-MMM-YYYY") : "-"),
        },
        {
          title: "visa_berlaku_akhir",
          dataIndex: "visa_berlaku_akhir",
          render: (val) => (!!val ? moment(val).format("DD-MMM-YYYY") : "-"),
        },
        {
          title: "nama_negara",
          dataIndex: "nama_negara",
        },
      ]}
    />
  );
};
