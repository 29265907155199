import React, { useMemo } from "react";
import MainRadioGroup from "../../Form/MainRadioGroup";
import DashboardChart from "../../../pages/dashboard/home/components/DashboardChartV2";
import { resetStyleTooltips } from "../../Chart/resetStyleTooltip";
import moment from "moment";
import customFormat from "../../../../../helper/customFormat";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import RadioPeriodic from "../../Form/RadioPeriodic";
import utilsTrendWithRoute from "./utilsTrendWithRoute";
import SelectAirport from "../../Form/SelectAirport";

const options = [
    {
        label: "Asal",
        value: "origin",
    },
    {
        label: "Tujuan",
        value: "dest",
    },
];

const getOptions = (dataSet, filters, fontSize) => {
    return {
        dataset: dataSet,
        tooltip: {
            trigger: "axis",
            ...resetStyleTooltips,
            formatter: (params) =>
                utilsTrendWithRoute.renderTooltip(params, filters),
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },

        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            left: "0%",
            right: "0%",
            bottom: "12%",
            containLabel: true,
            top: "10%",
        },
        xAxis: {
            type: "category",
            axisLabel: {
                textStyle: {
                    fontSize: fontSize.sm,
                },
                formatter:
                    filters.periodic === "daily"
                        ? (params) => moment(params).format("DD MMM")
                        : (params) => `${params}`,
            },
        },
        yAxis: {
            type: "value",
            axisLabel: {
                formatter: (params) => customFormat(params),
            },
        },
        series: [
            {
                name: `Domestic`,
                type:
                    filters.periodic === "daily" || filters.periodic === "all"
                        ? "line"
                        : "bar",
                symbol: "none",
                barGap: 0,
                datasetIndex: 0,
                barWidth: 16,
                encode: { x: "label", y: "domestic" },
                emphasis: {
                    focus: "series",
                },
                color: "#023047",
                itemStyle: {
                    color: "#023047",
                },
                areaStyle: {
                    color: "#02304720",
                },
                lineStyle: {
                    width: 3,
                },
            },
            {
                name: `International`,
                type:
                    filters.periodic === "daily" || filters.periodic === "all"
                        ? "line"
                        : "bar",
                symbol: "none",
                barGap: 0,
                barWidth: 16,
                datasetIndex: 0,
                encode: { x: "label", y: "international" },
                emphasis: {
                    focus: "series",
                },
                color: "#FFB703",
                lineStyle: {
                    width: 3,
                },
                itemStyle: {
                    color: "#FFB703",
                },
                areaStyle: {
                    color: "#FFB70320",
                },
            },
        ],
    };
};

export default function PanelChartTrendWithRoute({
    dataSource = [],
    loading,
    filterPage,
    filters,
    onChangeFilter,
}) {
    const fontSize = useFontSizeV2();
    const [dataset, datatable, airportsList] = useMemo(
        () =>
            utilsTrendWithRoute.getDataByAirport(
                dataSource,
                filters.airport,
                filters.periodic
            ),
        [dataSource, filters.airport, filters.periodic]
    );
    return (
        <DashboardChart
            // loading={loading}
            // key={filters.category || "alll"}
            printHeader={`${filters.category} Report`}
            printPeriode={`${filters.category} ${filterPage.year}`}
            repType={`${filters.category} Report`}
            title={`${
                options.find((opt) => opt.value === filters.category).label
            }`}
            dataSource={datatable}
            data={getOptions(dataset, { ...filters, ...filterPage }, fontSize)}
            more={
                <MainRadioGroup
                    value={filters.category}
                    onChange={(e) =>
                        onChangeFilter({
                            category: e.target.value,
                            airport: "all",
                        })
                    }
                    options={options}
                />
            }
            chartPanelRender={(chartPanel) => {
                return (
                    <div style={{ padding: 12, height: "100%" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <RadioPeriodic
                                value={filters.periodic}
                                onChange={(value) =>
                                    onChangeFilter({
                                        periodic: value,
                                    })
                                }
                            />
                            <SelectAirport
                                onChange={(value) =>
                                    onChangeFilter({
                                        airport: value,
                                    })
                                }
                                value={filters.airport}
                                dataSource={airportsList}
                            />
                        </div>
                        {chartPanel}
                    </div>
                );
            }}
        />
    );
}
