import React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const IcAirline = ({ isDark, width, height }) => {
  const { dashboardState } = useSelector((item) => item.apps);
  const [classDark, classLight] = useMemo(() => {
    return !isDark
      ? [` icon-svg-new fil0-dark`, ` icon-svg-new fil1-dark`]
      : [` icon-svg-new fil0-light`, ` icon-svg-new fil1-light`];
  }, [isDark, dashboardState.summaryCard]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 175393 158052"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2924522495344">
          <path
            className={classDark}
            d="M171318 79794c2134,-108 3951,1533 4060,3665 108,2133 -1533,3951 -3666,4059 -17980,937 -38115,2631 -58250,4784 -4528,484 -9051,992 -13545,1518l-29213 -4052c13501,-1884 27734,-3638 41941,-5159 20234,-2164 40513,-3869 58673,-4815zm-158271 22907c0,-2141 1736,-3876 3877,-3876 2141,0 3877,1735 3877,3876l0 9536c13302,3406 70283,17542 123354,23685 2123,241 3649,2160 3407,4285 -242,2124 -2161,3649 -4286,3407 -58887,-6815 -122560,-23392 -127074,-24578 -1796,-339 -3155,-1916 -3155,-3809l0 -12526z"
          />
          <path
            className={classDark}
            d="M137606 112189l-85075 2095c-372,8 -732,-36 -1075,-126l-1 5 -48570 -12863c-2066,-543 -3299,-2658 -2756,-4725 458,-1744 2039,-2895 3764,-2883l43671 -1717c232,-9 459,3 682,33l89993 12483c2115,293 3593,2246 3301,4361 -273,1973 -1989,3391 -3934,3337z"
          />
          <path
            className={classLight}
            d="M79115 20672c-2016,693 -4212,-378 -4906,-2393 -695,-2016 377,-4213 2393,-4907 3894,-1352 7905,-2387 12001,-3075 4060,-680 8203,-1036 12394,-1036 20544,0 39142,8327 52605,21790 13463,13463 21790,32061 21790,52605 0,20543 -8327,39143 -21790,52606 -13463,13463 -32061,21790 -52605,21790 -9023,0 -17681,-1613 -25700,-4566 -8309,-3060 -15928,-7567 -22552,-13216 -1622,-1389 -1812,-3830 -423,-5452 1387,-1623 3829,-1812 5451,-425 5917,5048 12737,9080 20189,11823 7169,2640 14929,4082 23035,4082 18403,0 35063,-7459 47123,-19519 12059,-12059 19518,-28721 19518,-47123 0,-18403 -7459,-35063 -19518,-47122 -12060,-12060 -28720,-19519 -47123,-19519 -3820,0 -7536,313 -11122,915 -3729,626 -7325,1549 -10760,2742z"
          />
          <path
            className={classLight}
            d="M47560 81975c820,1974 -116,4239 -2090,5058 -1974,820 -4239,-117 -5058,-2090l-33070 -79588c-819,-1974 116,-4238 2090,-5058 590,-245 1205,-332 1801,-284l0 -4 26253 2231c784,64 1496,360 2073,813l6926 4647c41800,28064 89762,60267 124456,61830 2133,92 3787,1897 3695,4030 -92,2132 -1896,3786 -4029,3694 -36866,-1662 -85786,-34508 -128423,-63134l-6319 -4240 -18930 -1609 30625 73704z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcAirline;
