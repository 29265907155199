import React, { useMemo, useState } from "react";
import MainPage from "../../../components/MainPage";
import moment from "moment";
import QuequeRowBtn from "../../../components/QuequeRowBtn";
import { blue, green, orange, red, yellow } from "@ant-design/colors";
import Search from "./Search";

const defValue = {
    subSmi: "",
    refresh: moment().unix(),
    status: 10,
};

const getStatusTxt = (status, retry) => {
    switch (status) {
        case 0:
            return ["Pending", yellow[7]];
        case 1:
            return ["Success", green[8]];
        case 2:
            return ["Error", red[7]];
        case 3:
            return ["Ignored", orange[5]];
        case 4:
            return ["Processing", blue[6]];
        case 5:
            return ["Retrying " + retry, yellow[6]];
        default:
            break;
    }
};

export default function QueueProcess() {
    const [search, setSearch] = useState(defValue);
    const handleNeedRefresh = (resp) => {
        setSearch({ ...search, refresh: moment().unix() });
    };

    const formatedSearchVal = useMemo(() => {
        const { status, ...others } = search;
        const s = status === 10 ? undefined : status;
        return { ...others, status: s };
    }, [search]);

    const COLUMNS = [
        {
            title: "Action",
            dataIndex: "_id",
            width: "320px",
            render: (val, row) => (
                <QuequeRowBtn
                    onNeedRefresh={handleNeedRefresh}
                    telex_data={row}
                />
            ),
        },
        {
            title: "Handle By",
            dataIndex: "handle_by",
            ellipsis: true,
            render: (val, row) => (
                <strong className="text-primary-new">{val}</strong>
            ),
        },
        {
            title: "smi",
            dataIndex: "sub_smi",
            ellipsis: true,
            width: "120px",
        },
        {
            title: "error",
            dataIndex: "error",
            ellipsis: true,
            width: "120px",
        },
        {
            title: "status",
            dataIndex: "status",
            render: (val, row) => {
                const { retries } = row;
                const [txt, color] = getStatusTxt(val, retries);
                return (
                    <span
                        style={{
                            background: color,
                            padding: 4,
                            color: "white",
                            borderRadius: 16,
                        }}
                    >
                        {txt}
                    </span>
                );
            },
        },

        {
            title: "Waktu Pesan Masuk",
            dataIndex: "created_at",
            render: (val, row) =>
                row.createdAt &&
                moment(row.createdAt).format("DD-MMM-YYYY HH:mm"),
        },
    ];
    return (
        <MainPage
            url={"api/v1/buffer/pagination"}
            searchValue={formatedSearchVal}
            columns={COLUMNS}
            searchComponent={
                <Search
                    onSearchChanged={(value) => {
                        setSearch({ ...search, ...value });
                    }}
                    onSearchCallback={(searchValue) => {
                        setSearch({
                            ...searchValue,
                            refresh: moment().unix(),
                        });
                    }}
                />
            }
        />
    );
}
