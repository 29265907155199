import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import moment from 'moment';


const getStatusFlag=(flag)=>{
    if((flag & 24)>0)return 'PPT/VIT';
    if((flag & 7)>0) return 'ITP/CKL';
    return '-';
}

/**
 * Format Cell  
 * @param {ExcelJS.Cell} cell 
 * @param {any} value 
 * @param {string} format 
 * @param {boolean} bold 
 * @param {any} align 
 */

 const formatCell=(cell, value, format="", bold=false, align={horizontal:'center', vertical:'middle'}, noBorder=false )=>{
    if(format!=='')cell.numFmt=format;
    if(bold)cell.font={bold:true};
    cell.alignment=align;
    cell.value=value;
    if(!noBorder)cell.border={bottom:{style:'thin'}, top:{style:'thin'}, left:{style:'thin'}, right:{style:'thin'}};
}

/**
 * 
 * @param {ExcelJS.Worksheet} sheet 
 * @param {Array} data 
 * @param {String} title 
 * @param {Object} flrec 
 */
const createReport=(sheet, data, title, flrec)=>{
    sheet.mergeCells('B1:L1');
    formatCell(sheet.getCell('B1'), title, '', true,undefined,true); 
    let rowIndex = 4;
    formatCell(sheet.getCell(`B3`), '#', '', true);
    formatCell(sheet.getCell(`C3`), 'Passenger Name', '', true);
    formatCell(sheet.getCell(`D3`), 'PNR', '', true);
    formatCell(sheet.getCell(`E3`), 'Gender', '', true);
    formatCell(sheet.getCell(`F3`), 'Dob', '', true);
    formatCell(sheet.getCell(`G3`), 'Age', '', true);
    formatCell(sheet.getCell(`H3`), 'Seat Number', '', true);
    formatCell(sheet.getCell(`I3`), 'Nationality', '', true);
    formatCell(sheet.getCell(`J3`), 'Passport Number', '', true);
    formatCell(sheet.getCell(`K3`), 'Expiry', '', true);
    formatCell(sheet.getCell(`L3`), 'Status', '', true);
    formatCell(sheet.getCell(`M3`), 'Watchlist', '', true);
    formatCell(sheet.getCell(`N3`), 'Melintas', '', true);
    for (let iii = 0; iii < data.length; iii++) {
        const row = data[iii];
        const {passenger_name, pnr, watch_flag, gender, dob, age, reference, doc_nationality, doc_number, doc_expiry, status, perlintasan} = row;
        formatCell(sheet.getCell(`B${rowIndex+iii}`), iii+1);
        formatCell(sheet.getCell(`C${rowIndex+iii}`), passenger_name, '', false, {horizontal:'left', vertical:'middle'});
        formatCell(sheet.getCell(`D${rowIndex+iii}`), pnr);
        formatCell(sheet.getCell(`E${rowIndex+iii}`), gender);
        formatCell(sheet.getCell(`F${rowIndex+iii}`), moment(dob).format('DD-MMM-YYYY'));
        formatCell(sheet.getCell(`G${rowIndex+iii}`), age);
        formatCell(sheet.getCell(`H${rowIndex+iii}`), reference?.seat_number);
        formatCell(sheet.getCell(`I${rowIndex+iii}`), doc_nationality);
        formatCell(sheet.getCell(`J${rowIndex+iii}`), doc_number);
        formatCell(sheet.getCell(`K${rowIndex+iii}`), moment(doc_expiry).format('DD-MMM-YYYY'));
        formatCell(sheet.getCell(`L${rowIndex+iii}`), status);
        formatCell(sheet.getCell(`M${rowIndex+iii}`), getStatusFlag(watch_flag));
        formatCell(sheet.getCell(`N${rowIndex+iii}`), perlintasan?.timestamp && moment(perlintasan?.timestamp).format('DD-MMM-YYYY HH:mm') || '-');
    }
}

/**
 * 
 * @param {ExcelJS.Worksheet} sheet 
 * @param {Array} data 
 * @param {String} title 
 * @param {Object} flrec 
 */
const createReportVessel=(sheet, data, title, flrec)=>{
    sheet.mergeCells('A1:J1');
    formatCell(sheet.getCell('A1'), title, '', true,undefined,true); 
    let rowIndex = 4;
    formatCell(sheet.getCell(`B3`), '#', '', true);
    formatCell(sheet.getCell(`C3`), 'Passenger Name', '', true);
    formatCell(sheet.getCell(`D3`), 'Gender', '', true);
    formatCell(sheet.getCell(`E3`), 'Dob', '', true);
    formatCell(sheet.getCell(`F3`), 'Age', '', true);
    formatCell(sheet.getCell(`G3`), 'Nationality', '', true);
    formatCell(sheet.getCell(`H3`), 'Passport Number', '', true);
    formatCell(sheet.getCell(`I3`), 'Expiry', '', true);
    formatCell(sheet.getCell(`J3`), 'Watchlist', '', true);
    for (let iii = 0; iii < data.length; iii++) {
        const row = data[iii];
        const {passenger_name, gender, dob, age, unclode_id, doc_number, doc_expiration:doc_expiry, status, watch_flag} = row;
        formatCell(sheet.getCell(`B${rowIndex+iii}`), iii+1);
        formatCell(sheet.getCell(`C${rowIndex+iii}`), passenger_name, '', false, {horizontal:'left', vertical:'middle'});
        formatCell(sheet.getCell(`D${rowIndex+iii}`), gender);
        formatCell(sheet.getCell(`E${rowIndex+iii}`), dob);
        formatCell(sheet.getCell(`F${rowIndex+iii}`), age);
        formatCell(sheet.getCell(`G${rowIndex+iii}`), unclode_id);
        formatCell(sheet.getCell(`H${rowIndex+iii}`), doc_number);
        formatCell(sheet.getCell(`I${rowIndex+iii}`), doc_expiry);
        formatCell(sheet.getCell(`J${rowIndex+iii}`), getStatusFlag(watch_flag));
    }
}

/**
 * 
 * @param {Array} data
 * @param {Object} flight_record
 */

export const exportManifest=async(data, flight_record)=>{
    console.log({flight_record, data});
    const route=`${flight_record?.flight_number} ${flight_record?.flight_date}(${flight_record?.origin}${flight_record?.dest})`;
    const Title=`Manifest Report`.toUpperCase();
    const fullName=`${Title} ${route}`;
    const wb=new ExcelJS.Workbook();
    wb.title=Title;
    wb.subject=fullName;
    wb.creator=`ESI - EDIFly Solusi Indonesia ~~~HK~~~`;
    wb.created=new Date();
    const sheet=wb.addWorksheet('Manifest', {properties:{tabColor:{argb:'FF00FF00'}}});
    createReport(sheet, data, fullName, flight_record);
    const wbout=await wb.xlsx.writeBuffer();    
    const filename=fullName.split(' ').join('_');
    saveAs(new Blob([wbout], {type:"application/octet-stream"}), `${filename}.xlsx`);
    return true;
}

/**
 * 
 * @param {Array} data
 * @param {Object} flight_record
 */

export const exportManifestVessel=async(data, vessel)=>{
    // console.log({flight_record, data});
    const vname=vessel?.ferry_key.split('.').join('').split(' ').join('_');
    const route=`${vname} ${vessel.schedule_id}(${vessel?.origin} - ${vessel?.destination})`;
    const Title=`Manifest Report`.toUpperCase();
    const fullName=`${Title} ${route}`;
    const wb=new ExcelJS.Workbook();
    wb.title=Title;
    wb.subject=fullName;
    wb.creator=`ESI - EDIFly Solusi Indonesia ~~~HK~~~`;
    wb.created=new Date();
    const sheet=wb.addWorksheet('Manifest', {properties:{tabColor:{argb:'FF00FF00'}}});
    createReportVessel(sheet, data, fullName, vessel);
    const wbout=await wb.xlsx.writeBuffer();    
    const filename=fullName.split(' ').join('_');
    saveAs(new Blob([wbout], {type:"application/octet-stream"}), `${filename}.xlsx`);
    return true;
}