import React, { useMemo } from 'react';
import Panel from '../panel/panel';
import { RiFile2Line } from 'react-icons/ri';
import { Typography } from 'antd';
import Row from './row';

const dict={
    nd:'Dimmension',
    nv:"Volume",
    nh:"HSCode",
}

export const DescCargo = ({description})=>{
    const {console, goods, others}=useMemo(()=>{
        if(!description)return {console:'', goods:'', others:[]}
        const res={others:{}};
        for (let iii = 0; iii < description.length; iii++) {
            const {code, desc} = description[iii];
            if(['NC', 'NG'].indexOf(code)>=0){
                if(!res[code]){
                    res[code]='';
                }
                res[code]+=desc.join('');
                continue;
            }
            if(!res.others[code]){
                res.others[code]=[];
            }
            res.others[code].push(desc);
        }
        const others=[];
        for (const key in res.others) {
            if (Object.hasOwnProperty.call(res.others, key)) {
                const r = res.others[key];
                others.push({code:key, value:r});
            }
        }
        return {console:res['NC'], goods:res['NG'], others};
    }, [description]);
    return (
        <Panel
            headerLeft={<Typography.Text>Nature</Typography.Text>}
            headerRight={
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <RiFile2Line />
                </div>
            }
        >
            { !!console && <Row name={'console'} value={console} />}
            { !!goods && <Row name={'goods'} value={goods} />}
            {
                others.map((row, idx)=>{
                    return (
                        <Row key={idx} name={dict[row.code.toLowerCase()]} value={ Array.isArray(row.value) && (
                            <ul>
                                {row.value.map((l, xx)=><li key={xx}>{l.join(' - ')}</li>)}
                            </ul>
                        ) || row.value } />
                    )
                })
            }
        </Panel>
    )
}