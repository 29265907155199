import moment from "moment";

export default function compareDates(a, b) {
  const dateA = moment(a.name, "DD-MMM-YYYY");
  const dateB = moment(b.name, "DD-MMM-YYYY");

  // Compare dates
  if (dateA.isBefore(dateB)) {
    return -1;
  } else if (dateA.isAfter(dateB)) {
    return 1;
  } else {
    return 0;
  }
}
