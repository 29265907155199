import React, { useState } from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import DashboardChart from "../../DashboardChartV2";
import getDataSHCByCargo from "./utilsShc";
import { customFormatWeight } from "../../../../../../../../helper/customFormat";
import { useFontSizeV2 } from "../../../../../../../../hooks/useDimension";
import Config from "../../../../../../../../config/env";
const { chartColor } = Config;

const optionsMaskapai = (dataSource, fontSize, series) => {
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
      formatter: (params) => {
        let string = `<strong>${params[0].name}</strong>`;
        const param = params?.map((item) => {
          const { dexport, dimport, domestic } = item.data;
          return ` <br/> ${item.marker}<strong>${item.seriesName}</strong>: ${
            item.seriesName === "Export"
              ? (dexport / 1000).toFixed(2)
              : item.seriesName === "Import"
              ? (dimport / 1000).toFixed(2)
              : (domestic / 1000).toFixed(2)
          } Ton`;
        });
        return string + param.join("");
      },
    },
    dataset: dataSource,
    legend: {
      bottom: -5,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "24%",
      containLabel: true,
      top: "5%",
    },
    dataZoom: [
      {
        type: "inside",
        start: 1,
        end:
          dataSource?.source?.length < 20
            ? 100
            : dataSource?.source?.length < 50
            ? 80
            : dataSource?.source?.length < 100
            ? 60
            : 50,
      },
      {
        show: true,
        type: "slider",
        start: 1,
        end: 100,
        height: 15,
        bottom: "15%",
      },
    ],
    xAxis: {
      type: "category",
      axisLabel: {
        textStyle: {
          fontSize: fontSize.sm,
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => customFormatWeight(params),
      },
    },
    series: series,
  };
  return option;
};


const series = [
  {
    name: "Export",
    type: "bar",
    stack: "total",
    itemStyle: { color: "#fb8500" },
  },
  {
    name: "Import",
    type: "bar",
    stack: "total",
    itemStyle: { color: "#ffb703" },
  },

  {
    name: "Domestik",
    type: "bar",
    itemStyle: { color: "#023047" },
  },
];

const ShcHandlingChart = ({ datav2, loadingv2 }) => {
  const [direct, setDirect] = useState("");
  const dispatch = useDispatch();
  const fontSize = useFontSizeV2();

  const dataSource = useMemo(() => {
    const data =
      Array.isArray(datav2) &&
      datav2?.map((item) => {
        return {
          code: item.shc,
          dexport: item.dexport,
          dimport: item.dimport,
          domestic: item.domestic,
          sumData: item.dexport + item.dimport + item.domestic,
        };
      });
    return data;
  }, [datav2]);


  const handleClickItem = (params) => {
    console.log({ params });
  };

  const [dataset, datatable] = getDataSHCByCargo(dataSource);

  return (
    <DashboardChart
      repType={"SHC"}
      printHeader={"SHC Report"}
      dataSource={datatable}
      OnClick={handleClickItem}
      //   loading={loadingv2}
      title={"Special Handling (Tons)"}
      data={optionsMaskapai(dataset, fontSize, series)}
    />
  );
};

export default ShcHandlingChart;
