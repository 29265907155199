import { useMemo, useState } from "react";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import DashboardChart from "../../../pages/dashboard/home/components/DashboardChartV2";
import { resetStyleTooltips } from "../../Chart/resetStyleTooltip";
import utilsOtp from "./utilsOtp";
import { Button } from "antd";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { useHistory } from "react-router-dom";

const MAX_ITEM_BAR = 9;
const MAX_PERCENT_ITEM = 100;

const getOption = (dataSet, getLenghtData, getPersen, fontSize) => {
    return {
        dataset: dataSet,
        tooltip: {
            trigger: "axis",
            ...resetStyleTooltips,
            formatter: (params) => utilsOtp.renderTooltip(params),
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },

        grid: {
            x: 40,
            x2: 2,
            y: 2,
            y2: 2,
        },
        xAxis: {
            show: true,
            axisTick: {
                show: false,
            },
            axisLine: {
                show: false,
            },
        },

        yAxis: {
            type: "category",
            axisLabel: {
                show: true,
                textStyle: {
                    fontSize: fontSize.xs,
                },
            },
        },
        dataZoom: [
            {
                type: "slider",
                start: !!getLenghtData ? 100 : 0,
                orient: "vertical",
                right: 24,
                end: !!getLenghtData ? getPersen : 100,
                handleSize: 8,
            },
            {
                type: "inside",
                start: !!getLenghtData ? 100 : 0,
                end: !!getLenghtData ? getPersen : 100,
                orient: "vertical",
                zoomOnMouseWheel: false,
                moveOnMouseMove: true,
                moveOnMouseWheel: true,
            },
        ],
        series: [
            {
                name: `OTP (%)`,
                type: "bar",
                symbol: "none",
                barWidth: 12,
                barGap: 0,
                datasetIndex: 0,
                encode: { y: "label", x: "otp" },
                emphasis: {
                    focus: "series",
                },
                stack: "total",
                itemStyle: {
                    color: "#023047",
                },
            },
        ],
    };
};

export default function PanelChartOtp({
    dataSource = [],
    loading,
    filterPage,
}) {
    const history = useHistory();
    const fontSize = useFontSizeV2();
    const [filters, setFilters] = useState({
        sort: "down",
    });

    const [dataset, dataTable] = useMemo(
        () => utilsOtp.getDataByAirport(dataSource, filters.sort),
        [dataSource, filters.sort]
    );

    const getLenghtData =
        !!dataset[0]?.source && dataset[0]?.source?.length > 10;

    const getPersen = 100 - (MAX_ITEM_BAR / dataset[0]?.source?.length) * 100;

    return (
        <DashboardChart
            // loading={loading}
            printHeader={`On time performance Report`}
            printPeriode={`${filterPage?.year}`}
            repType={`On time performance Report`}
            title={"On time performance"}
            dataSource={dataTable}
            data={getOption(dataset, getLenghtData, getPersen, fontSize)}
            OnClick={(row) => {
                console.log(row);
                history.push(`/detail/airport/otp/${row?.data[2]?._id}`);
            }}
            more={
                <Button
                    type="text"
                    icon={
                        filters.sort === "top" ? <BiSortUp /> : <BiSortDown />
                    }
                    onClick={() => {
                        setFilters((state) => ({
                            ...state,
                            sort: filters.sort === "top" ? "down" : "top",
                        }));
                    }}
                />
            }
        />
    );
}
