import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routing } from "./routing";
import { Route, Switch } from "react-router-dom";
import { loadAirlines, loadAirports } from "../../../redux";

const Router = ({ props }) => {
    return (
        <Switch>
            {Routing.map((route, idx) => (
                <Route
                    key={idx}
                    path={route.to}
                    exact={route.isExact !== false}
                    render={(props) => {
                        return !!route.component && route.component;
                    }}
                />
            ))}
            <Route path={"/*"}>
                <div>Page Not Found</div>
            </Route>
        </Switch>
    );
};

export default () => {
    const { userdata } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAirlines());
        dispatch(loadAirports());
    }, [userdata]);

    return <Router />
};
