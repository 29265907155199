import { Fragment, useEffect } from "react";
import MainRouter from "./content/MainRouter";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from 'react-router-dom';
import Aos from "aos";
import "aos/dist/aos.css";
import CFG from "./config/env";
import { loadConfig } from "./redux/reducer/apps";

const { Title } = CFG;

LoadingOverlay.propTypes = undefined;

function App() {
    const { loading, config:{loaded} } = useSelector((state) => state.apps);
    Aos.init({
        duration: 1500,
        mirror: true,
        anchorPlacement: "top-top",
    });
    const dispatch=useDispatch();
    useEffect(() => {
        document.title = Title;
        dispatch(loadConfig());
    }, []);

    return (
        <Fragment>
            <LoadingOverlay active={loading} spinner text="Loading">
                {/* <AnimatePresence> */}
                { loaded && <MainRouter />}
                {/* </AnimatePresence> */}
            </LoadingOverlay>
        </Fragment>
    );
}

export default App;
