import React, { useMemo, useState } from "react";
import DashboardChart from "../../dashboard/home/components/DashboardChart";
import { customFormatWeight } from "../../../../../helper/customFormat";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import { useDashboardChart } from "../../../../../hooks/useDashboardChart";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import pickOneData from "../../../../../helper/pickOneData";
import Config from "../../../../../config/env";
const { chartColor } = Config;

let options = [
  { label: "Commodity", value: "commodity" },
  // { label: "SHC", value: "shc" },
];

const FourthChart = () => {
  const [view, setView] = useState("commodity");
  const fontSize = useFontSizeV2();
  const location = useLocation();
  const { data } = location.state;
  const { filterPage } = useSelector((item) => item.apps);

  const id = useMemo(() => {
    return data?.detail === "Airline"
      ? { airline_id: data.id }
      : { airport_id: data.id };
  }, [data?.detail, location]);

  const filterDetail = useMemo(() => {
    const filterEliminate = pickOneData(filterPage, "year");
    return { ...filterEliminate, ...id };
  }, [filterPage, location]);

  const [detailCommodity, loadingDetailCommodity] = useDashboardChart({
    ...filterDetail,
    type: "commodity",
    routes: `/cargo-detail/${data?.detail.toLowerCase()}`,
  });

  const [detailAirline, loadingDetailAirline] = useDashboardChart({
    ...filterDetail,
    type: "airline",
    routes: `/cargo-detail/${data?.detail.toLowerCase()}`,
  });

  const [detailAirport, loadingDetailAirport] = useDashboardChart({
    ...filterDetail,
    type: "airport",
    routes: `/cargo-detail/${data?.detail.toLowerCase()}`,
  });

  const optionsByChart = useMemo(() => {
    return data?.detail === "Airport"
      ? [...options, { label: "Airline", value: "airline" }]
      : [...options, { label: "Airport", value: "airport" }];
  }, [view]);

  const dataSource = useMemo(() => {
    const commodity =
      Array.isArray(detailCommodity) &&
      detailCommodity?.map((item) => {
        const airlineData = {
          domestic: item.domestic,
          sumData: item.dexport + item.domestic + item.dimport,
        };
        const airportData = {
          incoming: item.incoming,
          outgoing: item.outgoing,
          transit: item.transit,
          sumData:
            item.dexport +
            item.dimport +
            item.incoming +
            item.outgoing +
            item.transit,
        };
        const merge = data?.detail === "Airline" ? airlineData : airportData;
        return {
          label: item.commodity,
          dexport: item.dexport,
          dimport: item.dimport,
          ...merge,
        };
      });

    const airline =
      Array.isArray(detailAirline) &&
      detailAirline?.map((item) => {
        return {
          label: item.code,
          dexport: item.dexport,
          dimport: item.dimport,
          incoming: item.incoming,
          outgoing: item.outgoing,
          transit: item.transit,
          sumData:
            item.dexport +
            item.dimport +
            item.incoming +
            item.outgoing +
            item.transit,
        };
      });

    const airport =
      Array.isArray(detailAirport) &&
      detailAirport?.map((item) => {
        return {
          label: item.code,
          dexport: item.dexport,
          dimport: item.dimport,
          incoming: item.incoming,
          incoming: item.incoming,
          outgoing: item.outgoing,
          transit: item.transit,
          sumData:
            item.dexport +
            item.dimport +
            item.incoming +
            item.outgoing +
            item.transit,
        };
      });

    return {
      commodity,
      airline,
      airport,
    };
  }, [detailCommodity, detailAirport, detailAirline]);

  const seriesData = useMemo(() => {
    const series = [
      {
        name: "Export",
        type: "bar",
        stack: "total",
        itemStyle: { color: chartColor[1] },
      },
      {
        name: "Import",
        type: "bar",
        stack: "total",
        itemStyle: { color: chartColor[4] },
      },
    ];
    return data?.detail === "Airline" && view === "commodity"
      ? [
          ...series,
          {
            name: "Domestik",
            type: "bar",
            itemStyle: { color: chartColor[5] },
          },
        ]
      : [
          ...series,
          {
            name: "Incoming",
            type: "bar",
            // stack: "total",
            itemStyle: { color: chartColor[2] },
          },
          {
            name: "Outgoing",
            type: "bar",
            // stack: "total",
            itemStyle: { color: chartColor[0] },
          },
          {
            name: "Transit",
            type: "bar",
            // stack: "total",
            itemStyle: { color: chartColor[4] },
          },
        ];
  }, [view]);

  const option = {
    legend: {
      bottom: 0,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
      formatter: (params) => {
        const { name: label } = params[0];
        const tooltipComponent = params?.map((item) => {
          return `${item.marker}<strong>${item?.seriesName}</strong> : ${
            item.seriesName === "Domestik"
              ? customFormatWeight(item?.data?.domestic)
              : item?.seriesName === "Export"
              ? customFormatWeight(item?.data?.dexport)
              : item?.seriesName === "Import"
              ? customFormatWeight(item?.data?.dimport)
              : item?.seriesName === "Incoming"
              ? customFormatWeight(item?.data?.incoming)
              : item?.seriesName === "Outgoing"
              ? customFormatWeight(item?.data?.outgoing)
              : customFormatWeight(item?.data?.transit)
          } ton <br/>`;
        });
        return (
          `<strong>${label}</strong>` + "<br/>" + tooltipComponent.join(" ")
        );
      },
    },
    dataset: {
      dimension:
        data?.detail === "Airline" && view === "commodity"
          ? ["code", "dexport", "dimport", "domestic"]
          : [
              "code",
              "dexport",
              "dimport",
              "domestic",
              "incoming",
              "outgoing",
              "transit",
            ],
      source: Array.isArray(detailCommodity)
        ? dataSource[view].sort((a, b) => b.sumData - a.sumData)
        : [],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "12%",
      top: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      axisLabel: {
        textStyle: {
          fontSize: view === "commodity" ? fontSize.xs - 5 : fontSize.sm,
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => customFormatWeight(params) + " ton",
      },
    },
    series: seriesData,
  };

  return (
    <DashboardChart
      titleSelected={`of ${data?.name} (${data?.code})`}
      isDetail
      key={`${view.slice(0, 1).toUpperCase() + view.slice(1)} (Ton)`}
      setView={setView}
      view={view}
      loading={loadingDetailCommodity}
      title={`${view.slice(0, 1).toUpperCase() + view.slice(1)} (Ton)`}
      data={option}
      options={optionsByChart}
      isFilterComponent
    />
  );
};

export default FourthChart;
