import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDashboardChart } from "../../../../../hooks/useDashboardChart";
import CardMaps from "../../../../../components/Maps/CardMaps";
import { useState } from "react";
import { formatedOptions } from "../../../../../helper/formatedOptions";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import pickOneData from "../../../../../helper/pickOneData";

const removeDuplicates = (dataArray) => {
  // Create an empty object to store unique items based on label and value
  const uniqueItems = {};

  // Iterate over the array and add items to the object
  Array.isArray(dataArray) &&
    dataArray?.forEach((item) => {
      const key = `${item.label}-${item.value}`;
      uniqueItems[key] = item;
    });

  // Convert the object values back to an array
  const uniqueArray = Object.values(uniqueItems);

  return uniqueArray;
};

const RutePengiriman = ({ isMobile = false, title }) => {
  const location = useLocation();
  const [view, setView] = useState("incoming");
  const { data } = location.state;
  const { filterPage } = useSelector((item) => item.apps);

  const id = useMemo(() => {
    return data?.detail === "Airline"
      ? { airline_id: data.id }
      : { airport_id: data.id };
  }, [data?.detail, location]);

  const filterDetail = useMemo(() => {
    const filterEliminate = pickOneData(filterPage, "year");
    return { ...filterEliminate, ...id };
  }, [filterPage, location]);

  const [detailAirlineMaps, loadingDetailAirlineMaps] = useDashboardChart({
    ...filterDetail,
    type: data?.detail === "Airline" ? "route" : "map",
    routes: `/cargo-detail/${data?.detail.toLowerCase()}`,
  });

  const [routes, setRoutes] = useState([
    {
      label: "UPG/Makassar Sultan Hasanuddin International Airport",
      value: "60a3d866045fd93f38efc1f6",
    },
  ]);

  const originFilter = useMemo((item) => {
    return (
      Array.isArray(detailAirlineMaps) &&
      detailAirlineMaps?.map((item) => item.origin)
    );
  });

  const formatedOpt = formatedOptions(originFilter, ["code", "name"], "_id");

  const idOrigin = useMemo(() => {
    return routes.map((item) => {
      return item.value;
    });
  }, [routes]);

  const filterRoutes = useMemo(() => {
    return (
      !!Array.isArray(detailAirlineMaps) &&
      detailAirlineMaps?.filter((item) => {
        return idOrigin.includes(item.origin?._id);
      })
    );
  }, [routes, detailAirlineMaps]);

  const routesData = useMemo(() => {
    return (
      Array.isArray(filterRoutes) &&
      !!filterRoutes &&
      filterRoutes?.map((item) => {
        return {
          code: [item?.origin?.code || "", item?.dest?.code || ""],
          country: [item?.origin?.country || "", item?.dest?.country || ""],
          kota: [item?.origin?.kota || "", item?.dest?.kota || ""],
          name: [item?.origin?.name || "", item?.dest?.name || ""],
          lonlat: [
            [item?.origin?.lat || 0, item?.origin?.lon || 0],
            [item?.dest?.lat || 0, item?.dest?.lon || 0],
          ],
        };
      })
    );
  }, [filterRoutes, detailAirlineMaps]);

  const mapsData = useMemo(() => {
    return detailAirlineMaps[view];
  }, [view, detailAirlineMaps]);

  const originRoutes = useMemo(() => {
    return (
      !!routesData &&
      routesData.map((item) => {
        const [lat] = item.lonlat || [0];
        const [name] = item.name || [""];
        return [lat, name];
      })
    );
  }, [routesData]);

  useEffect(() => {
    if (formatedOpt[0] !== undefined) {
      setRoutes([formatedOpt[0]]);
    }
  }, [detailAirlineMaps]);

  let options = [
    { label: "Outgoing", value: "outgoing" },
    { label: "Incoming", value: "incoming" },
    { label: "Transit", value: "transit" },
  ];

  return (
    <CardMaps
      isCargo
      titleSelected={`of ${data?.name} (${data?.code})`}
      isDetail
      viewData={view}
      filterDetail={filterDetail}
      setValueFilter={setView}
      valueFilter={view}
      filterComp={data?.detail === "Airport" ? options : null}
      mulitple={data?.detail === "Airline"}
      origin={originRoutes}
      loading={loadingDetailAirlineMaps}
      isRoute={data?.detail === "Airline"}
      data={data?.detail === "Airline" ? routesData : mapsData}
      fullscreen
      height={isMobile ? "65%" : "100%"}
      title={<Fragment>{title}</Fragment>}
      selected={routes}
      setSelected={setRoutes}
      options={removeDuplicates(formatedOpt)}
    />
  );
};

export default RutePengiriman;
