import React, { useMemo } from 'react';
import Panel from '../panel/panel';
import { Typography } from 'antd';
import Row from './row';
import { GiPerson } from 'react-icons/gi';
import Flags from '../../../../../components/flags';
// import second from '../fl'
export const ShpCne = ({data, title})=>{
    const [address, contact_identifier, contact_number, country_code, location, name, post_code] = useMemo(()=>{
        if(!data)return ['-', '-', '-', '-', '-', '-', '-'];
        const {address, contact_identifier, contact_number, country_code, location, name, post_code}=data;
        return [address || '-', contact_identifier || '-', contact_number || '-', country_code || '-', location || '-', name || '-', post_code || '-'];
    }, [data]);
    return (
        <Panel
            headerLeft={<Typography.Text>{title}</Typography.Text>}
            headerRight={
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <GiPerson />
                </div>
            }
        >
            <Row name={'name'} value={name} />
            <Row name={'location'} value={location} />
            <Row name={'address'} value={address} />
            <Row name={'post_code'} value={post_code} />
            <Row name={'country_code'} value={(
                <Flags country_code={country_code} withCountry />
            )} />
            <Row name={'contact'} value={`${contact_number}(${contact_identifier})`} last />
        </Panel>
    )
}