import React, { useMemo, useState } from "react";
import DashboardChart from "../../../pages/dashboard/home/components/DashboardChartV2";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import utilsAirlineAndAirport from "./utilsAirlineAndAirport";
import { resetStyleTooltips } from "../../Chart/resetStyleTooltip";
import MainRadioGroup from "../../Form/MainRadioGroup";
import customFormat from "../../../../../helper/customFormat";

const MAX_ITEM_BAR = 9;
const MAX_PERCENT_ITEM = 100;

const options = [
    {
        label: "Bandara",
        value: "Airport",
    },
    {
        label: "Maskapai",
        value: "Airline",
    },
];

const getOptions = (dataSet, getLenghtData, getPersen, fontSize) => {
    return {
        dataset: dataSet,
        tooltip: {
            trigger: "axis",
            ...resetStyleTooltips,
            formatter: (params) => utilsAirlineAndAirport.renderTooltip(params),
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            left: "0%",
            right: "5%",
            bottom: "12%",
            containLabel: true,
            top: "10%",
        },
        xAxis: {
            type: "category",
            axisLabel: {
                textStyle: {
                    fontSize: fontSize.sm,
                },
            },
        },

        yAxis: {
            type: "value",
            axisLabel: {
                formatter: (params) => customFormat(params),
            },
        },
        dataZoom: getPersen.toFixed() > 0 &&
            getPersen.toFixed() < MAX_PERCENT_ITEM && [
                {
                    type: "slider",
                    start: 0,
                    orient: "horizontal",
                    // right: 24,
                    end: !!getLenghtData ? getPersen.toFixed() : 100,
                    handleSize: 8,
                    bottom: 20,
                    height: 12,
                },
                {
                    type: "inside",
                    start: 0,
                    end: !!getLenghtData && 1 ? getPersen.toFixed() : 100,
                    orient: "horizontal",
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true,
                },
            ],
        series: [
            {
                name: `Domestic`,
                type: "bar",
                symbol: "none",
                barWidth: 10,
                barGap: 0,
                datasetIndex: 0,
                encode: { x: "label", y: "domestic" },
                emphasis: {
                    focus: "series",
                },
                itemStyle: {
                    color: "#023047",
                },
            },
            {
                name: `International In`,
                type: "bar",
                symbol: "none",
                barWidth: 10,
                barGap: 0,
                datasetIndex: 0,
                encode: { x: "label", y: "international_in" },
                emphasis: {
                    focus: "series",
                },
                itemStyle: {
                    color: "#FB8500",
                },
            },
            {
                name: `International Out`,
                type: "bar",
                symbol: "none",
                barWidth: 10,
                barGap: 0,
                datasetIndex: 0,
                encode: { x: "label", y: "international_out" },
                emphasis: {
                    focus: "series",
                },
                itemStyle: {
                    color: "#FFB703",
                },
            },
        ],
    };
};
export default function PanelChartAirlineAndAirport({
    dataSource = [],
    loading,
    filterPage,
    filters,
    onChangeFilter,
}) {
    const fontSize = useFontSizeV2();

    const [dataset, dataTable] = useMemo(
        () => utilsAirlineAndAirport.getData(dataSource),
        [dataSource]
    );

    const [getLenghtData, getPersen] = useMemo(() => {
        if (!Array.isArray(dataset)) return [false, 0];
        let isLenghtData =
            !!dataset[0]?.source && dataset[0]?.source?.length > 10;
        let percent = isLenghtData
            ? (MAX_ITEM_BAR / dataset[0]?.source?.length) * 100
            : 0;
        return [isLenghtData, percent];
    }, [filters, dataset]);

    return (
        <DashboardChart
            // loading={loading}
            // key={filters.category || "alll"}
            printHeader={`${filters.category} Report`}
            printPeriode={`${filters.category} ${filterPage.year}`}
            repType={`${filters.category} Report`}
            title={`${
                options.find((opt) => opt.value === filters.category).label
            }`}
            dataSource={dataTable}
            data={getOptions(dataset, getLenghtData, getPersen, fontSize)}
            more={
                <MainRadioGroup
                    value={filters.category}
                    onChange={onChangeFilter}
                    options={options}
                />
            }
        />
    );
}
