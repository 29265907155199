// const BaseUrl='http://sipp.imigrasi.go.id/service/';
// const ImageUrl='http://sipp.imigrasi.go.id/images/';
// const BaseUrl='https://prm.edifly-si.com/service/';
// const ImageUrl='https://prm.edifly-si.com/images/';
// const BaseUrl='http://10.8.0.100/service/';
// const ImageUrl='http://10.8.0.100/images/';
// const BaseUrl = "http://localhost:11611/";
const BaseUrl = "http://localhost:11611/";
const ImageUrl = "http://localhost:11611/";
const Title = "Direktorat Jenderal Perhubungan Udara";
const BgColor = "#091E3E";
const withDomestic = true;
const defaultOriginDom = {
  _id: "60a3d868045fd93f38efc29a",
  code: "CGK",
  kota: "Jakarta",
};
const backgroundGradient = "rgba(44,113,146,1)";
const NoVessel = true;
const internalDetail = false;
export default {
  colorGray: "rgba(132, 132, 132, 1)",
  primaryColor: "#023047",
  secondaryColor: "#ff9f40",
  teritaryColor: '"#5a6acf"',
  chartColor: [
    "#219EBC",
    "#023047",
    "#FFB703",
    "#Fb8500",
    "#64a4c9",
    "#8ECAE6",
    "#2063a7",
  ],
  BaseUrl,
  Title,
  BgColor,
  withDomestic,
  defaultOriginDom,
  backgroundGradient,
  ImageUrl,
  NoVessel,
  internalDetail,
};
