import React, { useMemo, useState } from "react";
import DashboardChart from "../../../pages/dashboard/home/components/DashboardChartV2";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import utilsAirline from "./utilsAirline";
import SelectAirport from "../../Form/SelectAirport";
import { resetStyleTooltips } from "../../Chart/resetStyleTooltip";

const MAX_ITEM_BAR = 9;
const MAX_PERCENT_ITEM = 100;

const getOption = (dataSet, getLenghtData, getPersen, fontSize) => {
    return {
        dataset: dataSet,
        tooltip: {
            trigger: "axis",
            ...resetStyleTooltips,
            formatter: (params) => utilsAirline.renderTooltip(params),
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },

        grid: {
            x: 40,
            x2: 2,
            y: 2,
            y2: 2,
        },
        xAxis: {
            show: true,
            axisTick: {
                show: false,
            },
            axisLine: {
                show: false,
            },
        },

        yAxis: {
            type: "category",
            axisLabel: {
                show: true,
                textStyle: {
                    fontSize: fontSize.xs,
                },
            },
        },
        dataZoom: [
            {
                type: "slider",
                start: !!getLenghtData ? 100 : 0,
                orient: "vertical",
                right: 24,
                end: !!getLenghtData ? getPersen : 100,
                handleSize: 8,
            },
            {
                type: "inside",
                start: !!getLenghtData ? 100 : 0,
                end: !!getLenghtData ? getPersen : 100,
                orient: "vertical",
                zoomOnMouseWheel: false,
                moveOnMouseMove: true,
                moveOnMouseWheel: true,
            },
        ],
        series: [
            {
                name: `Domestic`,
                type: "bar",
                symbol: "none",
                barWidth: 12,
                barGap: 0,
                datasetIndex: 0,
                encode: { y: "label", x: "domestic" },
                emphasis: {
                    focus: "series",
                },
                stack: "total",
                itemStyle: {
                    color: "#023047",
                },
            },
            {
                name: `International`,
                type: "bar",
                symbol: "none",
                barWidth: 12,
                barGap: 0,
                datasetIndex: 0,
                encode: { y: "label", x: "international" },
                emphasis: {
                    focus: "series",
                },
                stack: "total",
                itemStyle: {
                    color: "#FFB703",
                },
            },
        ],
    };
};

export default function PanelChartAirline({
    dataSource = [],
    loading,
    filterPage,
}) {
    const fontSize = useFontSizeV2();
    const [filters, setFilters] = useState({
        airport: "all",
    });

    const [dataset, dataTable, airportsList] = useMemo(
        () => utilsAirline.getDataAirlineByAirport(dataSource, filters.airport),
        [dataSource, filters.airport]
    );

    const getLenghtData =
        !!dataset[0]?.source && dataset[0]?.source?.length > 10;

    const getPersen = 100 - (MAX_ITEM_BAR / dataset[0]?.source?.length) * 100;

    return (
        <DashboardChart
            // loading={loading}
            printHeader={`Maskapai ${filters.airport} Report`}
            printPeriode={`${filters.airport} ${filterPage.year}`}
            repType={`Maskapai ${filters.airport} Report`}
            title={"Maskapai"}
            dataSource={dataTable}
            data={getOption(dataset, getLenghtData, getPersen, fontSize)}
            more={
                <SelectAirport
                    onChange={(value) =>
                        setFilters((state) => ({ ...state, airport: value }))
                    }
                    value={filters.airport}
                    dataSource={airportsList}
                />
            }
        />
    );
}
