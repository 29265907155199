import React from 'react';
import Panel from '../panel/panel';
import { RiFile2Line } from 'react-icons/ri';
import { Typography } from 'antd';
import Row from './row';
import {countries} from 'country-data'   

export const Airwaybill = ({awb_number, shipment_detail, origin, dest, product_desc, hscode, shc})=>{
    const getUnlocode=(airport)=>{
        const alpha2=countries[airport.country||'']?.alpha2;
        return `${alpha2}${airport.code}`;
    }
    return (
        <Panel
            headerLeft={<Typography.Text>Master Airwaybill</Typography.Text>}
            headerRight={
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <RiFile2Line />
                </div>
            }
        >
            <Row name={'awb_number'}  value={awb_number} />
            <Row name={'product_desc'} value={product_desc} />
            <Row name={'shc'} value={shc?.join(', ')} />
            <Row name={'origin'} value={getUnlocode(origin)} />
            <Row name={'dest'} value={getUnlocode(dest)} />
            <Row name={'total'} value={shipment_detail?.total} isNumber />
            <Row name={'weight'} value={shipment_detail?.kilo} isNumber last />
        </Panel>
    )
}