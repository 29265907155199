import { buildDataTable } from "../../../../../../../../helper/buildDataTable";

const getDataAirlineByCargo = (dataSource) => {
  if (!dataSource) {
    return [[], []];
  }
  let set = dataSource?.sort((a, b) => b.sumData - a.sumData);
  const dataset = [
    {
      dimensions: ["code", "dexport", "dimport", "domestic", "name", "_id"],
      source: set,
    },
    {
      transform: {
        type: "sort",
        config: { dimension: "label", order: "asc" },
      },
    },
  ];
  return [
    dataset,
    buildDataTable(set, [
      {
        title: "Code",
        type: "string",
        name: "code",
        align: "left",
      },
      {
        title: "Name",
        type: "string",
        name: "name",
        align: "left",
      },
      {
        title: "Export",
        type: "number",
        name: "dexport",
        align: "left",
      },
      {
        title: "Import",
        type: "number",
        name: "dimport",
        align: "left",
      },
      {
        title: "Domestic",
        type: "number",
        name: "domestic",
        align: "left",
      },
    ]),
  ];
};
export default getDataAirlineByCargo;
