import { buildDataTable } from "../../../../../helper/buildDataTable";
import { tooltipFormater } from "../../Chart/tooltipFormater";

const getData = (dataSource) => {
    if (!dataSource) {
        return [[], []];
    }
    let result = {};

    for (let iii = 0; iii < dataSource.length; iii++) {
        const { flight_count, origin, dest } = dataSource[iii];
        const by = `${origin.code}-${dest.code}` || "-";

        if (!result[by]) {
            result[by] = {
                flight_count,
            };
        } else {
            let sum = (result[by].flight_count += flight_count);

            result[by] = {
                flight_count: sum,
            };
        }
        if (!result[by]?.originObj) {
            result[by] = {
                ...result[by],
                originObj: { origin, dest },
            };
        }
    }

    let set = [];
    let dataTable = [];
    for (const key in result) {
        if (Object.hasOwnProperty.call(result, key)) {
            const { flight_count, originObj } = result[key];
            set.push([key, flight_count, originObj]);
            dataTable.push({
                flight_count,
                key,
                ...originObj,
            });
        }
    }

    const dataset = [
        {
            dimensions: ["label", "total", "originObj"],
            source: set,
        },
        {
            transform: {
                type: "sort",
                config: { dimension: "total", order: "asc" },
            },
        },
    ];
    return [
        dataset,
        buildDataTable(dataTable, [
            {
                title: "Rute",
                type: "string",
                name: "key",
                align: "left",
            },
            {
                title: "Total Penerbangan",
                type: "number",
                name: "flight_count",
                align: "right",
            },
        ]),
    ];
};
const renderTooltip = (params) => {
    const { data } = params[0];
    return tooltipFormater({
        title: {
            label: `${data[0]} `,
        },
        params,
    });
};

export default {
    getData,
    renderTooltip,
};
