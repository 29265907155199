import { buildDataTable } from "../../../../../helper/buildDataTable";
import { getIdPeriodic } from "../../../../../helper/getIdPeriodic";
import { sortingLabel } from "../../../../../helper/sortingLabel";
import { tooltipFormater } from "../../Chart/tooltipFormater";

const getDataPaxByAirport = (dataSource = [], airportCode, periodic) => {
    if (!dataSource || !airportCode || !periodic) {
        return [[], [], []];
    }
    let result = {};
    let airportsList = {};

    for (let iiii = 0; iiii < dataSource.length; iiii++) {
        const { international, domestic, code, flight_date, ...rest } =
            dataSource[iiii];
        const by = getIdPeriodic(periodic, flight_date);
        if (airportCode === "all") {
            if (!result[by]) {
                result[by] = {
                    totalDom: domestic,
                    totalInt: international,
                };
            } else {
                let sumDom = (result[by].totalDom += domestic);
                let sumInt = (result[by].totalInt += international);

                result[by] = {
                    totalDom: sumDom,
                    totalInt: sumInt,
                };
            }
        } else if (code === airportCode) {
            if (!result[by]) {
                result[by] = {
                    totalDom: domestic,
                    totalInt: international,
                };
            } else {
                let sumDom = (result[by].totalDom += domestic);
                let sumInt = (result[by].totalInt += international);

                result[by] = {
                    totalDom: sumDom,
                    totalInt: sumInt,
                };
            }
        }

        airportsList[code] = {
            code,
            ...rest,
        };
    }
    let set = [];
    let airports = [];
    let dataTable = [];
    for (const key in result) {
        if (Object.hasOwnProperty.call(result, key)) {
            const { totalDom, totalInt, originObj } = result[key];
            const grandTotal = totalDom + totalInt;
            set.push([key, totalDom, totalInt, grandTotal, originObj]);
            dataTable.push({ totalDom, totalInt, periode: key, grandTotal });
        }
    }

    for (const key in airportsList) {
        if (Object.hasOwnProperty.call(airportsList, key)) {
            const airport = airportsList[key];
            airports.push(airport);
        }
    }

    const dataset = [
        {
            dimensions: [
                "label",
                "domestic",
                "international",
                "grandTotal",
                "originObj",
            ],
            source: set,
        },
        {
            transform: {
                type: "sort",
                config: { dimension: "label", order: "asc" },
            },
        },
    ];

    return [
        dataset,
        buildDataTable(dataTable, [
            {
                title: "Periode",
                type: "string",
                name: "periode",
                align: "left",
            },
            {
                title: "Total Domestik",
                type: "number",
                name: "totalDom",
                align: "right",
            },
            {
                title: "Total Internasional",
                type: "number",
                name: "totalInt",
                align: "right",
            },
            {
                title: "Grand Total",
                type: "number",
                name: "grandTotal",
                align: "right",
            },
        ]),
        sortingLabel(airports, "code"),
    ];
};

const renderTooltip = (params, filters) => {
    const { data } = params[0];
    return tooltipFormater({
        title: {
            label: `${
                filters?.airport !== "all" ? `${filters?.airport} - ` : ""
            } ${data[0]} ${filters?.year || ""}`,
        },
        params,
    });
};

export default { getDataPaxByAirport, renderTooltip };
