import less from "less";
export const changeThemeVariable = (variables) => {
    console.log(variables);
    const styleTag = document.getElementById("dynamic-styles");

    // Create a new style tag if it doesn't exist
    if (!styleTag) {
        const newStyleTag = document.createElement("style");
        newStyleTag.id = "dynamic-styles";
        document.head.appendChild(newStyleTag);
    }
    less.modifyVars({ "@primary-color": variables })
        .then(() => {
            console.log("Variable theme changed successfully");
        })
        .catch((error) => {
            console.error("Error changing variable theme", error);
        });
};
