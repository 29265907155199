import React from "react";
import PanelAirportChart from "./AirportChart";
import { useSelector } from "react-redux";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";

const AiportChartByAirline = () => {
  const { filterPage } = useSelector((item) => item.apps);
  const [data, loading] = useDashboardChart({
    ...filterPage,
    routes: "/airline",
    type: "airport",
    doSend: true,
    regenerate: true,
  });
  return <PanelAirportChart data={data} />;
};

export default AiportChartByAirline;
