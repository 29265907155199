import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import PanelChartCargo from "../../../../../../features/PanelChart/Cargo";

export default function AirportCargo() {
    const {
        filterPage,
    } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/airport",
        type: "cargo",
        doSend: true,
        regenerate: true,
    });
    return (
        <PanelChartCargo
            dataSource={data}
            loading={loading}
            filterPage={filterPage}
        />
    );
}
