import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import PanelChartTrafficFlight from "../../../../../../features/PanelChart/TrafficFlight";

export default function TrafficAirport() {
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/flight",
        type: "flight",
        doSend: true,
        regenerate: true,
    });

    return (
        <PanelChartTrafficFlight dataSource={data} filterPage={filterPage} />
    );
}
