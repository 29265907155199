import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import PanelChartAirlineWithRoute from "../../../../../../features/PanelChart/AirlineWithRoute";

export default function FlightAirline() {
    const {
        filterPage,
    } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/flight",
        type: "airline",
        doSend: true,
        regenerate: true,
    });

    return (
        <PanelChartAirlineWithRoute
            dataSource={data}
            loading={loading}
            filterPage={filterPage}
        />
    );
}
