import numeral from "numeral";

export const Version = 1;
export const MajorVersion = 1;
export const MinorVersion = 10;
export const buildNumber = 75;
export const AppVersion = `${Version}.${MajorVersion}.${MinorVersion}.${numeral(
    buildNumber
).format("0000")}`;

export const UpdateHistory = [
    {
        version: `0.0.00.005`,
        update: {
            id: [
                "0.05 Penambahan Menu Penumpang",
                "0.04 Memperbaiki layout yang masih statis",
                "0.03 Integrasi API untuk halaman login",
            ],
            gb: [
                "0.05 Add Passengers Menu",
                "0.04 Fixing layout which still static",
                "0.03 Integration API for login page",
            ],
        },
    },
    {
        version: `0.0.00.002`,
        update: {
            id: ["0.02 Penambahan tampilan page home dashboard kemenhub"],
            gb: ["0.02 Add UI dashboard home page kemenhub"],
        },
    },
    {
        version: `0.0.00.001`,
        update: {
            id: ["0.01 Penambahan tampilan page login dashboard kemenhub"],
            gb: ["0.01 Add UI dashboard login page kemenhub"],
        },
    },
];

export const getUpdateLogs = () => {
    if (UpdateHistory.length < 5) return UpdateHistory;
    const up = [];
    for (let uuu = 0; uuu < 5; uuu++) {
        const u = UpdateHistory[uuu];
        up.push(u);
    }
    return up;
};
