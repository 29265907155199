import React, { useState } from "react";
import MainPage from "../../../components/MainPage";
import moment from "moment";
import Search from "./Search";

const defValue = {
    date: moment(),
    msg_type: null,
};

export default function MessageChecker() {
    const [search, setSearch] = useState(defValue);
    const COLUMNS = [
        {
            title: "flight_number",
            dataIndex: "flight_schedule_id",
            ellipsis: true,
            render: (val, row) => val?.flight_number,
        },
        {
            title: "flight_date",
            dataIndex: "flight_schedule_id",
            ellipsis: true,
            render: (val, row) => val?.flight_date,
        },
        {
            title: "msg_type",
            dataIndex: "msg_type",
            ellipsis: true,
            width: "120px",
        },
        {
            title: "time_missing",
            dataIndex: "time_missing",
            ellipsis: true,
        },
        {
            render: (val, row) =>
                row.createdAt &&
                moment(row.createdAt).format("DD-MMM-YYYY HH:mm"),
            title: "created_at",
        },
    ];
    return (
        <MainPage
            url={"api/v1/message/checker/pagination"}
            searchValue={search}
            columns={COLUMNS}
            searchComponent={
                <Search
                    searchValues={search}
                    onSearchChanged={(values) => {
                        const { date, ...rest } = values;
                        let newModel = { ...rest };
                        if (!date) {
                            newModel = { ...newModel, date: moment() };
                        }

                        setSearch({ ...search, ...newModel });
                    }}
                    onSearchCallback={(searchValue) => {
                        setSearch({
                            ...search,
                            ...searchValue,
                        });
                    }}
                />
            }
        />
    );
}
