import React, { useMemo } from "react";
import utilsForeignCitizen from "./utilsForeignCitizen";
import { resetStyleTooltips } from "../../Chart/resetStyleTooltip";
import customFormat from "../../../../../helper/customFormat";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import DashboardChart from "../../../pages/dashboard/home/components/DashboardChartV2";

const MAX_ITEM_BAR = 14;
const MAX_PERCENT_ITEM = 100;

const getOptions = (dataSet, getLenghtData, getPersen, fontSize) => {
    return {
        dataset: dataSet,
        tooltip: {
            trigger: "axis",
            ...resetStyleTooltips,
            formatter: (params) => utilsForeignCitizen.renderTooltip(params),
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            left: "0%",
            right: "5%",
            bottom: "12%",
            containLabel: true,
            top: "10%",
        },
        xAxis: {
            type: "category",
            axisLabel: {
                textStyle: {
                    fontSize: fontSize.sm,
                },
            },
        },

        yAxis: {
            type: "value",
            axisLabel: {
                formatter: (params) => customFormat(params),
            },
        },
        dataZoom: getPersen.toFixed() > 0 &&
            getPersen.toFixed() < MAX_PERCENT_ITEM && [
                {
                    type: "slider",
                    start: 0,
                    orient: "horizontal",
                    // right: 24,
                    end: !!getLenghtData ? getPersen.toFixed() : 100,
                    handleSize: 8,
                    bottom: 20,
                    height: 12,
                },
                {
                    type: "inside",
                    start: 0,
                    end: !!getLenghtData && 1 ? getPersen.toFixed() : 100,
                    orient: "horizontal",
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true,
                },
            ],
        series: [
            {
                name: `Jumlah Penumpang`,
                type: "bar",
                symbol: "none",
                barWidth: 16,
                barGap: 0,
                datasetIndex: 0,
                encode: { x: "label", y: "total" },
                emphasis: {
                    focus: "series",
                },
                itemStyle: {
                    color: "#023047",
                },
            },
        ],
    };
};

export default function ForeignCitizen({ dataSource, filterPage }) {
    const fontSize = useFontSizeV2();

    const [dataset, dataTable] = useMemo(
        () => utilsForeignCitizen.getDataPax(dataSource),
        [dataSource]
    );

    const [getLenghtData, getPersen] = useMemo(() => getData(dataset), [dataset]);

    return (
        <DashboardChart
            // loading={loading}
            printHeader={`Kewarganegaraan Asing Report`}
            printPeriode={`${filterPage.year}`}
            repType={`Kewarganegaraan Asing Report`}
            title={"Kewarganegaraan Asing"}
            dataSource={dataTable}
            data={getOptions(dataset, getLenghtData, getPersen, fontSize)}
        />
    );
}

const getData = (dataset) => {
    if (!Array.isArray(dataset)) return [false, 0];
    let isLenghtData =
        !!dataset[0]?.source && dataset[0]?.source?.length > 10;
    let percent = isLenghtData
        ? (MAX_ITEM_BAR / dataset[0]?.source?.length) * 100
        : 0;
    return [isLenghtData, percent];
}
