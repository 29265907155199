import React, { Fragment, useState } from "react";
import CardMaps from "../../../../../components/Maps/CardMaps";
import { Typography, Divider, Col, Row } from "antd";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import RowList from "../../RowList";
import { useEffect } from "react";

function getInitials(name) {
  const words = name.split(" ");
  const initials =
    words.length >= 2 &&
    words.map((word) => word.charAt(0).toUpperCase()).join("");

  return initials;
}

function sliceData(data, intervalLength) {
  const slicedData = [];
  let startIndex = 0;

  while (startIndex < data.length) {
    const endIndex = Math.min(startIndex + intervalLength, data.length);
    slicedData.push(data.slice(startIndex, endIndex));
    startIndex += intervalLength;
  }

  return slicedData;
}

const RenderTooltips = ({ data }) => {
  const fontSize = useFontSizeV2();
  const [index, setIndex] = useState(0);
  const { airlines, code, country, kota, name, operator } = data;

  const directory = (code) => {
    return `/assets/carrier/${code}.png`;
  };

  const slicedDatas = sliceData(airlines, 5);

  useEffect(() => {
    if (index <= slicedDatas.length - 1) {
      const time = setInterval(() => {
        setIndex((state) => state + 1);
      }, 4000);
      return () => clearInterval(time);
    } else {
      setIndex(0);
    }
  }, [data, index]);

  //   console.log({ airlines });

  return (
    <div>
      <Typography
        style={{
          fontSize: fontSize.xs,
          fontWeight: "bold",
        }}
      >
        {name}
      </Typography>
      <Divider style={{ margin: "8px 0px" }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          gap: 8,
          marginBottom: 10,
          flexWrap: "wrap",
        }}
      >
        {slicedDatas[index]?.map((item) => {
          const initial = getInitials(item.name);

          return (
            <Fragment>
              {!!item?.code && (
                <img
                  src={
                    !!item?.code
                      ? directory(item?.code?.toLowerCase())
                      : item?.code === "UN"
                      ? "/assets/carrier/no-image.png"
                      : "/assets/carrier/no-image.png"
                  }
                  style={{ width: 40 }}
                  title={item.name}
                  alt={initial}
                  onError={(err) => {
                    console.log(err);
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </div>
      <RowList title={"Airport Code"} description={code} isBoldDescription />
      <RowList title={"Country"} description={country} isBoldDescription />
      <RowList title={"City"} description={kota} isBoldDescription />
      <RowList title={"Operator"} description={operator} isBoldDescription />

      <RowList
        title={"Total"}
        description={airlines.length}
        isBoldDescription
      />
    </div>
  );
};

export default function MapAirlineActive({ dataMaps, isMobile, title }) {
  return (
    <CardMaps
      isPlane
      data={dataMaps}
      fullscreen
      height={isMobile ? "65%" : "100%"}
      title={<Fragment>{title || "Peta Maskapai Aktif"}</Fragment>}
      customMarkRadius={10000}
      customTooltip={(data) => <RenderTooltips data={data} />}
    />
  );
}
