import React from "react";
import { Radio } from "antd";
import { createUseStyles } from "react-jss";
import { useFontSizeV2 } from "../../../../hooks/useDimension";

const useStyles = createUseStyles({
    radioStyle: {
        "& .ant-radio-button-wrapper": {
            marginRight: "4px",
            borderRadius: "6px",
            borderLeftWidth: 1.02,
            fontSize: ({ fontSize }) => fontSize.xxs,
            fontWeight: "bold",
        },
        "& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before":
            {
                display: "none",
            },
        "& .ant-radio-button-wrapper:not(:first-child)::before": {
            display: "none",
        },
    },
});

export default function MainRadioGroup({ ...props }) {
    const fontSize = useFontSizeV2();
    const classes = useStyles({ fontSize });
    return (
        <Radio.Group
            size="small"
            optionType="button"
            className={classes.radioStyle}
            {...props}
        />
    );
}
