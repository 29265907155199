import React, { useMemo, useState } from 'react';

import Table from '../../lists/table';
import { Button, Select } from 'antd';
import {FileExcelOutlined} from '@ant-design/icons';
import { green } from '@ant-design/colors';
import { RiCheckDoubleFill } from 'react-icons/ri';

const OtherPanel=({ onExportBtnClick, data, setData })=>{
    return (
        <React.Fragment>
            <Button onClick={onExportBtnClick}  style={{background:green[8], borderRadius:99}} icon={<FileExcelOutlined style={{color:'white'}} />} type="text" />
        </React.Fragment>
    )
}

export default ({data}) => {
    const numberedData=useMemo(()=>{
        return data.map((row, idx)=>({...row, number:idx+1}));
    }, [data]);
    return (
        <Table
            data={numberedData}
            filterField={['awb_number', 'packet_desc']}
            url="/cargo/detail"
            getRowId={row=>row?.awb_id?._id}
            columns={[
                {
                    title:'#',
                    dataIndex:'number',
                },
                {
                    dataIndex:'flight_schedule_id',
                    title:'flight_number',
                    render:(val)=>val.flight_number
                },
                {
                    dataIndex:'flight_schedule_id',
                    title:'flight_date',
                    render:(val)=>val.flight_date
                },
                {
                    title:'uld',
                    dataIndex:'uld',
                },
                {
                    title:'awb_number',
                    dataIndex:'awb_number'
                },
                {
                    title:'unload_point',
                    dataIndex:'unload_point',
                    render:(val)=>val?.code
                },
                {
                    title:'route',
                    dataIndex:'origin',
                    render:(val, rec)=>`${rec.origin}-${rec.dest}`
                },
                {
                    title:'packet_desc',
                    dataIndex:'packet_desc',
                },
                {
                    title:'shc',
                    dataIndex:'shc',
                    render:(val)=>val.join(',')
                },
                {
                    title:'partial',
                    dataIndex:'partial',
                    align:'right'
                },
                {
                    title:'split',
                    dataIndex:'split',
                    align:'right'
                },
                {
                    title:'pieces',
                    dataIndex:'pieces',
                    align:'right'
                },
                {
                    title:'weight',
                    dataIndex:'weight',
                    align:'right'
                },
                {
                    title:'volume',
                    dataIndex:'volume',
                    align:'right'
                },
            ]}
        />
    )
}   