import React, { useState, useMemo } from "react";
import DashboardChart from "./DashboardChart";
import * as echarts from "echarts"; // Import ECharts library
import moment from "moment";
import customFormat from "../../../../../../helper/customFormat";
import { useDispatch, useSelector } from "react-redux";
import { useDashboardChart } from "../../../../../../hooks/useDashboardChart";
import {
  setDashboardState,
  setFilterPage,
} from "../../../../../../redux/reducer/apps";
import { useEffect } from "react";
import groupedData, {
  mergedDataByProp,
} from "../../../../../../helper/groupedDataDaily";
import { useFontSizeV2 } from "../../../../../../hooks/useDimension";
import { useHistory } from "react-router-dom";
import Config from "../../../../../../config/env";
const { chartColor } = Config;

const MaskapaiChart = () => {
  const { dashboardState, filterPage } = useSelector((item) => item.apps);
  const [viewProd, setViewProd] = useState("daily");
  const [direct, setDirect] = useState("");
  const dispatch = useDispatch();
  const fontSize = useFontSizeV2();
  const history = useHistory();

const [dataAirlineV2, loadingAirlineV2] = useDashboardChart({
    ...filterPage,
    type: "airlinev2",
    doSend:
        dashboardState.chart === "all" ||
        dashboardState.chart === "maskapai",
});

const [dataSHC, loadingSHC] = useDashboardChart({
    ...filterPage,
    type: "shc",
    doSend:
        dashboardState.chart === "all" ||
        dashboardState.chart === "Special Handling (Tons)".toLowerCase(),
    routes: "/cargov2",
});

const dataAirlinesv2 = useMemo(() => getData(dataAirlineV2), [dataAirlineV2, filterPage]);

  const airlines = useMemo(() => getAirLines(dataAirlinesv2), [dataAirlinesv2, filterPage]);

  const series = getSeries(airlines)

  const idx = useMemo(() =>  series[0]?.data?.length > series[1]?.data?.length ? 0 : 1, [filterPage, filterPage]);



  useEffect(() => {
    dispatch(setFilterPage({ direct }));
    dispatch(setDashboardState({ chart: direct === "" ? "all" : "maskapai" }));
  }, [direct]);

  const handleClickItem = (params) => {
    console.log({ params });
  };

  const dataTable = useMemo(() => getTables(dataAirlinesv2), [dataAirlinesv2]);

  return (
    <DashboardChart
      repType={"Maskapai"}
      printHeader={"Maskapai Report"}
      dataSource={dataTable}
      OnClick={handleClickItem}
      loading={loadingAirlineV2}
      direct={direct}
      setDirect={setDirect}
      view={
        dashboardState?.filterState === "all"
          ? dashboardState?.filterTime
          : viewProd
      }
      setView={setViewProd}
      //   isOtherComponent
      title={"Maskapai"}
      data={optionsMaskapai(series, dataAirlinesv2, airlines, idx, fontSize)}
    />
  );
};

export default MaskapaiChart;

const columns = [
  {
    title: "Maskapai",
    type: "string",
    name: "maskapai",
    align: "left",
  },
  {
    title: "Total",
    type: "number",
    name: "total",
    align: "right",
  },
];

const getTables = (data) =>  {
  const dataSource = [];
  const keys = Object.keys(data);
  keys?.forEach((item) => {
    dataSource.push({
      maskapai: item,
      total: data[item]?.reduce((prev, current) => prev + current.curr, 0),
    });
  });

  return {
    data: dataSource,
    fields: columns,
    header: columns.map((item) => item.title),
  };
}

const optionsMaskapai = (series, dataAirlinesv2, airlines, idx, fontSize) => {
  const stackInfo = {};
  for (let i = 0; i < series[idx].data.length; ++i) {
    for (let j = 0; j < series.length; ++j) {
      const stackName = series[j].stack;
      if (!stackName) {
        continue;
      }
      if (!stackInfo[stackName]) {
        stackInfo[stackName] = {
          stackStart: [],
          stackEnd: [],
        };
      }
      const info = stackInfo[stackName];
      const data = series[j].data[i];
      if (data && data !== "-") {
        if (info.stackStart[i] == null) {
          info.stackStart[i] = j;
        }
        info.stackEnd[i] = j;
      }
    }
  }
  for (let i = 0; i < series.length; ++i) {
    const data = series[i].data;
    const infoData = series[i].info;
    const info = stackInfo[series[i].stack];
    for (let j = 0; j < series[i].data.length; ++j) {
      const isEnd = info?.stackEnd[j] === i;
      const topBorder = isEnd ? 10 : 0;
      const bottomBorder = 0;
      data[j] = {
        value: data[j],
        info: infoData,
        border: topBorder,
      };
    }
  }

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
      // formatter: (params) => console.log(params),
    },
    legend: {
      bottom: -5,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "15%",
      containLabel: true,
      top: "5%",
    },
    dataZoom: [
      {
        type: "inside",
        start: 1,
        end:
        dataAirlinesv2.length < 20
            ? 100
            : dataAirlinesv2.length < 50
            ? 30
            : dataAirlinesv2.length < 100
            ? 20
            : 10,
      },
      {
        show: true,
        type: "slider",
        start: 1,
        end: 100,
        height: 15,
        bottom: "15%",
      },
    ],
    xAxis: {
      type: "category",
      data:
        airlines?.reguler.length > airlines?.carter.length
          ? airlines?.reguler.map((item) => item.name)
          : airlines?.reguler.length > airlines?.freighter.length
          ? airlines?.reguler.map((item) => item.name)
          : airlines?.carter.length > airlines?.freighter.length
          ? airlines?.carter.map((item) => item.name)
          : airlines?.freighter.map((item) => item.name),
      axisLabel: {
        textStyle: {
          fontSize: fontSize.sm,
        },
        // formatter:
        //   viewProd === "daily"
        //     ? (params) => moment(params).format("DD MMM")
        //     : (params) => `${params}`,
        // // interval: ,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (params) => customFormat(params),
      },
    },
    series: series,
  };
  return option;
};

const getData = (airlines) =>  {
  const sortData =
      !!airlines &&
      airlines
          ?.map(({ reguler, carter, freighter, _id, code, name }) => ({
              reguler,
              carter,
              freighter,
              name,
              code,
              _id,
              sum_data: reguler + carter + freighter,
          }))
          .sort((a, b) => b.sum_data - a.sum_data);
  const dataRef = !!airlines ? sortData : [];
  const datareguler = dataRef?.map(({ reguler, code, name }) => ({
      curr: reguler,
      code,
      airport: name,
  }));
  const datacarter = dataRef?.map(({ carter, code, name }) => ({
      curr: carter,
      code,
      airport: name,
  }));
  const datacfreighter = dataRef?.map(({ freighter, code, name }) => ({
      curr: freighter,
      code,
      airport: name,
  }));
  return {
      reguler: datareguler,
      carter: datacarter,
      freighter: datacfreighter,
  };
}

const getAirLines = (airlines) =>  {
  const { reguler, carter, freighter } = airlines;
  const regulerData = mergedDataByProp(reguler, "code");
  const carterData = mergedDataByProp(carter, "code");
  const freighterData = mergedDataByProp(freighter, "code");
  return {
    reguler: regulerData,
    carter: carterData,
    freighter: freighterData,
  };
}

const getSeries = (airlines) => {
  return [
    {
      name: "Reguler",
      type: "bar",
      stack: "total",
      itemStyle: { color: "#FFB703" },
      emphasis: {
        focus: "series",
      },
      data: airlines?.reguler?.map((item) => item.curr),
      info: {
        name: "Dicka",
      },
    },
    {
      name: "Freighter",
      type: "bar",
      stack: "total",
      itemStyle: {
        color: "#023047",
      },
      emphasis: {
        focus: "series",
      },
      data: airlines?.freighter?.map((item) => item.curr),
      info: {
        name: "Ferry",
      },
    },
    {
      name: "Charter",
      type: "bar",
      stack: "total",
      itemStyle: { color: "#FB8500" },
      emphasis: {
        focus: "series",
      },
      data: airlines?.carter?.map((item) => item.curr),
      info: {
        name: "Arya",
      },
    },
  ];
}