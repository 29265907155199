import React from 'react';
import { GiAirplaneArrival, GiAirplaneDeparture, GiGlobe, GiHouse } from 'react-icons/gi';
import { useParams } from 'react-router-dom';
import Detail from '../components/pages/detail';
import DetailComponent from '../components/details/cargo/domestic';
export default () => {
    const {direction, id} = useParams();
    
    const Icon=GiGlobe;
    return (
        <Detail 
            title={`domestic`} 
            icon={Icon} 
            id={id}
            url={`api/v1/manifest/detail/flight_schedule_id`}
            getBreadcumbValue={(data)=>data[0]?.flight_number}
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'flight_schedule'
                    },                    
                    {
                        item:'domestic',
                        href:'/flight/domestic'
                    },
                    {
                        item:'cargo'
                    },
                ]
            }
            DetailComponent={DetailComponent}
            additionalData={{direction, id}}
        />
    )
}