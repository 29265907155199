import React, { useEffect, useState } from "react";
import LogoKemenhub from "../../../../assets/images/logo-kemenhub.png";
import { useFontSizeV2 } from "../../../../hooks/useDimension";
import { Button, Checkbox, Form, Image, Input, Typography } from "antd";
import { createUseStyles } from "react-jss";
import { ReloadOutlined } from "@ant-design/icons";
import { CreateRandomString } from "../../../login/utils";
import { useDispatch } from "react-redux";
import { GetAPI } from "../../../../redux";
import { login } from "../../../../redux/reducer/auth";
import { useCallback } from "react";

const useStyles = createUseStyles({
  inputStyle: {
    "& .ant-input": {
      borderRadius: "8px",
      border: "1px solid #AADAB7",
      height: ({ fontSize }) => fontSize.xl * 2,
    },
  },
  inputPassStyle: {
    "& .ant-input-affix-wrapper.ant-input-password": {
      borderRadius: "8px !important",
      border: "1px solid #AADAB7",
    },
    "& .ant-input": {
      height: ({ fontSize }) => fontSize.xl * 1.5,
    },
  },
  customLabel: {
    // width: ({ fontSize }) => fontSize.xl * 5,
    // fontWeight: "bold", // Customize label text color
    fontSize: ({ fontSize }) => fontSize.sm + 2, // Customize label text size
    // Add other styles as needed
  },
});

const Login = ({ apps }) => {
  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  const fontSize = useFontSizeV2();
  const classes = useStyles({ fontSize });
  const createRandomStr = () => {
    const timestamps = new Date().getTime();
    return timestamps + "_" + CreateRandomString(16);
  };
  const [refresh, setRefresh] = useState(createRandomStr());

  const loadImage = () => {
    dispatch(GetAPI({ url: "auth/captcha/" + refresh })).then((resp) => {
      if (!!resp?.payload) {
        setImage(resp.payload.data);
      }
    });
  };
  useEffect(() => {
    loadImage();
  }, [refresh]);

  const onFinish = useCallback(
    (values) => {
      dispatch(login({ ...values, app: apps, token: refresh }));
    },
    [refresh]
  );

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <div style={{ padding: "10px" }}>
        <img
          src={LogoKemenhub}
          alt="logo"
          style={{ width: fontSize.xl3 * 8 }}
        />
      </div>
      <Typography
        style={{
          textAlign: "center",
          color: "#06a3da",
          //   fontWeight: "bold",
          fontSize: "35px",
          marginTop: "10px",
        }}
      >
        LOGIN
      </Typography>
      <div style={{ width: "100%", padding: "10px" }}>
        <Form
          name="basic"
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
            layout: "vertical",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label={<span className={classes.customLabel}>Username</span>}
            className={classes.inputStyle}
            name={"user"}
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={classes.inputPassStyle}
            label={<span className={classes.customLabel}>Password</span>}
            name={"password"}
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div
            style={{
              display: "flex",
              alignItems: "",
              flexDirection: "row-reverse",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                padding: 8,
                gap: 8,
                alignItems: "center",
                width: "50%",
              }}
            >
              <Button
                icon={<ReloadOutlined />}
                type="ghost"
                onClick={() => setRefresh(createRandomStr())}
              />
              <Image src={image} height={64} width={"100%"} />
            </div>
            <Form.Item
              style={{ width: "50%" }}
              label={<span className={classes.customLabel}>Captcha Code</span>}
              className={classes.inputStyle}
              name="captcha"
              // label="Captcha Result"
              required
            >
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 0,
              span: 24,
            }}
          >
            <Checkbox>Ingat saya</Checkbox>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 24,
            }}
          >
            <Button
              size="large"
              style={{
                width: "100%",
                background: "#57b4ce",
                border: "none",
                borderRadius: "8px",
                height: fontSize.xl * 2,
              }}
              type="primary"
              htmlType="submit"
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
