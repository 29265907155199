import React, { useMemo } from "react";
import { useSelector } from "react-redux";

export default function IcAirport({ isDark, width, height }) {
  const { dashboardState } = useSelector((item) => item.apps);
  const [classDark, classLight] = useMemo(() => {
    return !isDark
      ? [` icon-svg-new fil0-dark`, ` icon-svg-new fil1-dark`]
      : [` icon-svg-new fil0-light`, ` icon-svg-new fil1-light`];
  }, [isDark, dashboardState.summaryCard]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 39989 37883"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2924688906768">
          <path
            className={classDark}
            d="M39323 36551c368,0 666,299 666,666 0,368 -298,666 -666,666l-2555 0 -7421 0 -7027 0 -21563 0c-368,0 -666,-298 -666,-666 0,-41 4,-81 11,-120l2293 -24834 -2361 -7128c-115,-349 75,-725 424,-840 69,-23 139,-34 208,-34l15390 0c368,0 666,298 666,666 0,83 -15,162 -43,235l-2352 7101 304 3289 24692 0c368,0 666,298 666,666l0 4514c0,368 -298,666 -666,666l-1889 0 0 15153 1889 0zm-17669 -8868c0,-368 299,-666 666,-666 368,0 666,298 666,666l0 8868 5694 0 0 -8868c0,-368 299,-666 667,-666 367,0 665,298 665,666l0 8868 6090 0 0 -15153 -6090 0 0 3957c0,368 -298,666 -665,666 -368,0 -667,-298 -667,-666l0 -3957 -5694 0 0 3957c0,368 -298,666 -666,666 -367,0 -666,-298 -666,-666l0 -3957 -6483 0 1399 15153 5084 0 0 -8868zm-6900 -10799l293 3182 7273 0 7027 0 7421 0 1889 0 0 -3182 -23903 0zm-1706 -4038l-9375 0 -2188 23705 13752 0 -2189 -23705zm2086 -7253l-13546 0 1961 5921 9624 0 1961 -5921z"
          />
          <path
            className={classLight}
            d="M18221 10639c-367,0 -664,-297 -664,-664 0,-366 297,-663 664,-663l7074 -10 701 -834c613,-731 1802,-40 1162,684l-3744 4460 850 -6 7208 -4889 1301 -882c413,-280 1003,26 1020,538 7,247 -121,466 -317,587l-474 322 2275 -7c1527,-4 2421,-288 2767,-673 77,-86 105,-173 88,-253 -31,-143 -157,-308 -371,-480 -855,-683 -2731,-1177 -5492,-879 -16,2 -32,4 -48,5l-13070 1427c-312,33 -597,-155 -697,-439l-885 -2092c-142,-338 16,-727 354,-869 338,-142 727,16 869,354l707 1671 6603 -721 -798 -980 1 0c-81,-100 -135,-224 -147,-362 -31,-364 239,-686 604,-718l1875 -167c106,-18 218,-9 327,29l4292 1527c3082,-309 5268,323 6332,1173 463,370 749,799 844,1240 110,504 -19,994 -400,1419 -573,639 -1810,1110 -3755,1115l-4239 12 -6196 4202c-114,77 -242,114 -370,115l-2490 18c-366,2 -664,-294 -666,-661 0,-163 58,-312 155,-428l-1 -1 2710 -3228 -5959 8zm-9194 -7724c0,368 -298,666 -666,666 -368,0 -666,-298 -666,-666l0 -2249c0,-368 298,-666 666,-666 368,0 666,298 666,666l0 2249zm3672 17151c368,0 666,298 666,666 0,368 -298,666 -666,666l-8677 0c-367,0 -665,-298 -665,-666 0,-368 298,-666 665,-666l8677 0zm22187 4623c368,0 666,298 666,666 0,368 -298,666 -666,666l-18092 0c-368,0 -666,-298 -666,-666 0,-368 298,-666 666,-666l18092 0zm-5667 -18703l-1566 -557 -530 47 552 678 1544 -168z"
          />
        </g>
      </g>
    </svg>
  );
}
