import AppPage from './pages/home';
import FlightScheduleRoutes from './routes/flight_schedule';
import VesselRoutes from './routes/vessel';
import DashboardRoutes from './routes/dashboard';
import PassengersRoutes from './routes/passengers';
import TelexRoutes from './routes/telex';
import PnrRoutes from './routes/pnr';
import OthersRoutes from './routes/others';
import FlMasterRoutes from './routes/master';
import DomesticRoutes from './routes/domestic';
import SearchPage from './pages/search';
import CitizenRoutes from './routes/citizen';
import HomeDashboard from './pages/landing';
import { UserPageMobile } from '../interactive_mobile/';
import CargoRoutes from './routes/cargo';
export default [
    {
        path: '/',
        exact: true,
        // Component:HomeDashboard
        redirect: '/home'
    },
    {
        path: '/v2',
        exact: true,
        Component: UserPageMobile
    },
    {
        path: '/home',
        exact: true,
        Component: HomeDashboard
    },
    {
        path: '/search',
        exact: true,
        Component: SearchPage
    },
    ...DashboardRoutes,
    ...FlMasterRoutes,
    ...DomesticRoutes,
    ...FlightScheduleRoutes,
    ...VesselRoutes,
    ...PnrRoutes,
    ...PassengersRoutes,
    ...CitizenRoutes,
    ...TelexRoutes,
    ...OthersRoutes,
    ...CargoRoutes
].filter(x => !!x)