// import { Button } from '@material-ui/core';
// import { green } from '@material-ui/core/colors';
import React from "react";
// import Xls from 'exceljs';
import { downloadWatchlistTemplate, parseWatchlist } from "./excelParser";
import { Button, Upload } from "antd";
import { green } from "@ant-design/colors";
import { FileExcelOutlined, FileExcelFilled } from "@ant-design/icons";

export const UploadWatchlist = ({ onFormSubmit }) => {
    const readExcel = (file) => {
        if (!file) return false;
        const reader = new FileReader();

        reader.onload = async (ev) => {
            const excelData = ev.target.result;
            console.log({ excelData });
            const data = await parseWatchlist(excelData);
            if (!!onFormSubmit && typeof onFormSubmit === "function") {
                onFormSubmit(data);
            }
        };
        reader.readAsArrayBuffer(file);
    };
    return (
        <div>
            <Upload
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                beforeUpload={(file) => {
                    readExcel(file);
                    return false;
                }}
                name="excel-upload"
                showUploadList={false}
            >
                <Button
                    style={{
                        borderColor: green[5],
                        backgroundColor: green[6],
                        color: "white",
                    }}
                    icon={<FileExcelOutlined />}
                >
                    Upload
                </Button>
            </Upload>
        </div>
    );
};

export const DonwloadTemplateWatchlist = () => {
    return (
        <Button
            variant="outlined"
            style={{
                borderColor: green[5],
                backgroundColor: green[6],
                color: "white",
            }}
            icon={<FileExcelFilled />}
            onClick={() => downloadWatchlistTemplate()}
        >
            Download Template
        </Button>
    );
};
