import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { CacheRoute } from "react-router-cache-route";
import Dashboard from "./dashboard";
import DashboardAnalytic from "./dashboard-analytic";
import DashboardAnalyticMobile from "./dashboard-analytic-mobile";
import Interactive from "./interactive";
import AppsV2 from "./appsv2";
import AppsV3 from "./appsv3";
import ExecutiveSummary from "./executive_summary";
import { useState, useEffect } from "react";
import InteractiveMobile from "./interactive_mobile";
import Geojs from "./maps";
import Documents from "./document";

export default () => {
  const MAX_RES_MOBILE = 1200;

  const [sizeWindow, setSizeWindow] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setSizeWindow(window.innerWidth);
    });
  }, [window.innerWidth]);

  return (
    <BrowserRouter>
      <Route path="/sipp">
        {sizeWindow < MAX_RES_MOBILE ? (
          <Redirect to="/m" />
        ) : (
          <Redirect to="/apps" />
        )}
      </Route>
      <CacheRoute path="/apps">
        {sizeWindow < MAX_RES_MOBILE ? <Redirect to="/m" /> : <Interactive />}
      </CacheRoute>
      <CacheRoute path="/m">
        {sizeWindow < MAX_RES_MOBILE ? (
          <InteractiveMobile />
        ) : (
          <Redirect to="/apps" />
        )}
      </CacheRoute>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/" exact>
        {sizeWindow > MAX_RES_MOBILE ? (
          <DashboardAnalytic />
        ) : (
          <DashboardAnalyticMobile />
        )}
      </Route>
      <CacheRoute path="/dashboard-analytic">
        {sizeWindow < MAX_RES_MOBILE ? (
          <DashboardAnalyticMobile />
        ) : (
          <DashboardAnalytic />
        )}
      </CacheRoute>
      <CacheRoute path="/m">
        {sizeWindow < MAX_RES_MOBILE ? (
          <DashboardAnalyticMobile />
        ) : (
          <Redirect to="/dashboard-analytic" />
        )}
      </CacheRoute>
      <Route path="/maps">
        <Geojs />
      </Route>
    </BrowserRouter>
  );
};
