import React from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useDashboardChart } from "../../../../../../hooks/useDashboardChart";
import NotFoundData from "../../../../../../components/NotFound";
import { useMemo } from "react";
import PenumpangChart from "./PenumpangChart";
import AirportPaxTrend from "./Airport/PaxTrend";
import CargoDomesticChart from "./Cargo/Domestic";
import AirlinePaxChart from "./Airline/Pax";
import TrendAirline from "./Flight/TrendAirline";
import PaxDomestic from "./Pax/Domestic";
import { useQueryUrl } from "../../../../../../hooks/useQueryUrl";

const FirstCharts = () => {
    const {
        dashboardState: { summaryCard },
    } = useSelector((item) => item.apps);
    const query = useQueryUrl();

    return renderContent(summaryCard || query.get("s"));
};

const WrapperPaxChart = () => {
    const {
        filterPage,
        dashboardState: { summaryCard },
        dashboardState,
    } = useSelector((item) => item.apps);

    const [dataPaxV2, loadingPaxV2] = useDashboardChart({
        ...filterPage,
        type: "paxv2",
        doSend:
            dashboardState.chart === "all" ||
            dashboardState.chart === "penumpang",
    });

    const [dataDomv2, loadingDomv2] = useDashboardChart({
        ...filterPage,
        type: "cargov2",
        doSend:
            dashboardState.chart === "all" ||
            dashboardState.chart === "domestik",
        routes: "/cargov2",
    });

    const dataPassengers = useMemo(
        () => getPassengers(dataPaxV2),
        [dataPaxV2, filterPage]
    );
    const dataCargoDom = useMemo(
        () => cargoDom(dataDomv2),
        [dataDomv2, filterPage]
    );

    if (!dataPaxV2 && !dataDomv2) {
        return <NotFoundData />;
    }
    return (
        <PenumpangChart
            datatable={dataPaxV2}
            data={dataPassengers}
            loading={loadingPaxV2}
        />
    );
};

const renderContent = (summary) => {
    switch (summary) {
        case "cargo":
            return <CargoDomesticChart />;
        case "airline":
            return <AirlinePaxChart />;
        case "airport":
            return <AirportPaxTrend />;
        case "flight":
            return <TrendAirline />;
        case "pax":
            return <PaxDomestic />;
        default:
            return <WrapperPaxChart />;
    }
};

const getPassengers = (data) => {
    const dataRef = !!data ? data : [];
    const dataAll = dataRef?.map(
        ({ _id, domestic, international_in, international_out }) => ({
            name: _id,
            curr: domestic + international_in + international_out,
        })
    );
    const dataDom = dataRef?.map(({ _id, domestic }) => ({
        name: _id,
        curr: domestic,
    }));
    const dataInt_in = dataRef.map(({ _id, international_in }) => ({
        name: _id,
        curr: international_in,
    }));
    const dataInt_out = dataRef.map(({ _id, international_out }) => ({
        name: _id,
        curr: international_out,
    }));
    return {
        all: dataAll,
        int_in: dataInt_in,
        int_out: dataInt_out,
        dom: dataDom,
    };
};

const cargoDom = (data) => {
    const dataRef = !!data ? data : [];
    const dom = dataRef?.map(({ _id, domestic }) => ({
        name: _id,
        curr: domestic,
    }));
    return {
        dom,
    };
};

export default FirstCharts;
