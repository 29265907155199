import { Typography } from "antd";
import { Header } from "antd/lib/layout/layout";
import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { UserProfile } from '../../../components/layouts/userProfile';
import Profile from "./profile";
import SearchMenu from "../../../components/searchMenu";
import CFG from "../../../config/env";
// import Menu from '../menu';
import Languages from "../../../components/layouts/languages";
import { LightenDarkenColor } from "../utils";
import Menus, { SingleMenu } from "./menu";
import { useMenu } from "./useMenu";
import { useLanguage } from "../../../hooks/useLanguage";
import { RiSearch2Fill, RiDashboard2Fill } from "react-icons/ri";
const { Title, BgColor, NoVessel } = CFG;

export default () => {
  const menuColor = LightenDarkenColor(BgColor, -10);
  const [filteredMenu] = useMenu("");
  const {
    userdata: { level, subdit },
  } = useSelector((state) => state.auth);
  const [, getLan] = useLanguage();
  const [flight, vessel] = useMemo(() => {
    if ((level & 4) > 0) {
      const str = subdit.toLowerCase();
      if (str === "all") {
        return [true, true];
      } else if (str === "laut") {
        return [false, true];
      } else if (str === "udara") {
        return [true, false];
      } else if (str === "darat") {
        return [false, false];
      }
    }
    return [true, true];
  }, [level, subdit]);
  return (
    <Header
      style={{
        display: "flex",
        flexDirection: "column",
        background: "none",
        padding: 0,
        rowGap: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: 32,
          gap: 16,
          background: BgColor,
          paddingInline: 16,
          alignItems: "center",
        }}
      >
        <Link to={"/home"}>
          <Typography.Text
            title={Title}
            ellipsis
            style={{ color: "white", fontSize: "1.1rem" }}
          >
            {Title}
          </Typography.Text>
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifySelf: "end",
            flexGrow: 1,
            alignItems: "center",
            gap: 32,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              flexGrow: 1,
              alignItems: "center",
            }}
          >
            <Languages withCountry />
            <SearchMenu menu={filteredMenu} />
          </div>
        </div>
        <Profile />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            borderTop: `22px solid ${menuColor}`,
            width: 920,
            borderLeft: "22px solid transparent",
            borderRight: "22px solid transparent",
            position: "absolute",
          }}
        ></div>
        <div
          style={{
            background: menuColor,
            display: "flex",
            flexDirection: "row",
            gap: 24,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9,
          }}
        >
          <SingleMenu
            Icon={RiDashboard2Fill}
            background={menuColor}
            caption="dashboard"
            color={"white"}
            linkTo="/dashboard-analytic/home"
            isAnchor
          />
          <SingleMenu
            Icon={RiSearch2Fill}
            background={menuColor}
            caption="search"
            color={"white"}
            linkTo="/search"
          />
          {!!flight && (
            <Menus background={menuColor} category={"flight"} color="white" />
          )}
          {!NoVessel && vessel && (
            <Menus background={menuColor} category={"vessel"} color="white" />
          )}
          <Menus background={menuColor} category={"report"} color="white" />
          <Menus background={menuColor} category={"poi"} color="white" />
          <Menus background={menuColor} category={"passengers"} color="white" />
          {(level & 0x1ff0) > 0 && (
            <Menus background={menuColor} category={"telex"} color="white" />
          )}
          <Menus background={menuColor} category={"others"} color="white" />
        </div>
      </div>
    </Header>
  );
};
