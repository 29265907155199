import React from "react";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";
import MapAirportActive from "../../../../../../features/PanelChart/MapAirportActive";
import { logaritRadiusMarkMap } from "../../../../../../../../helper/logaritRadiusMarkMap";
import numeral from "numeral";
import { useFontSizeV2 } from "../../../../../../../../hooks/useDimension";
import { Typography, Divider } from "antd";
import RowList from "../../../../../../features/RowList";

const RenderTooltips = ({ data }) => {
    const fontSize = useFontSizeV2();
    const { name, international, domestic, code } = data;

    return (
        <div>
            <Typography
                style={{
                    fontSize: fontSize.xs,
                    fontWeight: "bold",
                    textAlign: "center",
                }}
            >
                {code} - {name}
            </Typography>
            <Divider style={{ margin: "8px 0px" }} />

            <RowList
                title={"Domestik"}
                description={numeral(domestic).format("0,000")}
                isBoldDescription
            />
            <RowList
                title={"Internasional"}
                description={numeral(international).format("0,000")}
                isBoldDescription
            />
            <Divider style={{ margin: "8px 0px" }} />
            <RowList
                title={"Grand Total"}
                description={numeral(domestic + international).format("0,000")}
                isBoldDescription
            />
        </div>
    );
};

export default function PaxMapAirport() {
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/pax",
        type: "maps",
        doSend: true,
        regenerate: true,
    });
    return (
        <MapAirportActive
            dataMaps={data}
            title={"Peta Jumlah Penumpang"}
            customTooltips={(data) => <RenderTooltips data={data} />}
            customMarkRadius={(data) => {
                const { domestic, international } = data || {
                    domestic: 0,
                    international: 0,
                };
                return logaritRadiusMarkMap(domestic + international);
            }}
        />
    );
}
