import { Button, DatePicker, Form, Input, Select, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useLanguage } from "../../../../../../hooks/useLanguage";
import AutoComplete from "../../../../../../components/autocomplete/Online";
import CFG from "../../../../../../config/env";
import { createUseStyles } from "react-jss";

const { withDomestic } = CFG;
const useStyles = createUseStyles({
  selectStyle: {
    "& .ant-select-selector": {
      borderRadius: "10px !important",
    },
  },
  autoCompleteStyle: {
    "& .css-13cymwt-control": {
      borderRadius: "10px",
    },
  },
});

const Search = ({ onSearchCallback, initValue }) => {
  const classes = useStyles();
  const [state, setState] = useState(initValue);
  const [, getLang] = useLanguage();
  return (
    <Form
      layout="vertical"
      initialValues={state}
      onFinish={onSearchCallback}
      autoComplete="off"
      style={{
        display: "flex",
        // alignItems: "center",
        gap: 8,
        justifyContent: "center",
      }}
    >
      <Form.Item name="passenger_name">
        <Input
          style={{ borderRadius: "10px" }}
          size="large"
          autoFocus
          placeholder="Name"
        />
      </Form.Item>
      <Form.Item name="db">
        <Select
          size="large"
          className={classes.selectStyle}
          onChange={(val) => {
            setState({ ...state, db: val });
          }}
        >
          <Select.Option value={"flight"}>
            {getLang("flight_international")}
          </Select.Option>
          {withDomestic && (
            <Select.Option value={"flight_domestic"}>
              {getLang("flight_domestic")}
            </Select.Option>
          )}
          <Select.Option value={"vessel"}>{getLang("vessel")}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="flight_date"
        rules={[
          {
            validator: async (rule, value) => {
              console.log({ value });
              if (!value) return true;
              // console.log({rule, value})
              const [from, to] = value;
              const diff = moment.duration(to.diff(from)).days();
              if (diff > 7) {
                throw new Error("Cannot more than 7 days");
              }
              return true;
            },
          },
        ]}
      >
        <DatePicker.RangePicker
          size="large"
          style={{ borderRadius: "10px" }}
          showTime={true}
          value={state.flight_date}
          format={"DD-MMM-YYYY"}
          onChange={(val, strVal) => {
            setState({ ...state, flight_date: val });
          }}
        />
      </Form.Item>
      {state.db !== "vessel" && (
        <div style={{ width: "20%" }}>
          <Form.Item name="origin">
            <AutoComplete
              className={classes.autoCompleteStyle}
              getKey={(opt) => opt._id}
              getSelectedOptions={(opt, val) => opt._id === val._id}
              getValues={(val) => `${val.code || val.icao} - ${val.kota}`}
              uri="api/v1/airport"
              onChange={(val) => setState({ ...state, origin: val })}
              value={state.origin}
            />
          </Form.Item>
        </div>
      )}
      {state.db !== "vessel" && (
        <div style={{ width: "20%" }}>
          <Form.Item name="dest">
            <AutoComplete
              className={classes.autoCompleteStyle}
              getKey={(opt) => opt._id}
              getSelectedOptions={(opt, val) => opt._id === val._id}
              getValues={(val) => `${val.code || val.icao} - ${val.kota}`}
              uri="api/v1/airport"
              onChange={(val) => setState({ ...state, dest: val })}
              value={state.dest}
            />
          </Form.Item>
        </div>
      )}
      <Form.Item name="doc_number">
        <Input
          placeholder="Pasport Name"
          size="large"
          style={{ borderRadius: "10px" }}
        />
      </Form.Item>
      <Button
        style={{ width: "20%", borderRadius: "10px", background: "#0C356A" }}
        size="large"
        block
        type="primary"
        htmlType="submit"
      >
        Search
      </Button>
    </Form>
  );
};

export default Search;
