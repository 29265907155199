import { Button, DatePicker, Form, Input } from "antd";
import React, { useState } from "react";
import { useLanguage } from "../../../../hooks/useLanguage";
import Panel from "../components/panel/panel";
import { SaveOutlined, UserAddOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AutoComplete from "../../../../components/autocomplete/Online";
import moment from "moment";
import { useEffect } from "react";

export default ({ onSearchCallback }) => {
  const [, getLan] = useLanguage();
  const [model, setModel] = useState({
    name: "",
    createdAt: moment(),
    screen_name: null,
  });
  const history = useHistory();
  useEffect(() => {
    const val = {
      name: "",
      createdAt: {
        $gte: moment().startOf("day").toDate(),
        $lt: moment().endOf("day").toDate(),
      },
    };
    onSearchCallback &&
      typeof onSearchCallback === "function" &&
      onSearchCallback(val);
  }, []);
  return (
    <div style={{ width: 320 }}>
      <Panel headerLeft={getLan("search")} overflow="unset">
        <div style={{ padding: 16 }}>
          <Form
            autoComplete="off"
            initialValues={model}
            labelCol={{ span: 5 }}
            onFinish={(val) => {
              // const {screen_name,} = model;
              // let value={};
              const newModel = { ...model, ...val };
              let { screen_name, createdAt, ...value } = newModel;
              const m = moment(createdAt).startOf("days");

              if (!!screen_name) {
                value = { ...value, screen_name: screen_name._id };
              }
              if (!!createdAt) {
                value = {
                  ...value,
                  createdAt: {
                    $gte: m.toDate(),
                    $lt: m.endOf("days").toDate(),
                  },
                };
              }
              onSearchCallback &&
                typeof onSearchCallback === "function" &&
                onSearchCallback(value);
            }}
          >
            <Form.Item label={getLan("date")} name="createdAt">
              <DatePicker
                style={{ width: "100%" }}
                showTime={false}
                format={"DD-MMM-YYYY"}
              />
            </Form.Item>
            <Form.Item label={getLan("name")} name={"name"}>
              <Input autoFocus />
            </Form.Item>
            <Form.Item label={getLan("command")} name="screen_name">
              <AutoComplete
                getKey={(opt) => opt._id}
                getSelectedOptions={(opt, val) => opt._id === val._id}
                getValues={(val) => val._id}
                uri="api/v1/matched/commands"
                onChange={(val) => setModel({ ...model, screen_name: val })}
                value={model.screen_name}
              />
            </Form.Item>
            <div
              style={{ display: "flex", flexDirection: "row-reverse", gap: 8 }}
            >
              <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
                Search
              </Button>
            </div>
          </Form>
        </div>
      </Panel>
    </div>
  );
};
