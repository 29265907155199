import Page from './page';      
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {Form, DatePicker, Button} from 'antd';
import moment from 'moment';
import { useLanguage } from '../../../../../hooks/useLanguage';
import { GiTable } from 'react-icons/gi';
import { useDispatch } from 'react-redux';
import { RiFileExcelLine, RiTable2 } from 'react-icons/ri';
import { GetAPI, PostAPI } from '../../../../../redux';
import {DownloadExcel} from './excel_writer';
import {Table} from 'antd';

export default ({pageProps, url, OtherComponents, otherState, Columns, onSortedData, title }) => {
    const [state, setState] = useState({date:moment().subtract(2,'days'), ...otherState});
    const [,getLang] = useLanguage();
    const [report, setReport]=useState([]);
    const [loading, setLoading]=useState(false);
    const dispatch=useDispatch();
    const data=useMemo(()=>{
        if(typeof onSortedData==='function'){
            return onSortedData(report);
        }
        return report;
    }, [report]);
    const onExportExcel=useCallback(()=>{
        const columns=Columns.map(({title})=>title);
        const reports=[];
        for (let iii = 0; iii < data.length; iii++) {
            const row = data[iii];
            const aRow=[];
            for (let iui = 0; iui < Columns.length; iui++) {
                const {render} = Columns[iui];
                if(typeof render==='function'){
                    const val=render(null, row);
                    aRow.push(val);
                }
            }
            reports.push(aRow);
        }
        console.log(reports, columns)
        DownloadExcel(reports, columns, `${title} ${state.date.format('DD-MMM-YYYY')}`)
    }, [Columns, data, title, state]);
    return (
        <Page
            {...pageProps}
        >
            <div style={{display:'flex', flexDirection:'row', alignItems:'start', gap:16, flexGrow:1, padding:16}}>
                <div style={{
                    display:'flex', 
                    flexDirection:'column', 
                    rowGap:8, 
                    width:300, 
                    background:'#ffffffaa', 
                    paddingInline:16, 
                    paddingBlock:8,
                    alignItems:'center'
                }}>
                    <Form
                        layout='vertical'
                        initialValues={state}
                        onFinish={(val)=>{
                            const {date, ...others} = state;
                            setLoading(true);
                            // const [start, stop] = date;
                            dispatch(PostAPI({url, data:{first:moment(date).startOf('date'), last:moment(date).endOf('date'), ...others}})).then((r)=>{
                                setLoading(false);
                                if(!!r.payload){
                                    setReport(r.payload);
                                }
                            })
                        }}
                        autoComplete="off"
                        style={{rowGap:8, width:'100%'}}
                    >
                        <Form.Item
                            label={getLang("date")}
                            rules={[{
                                validator:async(rule, value)=>{
                                    // console.log({rule, value})
                                    const [from, to]=value;
                                    const diff=moment.duration(to.diff(from)).days();
                                    if(diff>7){
                                        throw new Error('Cannot more than 7 days');
                                    }
                                    return true;
                                }
                            }]}
                        >
                            <DatePicker 
                                showTime={false}
                                value={state.date}
                                format={'DD-MMM-YYYY'}
                                style={{width:'100%'}}
                                onChange={(val, strVal)=>{
                                    setState({...state, date:val});
                                }}
                            />

                        </Form.Item>
                        {
                            OtherComponents &&  <OtherComponents state={state} setState={setState} />
                        }
                        <Form.Item>
                            <Button 
                                size='large' 
                                icon={<RiTable2 size={16} />} 
                                style={{display:'flex', justifyContent:'center', width:"100%", flexDirection:'row', gap:4, alignItems:'center'}}
                                htmlType='submit'
                            >
                                View
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button 
                                size='large' 
                                icon={<RiFileExcelLine size={16} />} 
                                style={{display:'flex', justifyContent:'center', width:"100%", flexDirection:'row', gap:4, alignItems:'center'}}
                                onClick={onExportExcel}
                            >
                                Export Excel
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div 
                    style={{
                        display:'flex', 
                        flexDirection:'column',
                        flexGrow:1,
                        background:'#ffffffaa', 
                        height:'100%',
                        paddingInline:8,
                        paddingBlock:24
                    }}
                >
                    <Table 
                        style={{paddingInline:16}}
                        columns={Columns || []}
                        dataSource={data || []}
                        size="small"
                        loading={loading}                        
                        bordered 
                        rowKey={(r)=>r._id}
                        
                    />
                </div>
            </div>
        </Page>
    )
}