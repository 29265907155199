import React, { Fragment } from 'react';
import FormReport from '../components/pages/report';
import { GiChart } from 'react-icons/gi';
import {Form, Select} from 'antd';
import { useLanguage } from '../../../../hooks/useLanguage';
import moment from 'moment';
import AutoComplete from '../../../../components/autocomplete/Online';

const FormItems=({state, setState})=>{
    const [,getLan]=useLanguage();
    return (
        <div style={{marginBlock:10}}>
            <Form.Item label={getLan("direction")}>
                <Select
                    options={[
                        {
                            label:getLan('arrival'),
                            value:'arrival',
                        },
                        {
                            label:getLan('departure'),
                            value:'departure'
                        }
                    ]}
                    onChange={(v)=>{
                        setState({...state, direction:v});
                    }}
                    value={state.direction}
                />
            </Form.Item>
            <Form.Item
                label={getLan('airport')}
                style={{marginBottom:4}}
                name="airport"
            >
                <AutoComplete 
                    getKey={(opt)=>opt._id}
                    getSelectedOptions={(opt, val)=>opt._id===val._id}
                    getValues={(val)=>`${val.code || val.icao} - ${val.kota}`}
                    uri="api/v1/airport/domestic"
                    onChange={(val)=>setState({...state, airport:val})}
                    value={state.airport}
                />
            </Form.Item>
        </div>
    )
}   


export default () => {
    const [,getLan]=useLanguage();
    
    return (
        <FormReport 
            pageProps={{
                title:'flight_daily_report',
                icon:GiChart
            }}
            title={"Daily Report"}
            OtherComponents={FormItems}
            url={'api/v1/report/flight'}
            otherState={{direction:'arrival', airport:null}}
            onSortedData={(data)=>{
                return Array.isArray(data) && data.sort((a, b)=>{
                    return a.schedule_time - b.schedule_time;
                }) || [];
            }}
            Columns={[
                {
                    render:(val, {flight_number})=> flight_number,
                    title:getLan('flight_number'),
                },
                {
                    render:(val, {origin, dest})=> `${origin}${dest}`,
                    title:getLan('route'),
                },
                {
                    render:(val, {flight_date})=> flight_date,
                    title:getLan('flight_date'),
                },
                {
                    render:(val, {acreg})=>  acreg,
                    title:getLan('aircraft_reg'),
                },
                {
                    render:(val, {schedule_time})=>moment.unix(schedule_time).format('HH:mm'),
                    title:getLan('schedule_time'),
                },
                {
                    render:(val, {actual_time})=>actual_time &&  moment.unix(actual_time).format('HH:mm') || '-',
                    title:getLan('actual_time'),
                },
                {
                    render:(val, {pnr})=> pnr,
                    title:getLan('pnrgov'),
                    align:'right'
                },
                {
                    render:(val, {apis})=> apis,
                    title:getLan('apis'),
                    align:'right'
                },
                
                {
                    render:(val, {melintas, autogate})=> parseInt(melintas) + parseInt(autogate),
                    title:getLan('crossing'),
                    align:'right'
                },
                {
                    render:(val, {wni})=> wni,
                    title:getLan('wni'),
                    align:'right'
                },
                {
                    render:(val, {wna})=> wna,
                    title:getLan('wna'),
                    align:'right'
                },
            ]}
        />
    )
}