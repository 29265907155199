import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Tabs from '../../tabs';
import Detail from './list';

export default ({data, id, direction}) => {
    return (
        <div style={{display:'flex', flexDirection:'column'}}>
            <Tabs 
                selTab={1}
                tabs={[
                    {
                        id:0,
                        caption:'detail',
                        href:`/flight/schedule/${direction}/${id}`,
                    },
                    {
                        id:1,
                        caption:'pnr',
                        href:`/flight/schedule/${direction}/pnr/${id}`,
                    },
                    {
                        id:2, 
                        caption:'passengers',
                        href:`/flight/schedule/${direction}/passengers/${id}`,
                    },
                    {
                        id:3, 
                        caption:'cargo',
                        href:`/flight/schedule/${direction}/cargo/${id}`,
                    }
                ]}
            />
            <Detail data={data}  />
        </div>
    )
}