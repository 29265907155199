import React, { Fragment, useEffect, useMemo, useState } from "react";
import PolylineMap from "../FlightMaps/PolylineMap";
import { Col, Radio, Row, Typography } from "antd";
import MainCard from "../MainCard";
import { useFontSizeV2 } from "../../hooks/useDimension";
// import FiltersSwapper from "../Chart/FiltersSwapper";
import { createUseStyles } from "react-jss";
import FlightMap from "../FlightMaps";
import Config from "../../config/env";
import ModalChart from "../Chart/ModalChart";
import MultiSelectComponent from "../CustomSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardState } from "../../redux/reducer/apps";

const { colorPrimary } = Config;

const useStyles = createUseStyles({
    Container: {
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",
    },
    select: {
        // zIndex: 9999,
        "& .dropdown-container": {
            borderRadius: 8,
            position: "relative",
            zIndex: 3,
            width: "200px",
        },
    },
    radioStyle: {
        "& .ant-radio-button-wrapper": {
            marginRight: "4px",
            borderRadius: "6px",
            borderLeftWidth: 1.02,
            fontSize: ({ fontSize }) => fontSize.xxs,
            fontWeight: "bold",
        },
        "& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before":
            {
                display: "none",
            },
        "& .ant-radio-button-wrapper:not(:first-child)::before": {
            display: "none",
        },
    },
});

const zoomDef = 4;

export default function CardMaps({
    title,
    subTitle,
    filterComp = [],
    height,
    isMobile = false,
    data,
    mulitple = false,
    options,
    selected,
    multipleSelect,
    dir,
    setSelected,
    origin,
    isRoute = false,
    fullscreen = false,
    otherComponent,
    onFullScreen,
    onCentered,
    loading,
    state,
    apps,
    total,
    viewData,
    setValueFilter,
    valueFilter = "",
    filterDetail,
    titleSelected = "",
    isDetail,
    isCargo,
    customMarkRadius,
    customTooltip,
    isPlane,
}) {
    // const classes = useStyles();
    const [defZoomMap, setDefZoomMap] = useState(zoomDef);
    const fontSize = useFontSizeV2();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { dashboardState } = useSelector((item) => item.apps);
    const classes = useStyles({ fontSize });

    const center = useMemo(() => {
        return !!selected &&
            selected?.length !== 0 &&
            !!onCentered &&
            typeof onCentered === "function"
            ? onCentered(data)
            : false;
    }, [selected, data]);

    useEffect(() => {
        !!onFullScreen && onFullScreen(open);
    }, [open]);

    const renderBody = (isModal, titleSelected, isDetail) => {
        return (
            <Fragment>
                <Row
                    className={classes.Container}
                    style={{
                        zIndex: 2,
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Col>
                        <Typography
                            className="title-onDoubleClick"
                            style={{
                                fontWeight: "bold",
                                cursor: !!fullscreen && "pointer",
                                fontSize: fontSize.md,
                                color: "#024157",
                            }}
                        >
                            {title} {isDetail && <span>{titleSelected}</span>}
                        </Typography>
                        {!!subTitle && typeof subTitle === "string" && (
                            <Typography
                                style={{
                                    fontSize: fontSize.sm,
                                    color: "gray",
                                }}
                            >
                                <em>{subTitle}</em>
                            </Typography>
                        )}
                    </Col>
                    {!!filterComp && filterComp.length !== 0 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <Radio.Group
                                size="small"
                                options={filterComp}
                                onChange={({ target: { value } }) => {
                                    setValueFilter(value);
                                    dispatch(
                                        setDashboardState({
                                            filterState: "some",
                                        })
                                    );
                                }}
                                value={
                                    dashboardState.filterState === "all"
                                        ? dashboardState.filterTime
                                        : valueFilter
                                }
                                optionType="button"
                                className={classes.radioStyle}
                            />
                        </div>
                    )}
                    {mulitple && (
                        <Col>
                            <div
                                style={{
                                    width: "100%",
                                    // border: "1px solid red",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                }}
                            >
                                <MultiSelectComponent
                                    hasSelectAll={multipleSelect}
                                    className={classes.select}
                                    loading={loading}
                                    onChange={(val) => {
                                        if (val?.length !== 0) {
                                            let newVal = !multipleSelect
                                                ? [val[val?.length - 1]]
                                                : val;

                                            setSelected(newVal);
                                        } else {
                                            setSelected([]);
                                        }
                                    }}
                                    options={options}
                                    selected={selected}
                                />
                            </div>
                        </Col>
                    )}
                </Row>
                {!!otherComponent && typeof otherComponent === "function" && (
                    <div>{otherComponent()}</div>
                )}
                <div
                    style={{
                        flexGrow: 1,
                        height: isModal ? "65%" : height,
                    }}
                >
                    {!!isRoute ? (
                        <PolylineMap
                            isMobile={isMobile}
                            fullScreen={open}
                            selected={selected}
                            loading={loading}
                            origin={origin}
                            data={data}
                            customTooltip={customTooltip}
                        />
                    ) : (
                        <FlightMap
                            isPlane={isPlane}
                            isCargo={isCargo}
                            filterDetail={filterDetail}
                            viewData={viewData}
                            total={total}
                            apps={apps}
                            isMobile={isMobile}
                            dir={dir}
                            mapCenter={center}
                            state={state}
                            height={fontSize.md * 12}
                            data={data}
                            customMarkRadius={customMarkRadius}
                            customTooltip={customTooltip}
                            zoomInitial={defZoomMap}
                        />
                    )}
                </div>
            </Fragment>
        );
    };
    return (
        <MainCard isBorder isNonBackground isMobile={false}>
            <div
                style={{
                    padding: "8px 16px",
                    height: isMobile ? "90%" : "100%",
                    display: "flex",
                    flexDirection: "column",
                    // border: "1px solid red",
                }}
                onDoubleClick={(e) => {
                    setDefZoomMap(5);
                    return fullscreen && setOpen(true);
                }}
            >
                {renderBody()}
            </div>

            {!!open && (
                <ModalChart open={open} setOpen={setOpen}>
                    {renderBody(true, titleSelected, isDetail)}
                </ModalChart>
            )}
        </MainCard>
    );
}
