import React from "react";
import { useDashboardChart } from "../../../../../../../hooks/useDashboardChart";
import { Progress } from "antd";
import { useParams } from "react-router-dom";
import Typography from "antd/lib/typography/Typography";
import Config from "../../../../../../../config/env";
import { useFontSizeV2 } from "../../../../../../../hooks/useDimension";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    "&::-webkit-scrollbar": {
      width: "0", // Hide the scrollbar width
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide the scrollbar thumb
    },
    "&:hover::-webkit-scrollbar": {
      width: "8px", // Show scrollbar width on hover
    },
    "&:hover::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc", // Show scrollbar thumb on hover
      borderRadius: "4px",
    },
  },
});
const { primaryColor, secondaryColor, teritaryColor } = Config;

const ListAirlines = ({ filterPage }) => {
  const { idAirline } = useParams();
  const fontSize = useFontSizeV2();
  const classes = useStyles();

  const [detailAirline, loadingDetailAirline] = useDashboardChart({
    ...filterPage,
    airline_id: idAirline,
    type: "airport",
    routes: "/airline-detail/otp",
  });

  return (
    <div
      style={{
        // border: "1px solid red",
        height: "100%",
        padding: 8,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        style={{
          textAlign: "center",
          fontSize: 24,
          fontWeight: "bold",
          color: primaryColor,
          marginBottom: 20,
        }}
      >
        AIRPORTS
      </Typography>
      <div
        className={classes.container}
        style={{
          width: "95%",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {!!detailAirline &&
          detailAirline?.map((item, idx) => {
            return (
              <div
                key={idx}
                style={{
                  width: "95%",
                  display: "flex",
                  //   border: "1px solid red",
                  gap: 24,
                  marginBottom: 10,
                  padding: "8px",
                  background: "#ffffff",
                  borderRadius: 8,
                  //   marginBottom: 8,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    // justifyContent: "center",
                    // border: "1px solid red",
                  }}
                >
                  <div
                    style={{
                      padding: 8,
                      background: "#219ebc",
                      borderRadius: "100%",
                    }}
                  />
                  <Typography
                    style={{
                      color: primaryColor,
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {item?.code}
                  </Typography>
                </div>
                <div style={{ width: "80%" }}>
                  <Typography
                    style={{
                      fontSize: fontSize.md,
                      fontWeight: "bold",
                      //   color: secondaryColor,
                    }}
                  >
                    {item?.name} ({item.kota}) -- {item?.operator}
                  </Typography>
                  <Progress
                    strokeColor={{
                      "0%": primaryColor,
                      "100%": secondaryColor,
                    }}
                    percent={item?.otp?.toFixed(2)}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ListAirlines;
