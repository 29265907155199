import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { GiHouse } from 'react-icons/gi';
import Page from '../components/pages/paging';
import Search from './search';
import { IoIosPeople } from 'react-icons/io';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default () => {
    const Icon=IoIosPeople;
    const uri=`api/v1/dual_citizen`;
    const [searchValue, setSearchValue] = useState({
        passenger_name: '', doc_number: ''
    });

    const getJenisDoc=(jenis_doc)=>{
        return jenis_doc==='P'?'P':jenis_doc.toLowerCase()==='passport'?'P':jenis_doc
    }

    const formatedSearchVal = useMemo(() => {
        return searchValue;
    }, [searchValue]);
    return (
        <Page 
            url={uri}
            searchValue={formatedSearchVal}
            columns={[
                {
                    render:(val, {passenger_name, citizen_id})=><Link to={`/passengers/citizen/detail/${citizen_id?._id}`}>{passenger_name}</Link>,
                    title:'name'
                },
                {
                    render:(val, {doc_number})=>doc_number,
                    title:'passport'
                },
                {
                    render:(val, {citizen_id:{jenis_doc}})=>getJenisDoc(jenis_doc),
                    title:'doc_type'
                },
                {
                    render:(val, {dob})=>dob,
                    title:'dob'
                },
                {
                    render:(val, {nationality})=>nationality,
                    title:'nationality'
                },
                {
                    render:(val, {match_ids})=>(
                        <ul>
                        {
                            match_ids.map((row, idx)=>(
                                <li key={idx}>{row.no_id} / {row.nationality}</li>
                            ))
                        }
                        </ul>
                    ),
                    title:'other_citizen'
                },
                {
                    render:(val, row)=>row.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm'),
                    title:'time'
                },
            ]}
            title={`dual_citizenship`} 
            icon={Icon} 
            searchComponent={
                <Search 
                    onSearchCallback={(value)=>{
                        setSearchValue({...value, timestamp:moment().unix()});
                    }} 
                    initValue={searchValue}
                />
            }
            breadcrumbs={
                [
                    {
                        href:'/home',
                        icon:<GiHouse color='white' />,
                        item:'home'
                    },
                    {
                        item:'poi'
                    },
                    {
                        item:'dual_citizenship'
                    }
                ]
            }
        />
    )
}