import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.code,
            name:"Code",
            id:'code',
            compact:true
        },
        {
            selector:(row)=>row?.tpi_code,
            name:"TPI",
            id:'tpi_code',
            compact:true
        },
        {
            selector:(row)=>row?.name,
            name:"Name",
            id:'name'
        },
        {
            selector:(row)=>row?.alternate_names?.join(', '),
            name:"Alt Name",
            id:'alternate_names'
        },
        {
            selector:(row)=>row?.kota,
            name:"City",
            id:'kota'
        },
        {
            selector:(row)=>row?.country,
            name:"Country",
            id:'country'
        },
        {
            selector:(row)=>row?.timezone,
            name:"Timezone",
            id:'timezone'
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
            id:'created_at'
        },
    ]
    return (
        <TablePage
            title={"Master Land"}
            url="api/v1/land"
            actionPosition={'first'}
            createPath="/master/land/create"
            editPath={"/master/land/edit"}
            columns={columns}            
        />
    )
}