import { Avatar, Dropdown, Menu, Typography } from 'antd';
import React from 'react';
import { GiKeyLock } from 'react-icons/gi';
import {GrUserManager} from 'react-icons/gr'
import { IoExit } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import Logo from '../../../assets/images/logo-dashboard.png';
import { doLogout } from '../../../redux';

const OverlayMenu=({username, levelStr})=>{
    const dispatch=useDispatch();
    return (
        <div style={{background:'white'}}>
            <div style={{display:'flex', flexDirection:'column', rowGap:4, paddingInline:16}}>
                <Typography.Text style={{textAlign:'right', textTransform:'capitalize'}}>
                    {username} 
                </Typography.Text>
                <Typography.Text>
                    ({levelStr})
                </Typography.Text>
            </div>
            <Menu>
                <Menu.Divider />
                <Menu.Item icon={<GiKeyLock size={18} />}>
                    <Link to={'/password'}>                    
                        Password
                    </Link>
                </Menu.Item>
                <Menu.Item icon={<GrUserManager size={18} />}>
                    <Link to={'/profile'}>                    
                        Profil
                    </Link>
                </Menu.Item>
                <Menu.Item icon={<IoExit size={18}/>}>
                    <Link to="/login" onClick={()=>dispatch(doLogout())} >Logout</Link>
                </Menu.Item>
            </Menu>
        </div>
    )
}

export default () => {
    const { userdata: { username, levelStr } } = useSelector(state => state.auth);
    return (
        <Dropdown placement='bottomLeft' overlay={<OverlayMenu  username={username} levelStr={levelStr}  />} trigger={['click']}>
            <Avatar 
                src={'/'}
                className="avatar-contain"
                style={{background:'white', padding:4, alignSelf:'start', cursor:'pointer'}}
                size="large"
            />
        </Dropdown>

    )
}