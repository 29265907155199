import React, { useState } from "react";
import PanelChartTrendWithRoute from "../../../../../../features/PanelChart/TrendWithRoute";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import { useSelector } from "react-redux";

export default function FlightTrendWithRoute() {
    const [filters, setFilters] = useState({
        category: "origin",
        periodic: "daily",
        airport: "all",
    });
    const { filterPage } = useSelector((item) => item.apps);
    const [data, loading] = useDashboardChart({
        ...filterPage,
        routes: "/flight",
        type: filters.category?.toLowerCase(),
        doSend: true,
        regenerate: true,
    });

    return (
        <PanelChartTrendWithRoute
            dataSource={data}
            filterPage={filterPage}
            filters={filters}
            onChangeFilter={(e) => {
                console.log(e);
                return setFilters((state) => ({ ...state, ...e }));
            }}
        />
    );
}
