import { GiAirplaneArrival, GiAirplaneDeparture, GiCargoCrate, GiCommercialAirplane, GiGlobe, GiHumanTarget, GiMoneyStack, GiPerson } from "react-icons/gi";
import CFG from '../../../config/env';
import { RiBarChartGroupedFill } from "react-icons/ri";
const {withDomestic}=CFG;
export default [
    // {
    //     caption:'flight_master',
    //     category:'flight',
    //     group:0, 
    //     Icon:GiCommercialAirplane,
    //     linkTo:'/flight/master'
    // },
    {
        caption:'passenger',
        category:'flight',
        group:0, 
        Icon:GiPerson,
        linkTo:'#',
        childs:[
            {
                caption:'arrival',
                category:'flight',
                group:0, 
                Icon:GiAirplaneArrival,
                linkTo:'/flight/schedule/type/passengers/arrival'
            },
            {
                caption:'departure',
                category:'flight',
                group:0, 
                Icon:GiAirplaneDeparture,
                linkTo:'/flight/schedule/type/passengers/departure'
            },
        ]    
    },
    {
        caption:'cargo',
        category:'flight',
        group:0, 
        Icon:GiCargoCrate,
        linkTo:'#',
        childs:[
            {
                caption:'arrival',
                category:'flight',
                group:0, 
                Icon:GiAirplaneArrival,
                linkTo:'/flight/schedule/type/cargo/arrival'
            },
            {
                caption:'departure',
                category:'flight',
                group:0, 
                Icon:GiAirplaneDeparture,
                linkTo:'/flight/schedule/type/cargo/departure'
            },
        ]
    },
    {
        caption:'charter',
        category:'flight',
        group:0, 
        Icon:GiMoneyStack,
        linkTo:'#',
        childs:[
            {
                caption:'arrival',
                category:'flight',
                group:0, 
                Icon:GiAirplaneArrival,
                linkTo:'/flight/schedule/type/charter/arrival'
            },
            {
                caption:'departure',
                category:'flight',
                group:0, 
                Icon:GiAirplaneDeparture,
                linkTo:'/flight/schedule/type/charter/departure'
            },
        ]
    },
    {
        caption:'flight_schedule_domestic',
        category:'flight',
        group:0, 
        Icon:GiGlobe,
        linkTo:'/flight/domestic'
    },
]