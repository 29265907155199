import { createSlice } from "@reduxjs/toolkit";
const Navigations = createSlice({
    name: "nav",
    initialState: {
        selectedMenu: "",
        selectedMenuKeys: "",
        openKeys: [""],
        breadcrumbs: { key: "", items: [], title: "" },
    },
    reducers: {
        setSelectedMenu(state, action) {
            state.selectedMenu = action.payload;
        },
        setSelectedMenuKeys(state, action) {
            state.selectedMenuKeys = action.payload;
        },
        setOpenKeys(state, action) {
            state.openKeys = action.payload;
        },
        setBreadcrumbs(state, action) {
            state.breadcrumbs = { ...state.breadcrumbs, items: action.payload };
        },
        setBreadcrumbsKey(state, action) {
            state.breadcrumbs = { ...state.breadcrumbs, key: action.payload };
        },
        setBreadcrumbsTitle(state, action) {
            state.breadcrumbs = { ...state.breadcrumbs, title: action.payload };
        },
    },
});

export const {
    setSelectedMenu,
    setSelectedMenuKeys,
    setOpenKeys,
    setBreadcrumbs,
    setBreadcrumbsKey,
    setBreadcrumbsTitle,
} = Navigations.actions;

export default Navigations.reducer;
