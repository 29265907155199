import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { useFontSizeV2 } from "../../../../hooks/useDimension";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../../assets/images/logo-kemenhub.png";
import ArahChart from "./components/ArahChart";
import RutePengiriman from "./components/RutePengirman";
import { setDashboardState } from "../../../../redux/reducer/apps";
import FourthChart from "./components/FourthChart";
import { useDashboardChart } from "../../../../hooks/useDashboardChart";
import { useMemo } from "react";
import FifthChart from "./components/FifthChart";

const BgCard = ({ children }) => {
  return (
    <div
      style={{
        background: "white",
        height: "100%",
        borderRadius: 16,
      }}
    >
      {children}
    </div>
  );
};

function pickOneData(obj, key) {
  if (obj.hasOwnProperty(key)) {
    return { [key]: obj[key] };
  } else {
    return { error: `Property '${key}' not found in the object.` };
  }
}

const DetailCargo = () => {
  const location = useLocation();
  const history = useHistory();
  const fontSize = useFontSizeV2();
  const dispatch = useDispatch();
  const { data } = location.state;
  const { filterPage } = useSelector((item) => item.apps);

  const filterDetail = useMemo(() => {
    const filterEliminate = pickOneData(filterPage, "year");
    return { ...filterEliminate, airline_id: data.id };
  }, [filterPage, location]);

  const [detailDaily, loadingDetailDaily] = useDashboardChart({
    ...filterDetail,
    airline_id: data.id,
    type: "daily",
    routes: "/cargo-detail/airline",
  });

  const [detailCommodity, loadingDetailCommodity] = useDashboardChart({
    ...filterDetail,
    airline_id: data.id,
    type: "commodity",
    routes: "/cargo-detail/airline",
  });
  return (
    <div
      style={{
        // border: "1px solid red",
        height: "100vh",
        background: "#ebf0ff",
        padding: "0px 20px",
      }}
    >
      <div
        style={{
          // border: "1px solid red",
          height: "10%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 8,
          }}
        >
          <Button
            style={{
              borderRadius: 8,
              background: "transparent",
              width: "50px",
              height: "50px",
            }}
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined
              style={{ color: "#11375C", fontSize: "20px", fontWeight: 700 }}
            />
          </Button>
          <Typography style={{ fontSize: fontSize.lg, color: "#024157" }}>
            Detail Kargo{" "}
            <span style={{ fontWeight: "bold" }}>
              {data?.name}({data?.code})
            </span>
          </Typography>
        </div>
        <div style={{ width: fontSize.xl3 * 8 }}>
          <img src={logo} alt="logo kemenhub" style={{ width: "100%" }} />
        </div>
      </div>
      <div
        style={{
          height: "88%",
          margin: "0px 10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 8,
        }}
      >
        <div style={{ flex: 2 }}>
          <Row gutter={8} style={{ height: "100%" }}>
            <Col lg={{ span: 8 }} style={{ height: "100%" }}>
              <BgCard>
                <ArahChart />
              </BgCard>
            </Col>
            <Col lg={{ span: 16 }} style={{ height: "100%" }}>
              <BgCard>
                <RutePengiriman title={"Rute Pengiriman"} />
              </BgCard>
            </Col>
          </Row>
        </div>
        <div style={{ flex: 2 }}>
          <Row gutter={8} style={{ height: "100%" }}>
            <Col lg={{ span: 12 }} style={{ height: "100%" }}>
              <BgCard>
                <FourthChart
                  data={detailCommodity}
                  loading={loadingDetailCommodity}
                />
              </BgCard>
            </Col>
            <Col lg={{ span: 12 }} style={{ height: "100%" }}>
              <BgCard>
                <FifthChart data={detailDaily} loading={loadingDetailDaily} />
              </BgCard>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DetailCargo;
