const groupedData = (data) => {
  return (
    !!data &&
    data?.reduce((accumulator, currentValue) => {
      const existingGroup = accumulator.find(
        (group) => group.code === currentValue.code
      );

      if (existingGroup) {
        // If the group already exists, add the current item to it
        existingGroup.items.push({
          name: currentValue.name,
          curr: currentValue.curr,
        });
      } else {
        // If the group doesn't exist, create a new group
        accumulator.push({
          code: currentValue.code,
          name: currentValue.airport,
          items: [{ name: currentValue.name, curr: currentValue.curr }],
        });
      }

      return accumulator;
    }, [])
  );
};

export const mergedDataByProp = (data, prop) => {
  const mergedData = {};

  Array.isArray(data) &&
    !!data &&
    data?.forEach((item) => {
      const name = item[`${prop}`];
      if (!mergedData[name]) {
        mergedData[name] = { name, curr: 0 };
      }
      mergedData[name].curr += item.curr;
    });

  return Object.values(mergedData);
};

export const groupedDataByOperator = (data) => {
  const dataByOperator = data?.reduce((acc, airport) => {
    const { operator, kilo } = airport;

    if (!acc[operator]) {
      acc[operator] = { code: operator, data: 0 };
    }

    acc[operator].data += kilo;

    return acc;
  }, {});
  return Object.values(dataByOperator);
};

export const categorizeAndSumKilos = (data) => {
  const result = data?.reduce((acc, airport) => {
    const { country, kilo, name } = airport;

    // Categorize as "local" or "foreign"
    const category = country === "ID" ? "Lokal" : "Asing";

    // Create the category if it doesn't exist in the accumulator
    if (!acc[category]) {
      acc[category] = { code: category, name, kilo: 0 };
    }

    // Sum the kilos for each category
    acc[category].kilo += kilo;

    return acc;
  }, {});

  // Convert the result object to an array
  const categorizedArray = Object.values(result);

  return categorizedArray;
};

export default groupedData;
