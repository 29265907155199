import { Button, Form } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Page from "./page";
import { SaveOutlined } from "@ant-design/icons";
import { GetAPI, PostAPI } from "../../../../../redux";
import CFG from "../../../../../config/env";

export default ({
    title,
    icon,
    breadcrumbs,
    url,
    model,
    children,
    bgColor,
    beforeFinish,
    callbackSuccess,
    wrapperCol,
    labelCol,
    setModel,
    onEditForm,
}) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [initFinish, setInitFinish] = useState(false);
    const history = useHistory();
    // const [model, setModel]=useState(data);
    useEffect(() => {
        if (!!id) {
            const payload = { url: url + "/detail/" + id };
            dispatch(GetAPI(payload)).then((resp) => {
                // console.log({resp:resp.payload.data})
                const { data } = resp.payload;
                const newModel =
                    (!!onEditForm &&
                        typeof onEditForm === "function" &&
                        onEditForm(data)) ||
                    data;
                setModel({ ...newModel });
                setInitFinish(true);
            });
        } else {
            setInitFinish(true);
        }
    }, [id]);
    return (
        <Page
            title={title}
            icon={icon}
            breadcrumbs={breadcrumbs}
            bgColor={bgColor || "#ffffff88"}
        >
            {initFinish && (
                <Form
                    initialValues={model}
                    autoComplete="off"
                    wrapperCol={wrapperCol}
                    labelCol={labelCol}
                    layout="vertical"
                    onFinish={(value) => {
                        const newModel = { ...model, ...value };
                        const data =
                            (!!beforeFinish &&
                                typeof beforeFinish === "function" &&
                                beforeFinish(newModel)) ||
                            newModel;
                        dispatch(PostAPI({ url, data })).then((resp) => {
                            console.log(resp);
                            if (!!resp.payload) {
                                history.push({ pathname: callbackSuccess });
                            }
                        });
                    }}
                    onFieldsChange={(fields) => {
                        // console.log({fields})
                        if (!fields) return false;
                        const [field] = fields;
                        if (!field) return false;
                        const [name] = field.name;
                        setModel({ ...model, [name]: field.value });
                    }}
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        padding: 32,
                    }}
                >
                    <div
                        style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 8,
                        }}
                    >
                        {children}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: 16,
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            icon={<SaveOutlined />}
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            )}
        </Page>
    );
};
