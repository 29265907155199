import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routing } from "./routing";
import { Route, Switch } from "react-router-dom";
import { setFilterPage } from "../../../redux/reducer/apps";
import { loadAirlines, loadAirports } from "../../../redux";

const Router = ({ props }) => {
  return (
    <Switch>
      {Routing.map((route, idx) => (
        <Route
          key={idx}
          path={route.to}
          exact={route.isExact !== false}
          render={(props) => {
            return !!route.component && route.component;
          }}
        />
      ))}
      <Route path={"/*"}>
        <div>Page Not Found</div>
      </Route>
    </Switch>
  );
};

const DashboardMobile = () => {
  const { filterPage } = useSelector((state) => state.apps);
  const { userdata } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleOnChange = (type, value, chart) => {
    switch (type) {
      case "airport":
        dispatch(setFilterPage({ airport_id: value, chart: "all" }));
        // setState((state) => ({ ...state, airports: value }));
        break;
      case "airline":
        dispatch(setFilterPage({ airline_id: value, chart: "all" }));
        // setState((state) => ({ ...state, airports: value }));
        break;
      case "year":
        // setState((state) => ({ ...state, year: value }));
        dispatch(
          setFilterPage({
            year: value,
            chart: "all",
          })
        );
        break;
      case "direct":
        if (filterPage.direct === value) {
          // setState((state) => ({ ...state, route: "" }));
          dispatch(setFilterPage({ direct: "" }));
        } else {
          // setState((state) => ({ ...state, route: value }));
          dispatch(setFilterPage({ direct: value }));
        }
        break;
      case "route":
        if (filterPage.route === value) {
          // setState((state) => ({ ...state, route: "" }));
          dispatch(setFilterPage({ route: "", chart }));
        } else {
          // setState((state) => ({ ...state, route: value }));
          dispatch(setFilterPage({ route: value, chart }));
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(loadAirlines());
    dispatch(loadAirports());
  }, [userdata]);

  return (
    <div>
      <Router />
    </div>
  );
};

export default DashboardMobile;
