import React, { useMemo, useState } from "react";
import utilsCargo from "./utilsCargo";
import DashboardChart from "../../../pages/dashboard/home/components/DashboardChartV2";
import customFormat from "../../../../../helper/customFormat";
import { useFontSizeV2 } from "../../../../../hooks/useDimension";
import { resetStyleTooltips } from "../../Chart/resetStyleTooltip";

const MAX_ITEM_BAR = 8;
const MAX_PERCENT_ITEM = 100;

const getOptions = (dataSet, getLenghtData, getPersen, fontSize) => {
    return {
        dataset: dataSet,
        tooltip: {
            trigger: "axis",
            ...resetStyleTooltips,
            formatter: (params) => utilsCargo.renderTooltip(params),
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            left: "0%",
            right: "5%",
            bottom: "12%",
            containLabel: true,
            top: "10%",
        },
        xAxis: {
            type: "category",
            axisLabel: {
                textStyle: {
                    fontSize: fontSize.sm,
                },
            },
        },

        yAxis: {
            type: "value",
            axisLabel: {
                formatter: (params) => customFormat(params),
            },
        },
        dataZoom: getPersen.toFixed() > 0 &&
            getPersen.toFixed() < MAX_PERCENT_ITEM && [
                {
                    type: "slider",
                    start: 0,
                    orient: "horizontal",
                    // right: 24,
                    end: !!getLenghtData ? getPersen.toFixed() : 100,
                    handleSize: 8,
                    bottom: 20,
                    height: 12,
                },
                {
                    type: "inside",
                    start: 0,
                    end: !!getLenghtData && 1 ? getPersen.toFixed() : 100,
                    orient: "horizontal",
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true,
                },
            ],
        series: [
            {
                name: `Domestic`,
                type: "bar",
                symbol: "none",
                barWidth: 10,
                barGap: 0,
                datasetIndex: 0,
                encode: { x: "label", y: "domestic" },
                emphasis: {
                    focus: "series",
                },
                itemStyle: {
                    color: "#023047",
                },
            },
            {
                name: `Import`,
                type: "bar",
                symbol: "none",
                barWidth: 10,
                barGap: 0,
                datasetIndex: 0,
                encode: { x: "label", y: "import" },
                emphasis: {
                    focus: "series",
                },
                itemStyle: {
                    color: "#FFB703",
                },
            },
            {
                name: `Export`,
                type: "bar",
                symbol: "none",
                barWidth: 10,
                barGap: 0,
                datasetIndex: 0,
                encode: { x: "label", y: "export" },
                emphasis: {
                    focus: "series",
                },
                itemStyle: {
                    color: "#FB8500",
                },
            },
        ],
    };
};

export default function PanelChartCargo({
    dataSource = [],
    loading,
    filterPage,
}) {
    const fontSize = useFontSizeV2();
    const [filters, setFilters] = useState({
        direction: "domestic",
    });

    const [dataset, dataTable] = useMemo(
        () => utilsCargo.getDataCargoByAirport(dataSource),
        [dataSource]
    );

    const [getLenghtData, getPersen] = useMemo(() => {
        if (!Array.isArray(dataset)) return [false, 0];
        let isLenghtData =
            !!dataset[0]?.source && dataset[0]?.source?.length > 10;
        let percent = isLenghtData
            ? (MAX_ITEM_BAR / dataset[0]?.source?.length) * 100
            : 0;
        return [isLenghtData, percent];
    }, [filters, dataset]);

    return (
        <DashboardChart
            // loading={loading}
            printHeader={`Kargo Report`}
            printPeriode={`${filterPage.year}`}
            repType={`Kargo Report`}
            title={"Kargo (Tons)"}
            dataSource={dataTable}
            data={getOptions(dataset, getLenghtData, getPersen, fontSize)}
        />
    );
}
