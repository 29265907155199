import React, { Fragment } from "react";
import MainChart from "../../../../../../components/Chart/MainChartV2";
import useWindowDimensions, {
    useFontSizeV2,
} from "../../../../../../hooks/useDimension";
import { Radio, Select } from "antd";
import { createUseStyles } from "react-jss";
import { useDispatch } from "react-redux";
import NotFoundData from "../../../../../../components/NotFound";

const useStyles = createUseStyles({
    Container: {
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",
    },
    buttonTransparent: {
        borderRadius: 4,
        border: "1px solid #7a6ea5",
        color: "gray",
        fontSize: ({ fontSize }) => fontSize.xxs,
        "&:hover": {
            background: "#dee7fe",
            border: "1px solid #7a6ea5",
            fontWeight: "bold", // Change the background color when the button is clicked
            color: "#7a6ea5", // Change the text color when the button is clicked
            // Add other styles as needed
        },
        "&:focus": {
            background: "#dee7fe",
            border: "1px solid #7a6ea5",
            fontWeight: "bold", // Change the background color when the button is clicked
            color: "#7a6ea5", // Change the text color when the button is clicked
            // Add other styles as needed
        },
    },
    radioStyle: {
        "& .ant-radio-button-wrapper": {
            marginRight: "4px",
            borderRadius: "6px",
            borderLeftWidth: 1.02,
            fontSize: ({ fontSize }) => fontSize.xxs,
            fontWeight: "bold",
        },
        "& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before":
            {
                display: "none",
            },
        "& .ant-radio-button-wrapper:not(:first-child)::before": {
            display: "none",
        },
    },
    selectStyle: {
        width: "100%",
        "& .ant-select-selector": {
            borderRadius: "8px !important",
            height: ({ fontSize, height }) =>
                height > 720
                    ? `${fontSize.lg * 1.4}px !important`
                    : `${fontSize.lg * 2}px !important`,
        },
        "& .ant-select-selection-placeholder": {
            fontSize: ({ fontSize }) => `${fontSize.sm - 2}px`,
            lineHeight: ({ fontSize }) => `${fontSize.xl2 - 8}px !important`,
            // paddingTop: ({ fontSize }) => `-5px !important`,
        },
        "& .ant-select-selection-search-input": {
            height: "100% !important",
        },
    },
    customDatePicker: {
        "& .ant-picker-suffix": {
            right: 8, // Adjust the right position as needed
            left: "auto",
        },
    },
});

const DashboardChart = ({
    title,
    view,
    direct,
    setDirect,
    data,
    isOtherComponent,
    isFilterComponent,
    isTotal,
    setView,
    isDaily = true,
    optionSelect,
    setLabelAirport,
    loading,
    labelAirport,
    OnClick,
    options,
    key,
    titleSelected,
    isDetail,
    dataSource,
    repType,
    printHeader,
    optDirect,
    more,
    chartPanelRender,
    printPeriode,
}) => {
    const fontSize = useFontSizeV2();
    const { height } = useWindowDimensions();
    const classes = useStyles({ fontSize, height });
    const dispatch = useDispatch();

    const OtherComponent = () => {
        return (
            <Fragment>
                <div
                    className={classes.Container}
                    style={{
                        padding: "0px 10px",
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        marginTop: 2,
                    }}
                >
                    <Radio.Group
                        size="small"
                        options={optDirect}
                        onChange={({ target: { value } }) => {
                            if (direct === value) {
                                setDirect(0);
                            } else {
                                setDirect(value);
                            }
                        }}
                        value={direct || 0}
                        optionType="button"
                        className={classes.radioStyle}
                    />
                </div>
                {!!optionSelect && (
                    <div
                        style={{
                            padding: "0px 10px",
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                        }}
                    >
                        <Select
                            className={classes.selectStyle}
                            placeholder="Pilih Maskapai"
                            style={{ width: "100%" }}
                            onChange={(e) => {
                                const filter = optionSelect?.filter(
                                    (item) => e == item.label
                                );
                                setLabelAirport({
                                    value: e,
                                    label: filter[0]?.name,
                                });
                            }}
                            value={labelAirport?.value}
                            options={optionSelect}
                        />
                    </div>
                )}
            </Fragment>
        );
    };

    if(!data || !dataSource || data?.length === 0 || dataSource?.length === 0){
        return <NotFoundData />
    }

    return (
        <MainChart
            printHeader={printHeader}
            printPeriode={printPeriode}
            repType={repType}
            dataSource={dataSource}
            titleSelected={titleSelected}
            isDetail={isDetail}
            key={key}
            onClickItemChart={OnClick}
            loading={loading}
            setValueFilter={setView}
            valueFilter={view}
            isTotal={isTotal}
            title={<Fragment>{title}</Fragment>}
            option={data}
            view={view}
            filterComp={
                isFilterComponent && isDaily
                    ? options
                    : isFilterComponent &&
                      options?.filter((item) => item.label !== "Hari")
            }
            onChange={(val) => console.log(val)}
            fullscreen
            height={"90%"}
            otherComponent={isOtherComponent ? OtherComponent : () => {}}
            more={more}
            chartPanelRender={chartPanelRender}
        />
    );
};

export default DashboardChart;
