import React from "react";
import { useSelector } from "react-redux";
import { useDashboardChart } from "../../../../../../../../hooks/useDashboardChart";
import PanelChartSHC from "./SHCChart";

const CargoSHCByCargo = () => {
  const { filterPage } = useSelector((item) => item.apps);
  const [data, loading] = useDashboardChart({
    ...filterPage,
    type: "shc",
    doSend: true,
    routes: "/cargov2",
  });
  return <PanelChartSHC datav2={data} loadingv2={loading} />;
};

export default CargoSHCByCargo;
