import { tooltipFormater } from "../../Chart/tooltipFormater";
import { buildDataTable } from "../../../../../helper/buildDataTable";

const onSort = (datas = [], sort) => {
    return datas.sort((a, b) => {
        if (sort === "down") {
            return a.totalOtp - b.totalOtp;
        } else {
            return b.totalOtp - a.totalOtp;
        }
    });
};

const getDataByAirport = (dataSource, sort) => {
    if (!dataSource) {
        return [[], [], []];
    }
    let result = {};

    for (let iii = 0; iii < dataSource.length; iii++) {
        const { code, otp, ...rest } = dataSource[iii];
        const by = code || "-";

        if (!result[by]) {
            result[by] = {
                totalOtp: otp,
            };
        } else {
            let sumOtp = (result[by].totalOtp += otp);
            result[by] = {
                totalOtp: sumOtp,
            };
        }
        if (!result[by]?.originObj) {
            result[by] = {
                ...result[by],
                originObj: { code, ...rest },
            };
        }
    }

    let set = [];
    let dataTable = [];
    for (const key in result) {
        if (Object.hasOwnProperty.call(result, key)) {
            const { totalOtp, originObj } = result[key];
            set.push([key, totalOtp, originObj]);
            dataTable.push({
                totalOtp,
                key: `${key} - ${originObj.name}`,
                ...originObj,
            });
        }
    }

    const dataset = [
        {
            dimensions: ["label", "otp", "originObj"],
            source: set.sort((a, b) => {
                const [, aOtp] = a;
                const [, bOtp] = b;
                if (sort === "down") {
                    return bOtp - aOtp;
                } else {
                    return aOtp - bOtp;
                }
            }),
        },
        {
            transform: {
                type: "sort",
                config: {
                    dimension: "otp",
                    order: sort === "down" ? "asc" : "dsc",
                },
            },
        },
    ];
    return [
        dataset,
        buildDataTable(onSort(dataTable, sort), [
            {
                title: "Bandara",
                type: "string",
                name: "key",
                align: "left",
            },
            {
                title: "Total OTP (%)",
                type: "number",
                name: "totalOtp",
                align: "right",
            },
        ]),
    ];
};

const renderTooltip = (params) => {
    const { data: domData } = params[0];
    return tooltipFormater({
        title: {
            label: `${domData[2]["code"]} - ${domData[2]["name"]}`,
        },
        params,
    });
};

export default {
    getDataByAirport,
    renderTooltip,
};
