import React from "react";
import { useSelector } from "react-redux";
import KargoChart from "./KargoChart";
import AirlineCargo from "./Airline/Cargo";
import CargoInternationalChart from "./Cargo/International";
import PaxInternational from "./Pax/International";
import AirportFlightTrend from "./Airport/FlightTrend";
import FlightTrendWithRoute from "./Flight/TrendWithRoute";
import { useQueryUrl } from "../../../../../../hooks/useQueryUrl";

const SecondCharts = () => {
  const query = useQueryUrl();
  const {
    dashboardState: { summaryCard },
  } = useSelector((item) => item.apps);

  return getSummaryCard(summaryCard);
};

const getSummaryCard = (summary) => {
  switch (summary) {
    case "cargo":
      return <CargoInternationalChart />;
    case "airline":
      return <AirlineCargo />;
    case "airport":
      return <AirportFlightTrend />;
    case "flight":
      return <FlightTrendWithRoute />;
    case "pax":
      return <PaxInternational />;
    default:
      return <KargoChart />;
  }
};

export default SecondCharts;
