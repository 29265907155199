import React, { useState } from 'react';
import Tabs from '../../tabs';
import Detail from './pages/detail';
export default ({data, direction}) => {
    const dir=!direction?(data?.is_arrival?'arrival':'departure'):direction;
    return (
        <div style={{display:'flex', flexDirection:'column'}}>
            <Tabs 
                selTab={0}
                tabs={[
                    {
                        id:0,
                        caption:'detail',
                        href:`/flight/schedule/${dir}/${data._id}`,
                    },
                    {
                        id:1,
                        caption:'pnr',
                        href:`/flight/schedule/${dir}/pnr/${data._id}`,
                    },
                    {
                        id:2, 
                        caption:'passengers',
                        href:`/flight/schedule/${dir}/passengers/${data._id}`,
                    },
                    {
                        id:3, 
                        caption:'cargo',
                        href:`/flight/schedule/${dir}/cargo/${data._id}`,
                    }
                ]}
            />
            <Detail data={data} />
        </div>
    )
}