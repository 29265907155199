import React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const IcCargo = ({ isDark, width, height }) => {
  const { dashboardState } = useSelector((item) => item.apps);
  const [classDark, classLight] = useMemo(() => {
    return !isDark
      ? [` icon-svg-new fil0-dark`, ` icon-svg-new fil1-dark`]
      : [` icon-svg-new fil0-light`, ` icon-svg-new fil1-light`];
  }, [isDark, dashboardState.summaryCard]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 23544 21839"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2924529390624">
          <path
            className={classDark}
            d="M21564 19748l-7721 2069c-89,29 -185,29 -273,0l-9728 -2606c-77,-21 -144,-61 -196,-114l-1 0 -2858 -2858c-86,-85 -129,-198 -129,-310l0 -10568c0,-610 200,-1176 547,-1629 55,-71 113,-139 174,-204 11,-13 23,-25 35,-36 327,-333 744,-582 1225,-711l10025 -2686c236,-63 463,-95 691,-95 227,0 454,32 691,95l7518 2014c481,129 899,379 1226,713 11,10 22,21 32,33 62,66 120,134 175,206 348,453 547,1018 547,1629l0 12478c0,611 -199,1176 -547,1629 -348,454 -843,793 -1433,951zm-20027 -4156l8874 2378 0 -11372 -8542 -2289c-212,295 -332,658 -332,1052l0 10231zm9083 3342l-8054 -2158 1618 1618 8054 2158 -1618 -1618zm-7825 -15285l8053 2158 10560 -2830c-23,-7 -47,-14 -71,-20l-7518 -2015c-159,-42 -312,-64 -464,-64 -153,0 -305,22 -465,64l-10025 2687c-24,6 -47,13 -70,20zm19539 -12l-11048 2961 -1 11760 2553 2552 7499 -2009c400,-107 732,-334 964,-636 232,-302 365,-683 365,-1097l0 -12478c0,-394 -120,-758 -332,-1053z"
          />
          <path
            className={classLight}
            d="M674 10532c62,-234 303,-373 537,-311 234,63 373,303 310,537l-659 2459c-62,234 -303,373 -537,310 -234,-62 -373,-303 -310,-537l659 -2458zm13471 -2651l7483 -2005c234,-63 475,76 537,310 63,234 -77,474 -310,537l-7710 2066 0 4061 7483 -2005c234,-62 475,77 537,311 63,234 -77,474 -310,537l-7710 2065 0 4062 7483 -2005c234,-63 475,76 537,310 63,234 -77,474 -310,537l-7710 2066 0 1148c0,242 -196,439 -439,439 -242,0 -439,-197 -439,-439l0 -12922c0,-242 197,-439 439,-439 243,0 439,197 439,439l0 927zm-3721 4407c63,-234 303,-373 537,-311 234,63 373,303 311,537l-659 2459c-63,234 -303,373 -537,310 -234,-62 -373,-303 -310,-536l658 -2459z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcCargo;
